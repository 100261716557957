import React, { useEffect, useState } from 'react';
import { string } from 'prop-types';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '../../components';

import css from './SectionHero.module.css';
import { HERO_IMAGES, TIME_TRANSFORM } from '../../heroImages';
import { findOptionsForSelectFilter } from '../../util/search';
import config from '../../config';

const SectionHero = props => {
  const [backgroundIndex, setBackgroundIndex] = useState(0);
  const { rootClassName, className, intl } = props;
  const classes = classNames(rootClassName || css.root, className);
  const categories = findOptionsForSelectFilter('category', config.custom.filters);
  
  useEffect(() => {
    const setBackground = () => {
      setBackgroundIndex(index => index === HERO_IMAGES.length - 1 ? 0 : index + 1)
    };
    const interval = setInterval(setBackground, TIME_TRANSFORM);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const categoryLinks = categories.map((category, index) => (
    <NamedLink
      key={index}
      name="SearchPage"
      to={{
        search: `?pub_category=${category.key}`,
      }}
      className={css.categoryButton}
    >
      <FormattedMessage id={`SectionHero.${category.key}`} />
    </NamedLink>
  ));

  const timedAuctionLink = (
    <NamedLink
      name="SearchPage"
      to={{
        search: `?pub_isTimedAuction=true`,
      }}
      className={css.categoryButton}
    >
      <FormattedMessage id={`TopbarDesktop.timedAuction`} />
    </NamedLink>
  );

  return (
    <div className={classes}>
      <div className={css.heroContentContainer}>
        <div className={css.heroContent}>
          <h1 className={css.heroMainTitle}>
            <FormattedMessage id="SectionHero.title"/>
            <br/>
            <span className={css.titleHighlight}>
              <FormattedMessage id={'SectionHero.titleHighlight'} />
            </span>
          </h1>
          <h2 className={css.heroSubTitle}>
            <FormattedMessage id="SectionHero.subTitle" />
          </h2>
          <NamedLink
            name="SearchPage"
            to={{
              search: '',
            }}
            className={css.heroButton}
          >
            <FormattedMessage id="SectionHero.browseButton" />
          </NamedLink>
          <div className={css.categories}>
            {categoryLinks}
            {timedAuctionLink}
          </div>
        </div>
        <div className={css.heroImage} style={{ backgroundImage: `url(${HERO_IMAGES[backgroundIndex]}` }} />
      </div>
    </div>
  );
};

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
};

export default injectIntl(SectionHero);
