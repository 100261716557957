import React, { Fragment, useEffect } from 'react';
import { array, func } from 'prop-types';
import * as validators from '../../../util/validators';
import { FieldTextInput, ValidationError, IconSpinner } from '../../../components';
import { intlShape, FormattedMessage, FormattedHTMLMessage } from '../../../util/reactIntl';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import RemoveFieldButton from './RemoveFieldButton';
import { useDispatch, useSelector } from 'react-redux';
import { requestRecordImageUpload, requestDocumentRemove, removeRecordImage } from '../../../containers/EditConsignListingPage/EditConsignListingPage.duck';

import css from '../EditListingConsignForm.module.css';

const ACCEPT_IMAGES = 'image/*';
const MAX_FIELD_QTY = 5;

const FieldSetFeaturedRecords = props => {
  const { 
    intl, 
    form, 
    recordImages, 
    featuredRecords, 
    saveDataToSessionStorage, 
  } = props;
  const { push } = form.mutators;
  const dispatch = useDispatch();
  const uploadRecordImageInProgress = useSelector(state => state.EditConsignListingPage.uploadRecordImageInProgress);
  const linkRequiredMessage = intl.formatMessage({
    id: 'EditListingConsignForm.linkRequired',
  });

  const linkValidationMessage = intl.formatMessage({
    id: 'EditListingConsignForm.linkValidation',
  });

  const imageRequiredMessage = intl.formatMessage({
    id: 'EditListingConsignForm.imageRequiredMessage',
  });

  const linkRequired = validators.required(linkRequiredMessage);
  const linkValidation = validators.validDiscogsUrl(linkValidationMessage);
  const validDiscogsLinkValidator = validators.composeValidators(linkValidation, linkRequired);
  
  const linkLabel = (
    <span>
      <FormattedMessage id='EditListingConsignForm.featuredRecordsLinkLabel' />
      <span className={css.optionalLabel}><FormattedMessage id='EditListingConsignForm.optionalLabel' /></span>
    </span>
  );

  const imageLabel = intl.formatMessage({
    id: 'EditListingConsignForm.featuredRecordsImageLabel'
  })

  const chooseImageText = (
    <span className={css.chooseImageText}>
      <span className={css.chooseImage}>
        <FormattedMessage id='EditListingPhotosForm.chooseSquareImage' />
      </span>
      <span className={css.featuredImageTypes}>
        <FormattedHTMLMessage id='EditListingPhotosForm.featuredImageTypes' />
      </span>
    </span>
  );
  
  const loadingSpinner = uploadRecordImageInProgress && 
    (<div className={css.loadingScreen}>
        <div className={css.loader}>
          <IconSpinner/>
        </div>
      </div>
    );

  const canAddFeaturedRecordField = (length, currentIndex) => {
    return length < MAX_FIELD_QTY && length - currentIndex === 1;
  }
  
  useEffect(() => {
    if (uploadRecordImageInProgress)
      document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'unset';
  }, [uploadRecordImageInProgress]);

  return (
    <div className={css.sectionFeaturedRecord}>
      <h2>
        <FormattedMessage id='EditListingConsignForm.featuredRecordsTitle' />
      </h2>

      {loadingSpinner}

      <FieldArray name="featuredRecords" validate={validators.composeValidators(validators.nonEmptyArray(imageRequiredMessage))}>
        {(fieldProps) => {
            const { fields } = fieldProps;
            return fields.map((name, index) => {
              return (
                <div className={css.recordField} key={index}>
                  <div className={css.recordImageField}>
                    <label>
                      <FormattedMessage id='EditListingConsignForm.recordImageLabel' />
                      <span className={css.optionalLabel}><FormattedMessage id='EditListingConsignForm.optionalLabel' /></span>
                    </label>

                    {recordImages[index] &&
                      <div className={css.featuredImageField}>
                        <img src={recordImages[index]} alt={`image${index}`} />
                        <RemoveFieldButton 
                          className={css.removeRecordImageButton} 
                          onClick={() => {
                            dispatch(removeRecordImage(index));
                            dispatch(requestDocumentRemove(recordImages[index]));
                          }}
                          hasText={false} 
                        />
                      </div>
                    }

                    {!recordImages[index] &&
                      (<Field
                        id={`${name}.image`}
                        name={`${name}.image`}
                        accept={ACCEPT_IMAGES}
                        form={null}
                        parse={x => x}
                        label={chooseImageText}
                        type="file"
                        disabled={uploadRecordImageInProgress}
                      >
                        {fieldprops => {
                          const { accept, input, label, disabled: fieldDisabled } = fieldprops;
                          const { name, type } = input;
                          const onChange = e => {
                            const file = e.target.files[0];
                            if (file) {
                              dispatch(requestRecordImageUpload(file, index))
                              form.blur(`featuredRecords[${index}].image`);
                              saveDataToSessionStorage();
                            }
                          };
                          const inputProps = { accept, id: name, name, onChange, type };
                          return (
                            <div className={css.addSquareImageWrapper}>
                              <div className={css.aspectRatioSquareWrapper}>
                                {fieldDisabled ? null : (
                                  <input {...inputProps} className={css.addImageInput} />
                                )}
                                <label htmlFor={name} className={css.addImage}>
                                  {label}
                                </label>
                              </div>
                            </div>
                          );
                        }}
                      </Field>)
                    }
                    
                    { featuredRecords[index].url &&
                      <Field
                        component={props => {
                          const { input, meta } = props;
                          return (
                            <div className={css.imageRequiredWrapper}>
                              <input {...input} />
                              <ValidationError fieldMeta={{...meta, touched: true}} />
                            </div>
                          );
                        }}
                        name={`recordImages[${index}]`}
                        type="hidden"
                        validate={validators.composeValidators(validators.required(imageRequiredMessage))}
                      />
                    }
                  </div>
                  <FieldTextInput
                    id={`${name}.url`}
                    name={`${name}.url`}
                    type="text"
                    className={css.featuredHyperlink}
                    label={linkLabel}
                    validate={recordImages[index] ? validDiscogsLinkValidator : null}
                    onChange={(e) => {
                      form.change(`featuredRecords[${index}].url`, e.target.value);
                      if (e.target.value && canAddFeaturedRecordField(fields.length, index)) {
                        push("featuredRecords", {});
                      }
                    }}
                  />
                  {fields.length > 1 &&
                    (<RemoveFieldButton 
                      className={css.removeFeaturedRecordButton} 
                      onClick={() => {
                        fields.remove(index);
                        if (recordImages[index]) {
                          dispatch(requestDocumentRemove(recordImages[index]));
                          recordImages.splice(index, 1);
                        }
                      }} 
                    />)
                  }
                </div>
              )
            })
          }
        }
      </FieldArray>
    </div>
  );
}

FieldSetFeaturedRecords.defaultProps = {
  featuredRecords: [{}],
  recordImages: []
}

FieldSetFeaturedRecords.propTypes = {
  intl: intlShape.isRequired,
  featuredRecords: array,
  saveDataToSessionStorage: func.isRequired
};

export default FieldSetFeaturedRecords;