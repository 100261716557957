import React, { Fragment } from 'react';
import { array, object, bool } from 'prop-types';
import * as validators from '../../../util/validators';
import { intlShape, FormattedMessage } from '../../../util/reactIntl';
import { YES } from '../../../util/types';
import { FieldRadioButtonGroup, FieldCurrencyInput } from '../../../components';
import config from '../../../config';
import { types as sdkTypes } from '../../../util/sdkLoader';
import { formatMoney, createMoney } from '../../../util/currency';

import css from '../EditListingConsignForm.module.css';

const { Money } = sdkTypes;

const MAX = 'max', MIN = 'min';

const FieldSetPriceInformation = props => {
  const { 
    intl, 
    acceptOfferOptions, 
    acceptBuyItNowOptions, 
    highestOfferOptions, 
    askingPriceOptions, 
    pricing,
    isPricingChoiceInvalid,
    estimatePriceOptions
  }  = props;

  const { minEstPrice, maxEstPrice, acceptOffer, askingPrice } = pricing;

  const minPrice = new Money(config.listingMinimumPriceSubUnits, config.currency);
  const minPriceRequired = validators.moneySubUnitAmountAtLeast(
    intl.formatMessage(
      {
        id: 'EditListingPricingForm.priceTooLow',
      },
      {
        minPrice: formatMoney(intl, minPrice),
      }
    ),
    config.listingMinimumPriceSubUnits
  );

  // Specific labels and placeholders for this section
  const askingPriceLabel = intl.formatMessage({
    id: 'EditListingPricingForm.askingPriceLabel',
  });
  const highestOfferLabel = intl.formatMessage({
    id: 'EditListingPricingForm.highestOfferLabel',
  });
  const acceptOfferLabel = intl.formatMessage({
    id: 'EditListingPricingForm.acceptOfferLabel',
  });
  const acceptBuyItNowLabel = intl.formatMessage({
    id: 'EditListingPricingForm.acceptBuyItNowLabel',
  });
  const priceLabel = intl.formatMessage({
    id: 'EditListingPricingForm.priceLabel',
  });
  const priceRequired = validators.required(
    intl.formatMessage({
      id: 'EditListingPricingForm.priceRequired',
    })
  );

  const priceValidators = config.listingMinimumPriceSubUnits
    ? validators.composeValidators(priceRequired, minPriceRequired)
    : priceRequired;

  // Custom error handlers to display the errors of RadioButtonGroup
  const fieldRequired = validators.required(
    intl.formatMessage({
      id: 'EditListingPricingForm.fieldRequired',
    })
  );

  // Estimate price input
  const estPriceLabel = intl.formatMessage({
    id: 'EditListingPricingForm.estPriceLabel'
  });
  const minEstPriceLabel = intl.formatMessage({
    id: 'EditListingPricingForm.minEstPriceLabel'
  });
  const maxEstPriceLabel = intl.formatMessage({
    id: 'EditListingPricingForm.maxEstPriceLabel'
  });

  const minEstPriceMoney = minEstPrice && createMoney(minEstPrice);
  const maxEstPriceMoney = maxEstPrice && createMoney(maxEstPrice);
  const minEstPriceRequired = maxEstPriceMoney && validators.moneySubUnitAmountAtMost(
    intl.formatMessage(
      { id: 'EditListingPricingForm.estPriceTooHigh'},
      { maxPrice: formatMoney(intl, maxEstPriceMoney)}
    ),
    maxEstPriceMoney.amount
  );
  const maxEstPriceRequired = minEstPriceMoney && validators.moneySubUnitAmountAtLeast(
    intl.formatMessage(
      { id: 'EditListingPricingForm.estPriceTooLow' },
      { minPrice: formatMoney(intl, minEstPriceMoney)}
    ),
    minEstPriceMoney.amount
  );
  const showEstimatePriceLabel = intl.formatMessage({
    id: 'EditListingPricingForm.showEstimatePriceLabel'
  });

  return (
    <Fragment>
      <h2>
        <FormattedMessage id="EditListingConsignForm.priceTitle" />
      </h2>
      <label>{estPriceLabel}</label>
      <div className={css.estPriceInput}>
        <FieldCurrencyInput 
          id="pricing.minEstPrice"
          name="pricing.minEstPrice"
          label={minEstPriceLabel}
          className={css.estPriceInputField}
          currencyConfig={config.currencyConfig}
          validate={minEstPriceRequired}
          key={maxEstPrice?.amount + MAX}
        />
        <FieldCurrencyInput 
          id="pricing.maxEstPrice"
          name="pricing.maxEstPrice"
          label={maxEstPriceLabel}
          className={css.estPriceInputField}
          currencyConfig={config.currencyConfig}
          validate={maxEstPriceRequired}
          key={minEstPrice?.amount + MIN}
        />
      </div>
      <FieldCurrencyInput
        id="price"
        name="price"
        className={css.priceInput}
        label={priceLabel}
        currencyConfig={config.currencyConfig}
        validate={priceValidators}
      />
      <FieldRadioButtonGroup
        id="pricing.askingPrice"
        name="pricing.askingPrice"
        label={askingPriceLabel}
        twoColumns
        options={askingPriceOptions}
        intl={intl}
        validate={fieldRequired}
      />
      <FieldRadioButtonGroup
        id="pricing.acceptOffer"
        name="pricing.acceptOffer"
        label={acceptOfferLabel}
        twoColumns
        options={acceptOfferOptions}
        intl={intl}
        validate={fieldRequired}
      />
      {acceptOffer === YES && (
        <FieldRadioButtonGroup
          id="pricing.displayHighestOffer"
          name="pricing.displayHighestOffer"
          label={highestOfferLabel}
          twoColumns
          options={highestOfferOptions}
          intl={intl}
          validate={fieldRequired}
        />
      )}
      {askingPrice === YES && (
        <FieldRadioButtonGroup
          id="pricing.acceptBuyItNow"
          name="pricing.acceptBuyItNow"
          label={acceptBuyItNowLabel}
          twoColumns
          options={acceptBuyItNowOptions}
          intl={intl}
          validate={fieldRequired}
        />
      )}
      {isPricingChoiceInvalid && (
        <p className={css.error}>
          <FormattedMessage id="EditListingPricingForm.optionYesRequired" />
        </p>
      )}
      <FieldRadioButtonGroup
        id="pricing.showEstimatePrice"
        name="pricing.showEstimatePrice"
        label={showEstimatePriceLabel}
        twoColumns
        options={estimatePriceOptions}
        intl={intl}
        validate={fieldRequired}
      />
    </Fragment>
  );
}

FieldSetPriceInformation.defaultProps = {
  acceptOfferOptions: [],
  acceptBuyItNowOptions: [],
  askingPriceOptions: [],
  highestOfferOptions: [],
  pricing: {}
}

FieldSetPriceInformation.propTypes = {
  intl: intlShape.isRequired,
  values: object,
  acceptOfferOptions: array,
  acceptBuyItNowOptions: array,
  askingPriceOptions: array,
  highestOfferOptions: array,
  pricing: object,
  isPricingChoiceInvalid: bool
};

export default FieldSetPriceInformation;