import { denormalisedResponseEntities } from "../util/data";
import * as log from '../util/log';
import { storableError } from "../util/errors";
import { addMarketplaceEntities } from "./marketplaceData.duck";

// ================ Action types ================ //

export const UPDATE_LISTING_REQUEST =
  'app/user/UPDATE_LISTING_REQUEST';
export const UPDATE_LISTING_SUCCESS =
  'app/user/UPDATE_LISTING_SUCCESS';
export const UPDATE_LISTING_ERROR =
  'app/user/UPDATE_LISTING_ERROR';

const initialState = {
  listing: null,
  updateListingInProgress: false,
  updateListingError: null
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_LISTING_REQUEST:
      return { ...state, updateCurrentListingError: null, updateListingInProgress: true };
    case UPDATE_LISTING_SUCCESS:
      return { ...state, updateListingInProgress: false, listing: payload };
    case UPDATE_LISTING_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, updateListingInProgress: false, updateCurrentListingError: payload };
    default:
      return state;
  }
};

// ================ Action creators ================ //

export const updateListingRequest = () => ({ type: UPDATE_LISTING_REQUEST });
export const updateListingSuccess = (payload) => ({ type: UPDATE_LISTING_SUCCESS, payload });
export const updateListingError = (payload) => ({ type: UPDATE_LISTING_ERROR, payload });

export const updateListing = (data) => (dispatch, getState, sdk) => {
  dispatch(updateListingRequest());

  return sdk.ownListings
    .update(data)
    .then(res => {
      const listing = denormalisedResponseEntities(res);
      dispatch(addMarketplaceEntities(res));
      dispatch(updateListingSuccess(listing)); 
    })
    .catch(e => {
      log.error(e, 'update-listing-failed', { listingData: data });
      return dispatch(updateListingError(storableError(e)));
    });
}