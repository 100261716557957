import React from 'react';
import { string, array, bool, func } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import ListingItem from './ListingItem';

import css from './ListingTable.module.css';

export const ListingTableComponent = props => {
  const { className, rootClassName, intl, listings, headers, onRemoveFromWatchlist } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <table className={classes}>
      <thead>
        <tr className={css.itemHeaders}>
          {headers.map((header, index) => (
            <th key={index}>
              <FormattedMessage id={`ListingTable.${header}`} />
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {listings.map(l => (
          <ListingItem key={l.id.uuid} listing={l} onRemoveFromWatchlist={onRemoveFromWatchlist}/>
        ))}
      </tbody>
    </table>
  )
}

ListingTableComponent.defaultProps = {
  className: null,
  rootClassName: null,
  headers: ['item', 'askingPrice', 'numBids', 'highestBid', 'numWatchers', 'view', 'remove']
};

ListingTableComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listings: array.isRequired,
  queryInProgress: bool.isRequired,
  onRemoveFromWatchlist: func.isRequired
};

export default injectIntl(ListingTableComponent);