import React from 'react';
import { ReadOnlyRichTextComponent } from '../../../components';
import { FormattedMessage } from '../../../util/reactIntl';

import css from '../ListingPage.module.css';

const SectionDescriptionMaybe = props => {
  const { description } = props;

  return description ? (
    <div className={css.otherSection}>
      <h2 className={css.sectionTitle}>
        <FormattedMessage id="ListingPage.descriptionTitle" />
      </h2>
      <div className={css.description}>
        <ReadOnlyRichTextComponent
          id="description"
          readOnlyValue={description}
        />
      </div>
    </div>
  ) : null;
};

export default SectionDescriptionMaybe;
