import React from 'react'
import css from './TimedAuctionCheckoutPage.module.css';

const AgreementParagraph = (props) => {

  const { intl } = props;

  const heading = intl.formatMessage({ id: 'TimedAuctionCheckoutPage.agreementHeading' });

  const sectionOneTitle = intl.formatMessage({ id: 'TimedAuctionCheckoutPage.agreementSectionOneTitle' });
  const sectionTwoTitle = intl.formatMessage({ id: 'TimedAuctionCheckoutPage.agreementSectionTwoTitle' });
  const sectionThreeTitle = intl.formatMessage({ id: 'TimedAuctionCheckoutPage.agreementSectionThreeTitle' });
  const sectionFourTitle = intl.formatMessage({ id: 'TimedAuctionCheckoutPage.agreementSectionFourTitle' });
  
  const sectionOne = intl.formatMessage({ id: 'TimedAuctionCheckoutPage.agreementSectionOne' });
  const sectionTwo = intl.formatMessage({ id: 'TimedAuctionCheckoutPage.agreementSectionTwo' });
  const sectionThree = intl.formatMessage({ id: 'TimedAuctionCheckoutPage.agreementSectionThree' });
  const sectionFour = intl.formatMessage({ id: 'TimedAuctionCheckoutPage.agreementSectionFour' });;
  
  const sectionFourMeasurements = Array(6).fill(0).map((element, index) => {
    return <li className={css.measurement} key={index}>{intl.formatMessage({ id: `TimedAuctionCheckoutPage.agreementSectionFourMeasurement${index}` })}</li>;
  })

  return (
    <div className={css.agreement}>
      <p className={css.leadingSentence}>{heading}</p>
      <h2 className={css.title}>{sectionOneTitle}</h2>
      <p className={css.section}>{sectionOne}</p>
      <h2 className={css.title}>{sectionTwoTitle}</h2>
      <p className={css.section}>{sectionTwo}</p>
      <h2 className={css.title}>{sectionThreeTitle}</h2>
      <p className={css.section}>{sectionThree}</p>
      <h2 className={css.title}>{sectionFourTitle}</h2>
      <p className={css.section}>
        {sectionFour}
      </p>
      <ul>
        {sectionFourMeasurements}
      </ul>
    </div>
  )
}

export default AgreementParagraph