import React from 'react';
import { compose } from 'redux';
import { TradingForm } from '../../../forms';
import { withRouter } from 'react-router-dom';
import { intlShape } from '../../../util/reactIntl';
import classnames from 'classnames';
import { string, bool } from 'prop-types';

import css from '../ListingPage.module.css';

const SectionTrading = props => {
  const { 
    rootClassName, 
    onSubmit, 
    price, 
    pricing, 
    numberOfOffers, 
    highestOffer, 
    className, 
    intl, 
    isTradingDisabled,
    isBuyItNowAccepted,
    isOfferAccepted,
    currentUser,
    currentHighestBidder
  } = props;
  const classes = classnames(rootClassName || css.root, className);
  
  return (
    <TradingForm
      classes={classes}
      onSubmit={onSubmit}
      price={price}
      pricing={pricing}
      numberOfOffers={numberOfOffers}
      highestOffer={highestOffer}
      intl={intl}
      isTradingDisabled={isTradingDisabled}
      isBuyItNowAccepted={isBuyItNowAccepted}
      isOfferAccepted={isOfferAccepted}
      currentUser={currentUser}
      currentHighestBidder={currentHighestBidder}
    />
  );
};

SectionTrading.propTypes = {
  className: string,
  isTradingDisabled: bool,
  isOfferAccepted: bool,
  isBuyItNowAccepted: bool,
  intl: intlShape
};

export default compose(withRouter)(SectionTrading);
