import React from 'react';
import classnames from 'classnames';
import documentIcon from '../../../assets/documentIcon.png';
import { ExternalLink } from '../../../components';
import moment from 'moment-timezone';
import SectionWrapper from './SectionWrapper';

import css from '../ListingPage.module.css';

const SectionServiceMaybe = props => {
  const { className, rootClassName, service, intl } = props;
  const classes = classnames(rootClassName || className);

  const technician = service && service.technician;
  const lastService = service && 
                      service.dateTime.date 
                        ? moment(service.dateTime.date).format("MM/DD/YYYY")
                        : service.dateTime;
  const documents = service && service.documents;
  
  const technicianLabel = intl.formatMessage({
    id: "ListingPage.serviceTechnicialLabel"
  });

  const lastServiceLabel = intl.formatMessage({
    id: "ListingPage.lastServiceLabel"
  });

  const serviceRecordLabel = intl.formatMessage({
    id: "ListingPage.serviceRecordLabel"
  });

  const title = intl.formatMessage({ id: "ListingPage.serviceTitle" });

  const serviceDocs = 
    documents.length > 0 
    && (
      <div className={css.list}> 
          {documents.map((doc, index) => {
            return (
              <div key={index} className={css.listItem}>
                <ExternalLink href={doc.url} className={css.url}>
                  <img src={documentIcon} alt="documentIcon"/>
                  <span className={css.docName}>{`${index + 1}. ${serviceRecordLabel}`}</span>
                </ExternalLink>
              </div>
            )
          })}
        </div>
    )
  return (
    <SectionWrapper title={title} >
      <div className={css.sectionDetails}>
        {technician &&
        <>
          <span className={css.techTitle}>{lastServiceLabel}</span>
          <span className={css.techValue}>{lastService}</span>
          <span className={css.techTitle}>{technicianLabel}</span>
          <span className={css.techValue}>{technician}</span>
        </>}
      </div>
      {serviceDocs}
    </SectionWrapper>
  );
};

export default SectionServiceMaybe;
