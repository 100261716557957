import React, { Fragment, useEffect } from 'react';
import { array, func, bool } from 'prop-types';
import { nonEmptyArray, composeValidators, validYoutubeUrl } from '../../../util/validators';
import { intlShape, FormattedMessage, FormattedHTMLMessage } from '../../../util/reactIntl';
import { FieldTextInput, AddImages, ValidationError } from '../../../components';
import { Field } from 'react-final-form';
import RemoveFieldButton from './RemoveFieldButton';
import { FieldArray } from 'react-final-form-arrays';
import { types as sdkTypes } from '../../../util/sdkLoader';

import css from '../EditListingConsignForm.module.css';

const { UUID } = sdkTypes;

const ACCEPT_IMAGES = 'image/*';
const MAX_URL_QTY = 3;

const FieldSetMedia = props => {
  const { intl, images, onRemoveImage, imageUploadRequested, onImageUploadHandler, onUpdateImageOrder, form, saveDataToSessionStorage } = props;

  // Methods of Array Mutators to add/remove a field from the array
  const { push } = form.mutators;

  const chooseImageText = (
    <span className={css.chooseImageText}>
      <span className={css.chooseImage}>
        <FormattedMessage id='EditListingPhotosForm.chooseImage' />
      </span>
      <span className={css.imageTypes}>
        <FormattedHTMLMessage id='EditListingPhotosForm.imageTypes' />
      </span>
    </span>
  );

  const imageRequiredMessage = intl.formatMessage({
    id: 'EditListingPhotosForm.imageRequired',
  });

  const linkYoutubeInvalidUrlMessage = intl.formatMessage({
    id: 'EditListingHyperlinkForm.youtubelinkInvalidUrl',
  });

  const linkYoutubeValidators = composeValidators(validYoutubeUrl(linkYoutubeInvalidUrlMessage));

  const linkYoutubeLabel = (
    <span>
      <FormattedMessage id='EditListingConsignForm.linkYoutubeLabel' />
      <span className={css.optionalLabel}><FormattedMessage id='EditListingConsignForm.optionalLabel' /></span>
    </span>
  )

  const canAddYoutubeLinkField = (length, currentIndex) => {
    return length < MAX_URL_QTY && length - currentIndex === 1;
  }

  return (
    <Fragment>
      <h2>
        <FormattedMessage id='EditListingConsignForm.mediaTitle' />
      </h2>
      <AddImages
        className={css.imagesField}
        images={images}
        thumbnailClassName={css.thumbnail}
        savedImageAltText={intl.formatMessage({
          id: 'EditListingPhotosForm.savedImageAltText',
        })}
        onRemoveImage={onRemoveImage}
        onUpdateImageOrder={onUpdateImageOrder}
      >
        <Field
          id="addImage"
          name="addImage"
          accept={ACCEPT_IMAGES}
          form={null}
          label={chooseImageText}
          type="file"
          disabled={imageUploadRequested}
        >
          {fieldprops => {
            const { accept, input, label, disabled: fieldDisabled } = fieldprops;
            const { name, type } = input;

            const onChange = e => {
              const fileList = Object.keys(e.target.files).map((key) => e.target.files[key]);

              fileList.forEach(file => {
                form.change(`addImage`, file);
                form.blur(`addImage`);
                onImageUploadHandler(file);
                saveDataToSessionStorage();
              });

              e.target.value = '';
            };
            const inputProps = { accept, id: name, name, onChange, type };
            return (
              <div className={css.addImageWrapper}>
                <div className={css.aspectRatioWrapper}>
                  {fieldDisabled ? null : (
                    <input {...inputProps} className={css.addImageInput} multiple />
                  )}
                  <label htmlFor={name} className={css.addImage}>
                    {label}
                  </label>
                </div>
              </div>
            );
          }}
        </Field>

        <Field
          component={props => {
            const { input, meta } = props;
            return (
              <div className={css.imageRequiredWrapper}>
                <input {...input} />
                <ValidationError fieldMeta={{ ...meta, touched: true }} />
              </div>
            );
          }}
          name="images"
          type="hidden"
          validate={composeValidators(nonEmptyArray(imageRequiredMessage))}
        />
      </AddImages>

      <FieldArray name="youtubeLinks" >
        {(fieldProps) => {
          const { fields } = fieldProps;
          return fields.map((name, index) => {
            return (
              <div className={css.youtubeField} key={index}>
                <FieldTextInput
                  id={name}
                  name={name}
                  type="text"
                  className={css.hyperlink}
                  label={linkYoutubeLabel}
                  validate={linkYoutubeValidators}
                  onChange={(e) => {
                    form.change(name, e.target.value);
                    if (canAddYoutubeLinkField(fields.length, index) && e.target.value !== '') {
                      // Set to 'undefined' to create an empty value
                      push("youtubeLinks", undefined);
                    }
                  }}
                />
                {fields.length > 1 &&
                  (<RemoveFieldButton
                    className={css.removeTextFieldButton}
                    onClick={() => fields.remove(index)}
                  />)
                }
              </div>
            );
          })
        }
        }
      </FieldArray>
    </Fragment>
  );
}

FieldSetMedia.defaultProps = {
  imageUploadRequested: false
}

FieldSetMedia.propTypes = {
  intl: intlShape.isRequired,
  images: array.isRequired,
  onRemoveImage: func.isRequired,
  imageUploadRequested: bool.isRequired,
  saveDataToSessionStorage: func.isRequired
};

export default FieldSetMedia;