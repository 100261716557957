import React from 'react';
import { IconShareFacebook, IconShareWhatsapp, IconShareTwitter, IconShareEmail } from '../../../components';
import { string } from 'prop-types';

import css from '../ListingPage.module.css';

const SectionShare = props => {
  const { title, listingLink, intl } = props;

  const shareEmailSubject = intl.formatMessage({ id: 'SectionShare.shareEmailSubject' });

  const mailto = `mailto:?subject=${encodeURIComponent(shareEmailSubject)}&body=${encodeURIComponent(title + ' ' + listingLink)}`;

  return (
    <div className={css.sectionShare}>
      <a href={mailto} target="_blank">
        <IconShareEmail />
      </a>
      <a href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(listingLink)}`} target="_blank">
        <IconShareFacebook />
      </a>
      <a href={`https://wa.me/?text=${encodeURIComponent(listingLink)}`} target="_blank">
        <IconShareWhatsapp />
      </a>
      <a href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(listingLink)}`} target="_blank">
        <IconShareTwitter />
      </a>
    </div>
  );
}

SectionShare.propTypes = {
  title: string,
  listingLink: string
};

export default SectionShare;