import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { denormalisedResponseEntities } from '../../util/data';

// ================ Action types ================ //

export const SET_ACTIVE_LISTING = 'app/LandingPage/SET_ACTIVE_LISTING';

export const FETCH_NEWLY_LISTINGS_REQUEST = 'app/LandingPage/FETCH_NEWLY_LISTINGS_REQUEST';
export const FETCH_NEWLY_LISTINGS_SUCCESS = 'app/LandingPage/FETCH_NEWLY_LISTINGS_SUCCESS';
export const FETCH_NEWLY_LISTINGS_ERROR = 'app/LandingPage/FETCH_NEWLY_LISTINGS_ERROR';

export const FETCH_FEATURED_LISTINGS_REQUEST = 'app/LandingPage/FETCH_FEATURED_LISTINGS_REQUEST';
export const FETCH_FEATURED_LISTINGS_SUCCESS = 'app/LandingPage/FETCH_FEATURED_LISTINGS_SUCCESS';
export const FETCH_FEATURED_LISTINGS_ERROR = 'app/LandingPage/FETCH_FEATURED_LISTINGS_ERROR';

export const FETCH_TIMED_AUCTION_LISTINGS_REQUEST = 'app/LandingPage/FETCH_TIMED_AUCTION_LISTINGS_REQUEST';
export const FETCH_TIMED_AUCTION_LISTINGS_SUCCESS = 'app/LandingPage/FETCH_TIMED_AUCTION_LISTINGS_SUCCESS';
export const FETCH_TIMED_AUCTION_LISTINGS_ERROR = 'app/LandingPage/FETCH_TIMED_AUCTION_LISTINGS_ERROR';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  fetchNewlyListingsInProgress: false,
  fetchNewlyListingsError: null,
  fetchFeaturedListingsInProgress: false,
  fetchFeaturedListingsError: null,
  fetchTimedAuctionListingsInProgress: false,
  fetchTimedAuctionListingsError: null,
  currentNewlyListingIds: [],
  currentFeaturedListingIds: [],
  currentTimedAuctionListingIds: [],
};

const resultIds = data => data.data.map(l => l.id);

const landingPageReducer = (state = initialState, action = {}) => {
  const { type, payload, error } = action;
  switch (type) {
    case SET_ACTIVE_LISTING:
      return {
        ...state,
        activeListingId: payload,
      };

    case FETCH_NEWLY_LISTINGS_REQUEST:
      return {
        ...state,
        fetchNewlyListingsInProgress: true,
        fetchNewlyListingsError: null,
      };
    case FETCH_NEWLY_LISTINGS_SUCCESS:
      return {
        ...state,
        currentNewlyListingIds: resultIds(payload.data),
        fetchNewlyListingsInProgress: false,
        fetchNewlyListingsError: null,
      };
    case FETCH_NEWLY_LISTINGS_ERROR:
      return {
        ...state,
        fetchNewlyListingsInProgress: false,
        fetchNewlyListingsError: error
      };
    case FETCH_FEATURED_LISTINGS_REQUEST:
      return {
        ...state,
        fetchFeaturedListingsInProgress: true,
        fetchFeaturedListingsError: null
      };
    case FETCH_FEATURED_LISTINGS_SUCCESS:
      return {
        ...state,
        currentFeaturedListingIds: resultIds(payload.data),
        fetchFeaturedListingsInProgress: false,
        fetchFeaturedListingsError: null
      };
    case FETCH_FEATURED_LISTINGS_ERROR:
      return {
        ...state,
        fetchFeaturedListingsInProgress: false,
        fetchFeaturedListingsError: error
      };
    case FETCH_TIMED_AUCTION_LISTINGS_REQUEST:
      return {
        ...state,
        fetchTimedAuctionListingsInProgress: true,
        fetchTimedAuctionListingsError: null
      };
    case FETCH_TIMED_AUCTION_LISTINGS_SUCCESS:
      return {
        ...state,
        currentTimedAuctionListingIds: resultIds(payload.data),
        fetchTimedAuctionListingsInProgress: false,
        fetchTimedAuctionListingsError: null
      };
    case FETCH_TIMED_AUCTION_LISTINGS_ERROR:
      return {
        ...state,
        fetchTimedAuctionListingsInProgress: false,
        fetchTimedAuctionListingsError: error
      };
    default:
      return state;
  }
};

export default landingPageReducer;

// ================ Action creators ================ //

export const fetchNewlyListings = () => ({
  type: FETCH_NEWLY_LISTINGS_REQUEST,
});
export const fetchNewlyListingsSuccess = response => ({
  type: FETCH_NEWLY_LISTINGS_SUCCESS,
  payload: { data: response.data },
});
export const fetchNewlyListingsError = e => ({
  type: FETCH_NEWLY_LISTINGS_ERROR,
  error: e,
});

export const fetchFeaturedListings = () => ({
  type: FETCH_FEATURED_LISTINGS_REQUEST,
});
export const fetchFeaturedListingsSuccess = response => ({
  type: FETCH_FEATURED_LISTINGS_SUCCESS,
  payload: { data: response.data },
});
export const fetchFeaturedListingsError = e => ({
  type: FETCH_FEATURED_LISTINGS_ERROR,
  error: e,
});

export const fetchTimedAuctionListings = () => ({
  type: FETCH_TIMED_AUCTION_LISTINGS_REQUEST,
});
export const fetchTimedAuctionListingsSuccess = response => ({
  type: FETCH_TIMED_AUCTION_LISTINGS_SUCCESS,
  payload: { data: response.data },
});
export const fetchTimedAuctionListingsError = e => ({
  type: FETCH_TIMED_AUCTION_LISTINGS_ERROR,
  error: e,
});

export const setActiveListing = listingId => ({
  type: SET_ACTIVE_LISTING,
  payload: listingId,
});

export const queryNewlyListings = params => (dispatch, getState, sdk) => {
  dispatch(fetchNewlyListings());
  return sdk.listings
    .query({ ...params, sort: 'pub_publishTimestamp' })
    .then(res => {
      dispatch(addMarketplaceEntities(res));
      dispatch(fetchNewlyListingsSuccess(res));
    })
    .catch(err => {
      dispatch(fetchNewlyListingsError(storableError(err)))
    })
};

export const queryFeaturedListings = params => (dispatch, getState, sdk) => {
  dispatch(fetchFeaturedListings());
  return sdk.listings
    .query({ ...params, meta_isFeatured: true })
    .then(res => {
      dispatch(addMarketplaceEntities(res));
      dispatch(fetchFeaturedListingsSuccess(res));
    })
    .catch(err => {
      dispatch(fetchFeaturedListingsError(storableError(err)))
    })
};

export const queryTimedAuctionListings = params => (dispatch, getState, sdk) => {
  dispatch(fetchTimedAuctionListings());
  return sdk.listings
    .query({ 
      ...params, 
      pub_isTimedAuction: true, 
      pub_isPendingAutomatedTimedAuction: false, 
      meta_isSold: false, sort: '-pub_sortTimedAuctionEndDate' 
    })
    .then(res => {
      dispatch(addMarketplaceEntities(res));
      dispatch(fetchTimedAuctionListingsSuccess(res));
    })
    .catch(err => {
      dispatch(fetchTimedAuctionListingsError(storableError(err)))
    })
};

export const loadData = () => (dispatch, getState, sdk) => {
  const params = {
    page: 1,
    perPage: 4,
    include: ['author', 'images'],
    'fields.listing': ['title', 'price', 'publicData', 'metadata'],
    'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    'limit.images': 1,
  };
  return Promise.all([
    dispatch(queryNewlyListings(params)),
    dispatch(queryFeaturedListings(params)),
    dispatch(queryTimedAuctionListings(params))
  ])
};
