import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureOwnListing } from '../../util/data';
import { ListingLink } from '..';
import { EditListingDetailsForm, EditListingMicDetailsForm } from '../../forms';
import { findOptionsForSelectFilter } from '../../util/search';
import config from '../../config';

import css from './EditListingDetailsPanel.module.css';

const EditListingDetailsPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { publicData } = currentListing.attributes;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingDetailsPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingDetailsPanel.createListingTitle" />
  );
  const collectionListingWarning = <FormattedMessage id="EditListingPanels.collectionListingWarning" />;
  const conditionOptions = findOptionsForSelectFilter('condition', config.custom.filters);
  const issueOptions = findOptionsForSelectFilter('issue', config.custom.filters);
  const sellingPairOptions = findOptionsForSelectFilter('sellingPair', config.custom.filters);
  const sequentSerialOptions = findOptionsForSelectFilter('sequentSerial', config.custom.filters);
  const originalOptions = findOptionsForSelectFilter('original', config.custom.filters);
  const micPairOptions = findOptionsForSelectFilter('micPair', config.custom.filters);

  const isTypeMicrophones = publicData && publicData.category == 'microphones';
  const isCollection = publicData && publicData.category === 'collection';

  const { itemDetails = {}, micDetails = {} } = publicData;
  const form = !isTypeMicrophones ? (
    <EditListingDetailsForm
      className={css.form}
      publicData={publicData}
      initialValues={{ itemDetails }}
      initialValuesEqual={() => true}
      onSubmit={values => {
        const { itemDetails } = values;
        const { category } = publicData;
        const { brand, model, year, sku } = itemDetails;

        const newKeywords = 
          [brand, model, year, category, sku]
            .filter(keyword => keyword)
            .join(", ");

        const updateValues = {
          publicData: {
            itemDetails,
            micDetails: null,
            keywords: newKeywords
          },
        };
        onSubmit(updateValues);
      }}
      onChange={onChange}
      disabled={disabled}
      ready={ready}
      saveActionMsg={submitButtonText}
      updated={panelUpdated}
      updateInProgress={updateInProgress}
      fetchErrors={errors}
      conditionOptions={conditionOptions}
      issueOptions={issueOptions}
    />
  ) : (
    <EditListingMicDetailsForm
      className={css.form}
      publicData={publicData}
      initialValues={{ micDetails }}
      initialValuesEqual={() => true}
      onSubmit={values => {
        const { micDetails } = values;
        const { category } = publicData;
        const { brandMicPair, modelMicPair, yearMicPair, yearMicPairA, yearMicPairB, skuMicPair } = micDetails;

        const newKeywords = 
          [brandMicPair, modelMicPair, yearMicPair, yearMicPairA, yearMicPairB, category, skuMicPair]
            .filter(keyword => keyword)
            .join(", ");

        const updateValues = {
          publicData: {
            micDetails,
            itemDetails: null,
            keywords: newKeywords
          },
        };
        onSubmit(updateValues);
      }}
      onChange={onChange}
      disabled={disabled}
      ready={ready}
      saveActionMsg={submitButtonText}
      updated={panelUpdated}
      updateInProgress={updateInProgress}
      fetchErrors={errors}
      sellingPairOptions={sellingPairOptions}
      sequentSerialOptions={sequentSerialOptions}
      conditionMicPairOptions={conditionOptions}
      issueMicPairOptions={issueOptions}
      originalOptions={originalOptions}
      micPairOptions={micPairOptions}
    />
  );
  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      {isCollection ? collectionListingWarning : form}
    </div>
  );
};

const { func, object, string, bool } = PropTypes;

EditListingDetailsPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingDetailsPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingDetailsPanel;
