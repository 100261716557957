/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

const YES_NO_OPTIONS = [
  { key: 'yes', label: 'YesNoOptions.yes' },
  { key: 'no', label: 'YesNoOptions.no' },
]

export const filters = [
  // {
  //   id: 'dates',
  //   label: 'Dates',
  //   type: 'BookingDateRangeFilter',
  //   group: 'primary',
  //   // Note: BookingDateRangeFilter is fixed filter,
  //   // you can't change "queryParamNames: ['dates'],"
  //   queryParamNames: ['dates'],
  //   config: {},
  // },
  {
    id: 'price',
    label: 'Price',
    type: 'PriceFilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['price'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 250000,
      step: 1,
    },
  },
  // {
  //   id: 'keyword',
  //   label: 'Keyword',
  //   type: 'KeywordFilter',
  //   group: 'primary',
  //   // Note: KeywordFilter is fixed filter,
  //   // you can't change "queryParamNames: ['keywords'],"
  //   queryParamNames: ['keywords'],
  //   // NOTE: If you are ordering search results by distance
  //   // the keyword search can't be used at the same time.
  //   // You can turn on/off ordering by distance from config.js file.
  //   config: {},
  // },
  {
    id: 'category',
    label: 'Category',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_category'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'memorabilia', label: 'CategoryOptions.memorabilia' },
        { key: 'microphones', label: 'CategoryOptions.microphones' },
        { key: 'studioGear', label: 'CategoryOptions.studioGear' },
        { key: 'instrumentsAmps', label: 'CategoryOptions.instrumentsAmps' },
        { key: 'collection', label: 'CategoryOptions.collection' },
      ],
    },
  },
  {
    id: 'availability',
    label: 'Available/Sold',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['meta_isSold'],
    config: {
      options: [
        { key: 'false', label: 'AvailabilityOptions.available' },
        { key: 'true', label: 'AvailabilityOptions.sold' },
      ],
    },
  },
  {
    id: 'condition',
    label: 'Condition',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_condition'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'new', label: 'ConditionOptions.new' },
        { key: 'excellent', label: 'ConditionOptions.excellent' },
        { key: 'veryGood', label: 'ConditionOptions.veryGood' },
        { key: 'good', label: 'ConditionOptions.good' },
        { key: 'fair', label: 'ConditionOptions.fair' },
      ]
    },
  },
  {
    id: 'issue',
    label: 'Issue',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_issue'],
    config: {
      options: [
        { key: 'vintage', label: 'IssueOptions.vintage' },
        { key: 'reissue', label: 'IssueOptions.reissue' },
        { key: 'notSure', label: 'IssueOptions.notSure' },
      ],
    },
  },
  {
    id: 'sellingPair',
    label: 'SellingPair',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_sellingPair'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: YES_NO_OPTIONS,
    },
  },
  {
    id: 'sequentSerial',
    label: 'SequentSerial',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_sequentSerial'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: YES_NO_OPTIONS,
    },
  },
  {
    id: 'askingPrice',
    label: 'AskingPrice',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_askingPrice'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: YES_NO_OPTIONS,
    },
  },
  {
    id: 'highestOffer',
    label: 'AskingPrice',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_highestOffer'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: YES_NO_OPTIONS,
    },
  },
  {
    id: 'acceptOffer',
    label: 'AcceptOffer',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_acceptOffer'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: YES_NO_OPTIONS,
    },
  },
  {
    id: 'acceptBuyItNow',
    label: 'AcceptBuyItNow',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_acceptBuyItNow'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: YES_NO_OPTIONS,
    },
  },
  {
    id: 'showEstimatePrice',
    label: 'showEstimatePrice',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_showEstimatePrice'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: YES_NO_OPTIONS,
    },
  },
  {
    id: 'original',
    label: 'Original',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_original'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        ...YES_NO_OPTIONS,
        { key: 'notSure', label: 'YesNoOptions.notSure' },
      ],
    },
  },
  {
    id: 'micPair',
    label: 'MicPair',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_micPair'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: YES_NO_OPTIONS,
    },
  },
  {
    id: 'authenticity',
    label: 'Authenticity',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_authenticity'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          key: 'bill_of_sale',
          label: 'AuthenticityOptions.billOfSale',
        },
        {
          key: 'owner_manual',
          label: 'AuthenticityOptions.ownerManual',
        },
        {
          key: 'insurance_docs',
          label: 'AuthenticityOptions.insuranceDocs',
        },
        {
          key: 'letters',
          label: 'AuthenticityOptions.letters',
        },
      ],
    },
  },
  {
    id: 'service',
    label: 'Service',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_service'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          key: 'date_of_last_service',
          label: 'ServiceOptions.dateOfLastService',
        },
        {
          key: 'service_technician',
          label: 'ServiceOptions.serviceTechnician',
        },
        {
          key: 'service_records',
          label: 'ServiceOptions.serviceRecords',
        },
      ],
    },
  },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    { key: 'pub_publishTimestamp', label: 'Newest' },
    { key: '-pub_highestOffer', label: 'Lowest price' },
    { key: 'pub_highestOffer', label: 'Highest price' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    { key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
  ],
};

