import React from 'react';
import { required } from '../../util/validators';
import { FieldSelect } from '../../components';

import css from './EditListingDetailsForm.module.css';

const CustomConditionSelectFieldMaybe = props => {
  const { name, id, condition, conditionMicPairA, conditionMicPairB, intl } = props;
  const conditionLabel = intl.formatMessage({
    id: 'EditListingDetailsForm.conditionLabel',
  });
  return condition ? (
    <FieldSelect
      className={css.condition}
      name={name}
      id={id}
      label={conditionLabel}
    >
      <option disabled value="">
      </option>
      {condition.map(c => (
        <option key={c.key} value={c.key}>
          {intl.formatMessage({
            id: c.label
          })}
        </option>
      ))}
    </FieldSelect>
  ) : conditionMicPairA ? (
    <FieldSelect className={css.condition} name={name} id={id} label={conditionLabel}>
      <option disabled value=""></option>
      {conditionMicPairA.map(c => (
        <option key={c.key} value={c.key}>
          {intl.formatMessage({
            id: c.label
          })}
        </option>
      ))}
    </FieldSelect>
  ) : conditionMicPairB ? (
    <FieldSelect className={css.condition} name={name} id={id} label={conditionLabel}>
      <option disabled value=""></option>
      {conditionMicPairB.map(c => (
        <option key={c.key} value={c.key}>
          {intl.formatMessage({
            id: c.label
          })}
        </option>
      ))}
    </FieldSelect>
  ) : null;
};

export default CustomConditionSelectFieldMaybe;
