import React from 'react';
import { FieldSelect } from '../../components';

import css from './EditListingMicDetailsForm.module.css';

const CustomIssueSelectFieldMaybe = props => {
  const {
    name,
    id,
    issues,
    intl,
  } = props;
  const issueLabel = intl.formatMessage({
    id: 'EditListingMicDetailsForm.issueLabel',
  });
  return issues 
    ? (<FieldSelect className={css.micPair} name={name} id={id} label={issueLabel}>
        <option disabled value=""></option>
        {issues.map(c => (
          <option key={c.key} value={c.key}>
            {intl.formatMessage({
              id: c.label
            })}
          </option>
        ))}
      </FieldSelect>) 
    : null;
};

export default CustomIssueSelectFieldMaybe;
