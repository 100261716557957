import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { func, string } from 'prop-types';
import { createEditor } from 'slate';
import { Slate, Editable, withReact } from 'slate-react';
import { withHistory } from 'slate-history';
import { withHtml, withImages } from './helpers/RichTextFeatures';
import { Element, Leaf } from './helpers/RichTextConfig';

import css from './FieldRichText.module.css';
import { intlShape, injectIntl } from '../../util/reactIntl';
import { compose } from 'redux';

const ReadOnlyRichTextComponent = props => {
  const { id, intl, readOnlyValue } = props;

  const initialValue = getInitialValue(intl);

  const [slateValue, setValue] = useState(initialValue);

  useEffect(() => {
    const updatedValue = readOnlyValue ? JSON.parse(readOnlyValue) : initialValue
    setValue(updatedValue);
  }, [readOnlyValue]);

  const editor = useMemo(
    () => withImages(withHtml(withReact(withHistory(createEditor())))), []
  );

  const renderElement = useCallback(eprops => <Element {...eprops} />, []);
  const renderLeaf = useCallback(eprops => <Leaf {...eprops} />, []);

  return (
    <Slate
      id={id}
      editor={editor}
      value={slateValue}
      onChange={newValue => {
        setValue(newValue);
      }}>
      <Editable
        className={css.slateEditorReadOnly}
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        readOnly
      />
    </Slate>
  )
};

const getInitialValue = (intl) => {
  return [
    {
      type: 'paragraph',
      children: [
        { text: intl.formatMessage({ id: "FieldRichText.intro1" }) },
        { text: intl.formatMessage({ id: "FieldRichText.intro2" }), bold: true },
        { text: intl.formatMessage({ id: "FieldRichText.intro3" }) },
        { text: intl.formatMessage({ id: "FieldRichText.intro4" }), italic: true },
        { text: intl.formatMessage({ id: "FieldRichText.intro5" }) },
        { text: intl.formatMessage({ id: "FieldRichText.intro6" }), code: true },
        { text: intl.formatMessage({ id: "FieldRichText.intro7" }) },
      ],
    },
    {
      type: 'paragraph',
      children: [
        { text: intl.formatMessage({ id: "FieldRichText.explainFirstPart" }) },
        { text: intl.formatMessage({ id: "FieldRichText.bold" }), bold: true },
        { text: intl.formatMessage({ id: "FieldRichText.explainSecondPart" }) },
      ],
    },
    {
      type: 'block-quote',
      children: [{ text: intl.formatMessage({ id: 'FieldRichText.blockquote' }) }],
    },
    {
      type: 'paragraph',
      children: [{ text: intl.formatMessage({ id: 'FieldRichText.tryit' }) }],
    },
  ];
}

ReadOnlyRichTextComponent.defaultProps = {
  onUnmount: null,
  id: null,
  readOnlyValue: null
};

ReadOnlyRichTextComponent.propTypes = {
  onUnmount: func,
  id: string,
  intl: intlShape.isRequired,
  readOnlyValue: string
}

export default compose(injectIntl)(ReadOnlyRichTextComponent);