import { useDispatch, useSelector } from 'react-redux';
import { closeAuthModal, openAuthModal } from '../ducks/Auth.duck';

export const useAuthModal = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => state.Auth.isAuthenticated);

  const dispatchOpenAuthModal = () => dispatch(openAuthModal());
  const dispatchCloseAuthModal = () => dispatch(closeAuthModal());

  return [ isAuthenticated, dispatchOpenAuthModal, dispatchCloseAuthModal ];
}