import React from 'react';

import { SecondaryButton } from '../../../components';
import { FormattedMessage } from '../../../util/reactIntl';

import css from '../ListingPage.module.css';

const SectionMessageSeller = props => {
  const {
    onContactUser,
  } = props;
  return (
    <div className={css.lastSection}>
      <h2 className={css.sectionTitle}>
        <FormattedMessage id="ListingPage.messageSellerTitle" />
      </h2>
      <span className={css.contactWrapper}>
        <SecondaryButton type="submit" onClick={onContactUser}>
          <FormattedMessage id="ListingPage.ctaButtonMessageSeller" />
        </SecondaryButton>
      </span>
    </div>
  );
};

export default SectionMessageSeller;
