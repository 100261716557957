import React from 'react';
import { PrimaryButton, FieldCurrencyInputCustom } from '../../../components';
import config from '../../../config';
import { intlShape } from '../../../util/reactIntl';
import { formatMoney } from '../../../util/currency';
import { types as sdkTypes } from '../../../util/sdkLoader';
import { bool, string, number } from 'prop-types';
import * as validators from '../../../util/validators';
import { YES } from '../../../util/types';

import css from '../TradingForm.module.css';

const { Money } = sdkTypes;

const INCREASE_PERCENTAGE = [10, 15, 20, 25, 30];

const FieldSetMakeOffer = props => {
  const { 
    intl, 
    numberOfOffers, 
    highestOffer, 
    form, 
    currentOffer, 
    displayHighestOffer, 
    isTradingDisabled, 
    defaultBid, 
    highestBidValue,
    currentUser,
    currentHighestBidder
  } = props;

  const { bidderId = '' } = currentHighestBidder || {};

  const isCurrentHighestBidder = bidderId === currentUser?.id?.uuid;

  const currentHighestBidderText = intl.formatMessage({
    id: 'TradingForm.currentHighestBidder'
  });
  
  const makeOfferLabel = intl.formatMessage({
    id: 'TradingForm.makeOffer'
  });

  const sendOfferText = intl.formatMessage({
    id: 'TradingForm.sendOffer'
  });

  const totalBidsLabel = intl.formatMessage({
    id: 'TradingForm.totalBids'
  }, {
    number: numberOfOffers
  });

  const highestBidLabel = intl.formatMessage({
    id: 'TradingForm.highestBid'
  });

  const highestBid = highestOffer && formatMoney(intl, new Money(highestOffer, config.currency));
  const invalid = !currentOffer || currentOffer.amount < defaultBid;
  const disabled = invalid || isTradingDisabled;

  const makeOfferInfo = intl.formatMessage({
    id: "TradingForm.makeOfferInfo"
  })
  const invalidOfferMessage = intl.formatMessage({
    id: "TradingForm.invalidOffer"
  })
  const offerValidator = validators.validOffer(invalidOfferMessage, defaultBid);

  return (
    <div className={css.fieldsetMakeOffer}>
      <div className={css.totalBids}>
        {totalBidsLabel}
      </div>
      {displayHighestOffer === YES && highestOffer && 
        <div className={css.highestBids}>
          <div className={css.highestOfferLabel}>
            <label>{highestBidLabel}</label>
          </div>
          <div className={css.priceValue}>
            {highestBid}
          </div>
        </div>
      }
      {isCurrentHighestBidder && !isTradingDisabled && <div className={css.currentHighestBidder}>{currentHighestBidderText}</div> }
      <div className={css.makeOffer}>
        <FieldCurrencyInputCustom
          id="offer"
          name="offer"
          disabled={isTradingDisabled}
          className={css.makeOfferInput}
          currencyConfig={config.currencyConfig}
          label={makeOfferLabel}
          validate={offerValidator}
        />
        <div className={css.submitButtonWrapper}>
          <PrimaryButton
            disabled={disabled}
            type="submit"
            onClick={() => {
              form.change("button", "offer");
            }}
          >
            {sendOfferText}
          </PrimaryButton>
        </div>
      </div>
      <div className={css.offers}>
        <h3>{makeOfferInfo}</h3>
        <div className={css.offerButtons}>
          {INCREASE_PERCENTAGE.map(percentage => (
            <PrimaryButton
              key={percentage}
              className={css.offerButton}
              type="button"
              onClick={() => {
                const newBid = highestBidValue + (highestBidValue * percentage / 100);
                form.change("offer", new Money(newBid, config.currency));
              }}
              title={`${percentage}%`}
            >
              {`${percentage}%`}
            </PrimaryButton>
          ))}
        </div>
      </div>
    </div>
  );
}

FieldSetMakeOffer.propTypes = {
  intl: intlShape,
  isTradingDisabled: bool,
  displayHighestOffer: string,
  highestOffer: number,
  numberOfOffers: number
};

export default FieldSetMakeOffer;