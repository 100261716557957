import React from 'react';
import { ResponsiveImage } from '../../../components';
import ActionBarMaybe from './ActionBarMaybe';

import css from '../ListingPage.module.css';

const SectionListMedia = props => {
  const {
    title,
    listing,
    isOwnListing,
    editParams,
    handleMediaThumbnailClick,
    media
  } = props;
  
  const mediaItem = media.map((item, index) => {
    return item.type 
    ? (
        <ResponsiveImage
          onClick={(e) => handleMediaThumbnailClick(e, index)}
          key={index}
          rootClassName={css.rootForThumbnailImage}
          alt={title}
          image={item}
          variants={[
            'landscape-crop',
            'landscape-crop2x',
          ]}
        />
      )
    : (
      <div className={css.thumbnailVideoWrapper} key={index}>
        <img
          className={css.rootForThumbnailImage} 
          alt={title} src={item.thumbnail} 
          srcSet={`${item.thumbnail} 400w, ${item.thumbnail} 800w`} 
          onClick={(e) => handleMediaThumbnailClick(e, index)}
        />
      </div>
    );
  });

  return (
    <div className={css.sectionListImages}>
      <div className={css.imageWrapper}>
        <div className={css.listImageContent}>
          {mediaItem}
        </div>
      </div>
    </div>
  );
};

export default SectionListMedia;
