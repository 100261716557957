/**
 * Note: This form is using card from Stripe Elements https://stripe.com/docs/stripe-js#elements
 * Card is not a Final Form field so it's not available trough Final Form.
 * It's also handled separately in handleSubmit function.
 */
import React, { Component } from 'react';
import { bool, func, object, string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm, useForm } from 'react-final-form';
import classNames from 'classnames';
import config from '../../config';
import { propTypes } from '../../util/types';
import { ensurePaymentMethodCard } from '../../util/data';
import arrayMutators from 'final-form-arrays';
import {
  Form,
  PrimaryButton,
  IconSpinner,
  StripePaymentAddress,
} from '../../components';
import PaymentMethods from './PaymentMethods';

import css from './TimedAuctionPaymentForm.module.css';

/**
 * Translate a Stripe API error object.
 *
 * To keep up with possible keys from the Stripe API, see:
 *
 * https://stripe.com/docs/api#errors
 *
 * Note that at least at moment, the above link doesn't list all the
 * error codes that the API returns.
 *
 * @param {Object} intl - react-intl object from injectIntl
 * @param {Object} stripeError - error object from Stripe API
 *
 * @return {String} translation message for the specific Stripe error,
 * or the given error message (not translated) if the specific error
 * type/code is not defined in the translations
 *
 */
const stripeErrorTranslation = (intl, stripeError) => {
  const { message, code, type } = stripeError;

  if (!code || !type) {
    // Not a proper Stripe error object
    return intl.formatMessage({ id: 'TimedAuctionPaymentForm.genericError' });
  }

  const translationId =
    type === 'validation_error'
      ? `TimedAuctionPaymentForm.stripe.validation_error.${code}`
      : `TimedAuctionPaymentForm.stripe.${type}`;

  return intl.formatMessage({
    id: translationId,
    defaultMessage: message,
  });
};

const stripeElementsOptions = {
  fonts: [
    {
      family: 'poppins',
      fontSmoothing: 'antialiased',
      src:
        'local("poppins"), local("Poppins"), url("https://assets-sharetribecom.sharetribe.com/webfonts/poppins/Poppins-Medium.ttf") format("truetype")',
    },
  ],
};

const cardStyles = {
  base: {
    fontFamily: '"poppins", Helvetica, Arial, sans-serif',
    fontSize: '18px',
    fontSmoothing: 'antialiased',
    lineHeight: '24px',
    letterSpacing: '-0.1px',
    color: '#4A4A4A',
    '::placeholder': {
      color: '#B2B2B2',
    },
  },
};

const getPaymentMethod = (selectedPaymentMethod, hasDefaultPaymentMethod) => {
  return selectedPaymentMethod == null && hasDefaultPaymentMethod
    ? 'defaultCard'
    : selectedPaymentMethod == null
    ? 'onetimeCardPayment'
    : selectedPaymentMethod;
};

const initialState = {
  error: null,
  cardValueValid: false,
  // The mode can be 'onetimePayment', 'defaultCard', or 'replaceCard'
  // Check SavedCardDetails component for more information
  paymentMethod: null,
};

/**
 * Payment form that asks for credit card info using Stripe Elements.
 *
 * When the card is valid and the user submits the form, a request is
 * sent to the Stripe API to handle payment. `stripe.confirmCardPayment`
 * may ask more details from cardholder if 3D security steps are needed.
 *
 * See: https://stripe.com/docs/payments/payment-intents
 *      https://stripe.com/docs/elements
 */
class TimedAuctionPaymentForm extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      ...initialState
    };
    this.handleCardValueChange = this.handleCardValueChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.paymentForm = this.paymentForm.bind(this);
    this.initializeStripeElement = this.initializeStripeElement.bind(this);
    this.handleStripeElementRef = this.handleStripeElementRef.bind(this);
    this.changePaymentMethod = this.changePaymentMethod.bind(this);
    this.finalFormAPI = null;
    this.cardContainer = null;
  }

  componentDidMount() {
    if (!window.Stripe) {
      throw new Error('Stripe must be loaded for StripePaymentForm');
    }

    if (config.stripe.publishableKey) {
      const {
        onStripeInitialized,
        hasHandledCardPayment,
        defaultPaymentMethod,
        loadingData,
        isOfferHigherThanStripeLimit
      } = this.props;
      this.stripe = window.Stripe(config.stripe.publishableKey);
      onStripeInitialized(this.stripe);

      if (!(hasHandledCardPayment || defaultPaymentMethod || loadingData || isOfferHigherThanStripeLimit)) {
        this.initializeStripeElement();
      }
    }
  }

  componentWillUnmount() {
    if (this.card) {
      this.card.removeEventListener('change', this.handleCardValueChange);
      this.card.unmount();
      this.card = null;
    }
  }

  initializeStripeElement(element) {
    const elements = this.stripe.elements(stripeElementsOptions);

    if (!this.card) {
      this.card = elements.create('card', { style: cardStyles });
      this.card.mount(element || this.cardContainer);
      this.card.addEventListener('change', this.handleCardValueChange);
      // EventListener is the only way to simulate breakpoints with Stripe.
      window.addEventListener('resize', () => {
        if (this.card) {
          if (window.innerWidth < 1024) {
            this.card.update({ style: { base: { fontSize: '18px', lineHeight: '24px' } } });
          } else {
            this.card.update({ style: { base: { fontSize: '20px', lineHeight: '32px' } } });
          }
        }
      });
    }
  }

  changePaymentMethod(changedTo) {
    if (this.card) {
      this.card.removeEventListener('change', this.handleCardValueChange);
      this.card.unmount();
      this.card = null;
    }
    this.setState({ paymentMethod: changedTo });
  }

  handleStripeElementRef(el) {
    this.cardContainer = el;
    if (this.stripe && el) {
      this.initializeStripeElement(el);
    }
  }

  handleCardValueChange(event) {
    const { intl } = this.props;
    const { error, complete } = event;

    const postalCode = event.value.postalCode;
    if (this.finalFormAPI) {
      this.finalFormAPI.change('postal', postalCode);
    }

    this.setState(prevState => {
      return {
        error: error ? stripeErrorTranslation(intl, error) : null,
        cardValueValid: complete,
      };
    });
  }

  handleSubmit(values) {
    const {
      onSubmit,
      inProgress,
      formId,
      hasHandledCardPayment,
      defaultPaymentMethod,
    } = this.props;
    const { otherPayment } = values;
    const { cardValueValid, paymentMethod } = this.state;
    const billingDetailsKnown = hasHandledCardPayment || defaultPaymentMethod;
    const onetimePaymentNeedsAttention = !otherPayment && !billingDetailsKnown && !cardValueValid;

    if (inProgress || onetimePaymentNeedsAttention) {
      // Already submitting or card value incomplete/invalid
      return;
    }

    const params = {
      card: this.card,
      formId,
      formValues: { ...values },
      paymentMethod: getPaymentMethod(
        paymentMethod,
        ensurePaymentMethodCard(defaultPaymentMethod).id
      ),
    };
    onSubmit(params);
  }

  paymentForm(formRenderProps) {
    const {
      className,
      rootClassName,
      inProgress: submitInProgress,
      loadingData,
      formId,
      intl,
      initiateOrderError,
      confirmCardPaymentError,
      confirmPaymentError,
      invalid,
      handleSubmit,
      form,
      hasHandledCardPayment,
      defaultPaymentMethod,
      values,
      isOfferHigherThanStripeLimit
    } = formRenderProps;
    this.finalFormAPI = form;

    const isCCTabActive = !values.hasOwnProperty('otherPayment');

    const ensuredDefaultPaymentMethod = ensurePaymentMethodCard(defaultPaymentMethod);
    const billingDetailsNeeded = !(hasHandledCardPayment || confirmPaymentError);
    const billingDetailsKnown = hasHandledCardPayment || ensuredDefaultPaymentMethod;
    const onetimePaymentNeedsAttention = !billingDetailsKnown && !this.state.cardValueValid;
    const submitDisabled = invalid || onetimePaymentNeedsAttention || submitInProgress || (!isCCTabActive && !values.otherPayment);
    const hasCardError = this.state.error && !submitInProgress;
    const hasPaymentErrors = confirmCardPaymentError || confirmPaymentError;
    const classes = classNames(rootClassName || css.root, className);
    const cardClasses = classNames(css.card, {
      [css.cardSuccess]: this.state.cardValueValid,
      [css.cardError]: hasCardError,
    });

    // TODO: confirmCardPayment can create all kinds of errors.
    // Currently, we provide translation support for one:
    // https://stripe.com/docs/error-codes
    const piAuthenticationFailure = 'payment_intent_authentication_failure';
    const paymentErrorMessage =
      confirmCardPaymentError && confirmCardPaymentError.code === piAuthenticationFailure
        ? intl.formatMessage({ id: 'TimedAuctionPaymentForm.confirmCardPaymentError' })
        : confirmCardPaymentError
        ? confirmCardPaymentError.message
        : confirmPaymentError
        ? intl.formatMessage({ id: 'TimedAuctionPaymentForm.confirmPaymentError' })
        : intl.formatMessage({ id: 'TimedAuctionPaymentForm.genericError' });

    const paymentInfo = intl.formatMessage({ id: isCCTabActive ? 'CheckoutPage.paymentInfo' : 'CheckoutPage.paymentWCInfo' }); 
    
    const billingAddress = (
      <StripePaymentAddress intl={intl} form={form} fieldId={formId} card={this.card} />
    );

    const hasStripeKey = config.stripe.publishableKey;
    const showPaymentMethodSelector = ensuredDefaultPaymentMethod.id;
    const selectedPaymentMethod = getPaymentMethod(
      this.state.paymentMethod,
      showPaymentMethodSelector
    );
    const showOnetimePaymentFields = ['onetimeCardPayment', 'replaceCard'].includes(
      selectedPaymentMethod
    );
    return hasStripeKey ? (
      <Form className={classes} onSubmit={handleSubmit}>
        {billingDetailsNeeded && !loadingData ? (
          <PaymentMethods
            cardClasses={cardClasses}
            formId={formId}
            defaultPaymentMethod={ensuredDefaultPaymentMethod}
            changePaymentMethod={this.changePaymentMethod}
            handleStripeElementRef={this.handleStripeElementRef}
            hasCardError={hasCardError}
            error={this.state.error}
            paymentMethod={selectedPaymentMethod}
            intl={intl}
            showPaymentMethodSelector={showPaymentMethodSelector}
            showOnetimePaymentFields={showOnetimePaymentFields}
            billingAddress={billingAddress}
            form={form}
            isOfferHigherThanStripeLimit={isOfferHigherThanStripeLimit}
            isCCTabActive={isCCTabActive}
          />
        ) : loadingData ? (
          <p className={css.spinner}>
            <IconSpinner />
          </p>
        ) : null}

        {initiateOrderError ? (
          <span className={css.errorMessage}>{initiateOrderError.message}</span>
        ) : null}
    
        <div className={css.submitContainer}>
          {hasPaymentErrors ? (
            <span className={css.errorMessage}>{paymentErrorMessage}</span>
          ) : null}
          <p className={css.paymentInfo}>{paymentInfo}</p>
          {isCCTabActive && (
            <p className={css.paymentInfoNote}>
              <FormattedMessage id="TimedAuctionPaymentForm.paymentInfoNote"/>
            </p>
          )}
          <PrimaryButton
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
          >
            {billingDetailsNeeded ? (
              <FormattedMessage id="TimedAuctionPaymentForm.submitPaymentInfo" />
            ) : (
              <FormattedMessage id="TimedAuctionPaymentForm.submitConfirmPaymentInfo" />
            )}
          </PrimaryButton>
        </div>
      </Form>
    ) : (
      <div className={css.missingStripeKey}>
        <FormattedMessage id="TimedAuctionPaymentForm.missingStripeKey" />
      </div>
    );
  }

  render() {
    const { onSubmit, ...rest } = this.props;
    return <FinalForm mutators={{ ...arrayMutators }} onSubmit={this.handleSubmit} {...rest} render={this.paymentForm} />;
  }
}

TimedAuctionPaymentForm.defaultProps = {
  className: null,
  rootClassName: null,
  inProgress: false,
  loadingData: false,
  hasHandledCardPayment: false,
  defaultPaymentMethod: null,
  initiateOrderError: null,
  confirmCardPaymentError: null,
  confirmPaymentError: null,
};

TimedAuctionPaymentForm.propTypes = {
  className: string,
  rootClassName: string,
  inProgress: bool,
  loadingData: bool,
  initiateOrderError: object,
  confirmCardPaymentError: object,
  confirmPaymentError: object,
  formId: string.isRequired,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  authorDisplayName: string.isRequired,
  hasHandledCardPayment: bool,
  defaultPaymentMethod: propTypes.defaultPaymentMethod,
  isOfferHigherThanStripeLimit: bool
};

export default injectIntl(TimedAuctionPaymentForm);
