import React from 'react';
import { bool, func, shape, string, array } from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import { propTypes, AUTHENTICITY_DOCUMENT_UPLOAD, SESSION_FORM_KEYS } from '../../util/types';
import config from '../../config';
import { Button, Form, IconSpinner, FieldSelect } from '../../components';
import { FieldArray } from 'react-final-form-arrays';
import { useDispatch } from 'react-redux';
import { compose } from 'redux';
import truncate from 'lodash/truncate';
import RemoveFieldButton from '../../components/RemoveFieldButton/RemoveFieldButton';
import { requestDocumentUpload, requestDocumentRemove } from '../../containers/EditListingPage/EditListingPage.duck';

import css from './EditListingAuthenticityForm.module.css';

const ACCEPT_DOCS = 'application/pdf, application/msword, image/*';

const EditListingAuthenticityFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        disabled,
        ready,
        rootClassName,
        className,
        name,
        handleSubmit,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        filterConfig,
        intl,
        values,
        form,
        saveDataToSessionStorage,
        authDocuments
      } = formRenderProps;

      const classes = classNames(rootClassName || css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = disabled || submitInProgress;

      const { updateListingError, showListingsError } = fetchErrors || {};
      const dispatch = useDispatch();
      const { push } = form.mutators;

      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingAuthenticityForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingAuthenticityForm.showListingFailed" />
        </p>
      ) : null;

      const options = findOptionsForSelectFilter('authenticity', filterConfig);
      
      const authenticityDocumentsLabel = (
        <span>
          <FormattedMessage id='EditListingConsignForm.authenticityDocumentsLabel' />
          <span className={css.optionalLabel}><FormattedMessage id='EditListingConsignForm.optionalLabel' /></span>
        </span>
      );

      const chooseFileText = (
        <span className={css.chooseImageText}>
          <span className={css.chooseImage}>
            <FormattedMessage id="EditListingConsignForm.chooseFile" />
          </span>
        </span>
      );

      const loadingSpinner = (
        <div className={css.loader}>
          <IconSpinner/>
        </div>
      )

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}

          <FieldArray name="authenticity" >
            {(fieldProps) => {
                const { fields } = fieldProps;
                return fields.map((name, index) => {
                  return (
                  <div className={css.authDocField} key={name}>
                    <FieldSelect
                      className={css.fillWidth}
                      name={`${name}.type`}
                      id={`${name}.type`}
                      label={authenticityDocumentsLabel}
                      onChange={(e) => {
                        form.change(`authenticity[${index}].type`, e.target.value);
                        if (index === fields.length - 1)
                          push("authenticity", {});
                      }}
                    >
                      <option disabled value=""></option>
                      {options.map(doc => (
                        <option key={doc.key} value={doc.key}>
                          {intl.formatMessage({
                            id: doc.label
                          })}
                        </option>
                      ))}
                    </FieldSelect>
                    {values.authenticity[index].type &&
                      (<Field
                        id={`${name}.file`}
                        name={`${name}.file`}
                        accept={ACCEPT_DOCS}
                        parse={x => x}
                        label={chooseFileText}
                        type="file"
                      >
                        {fieldprops => {
                          const { accept, input, label, disabled: fieldDisabled } = fieldprops;
                          const { name, type } = input;
                          const onChange = e => {
                            const file = e.target.files[0];
                            if (file) {
                              saveDataToSessionStorage(SESSION_FORM_KEYS.EDIT_LISTING_AUTHENTICITY, values);
                              dispatch(requestDocumentUpload({file, type: AUTHENTICITY_DOCUMENT_UPLOAD}));
                              form.blur(`authenticity[${index}].file`);
                            }
                          };
                          const inputProps = { accept, id: name, name, onChange, type };
                          return (
                            <div className={css.addFileWrapper}>
                              <div className={css.aspectRatioFileWrapper}> 
                                {fieldDisabled ? null : (
                                  <input {...inputProps} className={css.addFileInput} />
                                )}
                                {authDocuments[index]
                                ? (
                                  <div className={css.uploadedFileName}>
                                    <span>{truncate(authDocuments[index].name, 10)}</span>
                                    <RemoveFieldButton 
                                      className={css.removeFileUploadRowButton} 
                                      onClick={() => {
                                        dispatch(requestDocumentRemove(authDocuments[index].url));
                                        fields.remove(index);
                                        authDocuments.splice(index, 1);
                                      }} 
                                    />
                                  </div>
                                )
                                : (
                                  submitInProgress 
                                  ? loadingSpinner
                                  : (
                                    <label htmlFor={name} className={css.addFile}>
                                      {label}
                                    </label>
                                  )
                                )}
                              </div>
                            </div>
                          );
                        }}
                      </Field>)}
                  </div>
                  )
                })
              }
            }
          </FieldArray>

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingAuthenticityFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingAuthenticityFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  name: string.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  saveDataToSessionStorage: func.isRequired,
  authDocuments: array.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

const EditListingAuthenticityForm = EditListingAuthenticityFormComponent;

export default compose(injectIntl)(EditListingAuthenticityForm);
