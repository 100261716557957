import React from 'react';
import { bool, func, string } from 'prop-types';
import { Form as FinalForm } from 'react-final-form';
import { intlShape } from '../../util/reactIntl';
import { Form, Button, FieldTextInput, FieldSelect, FieldPhoneNumberInput } from '../../components';

import config from '../../config';
import getCountryCodes from '../../translations/countryCodes';
import * as validators from '../../util/validators';
import classNames from 'classnames';
import css from './ShippingAddressForm.module.css';

export const ShippingAddressForm = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        handleSubmit,
        intl,
        invalid,
        updateInProgress,
      } = formRenderProps;

      const optionalText = intl.formatMessage({
        id: 'ShippingAddressForm.optionalText',
      });

      const recipientNameLabel = intl.formatMessage({
        id: 'ShippingAddressForm.recipientNameLabel'
      });
      const recipientNamePlaceholder = intl.formatMessage({
        id: 'ShippingAddressForm.recipientNamePlaceholder'
      });
      const recipientNameRequired = validators.required(intl.formatMessage({
        id: 'ShippingAddressForm.recipientNameRequired'
      }));

      const addressLine1Label = intl.formatMessage({
        id: 'ShippingAddressForm.addressLine1Label',
      });
      const addressLine1Placeholder = intl.formatMessage({
        id: 'ShippingAddressForm.addressLine1Placeholder',
      });
      const addressLine1Required = validators.required(
        intl.formatMessage({
          id: 'ShippingAddressForm.addressLine1Required',
        })
      );

      const addressLine2Label = intl.formatMessage(
        { id: 'ShippingAddressForm.addressLine2Label' },
        { optionalText: optionalText }
      );

      const addressLine2Placeholder = intl.formatMessage({
        id: 'ShippingAddressForm.addressLine2Placeholder',
      });

      const zipCodeLabel = intl.formatMessage({ id: 'ShippingAddressForm.zipCodeLabel' });
      const zipCodePlaceholder = intl.formatMessage({
        id: 'ShippingAddressForm.zipCodePlaceholder',
      });
      const zipCodeRequired = validators.required(
        intl.formatMessage({
          id: 'ShippingAddressForm.zipCodeRequired',
        })
      );

      const cityLabel = intl.formatMessage({ id: 'ShippingAddressForm.cityLabel' });
      const cityPlaceholder = intl.formatMessage({ id: 'ShippingAddressForm.cityPlaceholder' });
      const cityRequired = validators.required(
        intl.formatMessage({
          id: 'ShippingAddressForm.cityRequired',
        })
      );

      const stateLabel = intl.formatMessage(
        { id: 'ShippingAddressForm.stateLabel' },
        { optionalText: optionalText }
      );
      const statePlaceholder = intl.formatMessage({ id: 'ShippingAddressForm.statePlaceholder' });

      const countryLabel = intl.formatMessage({ id: 'ShippingAddressForm.countryLabel' });
      const countryPlaceholder = intl.formatMessage({ id: 'ShippingAddressForm.countryPlaceholder' });
      const countryRequired = validators.required(
        intl.formatMessage({
          id: 'ShippingAddressForm.countryRequired',
        })
      );

      const phoneLabel = intl.formatMessage({ id: 'ShippingAddressForm.phoneLabel' });
      const phoneRequired = validators.required(
        intl.formatMessage({
          id: 'ShippingAddressForm.phoneRequired',
        })
      );

      const saveActionMsg = intl.formatMessage({ id: 'ShippingAddressForm.save' });

      // Use tha language set in config.locale to get the correct translations of the country names
      const countryCodes = getCountryCodes(config.locale);
      const classes = classNames(css.root, className);
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div className={css.formRow}>
            <FieldTextInput
              id="recipientName"
              name="recipientName"
              type="text"
              className={css.field}
              label={recipientNameLabel}
              validate={recipientNameRequired}
            />
            <FieldPhoneNumberInput
              id="phoneNumber"
              name="phoneNumber"
              type="phone"
              className={css.field}
              label={phoneLabel}
              validate={phoneRequired}
            />
          </div>
          <div className={css.formRow}>
            <FieldTextInput
              id="addressLine1"
              name="addressLine1"
              className={css.field}
              type="text"
              autoComplete="billing address-line1"
              label={addressLine1Label}
              validate={addressLine1Required}
            />

            <FieldTextInput
              id="addressLine2"
              name="addressLine2"
              className={css.field}
              type="text"
              autoComplete="billing address-line2"
              label={addressLine2Label}
            />
          </div>
          <div className={css.formRow}>
            <FieldTextInput
              id="city"
              name="city"
              className={css.field}
              type="text"
              autoComplete="billing address-level2"
              label={cityLabel}
              validate={cityRequired}
            />
            <FieldTextInput
              id="state"
              name="state"
              className={css.field}
              type="text"
              autoComplete="billing address-level1"
              label={stateLabel}
            />
          </div>
          <div className={css.formRow}>
            <FieldTextInput
              id="zipCode"
              name="postal"
              className={css.field}
              type="text"
              autoComplete="billing postal-code"
              label={zipCodeLabel}
              validate={zipCodeRequired}
            />

            <FieldSelect
              id="country"
              name="country"
              className={css.field}
              label={countryLabel}
              validate={countryRequired}
            >
              <option disabled value=""></option>
              {countryCodes.map(country => {
                return (
                  <option key={country.code} value={country.code}>
                    {country.name}
                  </option>
                );
              })}
            </FieldSelect>
          </div>

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

ShippingAddressForm.defaultProps = { className: null, fetchErrors: null };

ShippingAddressForm.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  updateInProgress: bool.isRequired,
};

export default ShippingAddressForm;
