import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import StripeLogo from './stripe-logo.png';
import css from './IconStripeLogo.module.css';

const IconStripeLogo = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <img className={classes} src={StripeLogo} alt="Stripe Logo"/>
  );
}

IconStripeLogo.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconStripeLogo.propTypes = { rootClassName: string, className: string };

export default IconStripeLogo;
