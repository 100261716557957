import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { useSelector } from 'react-redux';
import { LISTING_STATE_DRAFT, SESSION_FORM_KEYS, AUTHENTICITY_NAME } from '../../util/types';
import { ensureListing } from '../../util/data';
import { EditListingAuthenticityForm } from '../../forms';
import { ListingLink } from '..';
import isEmpty from 'lodash/isEmpty';

import css from './EditListingAuthenticityPanel.module.css';

const EditListingAuthenticityPanel = props => {
  const {
    rootClassName,
    className,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    saveDataToSessionStorage,
  } = props;
  
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const { publicData } = currentListing.attributes;
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingAuthenticityPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingAuthenticityPanel.createListingTitle" />
  );
  const initialData = JSON.parse(sessionStorage.getItem(SESSION_FORM_KEYS.EDIT_LISTING_AUTHENTICITY));
  // If the data is from sessionStorage, no need to add an extra field
  const authenticity = initialData 
                       ? initialData.authenticity
                       : publicData 
                         ? [...publicData.authenticity, {}]
                         : []
  const authDocuments = useSelector(state => state.EditListingPage.authDocuments);
  
  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingAuthenticityForm
        className={css.form}
        name={AUTHENTICITY_NAME}
        initialValues={{ authenticity }}
        onSubmit={values => {
          const { authenticity = [] } = values;

          const newAuthenticity = [...authenticity];
          newAuthenticity.length = authDocuments.length;
          newAuthenticity.forEach((auth, index) => {
            auth['file'] = authDocuments[index]; 
          });

          const updatedValues = {
            publicData: { authenticity: newAuthenticity },
          };

          // The form will be re-rendered so that the data must be saved for initial values 
          saveDataToSessionStorage(SESSION_FORM_KEYS.EDIT_LISTING_AUTHENTICITY, values);
          onSubmit(updatedValues);
        }}
        onChange={onChange}
        saveActionMsg={submitButtonText}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        saveDataToSessionStorage={saveDataToSessionStorage}
        authDocuments={authDocuments}
      />
    </div>
  );
};

EditListingAuthenticityPanel.defaultProps = {
  rootClassName: null,
  className: null,
  listing: null,
};

const { bool, func, object, string } = PropTypes;

EditListingAuthenticityPanel.propTypes = {
  rootClassName: string,
  className: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingAuthenticityPanel;
