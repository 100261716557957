import React, { Fragment } from 'react';
import { array } from 'prop-types';
import * as validators from '../../../util/validators';
import { FieldTextInput } from '../../../components';
import CustomConditionSelectFieldMaybe from '../../EditListingDetailsForm/CustomConditionSelectFieldMaybe';
import CustomIssueSelectFieldMaybe from '../../EditListingDetailsForm/CustomIssueSelectFieldMaybe';
import { intlShape } from '../../../util/reactIntl';

import css from '../EditListingConsignForm.module.css';


export const FieldSetItemInformation = props => {
  const { intl, conditionOptions, issueOptions }  = props;
  // Serial
  const serialLabel = intl.formatMessage({
    id: 'EditListingConsignForm.serialLabel',
  });

  // Quantity
  const quantityLabel = intl.formatMessage({
    id: 'EditListingConsignForm.quantityLabel',
  });
  const quantityValidMessage = intl.formatMessage({
    id: 'EditListingDetailsForm.quantityValid',
  });
  const quantityValidators = validators.validQuantity(quantityValidMessage);

  // Brand
  const brandLabel = intl.formatMessage({
    id: 'EditListingConsignForm.brandLabel',
  });

  const brandRequiredMessage = intl.formatMessage({
    id: 'EditListingDetailsForm.brandRequired',
  });
  const brandRequired = validators.required(brandRequiredMessage);
  const brandValidators = validators.composeValidators(brandRequired);


  // Model
  const modelLabel = intl.formatMessage({
    id: 'EditListingConsignForm.modelLabel',
  });
  
   // Year
  const yearLabel = intl.formatMessage({
    id: 'EditListingConsignForm.yearLabel',
  });
  
  // Color
  const colorLabel = intl.formatMessage({
    id: 'EditListingConsignForm.colorLabel',
  });

  // SKU
  const skuLabel = intl.formatMessage({
    id: 'EditListingConsignForm.skuLabel'
  });

  return (
    <Fragment>
      <FieldTextInput
        id="itemDetails.brand"
        name="itemDetails.brand"
        className={css.details}
        type="textarea"
        label={brandLabel}
        validate={brandValidators}
      />

      <FieldTextInput
        id="itemDetails.model"
        name="itemDetails.model"
        className={css.details}
        type="textarea"
        label={modelLabel}
      />

      <FieldTextInput
        id="itemDetails.year"
        name="itemDetails.year"
        className={css.details}
        type="text"
        label={yearLabel}
      />

      <FieldTextInput
        id="itemDetails.color"
        name="itemDetails.color"
        className={css.details}
        type="textarea"
        label={colorLabel}
      />

      <CustomConditionSelectFieldMaybe
        id="itemDetails.condition"
        name="itemDetails.condition"
        condition={conditionOptions}
        intl={intl}
      />
      
      <CustomIssueSelectFieldMaybe
        id="itemDetails.issue"
        name="itemDetails.issue"
        issue={issueOptions}
        intl={intl}
      />

      <FieldTextInput
        id="itemDetails.quantity"
        name="itemDetails.quantity"
        className={css.details}
        type="number"
        min={1}
        label={quantityLabel}
        validate={quantityValidators}
      />

      <FieldTextInput
        id="itemDetails.serial"
        name="itemDetails.serial"
        className={css.details}
        type="textarea"
        label={serialLabel}
      />

      <FieldTextInput
        id="itemDetails.sku"
        name="itemDetails.sku"
        className={css.details}
        type="text"
        label={skuLabel}
      />
    </Fragment>
  )
}

FieldSetItemInformation.defaultProps = {
  conditionOptions: [],
  issueOptions: []
}

FieldSetItemInformation.propTypes = {
  intl: intlShape.isRequired,
  conditionOptions: array.isRequired,
  issueOptions: array.isRequired,
};

export default FieldSetItemInformation;
 