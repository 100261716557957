import { array, func, object, string } from 'prop-types';
import React, { useState } from 'react';
import { Field } from 'react-final-form';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import {
  AffirmMessage,
  FieldCheckbox,
  FieldRadioButtonGroup,
  FieldTextInput,
  SavedCardDetails,
  ValidationError,
} from '../../components';
import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';

import css from './StripePaymentForm.module.css';

const DEFAULT_CARD = 'defaultCard';
const ONETIME_CARD = 'onetimeCardPayment';

const OneTimePaymentWithCardElement = props => {
  const { cardClasses, formId, handleStripeElementRef, hasCardError, error, label, intl } = props;
  const labelText =
    label || intl.formatMessage({ id: 'StripePaymentForm.saveAfterOnetimePayment' });
  return (
    <React.Fragment>
      <label className={css.paymentLabel} htmlFor={`${formId}-card`}>
        <FormattedMessage id="StripePaymentForm.paymentCardDetails" />
      </label>
      <div className={cardClasses} id={`${formId}-card`} ref={handleStripeElementRef} />
      {hasCardError ? <span className={css.error}>{error}</span> : null}
      <div className={css.saveForLaterUse}>
        <FieldCheckbox
          className={css.saveForLaterUseCheckbox}
          textClassName={css.saveForLaterUseLabel}
          id="saveAfterOnetimePayment"
          name="saveAfterOnetimePayment"
          label={labelText}
          value="saveAfterOnetimePayment"
          useSuccessColor
        />
        <span className={css.saveForLaterUseLegalInfo}>
          <FormattedHTMLMessage id="StripePaymentForm.saveforLaterUseLegalInfo" />
        </span>
      </div>
    </React.Fragment>
  );
};

const PaymentMethodSelector = props => {
  const {
    cardClasses,
    formId,
    changePaymentMethod,
    defaultPaymentMethod,
    handleStripeElementRef,
    hasCardError,
    error,
    paymentMethod,
    intl,
  } = props;
  const last4Digits = defaultPaymentMethod.attributes.card.last4Digits;
  const labelText = intl.formatMessage(
    { id: 'StripePaymentForm.replaceAfterOnetimePayment' },
    { last4Digits }
  );

  return (
    <React.Fragment>
      <SavedCardDetails
        className={css.paymentMethodSelector}
        card={defaultPaymentMethod.attributes.card}
        onChange={changePaymentMethod}
      />
      {paymentMethod === 'replaceCard' ? (
        <OneTimePaymentWithCardElement
          cardClasses={cardClasses}
          formId={formId}
          handleStripeElementRef={handleStripeElementRef}
          hasCardError={hasCardError}
          error={error}
          label={labelText}
          intl={intl}
        />
      ) : null}
    </React.Fragment>
  );
};

const PaymentMethods = props => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const {
    cardClasses,
    formId,
    defaultPaymentMethod,
    changePaymentMethod,
    handleStripeElementRef,
    hasCardError,
    error,
    paymentMethod,
    intl,
    showPaymentMethodSelector,
    showOnetimePaymentFields,
    billingAddress,
    sameAsShippingAddress,
    form,
    isOfferHigherThanStripeLimit,
    isPickupOnly,
    offerPrice,
    isOfferOutOfAffirmLimit
  } = props;

  const billingDetailsNameLabel = intl.formatMessage({
    id: 'StripePaymentForm.billingDetailsNameLabel',
  });

  const billingDetailsNamePlaceholder = intl.formatMessage({
    id: 'StripePaymentForm.billingDetailsNamePlaceholder',
  });

  const sameAsShippingAddressLabel = intl.formatMessage({
    id: 'StripePaymentForm.sameAsShippingAddressLabel',
  });

  const unavailablePaymentText = intl.formatMessage({
    id: 'StripePaymentForm.unavailablePayment',
  });

  const unavailablePayment = validators.required(unavailablePaymentText);

  const paymentByCard = isOfferHigherThanStripeLimit ? (
    //This is a field to prevent the user from pressing submit if C/DC payment is unavailable
    <Field
      component={props => {
        const { input, meta } = props;
        return (
          <div>
            <input {...input} />
            <ValidationError fieldMeta={{ ...meta, touched: true }} />
          </div>
        );
      }}
      name="unavailable"
      type="hidden"
      validate={unavailablePayment}
    />
  ) : (
    <>
      {showPaymentMethodSelector ? (
        <PaymentMethodSelector
          cardClasses={cardClasses}
          formId={formId}
          defaultPaymentMethod={defaultPaymentMethod}
          changePaymentMethod={changePaymentMethod}
          handleStripeElementRef={handleStripeElementRef}
          hasCardError={hasCardError}
          error={error}
          paymentMethod={paymentMethod}
          intl={intl}
        />
      ) : (
        <React.Fragment>
          <h3 className={css.paymentHeading}>
            <FormattedMessage id="StripePaymentForm.paymentHeading" />
          </h3>
          <OneTimePaymentWithCardElement
            cardClasses={cardClasses}
            formId={formId}
            handleStripeElementRef={handleStripeElementRef}
            hasCardError={hasCardError}
            error={error}
            intl={intl}
          />
        </React.Fragment>
      )}

      {showOnetimePaymentFields ? (
        <div className={css.paymentAddressField}>
          <h3 className={css.billingHeading}>
            <FormattedMessage id="StripePaymentForm.billingDetails" />
          </h3>

          {!isPickupOnly && (
            <FieldCheckbox
              className={css.sameAsShippingCheckbox}
              textClassName={css.sameAsShippingLabel}
              id="sameAsShippingAddress"
              name="sameAsShippingAddress"
              label={sameAsShippingAddressLabel}
              value="checked"
              useSuccessColor
            />
          )}
          {!sameAsShippingAddress[0] && (
            <>
              <FieldTextInput
                className={css.field}
                type="text"
                id="name"
                name="name"
                autoComplete="cc-name"
                label={billingDetailsNameLabel}
              />
              {billingAddress}
            </>
          )}
        </div>
      ) : null}
    </>
  );

  const requiredText = intl.formatMessage({
    id: 'CheckoutPage.fieldRequired',
  });
  const fieldRequired = validators.required(requiredText);

  const otherPaymentOptions = [
    {
      key: 'wiretransfer',
      label: 'StripePaymentForm.wiretransferLabel',
    },
    {
      key: 'crypto',
      label: 'StripePaymentForm.cryptoLabel',
    },
  ];

  const paymentByWireTransferOrCrypto = (
    <>
      <FieldRadioButtonGroup
        id="otherPayment"
        name="otherPayment"
        className={css.wcPaymentOptions}
        options={otherPaymentOptions}
        intl={intl}
        validate={fieldRequired}
      />
      <div className={css.wcPaymentNotes}>
        <p>
          <FormattedMessage id="StripePaymentForm.noteWCPaymentLine1" />
        </p>
        <p>
          <FormattedMessage id="StripePaymentForm.noteWCPaymentLine2" />
        </p>
        <p>
          <FormattedHTMLMessage id="StripePaymentForm.noteWCPaymentLine3" />
        </p>
      </div>
    </>
  );

  const paymentByAffirm = <AffirmMessage price={offerPrice} />;

  const paymentInHouse = (
    <p className={css.paymentInHouseInstruction}>
      {intl.formatMessage({
        id: 'StripePaymentForm.paymentInHouseInstruction'
      })}
    </p>
  )

  const paymentCCLabel = intl.formatMessage({
    id: 'StripePaymentForm.paymentCCLabel',
  });
  const paymentWCLabel = intl.formatMessage({
    id: 'StripePaymentForm.paymentWCLabel',
  });
  const paymentAffirmLabel = intl.formatMessage({
    id: 'StripePaymentForm.paymentAffirmLabel',
  });
  const paymentInHouseLabel = intl.formatMessage({
    id: 'StripePaymentForm.paymentInHouseLabel',
  });

  const handleClickCCPaymentTab = () => {
    form.change('otherPayment', undefined); // Set this to undefined will remove this key-value pair from 'values'
    changePaymentMethod(showPaymentMethodSelector ? DEFAULT_CARD : ONETIME_CARD);
  };

  const handleClickWCPaymentTab = () => {
    form.change('otherPayment', ''); // Set this to an empty string to continue keeping the key-value pair in 'values'
  };

  const handleClickAffirmPaymentTab = () => {
    form.change('otherPayment', 'affirm');
  };

  const handleClickInHousePaymentPlanTab = () => {
    form.change('otherPayment', 'inhouse');
  };


  const affirmTabMaybe = isOfferOutOfAffirmLimit ? null : { element: paymentByAffirm, handleClick: handleClickAffirmPaymentTab, label: paymentAffirmLabel };

  const tabs = [
    { element: paymentByCard, handleClick: handleClickCCPaymentTab, label: paymentCCLabel },
    { element: paymentByWireTransferOrCrypto, handleClick: handleClickWCPaymentTab, label: paymentWCLabel },
    affirmTabMaybe,
    { element: paymentInHouse, handleClick: handleClickInHousePaymentPlanTab, label: paymentInHouseLabel }
  ].filter(Boolean);

  return (
    <div>
      <h3 className={css.paymentHeading}>
        <FormattedMessage id="StripePaymentForm.paymentMethod" />
      </h3>
      <ul className={css.tabList}>
        {tabs.map((tab, index) => (
          <li
            className={index === activeTabIndex ? css.activeTabListItem : css.tabListItem}
            onClick={() => {
              tab.handleClick(index);
              setActiveTabIndex(index);
            }}
          >
            {tab.label}
          </li>
        ))}
      </ul>
      <div className={css.paymentFormContent}>{tabs[activeTabIndex].element}</div>
    </div>
  );
};

PaymentMethods.propTypes = {
  formId: string.isRequired,
  defaultPaymentMethod: propTypes.defaultPaymentMethod,
  changePaymentMethod: func,
  handleStripeElementRef: func,
  error: object,
  sameAsShippingAddress: array,
};

export default PaymentMethods;
