
// Note:
// - Format << "URL", >>
// - No space between quotation marks ("") and URL
// - Have to put the comma in the end of row
// - The URL must be public, everyone can access

export const HERO_IMAGES = [    // can't change
 "https://img.analogr.com/MB140_Neve_1057-59.jpg",
  "https://img.analogr.com/TF1543_JohnLennons_Signed_MindGame45_PictureSleeve-8-Edit.jpg",
  "https://img.analogr.com/TS101_1956_GibsonBBeauty-11.jpg",
  "https://img.analogr.com/Acos101wall.jpg",
  "https://img.analogr.com/EltonJohnGlasses1.png"
];   // can't change

export const TIME_TRANSFORM = 3000; // 1000 = 1 seconds

// This is the section for information modal
export const infoLandingPageTopText = '';
export const infoLandingPageBottomText = `Select In-House Payment Plan at Check Out`;
export const infoYoutubeLink = '';
export const infoImageLink = 'https://analogr.imgix.net/PaymentPlanPopUp92k.jpg';
export const enableInfoModal = false;

// This is the section for inline modal in Listing Page
export const enableInfoModalListingPage = false;
export const imageLinkListingPageModal = 'https://analogr.imgix.net/PaymentPlanPopUp92k.jpg';
export const imageAltListingPageModal = 'Payment Plan Ads';
export const infoInstructionListingPageModal = 'Select <strong>In-House Payment Plan</strong> at Check Out';
