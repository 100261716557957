import React, { useState } from 'react';
import { Fragment } from 'react';
import { FormattedMessage } from '../../../util/reactIntl';
import { richText } from '../../../util/richText';
import SectionWrapper from './SectionWrapper';
import { YES, NO } from '../../../util/types';

import css from '../ListingPage.module.css';

const MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION = 20;

const SectionDetailsMaybe = props => {
  const { itemDetails, micDetails, category, intl } = props;
  const [readMore, setReadMore] = useState(false);
  const linkName = readMore ? 'Read Less ...' : 'Read More ...';

  const optimizeTextLength = (value) => richText(value, {
    longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,
    longWordClass: css.longWord,
  });

  const micPairDetails = ({
    yearMicPair,
    colorMicPair,
    conditionMicPair,
    issueMicPair,
    issueTube,
    modelTube,
    issueCapsule,
    modelCapsule,
    issueTransformer,
    modelTransformer,
    powerSupply,
    issueSupply,
    modelSupply,
    cabling,
    issueCabling,
    modelCabling,
    shockMount,
    issueShockMount,
    modelShockMount,
    micCase,
    issueMicCase,
    modelMicCase,
    carryCase,
    issueCarryCase,
    modelCarryCase
  }) => (
    <>
      <span className={css.techTitle}><FormattedMessage id="ListingPage.year" /></span>
      <span className={css.techValue}>{yearMicPair || "-"}</span>
      <span className={css.techTitle}><FormattedMessage id="ListingPage.color" /></span>
      <span className={css.techValue}>{colorMicPair || "-"}</span>
      <span className={css.techTitle}><FormattedMessage id="ListingPage.condition" /></span>
      <span className={css.techValue}>{conditionMicPair ? <FormattedMessage id={`ConditionOptions.${conditionMicPair}`} /> : "-"}</span>
      <span className={css.techTitle}><FormattedMessage id="ListingPage.issue" /></span>
      <span className={css.techValue}>{issueMicPair ? <FormattedMessage id={`IssueOptions.${issueMicPair}`} /> : "-"}</span>
      {(issueTube || modelTube) &&
        <>
          <span className={css.techTitle}><FormattedMessage id="ListingPage.tube" /></span>
          <span className={css.techValue}>&nbsp;</span>
          <span className={css.techSubtitle}><FormattedMessage id="ListingPage.issueSmall" /></span>
          <span className={css.techValue}>{issueTube ? <FormattedMessage id={`IssueOptions.${issueTube}`} /> : "-"}</span>
          <span className={css.techSubtitle}><FormattedMessage id="ListingPage.makeModel" /></span>
          <span className={css.techValue}>{modelTube || "-"}</span>
        </>
      }
      {(issueCapsule || modelCapsule) &&
        <>
          <span className={css.techTitle}><FormattedMessage id="ListingPage.capsule" /></span>
          <span className={css.techValue}>&nbsp;</span>
          <span className={css.techSubtitle}><FormattedMessage id="ListingPage.issueSmall" /></span>
          <span className={css.techValue}>{issueCapsule ? <FormattedMessage id={`IssueOptions.${issueCapsule}`} /> : "-"}</span>
          <span className={css.techSubtitle}><FormattedMessage id="ListingPage.makeModel" /></span>
          <span className={css.techValue}>{modelCapsule || "-"}</span>
        </>
      }
      {(issueTransformer || modelTransformer) &&
        <>
          <span className={css.techTitle}><FormattedMessage id="ListingPage.transformer" /></span>
          <span className={css.techValue}>&nbsp;</span>
          <span className={css.techSubtitle}><FormattedMessage id="ListingPage.issueSmall" /></span>
          <span className={css.techValue}>{issueTransformer ? <FormattedMessage id={`IssueOptions.${issueTransformer}`} /> : "-"}</span>
          <span className={css.techSubtitle}><FormattedMessage id="ListingPage.makeModel" /></span>
          <span className={css.techValue}>{modelTransformer || "-"}</span>
        </>
      }
      {(issueSupply || modelSupply) &&
        <>
          <span className={css.techTitle}><FormattedMessage id="ListingPage.supply" /></span> 
          <span className={css.techValue}>&nbsp;</span>
          <span className={css.techSubtitle}><FormattedMessage id="ListingPage.powerSupply" /></span>
          <span className={css.techValue}>{powerSupply ? <FormattedMessage id={`YesNoOptions.${powerSupply}`} /> : "-"}</span>
          <span className={css.techSubtitle}><FormattedMessage id="ListingPage.issueSmall" /></span>
          <span className={css.techValue}>{issueSupply ? <FormattedMessage id={`IssueOptions.${issueSupply}`} /> : "-"}</span>
          <span className={css.techSubtitle}><FormattedMessage id="ListingPage.makeModel" /></span>
          <span className={css.techValue}>{modelSupply || "-"}</span>
        </>
      }
      {(issueCabling || modelCabling) &&
        <>
          <span className={css.techTitle}><FormattedMessage id="ListingPage.cabling" /></span>
          <span className={css.techValue}>&nbsp;</span>
          <span className={css.techSubtitle}><FormattedMessage id="ListingPage.cablingSmall" /></span>
          <span className={css.techValue}>{cabling ? <FormattedMessage id={`YesNoOptions.${cabling}`} /> : "-"}</span>
          <span className={css.techSubtitle}><FormattedMessage id="ListingPage.issueSmall" /></span>
          <span className={css.techValue}>{issueCabling ? <FormattedMessage id={`IssueOptions.${issueCabling}`} /> : "-"}</span>
          <span className={css.techSubtitle}><FormattedMessage id="ListingPage.makeModel" /></span> 
          <span className={css.techValue}>{modelCabling || "-"}</span>
        </>
      }
      {(issueShockMount || modelShockMount) &&
        <>
          <span className={css.techTitle}><FormattedMessage id="ListingPage.shockMount" /></span>
          <span className={css.techValue}>&nbsp;</span>
          <span className={css.techSubtitle}><FormattedMessage id="ListingPage.shockMountSmall" /></span>
          <span className={css.techValue}>{shockMount ? <FormattedMessage id={`YesNoOptions.${shockMount}`} /> : "-"}</span>
          <span className={css.techSubtitle}><FormattedMessage id="ListingPage.issueSmall" /></span>
          <span className={css.techValue}>{issueShockMount ? <FormattedMessage id={`IssueOptions.${issueShockMount}`} /> : "-"}</span>
          <span className={css.techSubtitle}><FormattedMessage id="ListingPage.makeModel" /></span>
          <span className={css.techValue}>{modelShockMount || "-"}</span>
        </>
      }
      {(issueMicCase || modelMicCase) &&
        <>
          <span className={css.techTitle}><FormattedMessage id="ListingPage.micCase" /></span>
          <span className={css.techValue}>&nbsp;</span>
          <span className={css.techSubtitle}><FormattedMessage id="ListingPage.micCaseSmall" /></span>
          <span className={css.techValue}>{micCase ? <FormattedMessage id={`YesNoOptions.${micCase}`} /> : "-"}</span>
          <span className={css.techSubtitle}><FormattedMessage id="ListingPage.issueSmall" /></span>
          <span className={css.techValue}>{issueMicCase ? <FormattedMessage id={`IssueOptions.${issueMicCase}`} /> : "-"}</span>
          <span className={css.techSubtitle}><FormattedMessage id="ListingPage.makeModel" /></span>
          <span className={css.techValue}>{modelMicCase || "-"}</span>
        </>
      }
      {(issueCarryCase || modelCarryCase) &&
        <>
          <span className={css.techTitle}><FormattedMessage id="ListingPage.carryCase" /></span>
          <span className={css.techValue}>&nbsp;</span>
          <span className={css.techSubtitle}><FormattedMessage id="ListingPage.carryCaseSmall" /></span>
          <span className={css.techValue}>{carryCase ? <FormattedMessage id={`YesNoOptions.${carryCase}`} /> : "-"}</span>
          <span className={css.techSubtitle}><FormattedMessage id="ListingPage.issueSmall" /></span>
          <span className={css.techValue}>{issueCarryCase ? <FormattedMessage id={`IssueOptions.${issueCarryCase}`} /> : "-"}</span>
          <span className={css.techSubtitle}><FormattedMessage id="ListingPage.makeModel" /></span>
          <span className={css.techValue}>{modelCarryCase || "-"}</span>
        </>
      }
    </>
  )

  const title = intl.formatMessage({ id: "ListingPage.detailsTitle" });

  const { brand, model, year, color, quantity, serial, condition, issue, sku } = itemDetails || {};
  const {
    sellingPair,
    sequentSerial,
    skuMicPair,
    serialMicPair,
    brandMicPair,
    modelMicPair,
    yearMicPair,
    colorMicPair,
    conditionMicPair,
    issueMicPair,
    issueTube,
    modelTube,
    issueCapsule,
    modelCapsule,
    issueTransformer,
    modelTransformer,
    powerSupply,
    issueSupply,
    modelSupply,
    cabling,
    issueCabling,
    modelCabling,
    shockMount,
    issueShockMount,
    modelShockMount,
    micCase,
    issueMicCase,
    modelMicCase,
    carryCase,
    issueCarryCase,
    modelCarryCase
  } = micDetails || {};

  // MIC PAIR
  const {
    yearMicPairA,
    colorMicPairA,
    conditionMicPairA,
    issueMicPairA,
    issueTubeA,
    modelTubeA,
    issueCapsuleA,
    modelCapsuleA,
    issueTransformerA,
    modelTransformerA,
    powerSupplyA,
    issueSupplyA,
    modelSupplyA,
    cablingA,
    issueCablingA,
    modelCablingA,
    shockMountA,
    issueShockMountA,
    modelShockMountA,
    micCaseA,
    issueMicCaseA,
    modelMicCaseA,
    carryCaseA,
    issueCarryCaseA,
    modelCarryCaseA,
    yearMicPairB,
    colorMicPairB,
    conditionMicPairB,
    issueMicPairB,
    issueTubeB,
    modelTubeB,
    issueCapsuleB,
    modelCapsuleB,
    issueTransformerB,
    modelTransformerB,
    powerSupplyB,
    issueSupplyB,
    modelSupplyB,
    cablingB,
    issueCablingB,
    modelCablingB,
    shockMountB,
    issueShockMountB,
    modelShockMountB,
    micCaseB,
    issueMicCaseB,
    modelMicCaseB,
    carryCaseB,
    issueCarryCaseB,
    modelCarryCaseB,
  } = micDetails || {};

  return (
    <SectionWrapper title={title}>
      {category === 'microphones' ? (
        <Fragment>
          <div className={css.sectionDetails}>
            {sequentSerial === YES && <span className={css.techTitle}><FormattedMessage id="ListingPage.sequentSerial" /></span>}
            {sequentSerial === YES && <span className={css.techValue}><FormattedMessage id={`YesNoOptions.${sequentSerial}`} /></span>}
            <span className={css.techTitle}><FormattedMessage id="ListingPage.serial" /></span>
            <span className={css.techValue}>{serialMicPair || "-"}</span>
            <span className={css.techTitle}><FormattedMessage id="ListingPage.sku" /></span>
            <span className={css.techValue}>{skuMicPair || "-"}</span>
            <span className={css.techTitle}><FormattedMessage id="ListingPage.brand" /></span>
            <span className={css.techValue}>{brandMicPair || "-"}</span>
            <span className={css.techTitle}><FormattedMessage id="ListingPage.model" /></span>
            <span className={css.techValue}>{modelMicPair || "-"}</span>
            {sellingPair === NO &&
              <>
                {micPairDetails({
                  yearMicPair,
                  colorMicPair,
                  conditionMicPair,
                  issueMicPair,
                  issueTube,
                  modelTube,
                  issueCapsule,
                  modelCapsule,
                  issueTransformer,
                  modelTransformer,
                  powerSupply,
                  issueSupply,
                  modelSupply,
                  cabling,
                  issueCabling,
                  modelCabling,
                  shockMount,
                  issueShockMount,
                  modelShockMount,
                  micCase,
                  issueMicCase,
                  modelMicCase,
                  carryCase,
                  issueCarryCase,
                  modelCarryCase
                })}
              </>
            }
          </div>
          {readMore && ( 
            <div className={css.readMoreTarget}>
              {sellingPair === YES &&
                <>
                <div className={css.col}>
                  <h2 className={css.category}>
                    <FormattedMessage id="ListingPage.a" />
                  </h2>
                  <div className={css.sectionDetails}>
                    {micPairDetails({
                      yearMicPair: yearMicPairA,
                      colorMicPair: colorMicPairA,
                      conditionMicPair: conditionMicPairA,
                      issueMicPair: issueMicPairA,
                      issueTube: issueTubeA,
                      modelTube: modelTubeA,
                      issueCapsule: issueCapsuleA,
                      modelCapsule: modelCapsuleA,
                      issueTransformer: issueTransformerA,
                      modelTransformer: modelTransformerA,
                      powerSupply: powerSupplyA,
                      issueSupply: issueSupplyA,
                      modelSupply: modelSupplyA,
                      cabling: cablingA,
                      issueCabling: issueCablingA,
                      modelCabling: modelCablingA,
                      shockMount: shockMountA,
                      issueShockMount: issueShockMountA,
                      modelShockMount: modelShockMountA,
                      micCase: micCaseA,
                      issueMicCase: issueMicCaseA,
                      modelMicCase: modelMicCaseA,
                      carryCase: carryCaseA,
                      issueCarryCase: issueCarryCaseA,
                      modelCarryCase: modelCarryCaseA
                    })}
                  </div>
                </div>
                <div className={css.col}>
                  <h2 className={css.category}>
                    <FormattedMessage id="ListingPage.b" />
                  </h2>
                  <div className={css.sectionDetails}>
                    {micPairDetails({
                      yearMicPair: yearMicPairB,
                      colorMicPair: colorMicPairB,
                      conditionMicPair: conditionMicPairB,
                      issueMicPair: issueMicPairB,
                      issueTube: issueTubeB,
                      modelTube: modelTubeB,
                      issueCapsule: issueCapsuleB,
                      modelCapsule: modelCapsuleB,
                      issueTransformer: issueTransformerB,
                      modelTransformer: modelTransformerB,
                      powerSupply: powerSupplyB,
                      issueSupply: issueSupplyB,
                      modelSupply: modelSupplyB,
                      cabling: cablingB,
                      issueCabling: issueCablingB,
                      modelCabling: modelCablingB,
                      shockMount: shockMountB,
                      issueShockMount: issueShockMountB,
                      modelShockMount: modelShockMountB,
                      micCase: micCaseB,
                      issueMicCase: issueMicCaseB,
                      modelMicCase: modelMicCaseB,
                      carryCase: carryCaseB,
                      issueCarryCase: issueCarryCaseB,
                      modelCarryCase: modelCarryCaseB
                    })}
                  </div>
                </div>
                </>
              }
            </div>
          )}
          {sellingPair === YES && (
            <h2
              className={css.readMoreLink}
              onClick={() => { setReadMore(!readMore); }}
            >
              {linkName}
            </h2>
          )}
        </Fragment>
      ) : (
        <div className={css.sectionDetails}>
          <span className={css.techTitle}><FormattedMessage id="ListingPage.brand" /></span>
          <span className={css.techValue}>{brand || "-"}</span>
          <span className={css.techTitle}><FormattedMessage id="ListingPage.model" /></span>
          <span className={css.techValue}>{model || "-"}</span>
          <span className={css.techTitle}><FormattedMessage id="ListingPage.year" /></span>
          <span className={css.techValue}>{year || "-"}</span>
          <span className={css.techTitle}><FormattedMessage id="ListingPage.color" /></span>
          <span className={css.techValue}>{color || "-"}</span>
          <span className={css.techTitle}><FormattedMessage id="ListingPage.quantity" /></span>
          <span className={css.techValue}>{quantity || "-"}</span>
          <span className={css.techTitle}><FormattedMessage id="ListingPage.serial" /></span>
          <span className={css.techValue}>{serial || "-"}</span>
          <span className={css.techTitle}><FormattedMessage id="ListingPage.sku" /></span>
          <span className={css.techValue}>{sku || "-"}</span>
          <span className={css.techTitle}><FormattedMessage id="ListingPage.condition" /></span>
          <span className={css.techValue}>{condition ? <FormattedMessage id={`ConditionOptions.${condition}`} /> : "-"}</span>
          <span className={css.techTitle}><FormattedMessage id="ListingPage.issue" /></span>
          <span className={css.techValue}>{issue ? <FormattedMessage id={`IssueOptions.${issue}`} /> : "-"}</span>
        </div>
      )}
    </SectionWrapper>
  );
};

export default SectionDetailsMaybe;
