import React, { Component } from 'react';
import { bool, func, instanceOf, object, oneOfType, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  FormattedMessage,
  injectIntl,
  intlShape,
  FormattedHTMLMessage,
} from '../../util/reactIntl';
import { withRouter } from 'react-router-dom';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { pathByRouteName, findRouteByRouteName } from '../../util/routes';
import {
  propTypes,
  LINE_ITEM_NIGHT,
  LINE_ITEM_DAY,
  DATE_TYPE_DATE,
  NEW_ADDRESS,
  EXISTING_ADDRESS,
  PAYMENT_TYPES_AFFIRM,
} from '../../util/types';
import {
  ensureListing,
  ensureCurrentUser,
  ensureUser,
  ensureTransaction,
  ensureBooking,
  ensureStripeCustomer,
  ensurePaymentMethodCard,
  denormalisedCustomAPIResponseEntities,
} from '../../util/data';
import { dateFromLocalToAPI, minutesBetween } from '../../util/dates';
import { createSlug } from '../../util/urlHelpers';
import {
  isTransactionInitiateAmountTooLowError,
  isTransactionInitiateListingNotFoundError,
  isTransactionInitiateMissingStripeAccountError,
  isTransactionInitiateBookingTimeNotAvailableError,
  isTransactionChargeDisabledError,
  isTransactionZeroPaymentError,
  transactionInitiateOrderStripeErrors,
} from '../../util/errors';
import { formatMoney } from '../../util/currency';
import { TRANSITION_ENQUIRE, txIsPaymentPending, txIsPaymentExpired } from '../../util/transaction';
import {
  BookingBreakdown,
  Logo,
  NamedLink,
  NamedRedirect,
  Page,
  ResponsiveImage,
  IconSpinner,
  Modal,
  Button,
  IconBannedUser,
} from '../../components';
import { StripePaymentForm } from '../../forms';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { confirmCardPayment, retrievePaymentIntent } from '../../ducks/stripe.duck';
import { savePaymentMethod } from '../../ducks/paymentMethods.duck';
import { types as sdkTypes } from '../../util/sdkLoader';

import {
  initiateOrder,
  setInitialValues,
  speculateTransaction,
  stripeCustomer,
  confirmPayment,
  sendMessage,
  saveShippingAddress,
  initiateOrderWithoutStripe,
  initiateOrderAutomaticDeclined,
  initiateAffirmOrder,
  createStripePaymentIntent
} from './CheckoutPage.duck';
import { storeData, storedData, clearData } from './CheckoutPageSessionHelpers';
import isEmpty from 'lodash/isEmpty';
import { MAX_AMOUNT_STRIPE, MIN_AMOUNT_AFFIRM, MAX_AMOUNT_AFFIRM } from '../../util/validators';

import css from './CheckoutPage.module.css';

const { Money } = sdkTypes;

const STORAGE_KEY = 'CheckoutPage';

// Stripe PaymentIntent statuses, where user actions are already completed
// https://stripe.com/docs/payments/payment-intents/status
const STRIPE_PI_USER_ACTIONS_DONE_STATUSES = ['processing', 'requires_capture', 'succeeded'];

// Payment charge options
const ONETIME_PAYMENT = 'ONETIME_PAYMENT';
const PAY_AND_SAVE_FOR_LATER_USE = 'PAY_AND_SAVE_FOR_LATER_USE';
const USE_SAVED_CARD = 'USE_SAVED_CARD';

const paymentFlow = (selectedPaymentMethod, saveAfterOnetimePayment) => {
  // Payment mode could be 'replaceCard', but without explicit saveAfterOnetimePayment flag,
  // we'll handle it as one-time payment
  return selectedPaymentMethod === 'defaultCard'
    ? USE_SAVED_CARD
    : saveAfterOnetimePayment
    ? PAY_AND_SAVE_FOR_LATER_USE
    : ONETIME_PAYMENT;
};

const initializeOrderPage = (initialValues, routes, dispatch) => {
  const OrderPage = findRouteByRouteName('OrderDetailsPage', routes);

  // Transaction is already created, but if the initial message
  // sending failed, we tell it to the OrderDetailsPage.
  dispatch(OrderPage.setInitialValues(initialValues));
};

const checkIsPaymentExpired = existingTransaction => {
  return txIsPaymentExpired(existingTransaction)
    ? true
    : txIsPaymentPending(existingTransaction)
    ? minutesBetween(existingTransaction.attributes.lastTransitionedAt, new Date()) >= 15
    : false;
};

export class CheckoutPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageData: {},
      dataLoaded: false,
      submitting: false,
      selectedShippingOption: null,
      openWarningModal: false,
      currentSubmittedValues: null,
      currentShippingAddressType: NEW_ADDRESS,
      currentShippingCountry: null,
      currentPostalCode: null,
    };
    this.stripe = null;

    this.onStripeInitialized = this.onStripeInitialized.bind(this);
    this.loadInitialData = this.loadInitialData.bind(this);
    this.handlePaymentIntent = this.handlePaymentIntent.bind(this);
    this.handlePaymentIntentWithoutStripe = this.handlePaymentIntentWithoutStripe.bind(this);
    this.handleAffirmPaymentIntent = this.handleAffirmPaymentIntent.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getOfferDifferenceLineItem = this.getOfferDifferenceLineItem.bind(this);
    this.getShippingLineItem = this.getShippingLineItem.bind(this);
    this.changeShippingOption = this.changeShippingOption.bind(this);
    this.handleOfferBelowReservePrice = this.handleOfferBelowReservePrice.bind(this);
    this.changeShippingAddress = this.changeShippingAddress.bind(this);
  }

  componentDidMount() {
    if (window) {
      this.loadInitialData();
    }
  }

  changeShippingOption(option) {
    this.setState({ selectedShippingOption: option });
  }

  changeShippingAddress(changedTo) {
    this.setState({ currentShippingAddressType: changedTo, currentPostalCode: null });
  }

  getShippingLineItem() {
    const option = this.state.selectedShippingOption;

    if (!option || option === 'collect') {
      return [];
    }
    const { shipping } = this.props.listing.attributes.publicData;
    const amount = option === 'domestic' ? shipping.domestic.amount : shipping.international.amount;

    const currency =
      option === 'domestic' ? shipping.domestic.currency : shipping.international.currency;

    return [
      {
        code: 'line-item/shipping',
        unitPrice: new Money(amount, currency),
        quantity: 1,
        includeFor: ['customer', 'provider'],
      },
    ];
  }

  getOfferDifferenceLineItem() {
    let lineItem = [];
    if (this.props.offer) {
      const offerDifference = this.props.listing.attributes.price.amount - this.props.offer.amount;
      lineItem = [
        {
          code: 'line-item/offer-difference',
          unitPrice: new Money(Math.abs(offerDifference), 'USD'),
          // If they offer MORE than the Asking... add it instead of subtract
          percentage: offerDifference > 0 ? -100 : 100,
          includeFor: ['customer', 'provider'],
        },
      ];
    }
    return lineItem;
  }

  /**
   * Load initial data for the page
   *
   * Since the data for the checkout is not passed in the URL (there
   * might be lots of options in the future), we must pass in the data
   * some other way. Currently the ListingPage sets the initial data
   * for the CheckoutPage's Redux store.
   *
   * For some cases (e.g. a refresh in the CheckoutPage), the Redux
   * store is empty. To handle that case, we store the received data
   * to window.sessionStorage and read it from there if no props from
   * the store exist.
   *
   * This function also sets of fetching the speculative transaction
   * based on this initial data.
   */
  async loadInitialData() {
    const {
      // bookingData,
      // bookingDates,
      listing,
      transaction,
      fetchSpeculatedTransaction,
      fetchStripeCustomer,
      history,
      currentUser,
    } = this.props;

    // Fetch currentUser with stripeCustomer entity
    // Note: since there's need for data loading in "componentWillMount" function,
    //       this is added here instead of loadData static function.
    fetchStripeCustomer();

    // Browser's back navigation should not rewrite data in session store.
    // Action is 'POP' on both history.back() and page refresh cases.
    // Action is 'PUSH' when user has directed through a link
    // Action is 'REPLACE' when user has directed through login/signup process
    const hasNavigatedThroughLink = history.action === 'PUSH' || history.action === 'REPLACE';

    // const hasDataInProps = !!(bookingData && bookingDates && listing) && hasNavigatedThroughLink;
    const hasDataInProps = !!listing && hasNavigatedThroughLink;
    if (hasDataInProps) {
      // Store data only if data is passed through props and user has navigated through a link.
      // storeData(bookingData, bookingDates, listing, transaction, STORAGE_KEY);
      storeData(listing, transaction, STORAGE_KEY);
    }

    // NOTE: stored data can be empty if user has already successfully completed transaction.
    // const pageData = hasDataInProps
    //   ? { bookingData, bookingDates, listing, transaction }
    //   : storedData(STORAGE_KEY);

    const pageData = hasDataInProps ? { listing, transaction } : storedData(STORAGE_KEY);

    // Check if a booking is already created according to stored data.
    const tx = pageData ? pageData.transaction : null;
    const isBookingCreated = tx && tx.booking && tx.booking.id;

    // const shouldFetchSpeculatedTransaction =
    //   pageData &&
    //   pageData.listing &&
    //   pageData.listing.id &&
    //   pageData.bookingData &&
    //   pageData.bookingDates &&
    //   pageData.bookingDates.bookingStart &&
    //   pageData.bookingDates.bookingEnd &&
    //   !isBookingCreated;

    const shouldFetchSpeculatedTransaction =
      pageData && pageData.listing && pageData.listing.id && !isBookingCreated;

    if (shouldFetchSpeculatedTransaction) {
      const listingId = pageData.listing.id;
      const transactionId = tx ? tx.id : null;

      // Convert picked date to date that will be converted on the API as
      // a noon of correct year-month-date combo in UTC
      // const bookingStartForAPI = dateFromLocalToAPI(bookingStart);
      // const bookingEndForAPI = dateFromLocalToAPI(bookingEnd);

      // Fetch speculated transaction for showing price in booking breakdown
      // NOTE: if unit type is line-item/units, quantity needs to be added.
      // The way to pass it to checkout page is through pageData.bookingData
      fetchSpeculatedTransaction(
        {
          listingId,
          lineItems: [...this.getOfferDifferenceLineItem()],
          // bookingStart: bookingStartForAPI,
          // bookingEnd: bookingEndForAPI,
        },
        transactionId
      );
    }

    const currentShippingAddress =
      currentUser?.attributes?.profile?.publicData?.shippingAddress || {};

    const isShippingAddressExisting = !isEmpty(currentShippingAddress);

    this.setState({
      pageData: pageData || {},
      dataLoaded: true,
      currentShippingAddressType: isShippingAddressExisting ? EXISTING_ADDRESS : NEW_ADDRESS,
      currentShippingCountry: isShippingAddressExisting && currentShippingAddress.country,
    });
  }

  handlePaymentIntent(handlePaymentParams) {
    const {
      currentUser,
      stripeCustomerFetched,
      onInitiateOrder,
      onConfirmCardPayment,
      onConfirmPayment,
      onSendMessage,
      onSavePaymentMethod,
      offer,
      intl,
    } = this.props;
    const {
      pageData,
      speculatedTransaction,
      message,
      paymentIntent,
      selectedPaymentMethod,
      saveAfterOnetimePayment,
    } = handlePaymentParams;
    const storedTx = ensureTransaction(pageData.transaction);

    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const ensuredStripeCustomer = ensureStripeCustomer(ensuredCurrentUser.stripeCustomer);
    const ensuredDefaultPaymentMethod = ensurePaymentMethodCard(
      ensuredStripeCustomer.defaultPaymentMethod
    );

    let createdPaymentIntent = null;

    const hasDefaultPaymentMethod = !!(
      stripeCustomerFetched &&
      ensuredStripeCustomer.attributes.stripeCustomerId &&
      ensuredDefaultPaymentMethod.id
    );
    const stripePaymentMethodId = hasDefaultPaymentMethod
      ? ensuredDefaultPaymentMethod.attributes.stripePaymentMethodId
      : null;

    const selectedPaymentFlow = paymentFlow(selectedPaymentMethod, saveAfterOnetimePayment);

    // Step 1: initiate order by requesting payment from Marketplace API
    const fnRequestPayment = fnParams => {
      // fnParams should be { listingId, bookingStart, bookingEnd }
      const hasPaymentIntents =
        storedTx.attributes.protectedData && storedTx.attributes.protectedData.stripePaymentIntents;

      // If paymentIntent exists, order has been initiated previously.
      return hasPaymentIntents ? Promise.resolve(storedTx) : onInitiateOrder(fnParams, storedTx.id);
    };

    // Step 2: pay using Stripe SDK
    const fnConfirmCardPayment = fnParams => {
      // fnParams should be returned transaction entity

      const order = ensureTransaction(fnParams);
      if (order.id) {
        // Store order.
        const { bookingData, bookingDates, listing } = pageData;
        storeData(bookingData, bookingDates, listing, order, STORAGE_KEY);
        this.setState({ pageData: { ...pageData, transaction: order } });
      }

      const hasPaymentIntents =
        order.attributes.protectedData && order.attributes.protectedData.stripePaymentIntents;

      if (!hasPaymentIntents) {
        throw new Error(
          `Missing StripePaymentIntents key in transaction's protectedData. Check that your transaction process is configured to use payment intents.`
        );
      }

      const { stripePaymentIntentClientSecret } = hasPaymentIntents
        ? order.attributes.protectedData.stripePaymentIntents.default
        : null;

      const { stripe, card, billingDetails, paymentIntent } = handlePaymentParams;
      const stripeElementMaybe = selectedPaymentFlow !== USE_SAVED_CARD ? { card } : {};

      // Note: payment_method could be set here for USE_SAVED_CARD flow.
      // { payment_method: stripePaymentMethodId }
      // However, we have set it already on API side, when PaymentIntent was created.
      const paymentParams =
        selectedPaymentFlow !== USE_SAVED_CARD
          ? {
              payment_method: {
                billing_details: billingDetails,
                card: card,
              },
            }
          : {};

      const params = {
        stripePaymentIntentClientSecret,
        orderId: order.id,
        stripe,
        ...stripeElementMaybe,
        paymentParams,
      };

      // If paymentIntent status is not waiting user action,
      // confirmCardPayment has been called previously.
      const hasPaymentIntentUserActionsDone =
        paymentIntent && STRIPE_PI_USER_ACTIONS_DONE_STATUSES.includes(paymentIntent.status);
      return hasPaymentIntentUserActionsDone
        ? Promise.resolve({ transactionId: order.id, paymentIntent })
        : onConfirmCardPayment(params);
    };

    // Step 3: complete order by confirming payment to Marketplace API
    // Parameter should contain { paymentIntent, transactionId } returned in step 2
    const fnConfirmPayment = fnParams => {
      createdPaymentIntent = fnParams.paymentIntent;
      return onConfirmPayment(fnParams);
    };

    // Step 4: send initial message
    const fnSendMessage = fnParams => {
      return onSendMessage({ ...fnParams, message });
    };

    // Step 5: optionally save card as defaultPaymentMethod
    const fnSavePaymentMethod = fnParams => {
      const pi = createdPaymentIntent || paymentIntent;

      if (selectedPaymentFlow === PAY_AND_SAVE_FOR_LATER_USE) {
        return onSavePaymentMethod(ensuredStripeCustomer, pi.payment_method)
          .then(response => {
            if (response.errors) {
              return { ...fnParams, paymentMethodSaved: false };
            }
            return { ...fnParams, paymentMethodSaved: true };
          })
          .catch(e => {
            // Real error cases are catched already in paymentMethods page.
            return { ...fnParams, paymentMethodSaved: false };
          });
      } else {
        return Promise.resolve({ ...fnParams, paymentMethodSaved: true });
      }
    };

    // Here we create promise calls in sequence
    // This is pretty much the same as:
    // fnRequestPayment({...initialParams})
    //   .then(result => fnConfirmCardPayment({...result}))
    //   .then(result => fnConfirmPayment({...result}))
    const applyAsync = (acc, val) => acc.then(val);
    const composeAsync = (...funcs) => x => funcs.reduce(applyAsync, Promise.resolve(x));
    const handlePaymentIntentCreation = composeAsync(
      fnRequestPayment,
      fnConfirmCardPayment,
      fnConfirmPayment,
      fnSendMessage,
      fnSavePaymentMethod
    );

    // Create order aka transaction
    // NOTE: if unit type is line-item/units, quantity needs to be added.
    // The way to pass it to checkout page is through pageData.bookingData
    const tx = speculatedTransaction ? speculatedTransaction : storedTx;

    // Note: optionalPaymentParams contains Stripe paymentMethod,
    // but that can also be passed on Step 2
    // stripe.confirmCardPayment(stripe, { payment_method: stripePaymentMethodId })
    const optionalPaymentParams =
      selectedPaymentFlow === USE_SAVED_CARD && hasDefaultPaymentMethod
        ? { paymentMethod: stripePaymentMethodId }
        : selectedPaymentFlow === PAY_AND_SAVE_FOR_LATER_USE
        ? { setupPaymentMethodForSaving: true }
        : {};

    const { listing } = pageData || {};
    const offerPrice = offer ? offer : listing?.attributes.price;
    const { timedAuction = {}, isPendingAutomatedTimedAuction, isTimedAuction } =
      listing?.attributes.publicData || {};
    const isAutomatedReservePriceMetFirstTime =
      isTimedAuction && isPendingAutomatedTimedAuction && !!timedAuction.duration;
    const remainingTime = isAutomatedReservePriceMetFirstTime
      ? intl.formatMessage(
          { id: 'TimedAuctionCheckoutPage.remainingTimeInTxAutomatedFirstMet' },
          { days: timedAuction.duration }
        )
      : null;
    const orderParams = {
      listingId: listing.id,
      lineItems: [...this.getOfferDifferenceLineItem(), ...this.getShippingLineItem()],
      // bookingStart: tx.booking.attributes.start,
      // bookingEnd: tx.booking.attributes.end,
      ...optionalPaymentParams,
      offer: {
        amount: offerPrice.amount,
        currency: offerPrice.currency,
      },
      remainingTime,
    };

    return handlePaymentIntentCreation(orderParams);
  }

  handlePaymentIntentWithoutStripe(handlePaymentParams) {
    const { onInitiateOrderWithoutStripe, onSendMessage, offer } = this.props;
    const { pageData, message, otherPayment } = handlePaymentParams;

    const storedTx = ensureTransaction(pageData.transaction);

    // Step 1: initiate order by requesting payment from Marketplace API
    const fnRequestPayment = fnParams => {
      // fnParams should be { listingId, bookingStart, bookingEnd }
      const hasPaymentIntents = storedTx.attributes.protectedData;

      // If paymentIntent exists, order has been initiated previously.
      return hasPaymentIntents
        ? Promise.resolve(storedTx)
        : onInitiateOrderWithoutStripe(fnParams, storedTx.id);
    };

    // Step 2: send initial message
    const fnSendMessage = fnParams => {
      return onSendMessage({ ...fnParams, message });
    };

    // Here we create promise calls in sequence
    // This is pretty much the same as:
    // fnRequestPayment({...initialParams})
    //   .then(result => fnSendMessage({...result}))
    const applyAsync = (acc, val) => acc.then(val);
    const composeAsync = (...funcs) => x => funcs.reduce(applyAsync, Promise.resolve(x));
    const handlePaymentIntentCreation = composeAsync(fnRequestPayment, fnSendMessage);

    const orderParams = {
      listingId: pageData.listing.id,
      lineItems: [...this.getOfferDifferenceLineItem(), ...this.getShippingLineItem()],
      otherPayment,
      offer: offer
        ? {
            amount: offer.amount,
            currency: offer.currency,
          }
        : {
            amount: pageData.listing.attributes.price.amount,
            currency: pageData.listing.attributes.price.currency,
          },
    };

    return handlePaymentIntentCreation(orderParams);
  }
  
  // Flex doesn't support Buy now, Pay later payments at the moment
  handleAffirmPaymentIntent(handlePaymentParams) {
    const { onInitiateAffirmOrder, onCreateStripePaymentIntent, onSendMessage, offer, currentUser } = this.props;
    const { pageData, message, otherPayment } = handlePaymentParams;

    const storedTx = ensureTransaction(pageData.transaction);

    const hasPaymentIntents = storedTx.attributes.metadata?.paymentIntentId;

    // Step 1: initiate order by requesting payment from Marketplace API
    const fnRequestPayment = fnParams => {
      // fnParams should be { listingId, bookingStart, bookingEnd }

      // If paymentIntent exists, order has been initiated previously.
      return hasPaymentIntents
        ? Promise.resolve(storedTx)
        : onInitiateAffirmOrder(fnParams, storedTx.id);
    };

    // Step 2: send initial message
    const fnSendMessage = fnParams => {
      return onSendMessage({ ...fnParams, message });
    };

    // Step 3: create payment intent and redirect user to payment page
    const fnCreatePaymentIntent = fnParams => {
      return hasPaymentIntents 
      ? Promise.resolve(storedTx)
      : onCreateStripePaymentIntent({ 
        transactionId: fnParams.orderId.uuid, 
        type: PAYMENT_TYPES_AFFIRM 
      });
    };

    // Step 4: redirect user to payment page
    const fnRedirectToPaymentPage = (transaction) => {
      const { paymentUrl } = transaction.attributes.metadata;
      window.location.href = paymentUrl;
    };

    // Here we create promise calls in sequence
    // This is pretty much the same as:
    // fnRequestPayment({...initialParams})
    //   .then(result => fnSendMessage({...result}))
    const applyAsync = (acc, val) => acc.then(val);
    const composeAsync = (...funcs) => x => funcs.reduce(applyAsync, Promise.resolve(x));
    const handlePaymentIntentCreation = composeAsync(
      fnRequestPayment, 
      fnSendMessage, 
      fnCreatePaymentIntent,
      fnRedirectToPaymentPage
    );

    const orderParams = {
      listingId: pageData.listing.id,
      lineItems: [...this.getOfferDifferenceLineItem(), ...this.getShippingLineItem()],
      otherPayment,
      offer: offer
        ? {
            amount: offer.amount,
            currency: offer.currency,
          }
        : {
            amount: pageData.listing.attributes.price.amount,
            currency: pageData.listing.attributes.price.currency,
          },
    };

    return handlePaymentIntentCreation(orderParams);
  }

  handleOfferBelowReservePrice(handlePaymentParams) {
    const { onInitiateOrderAutomaticDeclined, onSendMessage, offer } = this.props;
    const { pageData, message } = handlePaymentParams;

    const storedTx = ensureTransaction(pageData.transaction);

    // Step 1: initiate order that will be automatic declined
    const fnDeclineOffer = fnParams => {
      return onInitiateOrderAutomaticDeclined(fnParams, storedTx.id);
    };

    // Step 2: send initial message
    const fnSendMessage = fnParams => {
      return onSendMessage({ ...fnParams, message });
    };

    // Here we create promise calls in sequence
    // This is pretty much the same as:
    // fnDeclineOffer({...initialParams})
    //   .then(result => fnSendMessage({...result}))
    const applyAsync = (acc, val) => acc.then(val);
    const composeAsync = (...funcs) => x => funcs.reduce(applyAsync, Promise.resolve(x));
    const handleDeclineOffer = composeAsync(fnDeclineOffer, fnSendMessage);

    const offerPrice = offer ? offer : pageData.listing.attributes.price;

    const orderParams = {
      listingId: pageData.listing.id,
      lineItems: [...this.getOfferDifferenceLineItem(), ...this.getShippingLineItem()],
      offer: {
        amount: offerPrice.amount,
        current: offerPrice.currency,
      },
    };

    return handleDeclineOffer(orderParams);
  }

  handleSubmit(values) {
    if (this.state.submitting) {
      return;
    }
    this.setState({ submitting: true });

    const {
      history,
      speculatedTransaction,
      currentUser,
      paymentIntent,
      dispatch,
      onSaveShippingAddress,
    } = this.props;
    const { card, message, paymentMethod, formValues } = values;
    const {
      name,
      addressLine1,
      addressLine2,
      postal,
      city,
      state,
      country,
      saveAfterOnetimePayment,
      saveShippingAddress,
      sameAsShippingAddress,
      shipping,
      isSameShippingAddress,
      currentShippingAddress,
      otherPayment,
      isShippingAddressExisting,
      isPickupOnly,
    } = formValues;

    // Billing address is recommended.
    // However, let's not assume that <StripePaymentAddress> data is among formValues.
    // Read more about this from Stripe's docs
    // https://stripe.com/docs/stripe-js/reference#stripe-handle-card-payment-no-element
    const shippingAddress = isPickupOnly
      ? null
      : isSameShippingAddress
      ? currentShippingAddress
      : {
          city: shipping?.city,
          country: shipping?.country,
          addressLine1: shipping?.addressLine1,
          addressLine2: shipping?.addressLine2,
          postal: shipping?.postal,
          state: shipping?.state,
          recipientName: shipping?.recipientName,
          phoneNumber: shipping?.phoneNumber,
        };

    const addressMaybe =
      sameAsShippingAddress && sameAsShippingAddress[0] && shippingAddress && !otherPayment
        ? {
            address: {
              city: shippingAddress.city,
              country: shippingAddress.country,
              line1: shippingAddress.addressLine1,
              line2: shippingAddress.addressLine2,
              postal_code: shippingAddress.postal,
              state: shippingAddress.state,
            },
          }
        : addressLine1 && postal
        ? {
            address: {
              city: city,
              country: country,
              line1: addressLine1,
              line2: addressLine2,
              postal_code: postal,
              state: state,
            },
          }
        : {};

    const billingDetails = {
      name,
      email: ensureCurrentUser(currentUser).attributes.email,
      ...addressMaybe,
    };

    const requestPaymentParams = {
      pageData: this.state.pageData,
      speculatedTransaction,
      stripe: this.stripe,
      card,
      billingDetails,
      message,
      paymentIntent,
      selectedPaymentMethod: paymentMethod,
      saveAfterOnetimePayment: !!saveAfterOnetimePayment,
      otherPayment,
    };

    const { listing } = this.state.pageData;

    const { minReservePrice } = listing.attributes?.publicData?.pricing || {};
    const offerPrice = this.props.offer || listing.attributes.price;
    const isBelowReservePrice = minReservePrice && offerPrice?.amount < minReservePrice.amount;

    const handleProcessCheckout = isBelowReservePrice
      ? this.handleOfferBelowReservePrice
      : otherPayment
        ? otherPayment === PAYMENT_TYPES_AFFIRM
          ? this.handleAffirmPaymentIntent
          : this.handlePaymentIntentWithoutStripe
        : this.handlePaymentIntent;

    const allPromiseCalls = [handleProcessCheckout(requestPaymentParams)];

    if (!isShippingAddressExisting || (saveShippingAddress && saveShippingAddress[0])) {
      allPromiseCalls.push(onSaveShippingAddress(shippingAddress));
    }

    Promise.all(allPromiseCalls)
      .then(res => {
        const { orderId, messageSuccess, paymentMethodSaved } = res[0];
        this.setState({ submitting: false, openWarningModal: isBelowReservePrice });

        const routes = routeConfiguration();
        if (!isBelowReservePrice) {
          const initialMessageFailedToTransaction = messageSuccess || otherPayment ? null : orderId;
          const orderDetailsPath = pathByRouteName('OrderDetailsPage', routes, {
            id: orderId.uuid,
          });
          const initialValues = {
            initialMessageFailedToTransaction,
            savePaymentMethodFailed: otherPayment ? false : !paymentMethodSaved,
          };

          initializeOrderPage(initialValues, routes, dispatch);
          clearData(STORAGE_KEY);
          history.push(orderDetailsPath);
        }
      })
      .catch(err => {
        console.error(err);
        this.setState({ submitting: false });
      });
  }

  onStripeInitialized(stripe) {
    this.stripe = stripe;

    const { paymentIntent, onRetrievePaymentIntent } = this.props;
    const tx = this.state.pageData ? this.state.pageData.transaction : null;

    // We need to get up to date PI, if booking is created but payment is not expired.
    const shouldFetchPaymentIntent =
      this.stripe &&
      !paymentIntent &&
      tx &&
      tx.id &&
      tx.booking &&
      tx.booking.id &&
      txIsPaymentPending(tx) &&
      !checkIsPaymentExpired(tx);

    if (shouldFetchPaymentIntent) {
      const { stripePaymentIntentClientSecret } =
        tx.attributes.protectedData && tx.attributes.protectedData.stripePaymentIntents
          ? tx.attributes.protectedData.stripePaymentIntents.default
          : {};

      // Fetch up to date PaymentIntent from Stripe
      onRetrievePaymentIntent({ stripe, stripePaymentIntentClientSecret });
    }
  }

  render() {
    const {
      scrollingDisabled,
      speculateTransactionInProgress,
      speculateTransactionError,
      speculatedTransaction: speculatedTransactionMaybe,
      initiateOrderError,
      confirmPaymentError,
      intl,
      params,
      currentUser,
      confirmCardPaymentError,
      paymentIntent,
      retrievePaymentIntentError,
      stripeCustomerFetched,
    } = this.props;

    // Since the listing data is already given from the ListingPage
    // and stored to handle refreshes, it might not have the possible
    // deleted or closed information in it. If the transaction
    // initiate or the speculative initiate fail due to the listing
    // being deleted or closec, we should dig the information from the
    // errors and not the listing data.
    const listingNotFound =
      isTransactionInitiateListingNotFoundError(speculateTransactionError) ||
      isTransactionInitiateListingNotFoundError(initiateOrderError);

    const isLoading = !this.state.dataLoaded || speculateTransactionInProgress;

    const { listing, transaction } = this.state.pageData;
    const existingTransaction = ensureTransaction(transaction);
    const speculatedTransaction = ensureTransaction(speculatedTransactionMaybe, {}, null);
    const currentListing = ensureListing(listing);
    const currentAuthor = ensureUser(currentListing.author);

    const listingTitle = currentListing.attributes.title;
    const title = intl.formatMessage({ id: 'CheckoutPage.title' }, { listingTitle });

    const pageProps = { title, scrollingDisabled };
    const topbar = (
      <div className={css.topbar}>
        <NamedLink className={css.home} name="LandingPage">
          <Logo
            className={css.logoMobile}
            title={intl.formatMessage({ id: 'CheckoutPage.goToLandingPage' })}
            format="mobile"
          />
          <Logo
            className={css.logoDesktop}
            alt={intl.formatMessage({ id: 'CheckoutPage.goToLandingPage' })}
            format="desktop"
          />
        </NamedLink>
      </div>
    );

    if (isLoading) {
      return <Page {...pageProps}>{topbar}</Page>;
    }

    const isOwnListing =
      currentUser &&
      currentUser.id &&
      currentAuthor &&
      currentAuthor.id &&
      currentAuthor.id.uuid === currentUser.id.uuid;

    const hasListingAndAuthor = !!(currentListing.id && currentAuthor.id);
    // const hasBookingDates = !!(
    //   bookingDates &&
    //   bookingDates.bookingStart &&
    //   bookingDates.bookingEnd
    // );
    // const hasRequiredData = hasListingAndAuthor && hasBookingDates;
    const hasRequiredData = hasListingAndAuthor;
    const canShowPage = hasRequiredData && !isOwnListing;
    const shouldRedirect = !isLoading && !canShowPage;

    // Redirect back to ListingPage if data is missing.
    // Redirection must happen before any data format error is thrown (e.g. wrong currency)
    if (shouldRedirect) {
      // eslint-disable-next-line no-console
      console.error('Missing or invalid data for checkout, redirecting back to listing page.', {
        transaction: speculatedTransaction,
        listing,
      });
      return <NamedRedirect name="ListingPage" params={params} />;
    }

    // Get the shipping address if any
    const currentShippingAddress =
      currentUser &&
      currentUser.attributes &&
      currentUser.attributes.profile.publicData.shippingAddress
        ? currentUser.attributes.profile.publicData.shippingAddress
        : {};

    const isShippingAddressExisting = !isEmpty(currentShippingAddress);
    const shippingOptions = currentListing.attributes.publicData.shipping;
    const isPickupOnly = !shippingOptions.domestic && !shippingOptions.international;
    // Show breakdown only when speculated transaction and booking are loaded
    // (i.e. have an id)
    const tx = existingTransaction.booking ? existingTransaction : speculatedTransaction;
    const txBooking = ensureBooking(tx.booking);
    const breakdown = tx.id ? (
      <BookingBreakdown
        className={css.bookingBreakdown}
        userRole="customer"
        unitType={config.bookingUnitType}
        transaction={tx}
        booking={tx.booking}
        dateType={DATE_TYPE_DATE}
        selectedShippingOption={this.state.selectedShippingOption}
        shippingOptions={shippingOptions}
        isPickupOnly={isPickupOnly}
      />
    ) : null;

    const isPaymentExpired = checkIsPaymentExpired(existingTransaction);
    const hasDefaultPaymentMethod = !!(
      stripeCustomerFetched &&
      ensureStripeCustomer(currentUser.stripeCustomer).attributes.stripeCustomerId &&
      ensurePaymentMethodCard(currentUser.stripeCustomer.defaultPaymentMethod).id
    );

    // Allow showing page when currentUser is still being downloaded,
    // but show payment form only when user info is loaded.
    const showPaymentForm = !!(
      currentUser &&
      hasRequiredData &&
      !listingNotFound &&
      !initiateOrderError &&
      !speculateTransactionError &&
      !retrievePaymentIntentError &&
      !isPaymentExpired
    );

    const firstImage =
      currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

    const listingLink = (
      <NamedLink
        name="ListingPage"
        params={{ id: currentListing.id.uuid, slug: createSlug(listingTitle) }}
      >
        <FormattedMessage id="CheckoutPage.errorlistingLinkText" />
      </NamedLink>
    );

    const listingLinkBtn = (
      <NamedLink
        className={css.modalBtn}
        name="ListingPage"
        params={{ id: currentListing.id.uuid, slug: createSlug(listingTitle) }}
      >
        <FormattedMessage id="CheckoutPage.backToListing" />
      </NamedLink>
    );

    const isAmountTooLowError = isTransactionInitiateAmountTooLowError(initiateOrderError);
    const isChargeDisabledError = isTransactionChargeDisabledError(initiateOrderError);
    const isBookingTimeNotAvailableError = isTransactionInitiateBookingTimeNotAvailableError(
      initiateOrderError
    );
    const stripeErrors = transactionInitiateOrderStripeErrors(initiateOrderError);

    let initiateOrderErrorMessage = null;
    let listingNotFoundErrorMessage = null;

    if (listingNotFound) {
      listingNotFoundErrorMessage = (
        <p className={css.notFoundError}>
          <FormattedMessage id="CheckoutPage.listingNotFoundError" />
        </p>
      );
    } else if (isAmountTooLowError) {
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.initiateOrderAmountTooLow" />
        </p>
      );
    } else if (isBookingTimeNotAvailableError) {
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.bookingTimeNotAvailableMessage" />
        </p>
      );
    } else if (isChargeDisabledError) {
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.chargeDisabledMessage" />
        </p>
      );
    } else if (stripeErrors && stripeErrors.length > 0) {
      // NOTE: Error messages from Stripes are not part of translations.
      // By default they are in English.
      const stripeErrorsAsString = stripeErrors.join(', ');
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage
            id="CheckoutPage.initiateOrderStripeError"
            values={{ stripeErrors: stripeErrorsAsString }}
          />
        </p>
      );
    } else if (initiateOrderError) {
      // Generic initiate order error
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.initiateOrderError" values={{ listingLink }} />
        </p>
      );
    }

    const speculateTransactionErrorMessage = speculateTransactionError ? (
      <p className={css.speculateError}>
        <FormattedMessage id="CheckoutPage.speculateTransactionError" />
      </p>
    ) : null;
    let speculateErrorMessage = null;

    if (isTransactionInitiateMissingStripeAccountError(speculateTransactionError)) {
      speculateErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.providerStripeAccountMissingError" />
        </p>
      );
    } else if (isTransactionInitiateBookingTimeNotAvailableError(speculateTransactionError)) {
      speculateErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.bookingTimeNotAvailableMessage" />
        </p>
      );
    } else if (isTransactionZeroPaymentError(speculateTransactionError)) {
      speculateErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.initiateOrderAmountTooLow" />
        </p>
      );
    } else if (speculateTransactionError) {
      speculateErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.speculateFailedMessage" />
        </p>
      );
    }

    const unitType = config.bookingUnitType;
    const isNightly = unitType === LINE_ITEM_NIGHT;
    const isDaily = unitType === LINE_ITEM_DAY;

    const unitTranslationKey = isNightly
      ? 'CheckoutPage.perNight'
      : isDaily
      ? 'CheckoutPage.perDay'
      : 'CheckoutPage.perUnit';

    const price = currentListing.attributes.price;
    const offerPrice = this.props.offer || price;
    const { minReservePrice } = currentListing.attributes?.publicData?.pricing || {};
    const formattedPrice = formatMoney(intl, price);
    const detailsSubTitle = `${formattedPrice} ${intl.formatMessage({ id: unitTranslationKey })}`;

    const showInitialMessageInput = !(
      existingTransaction && existingTransaction.attributes.lastTransition === TRANSITION_ENQUIRE
    );

    // Get first and last name of the current user and use it in the StripePaymentForm to autofill the name field
    const userName =
      currentUser && currentUser.attributes
        ? `${currentUser.attributes.profile.firstName} ${currentUser.attributes.profile.lastName}`
        : null;

    // Check if the offer or listing price is higher than the limit amount of Stripe ( >= 1 million dollars )
    const isOfferHigherThanStripeLimit = offerPrice.amount >= MAX_AMOUNT_STRIPE;

    const isOfferOutOfAffirmLimit = offerPrice.amount <= MIN_AMOUNT_AFFIRM || offerPrice.amount >= MAX_AMOUNT_AFFIRM;

    // If paymentIntent status is not waiting user action,
    // confirmCardPayment has been called previously.
    const hasPaymentIntentUserActionsDone =
      paymentIntent && STRIPE_PI_USER_ACTIONS_DONE_STATUSES.includes(paymentIntent.status);

    // If your marketplace works mostly in one country you can use initial values to select country automatically
    // e.g. {country: 'FI'}

    const initalValuesForStripePayment = {
      name: userName,
    };

    const note = intl.formatMessage({ id: 'CheckoutPage.note' });

    return (
      <Page {...pageProps}>
        {topbar}
        <div className={css.contentContainer}>
          <div className={css.aspectWrapper}>
            <ResponsiveImage
              rootClassName={css.rootForImage}
              alt={listingTitle}
              image={firstImage}
              variants={['scaled-small', 'scaled-medium']}
            />
          </div>
          <div className={css.bookListingContainer}>
            <div className={css.heading}>
              <h1 className={css.title}>
                <FormattedHTMLMessage id="CheckoutPage.displayTitle" values={{ listingTitle }} />
              </h1>
            </div>

            <h3 className={css.note}>{note}</h3>

            <div className={css.priceBreakdownContainer}>
              {speculateTransactionErrorMessage}
              {breakdown}
            </div>

            <section className={css.paymentContainer}>
              {initiateOrderErrorMessage}
              {listingNotFoundErrorMessage}
              {speculateErrorMessage}
              {retrievePaymentIntentError ? (
                <p className={css.orderError}>
                  <FormattedMessage
                    id="CheckoutPage.retrievingStripePaymentIntentFailed"
                    values={{ listingLink }}
                  />
                </p>
              ) : null}
              {showPaymentForm ? (
                <StripePaymentForm
                  className={css.paymentForm}
                  onSubmit={this.handleSubmit}
                  inProgress={this.state.submitting}
                  formId="CheckoutPagePaymentForm"
                  authorDisplayName={currentAuthor.attributes.profile.displayName}
                  showInitialMessageInput={showInitialMessageInput}
                  initialValues={initalValuesForStripePayment}
                  initiateOrderError={initiateOrderError}
                  confirmCardPaymentError={confirmCardPaymentError}
                  confirmPaymentError={confirmPaymentError}
                  hasHandledCardPayment={hasPaymentIntentUserActionsDone}
                  loadingData={!stripeCustomerFetched}
                  defaultPaymentMethod={
                    hasDefaultPaymentMethod ? currentUser.stripeCustomer.defaultPaymentMethod : null
                  }
                  paymentIntent={paymentIntent}
                  onStripeInitialized={this.onStripeInitialized}
                  shipping={shippingOptions}
                  isShippingAddressExisting={isShippingAddressExisting}
                  currentShippingAddress={currentShippingAddress}
                  changeShippingOption={this.changeShippingOption}
                  isOfferHigherThanStripeLimit={isOfferHigherThanStripeLimit}
                  isPickupOnly={isPickupOnly}
                  changeShippingAddress={this.changeShippingAddress}
                  currentShippingAddressType={this.state.currentShippingAddressType}
                  offerPrice={offerPrice}
                  isOfferOutOfAffirmLimit={isOfferOutOfAffirmLimit}
                />
              ) : null}
              {isPaymentExpired ? (
                <p className={css.orderError}>
                  <FormattedMessage
                    id="CheckoutPage.paymentExpiredMessage"
                    values={{ listingLink }}
                  />
                </p>
              ) : null}
            </section>
          </div>

          <div className={css.detailsContainerDesktop}>
            <div className={css.detailsAspectWrapper}>
              <ResponsiveImage
                rootClassName={css.rootForImage}
                alt={listingTitle}
                image={firstImage}
                variants={['landscape-crop', 'landscape-crop2x']}
              />
            </div>
            <div className={css.detailsHeadings}>
              <h2 className={css.detailsTitle}>{listingTitle}</h2>
              {/* <p className={css.detailsSubtitle}>{detailsSubTitle}</p> */}
            </div>
            {speculateTransactionErrorMessage}
            {breakdown}
          </div>
        </div>
        <Modal
          id="CheckoutPage.warningModal"
          containerClassName={css.warningModalContainer}
          isOpen={this.state.openWarningModal}
          hideClose={true}
          usePortal
          onManageDisableScrolling={this.props.onManageDisableScrolling}
        >
          <div className={css.modalTitleContainer}>
            <IconBannedUser />
            <h2 className={css.modalTitle}>
              <FormattedMessage id="CheckoutPage.warningTitleBelowReservePrice" />
            </h2>
          </div>
          <FormattedHTMLMessage id="CheckoutPage.warningInfoBelowReservePrice" />
          {listingLinkBtn}
        </Modal>
      </Page>
    );
  }
}

CheckoutPageComponent.defaultProps = {
  initiateOrderError: null,
  confirmPaymentError: null,
  listing: null,
  bookingData: {},
  bookingDates: null,
  speculateTransactionError: null,
  speculatedTransaction: null,
  transaction: null,
  currentUser: null,
  paymentIntent: null,
};

CheckoutPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  listing: propTypes.listing,
  bookingData: object,
  bookingDates: shape({
    bookingStart: instanceOf(Date).isRequired,
    bookingEnd: instanceOf(Date).isRequired,
  }),
  fetchStripeCustomer: func.isRequired,
  stripeCustomerFetched: bool.isRequired,
  fetchSpeculatedTransaction: func.isRequired,
  speculateTransactionInProgress: bool.isRequired,
  speculateTransactionError: propTypes.error,
  speculatedTransaction: propTypes.transaction,
  transaction: propTypes.transaction,
  currentUser: propTypes.currentUser,
  params: shape({
    id: string,
    slug: string,
  }).isRequired,
  onConfirmPayment: func.isRequired,
  onInitiateOrder: func.isRequired,
  onInitiateOrderWithoutStripe: func.isRequired,
  onInitiateOrderAutomaticDeclined: func.isRequired,
  onInitiateAffirmOrder: func.isRequired,
  onConfirmCardPayment: func.isRequired,
  onRetrievePaymentIntent: func.isRequired,
  onSavePaymentMethod: func.isRequired,
  onSendMessage: func.isRequired,
  initiateOrderError: propTypes.error,
  confirmPaymentError: propTypes.error,
  // confirmCardPaymentError comes from Stripe so that's why we can't expect it to be in a specific form
  confirmCardPaymentError: oneOfType([propTypes.error, object]),
  paymentIntent: object,

  // from connect
  dispatch: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const mapStateToProps = state => {
  const {
    listing,
    bookingData,
    offer,
    shipping,
    bookingDates,
    stripeCustomerFetched,
    speculateTransactionInProgress,
    speculateTransactionError,
    speculatedTransaction,
    transaction,
    initiateOrderError,
    confirmPaymentError,
  } = state.CheckoutPage;
  const { currentUser } = state.user;
  const { confirmCardPaymentError, paymentIntent, retrievePaymentIntentError } = state.stripe;
  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    stripeCustomerFetched,
    bookingData,
    bookingDates,
    offer,
    shipping,
    speculateTransactionInProgress,
    speculateTransactionError,
    speculatedTransaction,
    transaction,
    listing,
    initiateOrderError,
    confirmCardPaymentError,
    confirmPaymentError,
    paymentIntent,
    retrievePaymentIntentError,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onSaveShippingAddress: params => dispatch(saveShippingAddress(params)),
  fetchSpeculatedTransaction: (params, transactionId) =>
    dispatch(speculateTransaction(params, transactionId)),
  fetchStripeCustomer: () => dispatch(stripeCustomer()),
  onInitiateOrder: (params, transactionId) => dispatch(initiateOrder(params, transactionId)),
  onInitiateOrderWithoutStripe: (params, transactionId) =>
    dispatch(initiateOrderWithoutStripe(params, transactionId)),
  onRetrievePaymentIntent: params => dispatch(retrievePaymentIntent(params)),
  onConfirmCardPayment: params => dispatch(confirmCardPayment(params)),
  onConfirmPayment: params => dispatch(confirmPayment(params)),
  onSendMessage: params => dispatch(sendMessage(params)),
  onSavePaymentMethod: (stripeCustomer, stripePaymentMethodId) =>
    dispatch(savePaymentMethod(stripeCustomer, stripePaymentMethodId)),
  onInitiateOrderAutomaticDeclined: (params, transactionId) =>
    dispatch(initiateOrderAutomaticDeclined(params, transactionId)),
  onInitiateAffirmOrder: (params, transactionId) =>
    dispatch(initiateAffirmOrder(params, transactionId)),
  onCreateStripePaymentIntent: ({ transactionId, type }) =>
    dispatch(createStripePaymentIntent({ transactionId, type }))
});

const CheckoutPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(CheckoutPageComponent);

CheckoutPage.setInitialValues = (initialValues, saveToSessionStorage = false) => {
  if (saveToSessionStorage) {
    const { listing, bookingData, bookingDates } = initialValues;
    storeData(bookingData, bookingDates, listing, null, STORAGE_KEY);
  }

  return setInitialValues(initialValues);
};

CheckoutPage.displayName = 'CheckoutPage';

export default CheckoutPage;
