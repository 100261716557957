import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { currentUserShowSuccess } from '../../ducks/user.duck';

// ================ Action types ================ //

export const SAVE_SHIPPING_ADDRESS_REQUEST = 'app/ShippingAddressPage/SAVE_SHIPPING_ADDRESS_REQUEST';
export const SAVE_SHIPPING_ADDRESS_SUCCESS = 'app/ShippingAddressPage/SAVE_SHIPPING_ADDRESS_SUCCESS';
export const SAVE_SHIPPING_ADDRESS_ERROR = 'app/ShippingAddressPage/SAVE_SHIPPING_ADDRESS_ERROR';

export const REMOVE_SHIPPING_ADDRESS_REQUEST = 'app/ShippingAddressPage/REMOVE_SHIPPING_ADDRESS_REQUEST';
export const REMOVE_SHIPPING_ADDRESS_SUCCESS = 'app/ShippingAddressPage/REMOVE_SHIPPING_ADDRESS_SUCCESS';
export const REMOVE_SHIPPING_ADDRESS_ERROR = 'app/ShippingAddressPage/REMOVE_SHIPPING_ADDRESS_ERROR';


// ================ Reducer ================ //

const initialState = {
  saveShippingAddressError: null,
  saveShippingAddressInProgress: false,
  removeShippingAddressError: null,
  removeShippingAddressInProgress: false
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SAVE_SHIPPING_ADDRESS_REQUEST:
      return {
        ...state,
        saveShippingAddressInProgress: true,
        saveShippingAddressError: null,
      };
    case SAVE_SHIPPING_ADDRESS_SUCCESS:
      return { ...state, saveShippingAddressInProgress: false };
    case SAVE_SHIPPING_ADDRESS_ERROR:
      return { ...state, saveShippingAddressInProgress: false, saveShippingAddressError: payload };
    case REMOVE_SHIPPING_ADDRESS_REQUEST:
      return {
        ...state,
        removeShippingAddressInProgress: true,
        removeShippingAddressError: null,
      };
    case REMOVE_SHIPPING_ADDRESS_SUCCESS:
      return { ...state, removeShippingAddressInProgress: false };
    case REMOVE_SHIPPING_ADDRESS_ERROR:
      return { ...state, removeShippingAddressInProgress: false, removeShippingAddressError: payload };
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const saveShippingAddressRequest = () => ({ type: SAVE_SHIPPING_ADDRESS_REQUEST });
export const saveShippingAddressSuccess = () => ({ type: SAVE_SHIPPING_ADDRESS_SUCCESS });
export const saveShippingAddressError = error => ({
  type: SAVE_SHIPPING_ADDRESS_ERROR,
  payload: error,
  error: true,
});

export const removeShippingAddressRequest = () => ({ type: REMOVE_SHIPPING_ADDRESS_REQUEST });
export const removeShippingAddressSuccess = () => ({ type: REMOVE_SHIPPING_ADDRESS_SUCCESS });
export const removeShippingAddressError = error => ({
  type: REMOVE_SHIPPING_ADDRESS_ERROR,
  payload: error,
  error: true,
});

// ================ Thunks ================ //

/**
 * Make a email update request to the API and return the current user.
 */
const requestChangeShippingAddress = params => (dispatch, getState, sdk) => {

  return sdk.currentUser
    .updateProfile(
      {
        publicData: {
          shippingAddress: params ? {
            ...params
          } : null
        }
      },
      {
        expand: true,
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      }
    )
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.changeEmail response');
      }

      const currentUser = entities[0];
      return currentUser;
    })
    .catch(e => {
      dispatch(saveShippingAddressError(storableError(e)));

      throw e;
    });
};

export const saveShippingAddress = params => (dispatch, getState, sdk) => {
  dispatch(saveShippingAddressRequest());

  return (
    dispatch(requestChangeShippingAddress(params))
      .then(user => {
        dispatch(currentUserShowSuccess(user));
        dispatch(saveShippingAddressSuccess());
      })
      .catch(e => null)
  );
};

export const removeShippingAddress = params => (dispatch, getState, sdk) => {
  dispatch(removeShippingAddressRequest());

  return (
    dispatch(requestChangeShippingAddress(null))
      .then(user => {
        dispatch(currentUserShowSuccess(user));
        dispatch(saveShippingAddressSuccess());
      })
      .catch(e => null)
  );
}
