import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { Modal } from '..';
import { CounterOfferForm } from '../../forms';
import { types as sdkTypes } from '../../util/sdkLoader';

import css from './CounterOfferModal.module.css';
import config from '../../config';

const { Money } = sdkTypes;

const CounterOfferModal = props => {
  const {
    className,
    rootClassName,
    id,
    intl,
    isOpen,
    onCloseModal,
    onManageDisableScrolling,
    setCounterOfferInProgress,
    setCounterOfferError,
    onHandleSubmitCounterOffer
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const closeButtonMessage = intl.formatMessage({ id: 'CounterOfferModal.close' });
  return (
    <Modal
      id={id}
      containerClassName={classes}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
      closeButtonMessage={closeButtonMessage}
    >
      <CounterOfferForm
        onSubmit={onHandleSubmitCounterOffer}
        setCounterOfferInProgress={setCounterOfferInProgress}
        setCounterOfferError={setCounterOfferError}
      />
    </Modal>
  );
};

const { bool, string } = PropTypes;

CounterOfferModal.defaultProps = {
  className: null,
  rootClassName: null,
  setCounterOfferInProgress: false,
  setCounterOfferError: null,
};

CounterOfferModal.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  setCounterOfferInProgress: bool,
  setCounterOfferError: propTypes.error,
};

export default injectIntl(CounterOfferModal);
