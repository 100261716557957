import React from 'react';
import { required } from '../../util/validators';
import { FieldSelect } from '../../components';

import css from './EditListingMicDetailsForm.module.css';

const CustomSellingPairSelectFieldMaybe = props => {
  const { name, id, sellingPair, sequentSerial, intl } = props;
  const sellingPairLabel = intl.formatMessage({
    id: 'EditListingMicDetailsForm.sellingPairLabel',
  });
  const sequentSerialLabel = intl.formatMessage({
    id: 'EditListingMicDetailsForm.sequentSerialLabel',
  });
  const sellingPairRequired = required(
    intl.formatMessage({
      id: 'EditListingMicDetailsForm.sellingPairRequired',
    })
  );
  return sellingPair ? (
    <FieldSelect
      className={css.micPair}
      name={name}
      id={id}
      label={sellingPairLabel}
      validate={sellingPairRequired}
    >
      <option disabled value=""></option>
      {sellingPair.map(c => (
        <option key={c.key} value={c.key}>
          {intl.formatMessage({
            id: c.label
          })}
        </option>
      ))}
    </FieldSelect>
  ) : sequentSerial ? (
    <FieldSelect
      className={css.micPair}
      name={name}
      id={id}
      label={sequentSerialLabel}
    >
      <option disabled value=""></option>
      {sequentSerial.map(c => (
        <option key={c.key} value={c.key}>
          {intl.formatMessage({
            id: c.label
          })}
        </option>
      ))}
    </FieldSelect>
  ) : null;
};

export default CustomSellingPairSelectFieldMaybe;
