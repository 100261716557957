import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { IconSpinner, CollectionResultsPanel, PrimaryButton } from '../../../components';
import SectionWrapper from './SectionWrapper';
import { string, bool, func, array } from 'prop-types';
import { propTypes } from '../../../util/types';

import css from '../ListingPage.module.css';

const AVAILABLE = 'available';
const SOLD = 'sold';

const FILTER_TABS = [AVAILABLE, SOLD];

const SectionCollectionMaybe = props => {
  const {
    collections,
    pagination,
    fetchCollectionsInProgress,
    intl,
    onChangeCollections,
    sectionRef,
    searchParamsForPagination,
  } = props;

  const [filterActive, setFilterActive] = useState(FILTER_TABS[0]);
  const [loadingTab, setLoadingTab] = useState(false);

  useEffect(() => {
    setFilterActive(() =>
      searchParamsForPagination.meta_isSold ? FILTER_TABS[1] : FILTER_TABS[0]
    );
  }, [searchParamsForPagination]);

  const title = intl.formatMessage({ id: 'ListingPage.collectionTitle' });

  const setFakeLoadingTab = () => {
    setLoadingTab(true);
    setTimeout(() => setLoadingTab(false), 500);
  };

  const tabButtons = FILTER_TABS.map(id => (
    <PrimaryButton
      className={classnames(css.filterButton, { [css.activeFilterButton]: filterActive === id })}
      key={id}
      onClick={() => {
        if (filterActive !== id) {
          onChangeCollections({ meta_isSold: id === SOLD, page: 1 });
          setFilterActive(id);
          setFakeLoadingTab();
        }
      }}
      disabled={filterActive !== id && fetchCollectionsInProgress}
    >
      {intl.formatMessage({ id: `SectionCollection.${id}` })}
    </PrimaryButton>
  ));

  const handleOnChangePagination = ({ page }) => {
    onChangeCollections({ meta_isSold: filterActive === SOLD, page });
    setFakeLoadingTab();
  }

  return (
    <SectionWrapper title={title} isOpen sectionRef={sectionRef}>
      <div className={css.filters}>{tabButtons}</div>
      {fetchCollectionsInProgress || loadingTab ? (
        <div className={css.loader}>
          <IconSpinner />
        </div>
      ) : (
        <CollectionResultsPanel
          className={css.searchListingsPanel}
          listings={collections}
          pagination={fetchCollectionsInProgress ? null : pagination}
          onChangePagination={handleOnChangePagination}
        />
      )}
    </SectionWrapper>
  );
};

SectionCollectionMaybe.defaultProps = {
  className: null,
  listings: [],
  collections: [],
  fetchCollectionsInProgress: false
};

SectionCollectionMaybe.propTypes = {
  className: string,
  collections: array,
  pagination: propTypes.pagination,
  rootClassName: string,
  onFetchCollections: func,
  fetchCollectionsInProgress: bool,
  onChangeCollections: func
};

export default SectionCollectionMaybe;
