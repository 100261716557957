import React from 'react';
import { intlShape, FormattedMessage } from '../../util/reactIntl';
import { bool, object, string } from 'prop-types';
import config from '../../config';
import * as validators from '../../util/validators';
import getCountryCodes from '../../translations/countryCodes';
import { FieldTextInput, FieldSelect, FieldCheckbox, SavedShippingAddress } from '..';
import { NEW_ADDRESS } from '../../util/types';
import FieldPhoneNumberInput from '../FieldPhoneNumberInput/FieldPhoneNumberInput';

import css from './ShippingAddress.module.css';

const ShippingAddress = props => {
  const { className, intl, disabled, form, fieldId, onChangeShippingAddress, isShippingAddressExisting, currentShippingAddressType, currentShippingAddress } = props;

  const optionalText = intl.formatMessage({
    id: 'ShippingAddress.optionalText',
  });

  const addressLine1Label = intl.formatMessage({
    id: 'ShippingAddress.addressLine1Label',
  });
  const addressLine1Placeholder = intl.formatMessage({
    id: 'ShippingAddress.addressLine1Placeholder',
  });
  const addressLine1Required = validators.required(
    intl.formatMessage({
      id: 'ShippingAddress.addressLine1Required',
    })
  );

  const addressLine2Label = (
    <>
      <FormattedMessage id='ShippingAddress.addressLine2Label' />
      <span className={css.optionalLabel}>{optionalText}</span>
    </>
  );
  const addressLine2Placeholder = intl.formatMessage({
    id: 'ShippingAddress.addressLine2Placeholder',
  });

  const postalCodeLabel = intl.formatMessage({ id: 'ShippingAddress.postalCodeLabel' });
  const postalCodePlaceholder = intl.formatMessage({
    id: 'ShippingAddress.postalCodePlaceholder',
  });
  const postalCodeRequired = validators.required(
    intl.formatMessage({
      id: 'ShippingAddress.postalCodeRequired',
    })
  );

  const cityLabel = intl.formatMessage({ id: 'ShippingAddress.cityLabel' });
  const cityPlaceholder = intl.formatMessage({ id: 'ShippingAddress.cityPlaceholder' });
  const cityRequired = validators.required(
    intl.formatMessage({
      id: 'ShippingAddress.cityRequired',
    })
  );

  const stateLabel = (
    <>
      <FormattedMessage id='ShippingAddress.stateLabel' />
      <span className={css.optionalLabel}>{optionalText}</span>
    </>
  );
  const statePlaceholder = intl.formatMessage({ id: 'ShippingAddress.statePlaceholder' });

  const countryLabel = intl.formatMessage({ id: 'ShippingAddress.countryLabel' });
  const countryPlaceholder = intl.formatMessage({ id: 'ShippingAddress.countryPlaceholder' });
  const countryRequired = validators.required(
    intl.formatMessage({
      id: 'ShippingAddress.countryRequired',
    })
  );

  const mainLabel = intl.formatMessage({
    id: 'ShippingAddress.mainLabel'
  })

  const saveShippingAddressLabel = intl.formatMessage({
    id: 'ShippingAddress.saveShippingAddressLabel'
  })

  const shippingNameLabel = intl.formatMessage({
    id: 'ShippingAddress.shippingNameLabel',
  });

  const shippingNamePlaceholder = intl.formatMessage({
    id: 'ShippingAddress.shippingNamePlaceholder',
  });
  const shippingNameRequired = validators.required(intl.formatMessage({
    id: 'ShippingAddress.shippingNameRequired'
  }));

  const phoneLabel = intl.formatMessage({ id: 'ShippingAddress.phoneLabel' });
  const phoneRequired = validators.required(
    intl.formatMessage({
      id: 'ShippingAddress.phoneRequired',
    })
  );

  // Use tha language set in config.locale to get the correct translations of the country names
  const countryCodes = getCountryCodes(config.locale);

  return (
    <div className={className ? className : css.root}>
      <label>{mainLabel}</label>
      {isShippingAddressExisting
        && (<SavedShippingAddress
          className={css.paymentMethodSelector}
          shippingAddress={currentShippingAddress}
          onChange={onChangeShippingAddress}
        />)
      }
      {(currentShippingAddressType === NEW_ADDRESS || !isShippingAddressExisting)
        &&
        (<>
          <div className={css.formRow}>
            <FieldTextInput
              className={css.field}
              type="text"
              id="shipping.recipientName"
              name="shipping.recipientName"
              autoComplete="cc-name"
              label={shippingNameLabel}
              validate={shippingNameRequired}
            />
            <FieldPhoneNumberInput
              className={css.field}
              type="phone"
              id="shipping.phoneNumber"
              name="shipping.phoneNumber"
              label={phoneLabel}
              validate={phoneRequired}
            />
          </div>
          <div className={css.formRow}>
            <FieldTextInput
              id={`${fieldId}.shippingAddressLine1`}
              name="shipping.addressLine1"
              disabled={disabled}
              className={css.field}
              type="text"
              autoComplete="shipping address-line1"
              label={addressLine1Label}
              validate={addressLine1Required}
              onUnmount={() => form.change('addressLine1', undefined)}
            />

            <FieldTextInput
              id={`${fieldId}.shippingAddressLine2`}
              name="shipping.addressLine2"
              disabled={disabled}
              className={css.field}
              type="text"
              autoComplete="shipping address-line2"
              label={addressLine2Label}
              onUnmount={() => form.change('addressLine2', undefined)}
            />
          </div>
          <div className={css.formRow}>


            <FieldTextInput
              id={`${fieldId}.shippingCity`}
              name="shipping.city"
              disabled={disabled}
              className={css.field}
              type="text"
              autoComplete="shipping address-level2"
              label={cityLabel}
              validate={cityRequired}
              onUnmount={() => form.change('shipping.city', undefined)}
            />
            <FieldTextInput
              id={`${fieldId}.shippingState`}
              name="shipping.state"
              disabled={disabled}
              className={css.field}
              type="text"
              autoComplete="shipping address-level1"
              label={stateLabel}
              onUnmount={() => form.change('shipping.state', undefined)}
            />
          </div>
          <div className={css.formRow}>
            <FieldTextInput
              id={`${fieldId}.shippingPostalCode`}
              name="shipping.postal"
              disabled={disabled}
              className={css.field}
              type="text"
              autoComplete="shipping postal-code"
              label={postalCodeLabel}
              validate={postalCodeRequired}
              onUnmount={() => form.change('shipping.postal', undefined)}
            />

            <FieldSelect
              id={`${fieldId}.shippingCountry`}
              name="shipping.country"
              disabled={disabled}
              className={css.field}
              label={countryLabel}
              validate={countryRequired}
            >
              <option disabled value=""></option>
              {countryCodes.map(country => {
                return (
                  <option key={country.code} value={country.code}>
                    {country.name}
                  </option>
                );
              })}
            </FieldSelect>
          </div>
          <FieldCheckbox
            className={css.saveForLaterUseCheckbox}
            textClassName={css.saveForLaterUseLabel}
            id="saveShippingAddress"
            name="saveShippingAddress"
            label={saveShippingAddressLabel}
            value="saveShippingAddress"
            useSuccessColor
          />
        </>)}
    </div>
  );
};
ShippingAddress.defaultProps = {
  country: null,
  disabled: false,
  fieldId: null,
};

ShippingAddress.propTypes = {
  country: string,
  disabled: bool,
  form: object.isRequired,
  fieldId: string,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default ShippingAddress;
