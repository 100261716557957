import { Editor, Range, Point, Transforms } from 'slate';
import { deserialize } from './RichTextConfig';
import { uploadFile } from '../../../util/custom-api';

import imageExtensions from 'image-extensions';
import isUrl from 'is-url';

export const fnRemoteImage = (editor, files) => {
  for (const file of files) {
    const [mime] = file.type.split('/');
    if (mime === 'image') {
      uploadFile({ file: file })
        .then(response => {
          insertImage(editor, response.data.data.url);
        })
        .catch(error => {
          alert(error);
        });
    }
  }
}

export const withImages = editor => {
  const { insertData, isVoid } = editor;

  editor.isVoid = element => {
    return element.type === 'image' ? true : isVoid(element);
  }

  editor.insertData = data => {
    const text = data.getData('text/plain');
    const { files } = data;

    if (files && files.length > 0) {
      fnRemoteImage(editor, files);
    } else if (isImageUrl(text)) {
      insertImage(editor, text);
    } else {
      insertData(data);
    }
  }

  return editor;
}

const isImageUrl = url => {
  if (!url) return false;
  if (!isUrl(url)) return false;

  const ext = new URL(url).pathname.split('.').pop();
  return imageExtensions.includes(ext);
}

const insertImage = (editor, url) => {
  const text = { text: '' };
  const image = { type: 'image', url, children: [text] };
  Transforms.insertNodes(editor, image);
}

export const withTables = editor => {
  const { deleteBackward, deleteForward, insertBreak } = editor;

  editor.deleteBackward = unit => {
    const { selection } = editor;

    if (selection && Range.isCollapsed(selection)) {
      const [cell] = Editor.nodes(editor, {
        match: n => n.type === 'table-cell',
      });

      if (cell) {
        const [, cellPath] = cell;
        const start = Editor.start(editor, cellPath);

        if (Point.equals(selection.anchor, start)) {
          return;
        }
      }
    }

    deleteBackward(unit);
  }

  editor.deleteForward = unit => {
    const { selection } = editor;

    if (selection && Range.isCollapsed(selection)) {
      const [cell] = Editor.nodes(editor, {
        match: n => n.type === 'table-cell',
      });

      if (cell) {
        const [, cellPath] = cell;
        const end = Editor.end(editor, cellPath);

        if (Point.equals(selection.anchor, end)) {
          return;
        }
      }
    }

    deleteForward(unit)
  }

  editor.insertBreak = () => {
    const { selection } = editor;

    if (selection) {
      const [table] = Editor.nodes(editor, { match: n => n.type === 'table' });

      if (table) {
        return;
      }
    }

    insertBreak();
  }

  return editor;
}

export const withHtml = editor => {
  const { insertData, isInline, isVoid } = editor;

  editor.isInline = element => {
    return element.type === 'link' ? true : isInline(element);
  }

  editor.isVoid = element => {
    return element.type === 'image' ? true : isVoid(element);
  }

  editor.insertData = data => {
    const html = data.getData('text/html');

    if (html) {
      const parsed = new DOMParser().parseFromString(html, 'text/html');
      const fragment = deserialize(parsed.body);
      Transforms.insertFragment(editor, fragment);
      return;
    }

    insertData(data);
  }

  return editor;
}