import React, { useState, useEffect } from 'react';
import { bool, func, object, shape, string, oneOf } from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { connect } from 'react-redux';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_NEW,
  LISTING_PAGE_PARAM_TYPES,
} from '../../util/urlHelpers';
import { LISTING_STATE_DRAFT, propTypes } from '../../util/types';
import { ensureOwnListing } from '../../util/data';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';

import { Button, Page, Modal } from '../../components';
import { TopbarContainer } from '../../containers';

import {
  requestImageUpload,
  updateImageOrder,
  removeListingImage,
  resetData
} from './EditConsignListingPage.duck';

import { EditConsignListingPanel } from '../../components';

import { submitConsignment } from '../../util/api';

import css from './EditConsignListingPage.module.css';

const { UUID } = sdkTypes;

// N.B. All the presentational content needs to be extracted to their own components
export const EditConsignListingPageComponent = props => {
  const {
    currentUser,
    getOwnListing,
    history,
    intl,
    onRemoveListingImage,
    onManageDisableScrolling,
    onImageUpload,
    onUpdateImageOrder,
    onResetData,
    page,
    params,
    scrollingDisabled,
  } = props;
  const { id, type } = params;

  const listingId = page.submittedListingId || (id ? new UUID(id) : null);

  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [notification, setNotification] = useState(false);

  const onOpenNotificationModal = () => {
    setNotification(true);
  };

  const [currentListing, setCurrentListing] = useState(ensureOwnListing(getOwnListing(listingId)));
  const getToken = (tokenName) => {
    try {
      const [, cookieData] = document.cookie
        .split(';')
        .map(cookie => cookie.split('='))
        .find(([key]) => /token$/.test(key));

      const cookieJSON = JSON.parse(decodeURIComponent(cookieData));
      return cookieJSON[tokenName];
    } catch (e) {
      return '';
    }
  }
  const getAccessToken = () => {
    return getToken('access_token')
  };
  const getRefreshToken = () => {
    return getToken('refresh_token')
  };

  const handleSubmit = async values => {
    handleConsign(values);
  };

  const handleConsign = async values => {
    setSubmitting(true);
    setSubmitError(false);
    return submitConsignment({
      refreshToken: getRefreshToken(),
      listingData: {
        ...values,
      },
    })
      .then(() => {
        onOpenNotificationModal();
        setSubmitting(false);
      })
      .catch(() => {
        setSubmitError(true);
        setSubmitting(false);
      });
  };

  const isNewURI = type === LISTING_PAGE_PARAM_TYPE_NEW;
  const isDraftURI = type === LISTING_PAGE_PARAM_TYPE_DRAFT;
  const isNewListingFlow = isNewURI || isDraftURI;

  const showListingConsignment = id => {
    try {
      return showConsignment(
        {
          listingId: id,
        },
        {
          accessToken: getAccessToken(),
        }
      );
    } catch (e) {
      return console.error(e);
    }
  };

  // Reset previous data if any
  useEffect(() => {
    onResetData();
  }, []);

  // Add an event to announce user if they are leaving page
  useEffect(() => {
    if (typeof window !== undefined) {
      window.onbeforeunload = () => true;
      return () => {
        sessionStorage.clear();
        window.onbeforeunload = null;
      }
    }
  }, []);

  const draftId = '00000000-0000-0000-0000-000000000000';

  useEffect(() => {
    if (listingId.uuid !== draftId) {
      showListingConsignment(id).then(data => {
        data && setCurrentListing(data);
      });
    }
  }, [id, listingId.uuid]);

  const { state: currentListingState } = currentListing.attributes;

  const showForm = isNewURI || currentListing.id;

  if (showForm) {
    const {
      uploadImageError = null,
    } = page;
    const errors = {
      uploadImageError,
    };
    // TODO: is this dead code? (shouldRedirect is checked before)
    const newListingPublished =
      isDraftURI && currentListing && currentListingState !== LISTING_STATE_DRAFT;

    const imageOrder = page.imageOrder || [];
    const unattachedImages = Object.keys(page.images).map(key => page.images[key]);

    const allImages = imageOrder.map(id => {
      return unattachedImages.find(img => {
        const imgId = img.imageId?.uuid || img.id;

        return imgId === id;
      });
    });

    const removedImageIds = page.removedImageIds || [];
    const images = allImages.length ? allImages.filter(img => {
      return !removedImageIds.includes(img.imageId?.uuid || img.id);
    }) : [];

    const recordImages = page.recordImages || [];
    const title = isNewListingFlow
      ? intl.formatMessage({ id: 'EditListingPage.titleCreateListing' })
      : intl.formatMessage({ id: 'EditListingPage.titleEditListing' });

    return (
      <Page title={title} scrollingDisabled={scrollingDisabled}>
        <TopbarContainer
          className={css.topbar}
          mobileRootClassName={css.mobileTopbar}
          desktopClassName={css.desktopTopbar}
          mobileClassName={css.mobileTopbar}
        />
        <EditConsignListingPanel
          ready={newListingPublished}
          errors={errors}
          listing={currentListing}
          updateInProgress={
            page.updateInProgress || page.uploadRecordImageInProgress || submitting
          }
          images={images}
          recordImages={recordImages}
          onImageUpload={onImageUpload}
          onUpdateImageOrder={onUpdateImageOrder}
          onRemoveImage={onRemoveListingImage}
          onSubmit={values => handleSubmit(values)}
        />
        <Modal
          id="EditConsignListingPage.errorModal"
          containerClassName={css.notificationModalContainer}
          isOpen={submitError}
          onClose={() => setSubmitError(false)}
          usePortal
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <p><FormattedMessage id="EditConsignListingPage.failedSubmission" /></p>
          <Button type="button" onClick={() => setSubmitError(false)}>
            <FormattedMessage id="EditConsignListingPage.retryButton" />
          </Button>
        </Modal>
        <Modal
          id="EditConsignListingPage.notificationModal"
          containerClassName={css.notificationModalContainer}
          isOpen={notification}
          onClose={() => setNotification(false)}
          usePortal
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <p>
            <FormattedMessage id="EditConsignListingPage.successfulSubmission" />
          </p>
          <Button type="button" onClick={() => history.push('/')}>
            <FormattedMessage id="EditConsignListingPage.backToHomeButton" />
          </Button>
        </Modal>
      </Page>
    );
  } else {
    // If user has come to this page through a direct linkto edit existing listing,
    // we need to load it first.
    const loadingPageMsg = {
      id: 'EditListingPage.loadingListingData',
    };
    return (
      <Page title={intl.formatMessage(loadingPageMsg)} scrollingDisabled={scrollingDisabled} />
    );
  }
};

EditConsignListingPageComponent.defaultProps = {
  currentUser: null,
};

EditConsignListingPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  onImageUpload: func.isRequired,
  onUpdateImageOrder: func.isRequired,
  onRemoveListingImage: func.isRequired,
  page: object.isRequired,
  params: shape({
    id: string.isRequired,
    slug: string.isRequired,
    type: oneOf(LISTING_PAGE_PARAM_TYPES).isRequired,
    tab: string.isRequired,
  }).isRequired,
  scrollingDisabled: bool.isRequired,

  /* from withRouter */
  history: shape({
    push: func.isRequired,
  }).isRequired,

  /* from injectIntl */
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const page = state.EditConsignListingPage;
  const { currentUser } = state.user;
  const getOwnListing = id => {
    const listings = getMarketplaceEntities(state, [{ id, type: 'ownListing' }]);

    return listings.length === 1 ? listings[0] : null;
  };

  return {
    currentUser,
    getOwnListing,
    page,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onImageUpload: data => dispatch(requestImageUpload(data)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onUpdateImageOrder: imageOrder => dispatch(updateImageOrder(imageOrder)),
  onRemoveListingImage: imageId => dispatch(removeListingImage(imageId)),
  onResetData: () => dispatch(resetData())
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const EditConsignListingPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(injectIntl(EditConsignListingPageComponent));

export default EditConsignListingPage;
