import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './TermsOfService.module.css';
import NamedLink from '../NamedLink/NamedLink';

const TermsOfService = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <h2>November 18, 2022</h2>

      <p className={css.alignCenter}>IMPORTANT – NOTICE OF LEGAL TERMS</p>

      <p>PLEASE CAREFULLY READ THESE TERMS OF USE (“TERMS OF USE”) BEFORE
        ACCESSING ANY PAGE OR FEATURE OF THE WEBSITE HOSTED AT <NamedLink
          name="LandingPage">WWW.ANALOGR.COM</NamedLink>, AS THEY CONSTITUTE
        BINDING CONTRACT TERMS AND AFFECT YOUR LEGAL RIGHTS, OBLIGATIONS, AND
        LIABILITIES TO ANALOGr LLC, INCLUDING, BUT NOT LIMITED TO, WAIVERS OF
        CLASS ACTION CLAIMS AND OTHER VOLUNTARY PRECLUSIONS OF PRESUMPTIVE LEGAL
        RIGHTS, LIMITATION OF LIABILITY, YOUR CONFIDENTIALITY, CONTENT-RELATED,
        AND OTHER DUTIES, LIMITATIONS, AND OBLIGATIONS, YOUR INDEMNITIES TO US,
        AND YOUR AGREEMENT TO MANDATORY ARBITRATION.</p>
      <h2>Scope of Terms.</h2>
      <p>These Terms of Service apply to the ANALOGr website located at <NamedLink
        name="LandingPage">www.ANALOGr.com</NamedLink>, and all associated
        sites, servers, and associated use or platform-access functionality
        hosted with, made accessible and/or provided through, or linked to <NamedLink
          name="LandingPage">www.ANALOGr.com</NamedLink> by ANALOGr, its
        subsidiaries and affiliates, or its posted designees (the “Site” or
        “Website”).</p>
      <h2>Acceptance of Terms.</h2>
      <p>These terms of use This Website and all associated assets is owned
        and operated by ANALOGr, LLC. (“ANALOGr” or “we,” “our,” or “us”).
        Certain portions of the Website may not be available to you unless you
        have registered. These Terms of Use govern your use of the Website
        whether accessed via computer, mobile device, tablet or other means. By
        using the Website or Services (defined below), you are stating that you
        have read and understand, and agree to be bound by, these Terms of Use.
        You agree that these Terms of Use incorporate and include ANALOGr’s
        Privacy Policy available at www.analogr.com. IF YOU DO NOT AGREE TO BE
        BOUND BY ALL OF THESE TERMS OF USE, YOU MAY NOT USE THE WEBSITE.</p>
      <p>In some instances, both these Terms of Use and separate terms or
        documents setting forth additional conditions may apply to the Service
        or a feature offered via the Website (“Additional Terms”) including, but
        not limited to, the Consignment Terms and the Artifact Custody Addendum
        to the Consignment Terms governing the consignment of items on the
        Website and the Conditions of Sale listed herein. To the extent there is
        a conflict between these Terms of Use and any Additional Terms, the
        Terms of Use will control unless the Additional Terms expressly state
        otherwise with reference to these Terms of Use, or, within the context
        of a discrete bilateral commercial relationship not arising solely from
        these Terms of Use, and created by mutually executed written instrument
        for an independent object between ANALOGr and one other party, such
        representations, warranties, and guarantees as may arise specifically
        within the context of that relationship as expressly provided by the
        foundational written instrument shall not be altered by any general
        terms or conditions applicable to that party’s relationship with ANALOGr
        by virtue of their access to the Website and acceptance of these Terms
        of Use thereby. Such representations, guarantees, or other specifically
        contracted provisions shall modify or supersede the terms and conditions
        hereof only to the extent specifically stated by ANALOGr in a fully
        executed contractual instrument creating a commercial relationship with
        a discrete object to which the modifications hereto are fundamentally
        related and essential to the contractual purpose, which shall be
        independent of that created solely by these Terms of Use. You consent to
        the collection and use of data in accordance with the Website’s Privacy
        Policy.</p>
      <p>BY USING THE WEBSITE, YOU AGREE TO THESE TERMS OF USE; IF YOU DO NOT
        AGREE, DO NOT USE THE SITE. ANALOGr reserves the right, at its sole
        discretion, to change, modify, add or remove portions of these Terms of
        Use, at any time. It is your responsibility to check these Terms of Use
        periodically for changes. Your continued use of the Site following the
        posting of changes will mean that you accept and agree to the changes.
        As long as you comply with these Terms of Use, and subject to the
        limitations, contingencies, and other provisions set forth in these
        Terms of Use and other applicable legal instruments, ANALOGr grants you
        a personal, non-exclusive, non-transferable, limited privilege to enter
        and use the Site.</p>
      <h2>Description of Services.</h2>
      <p>The Website provides users with access to content and networked
        functionality regarding the services and goods provided by ANALOGr LLC,
        including information services and e-commerce described, provided,
        and/or transacted thereby, and other informational and commercial goods
        and services relating to the evaluation, collection, curation, and
        public presentation of authentic and historically significant musical
        instruments, sound equipment, and other music-related artifacts
        associated with the music, culture, aesthetics, and evolution in genre
        and style of all things rock &amp; roll. ("the Services"). You are
        responsible for obtaining access to the Website which may involve
        third-party fees (including, but not limited to, Internet service
        provider or airtime charges).</p>
      <h2>Website Ownership and Permitted Use.</h2>
      <p>The Website is owned and operated by ANALOGr. The Website and all
        intellectual property and other rights relating thereto are and will
        remain the property of ANALOGr and its licensors (including, without
        limitation, the content, audio, images, photographs, illustrations,
        text, graphics, logos, button icons, other visuals, video, copy,
        software, code, data and materials used therein or available thereon;
        the look and feel, design and organization of the Website; the
        compilation of the content, code, data and materials in the Website; and
        all copyrights, trademark rights, patent rights, database rights, trade
        secrets, moral rights [including droits de suite, droits d’auteurs, and
        other rights of authorship, attribution and subsequent modification] and
        all other applicable intellectual property and proprietary rights
        vesting or arising under the laws of any other jurisdiction(s), whether
        or not registered or unregistered thereunder or therein. The Website is
        protected by U.S. and international copyright, trademark, and other
        laws, and you acknowledge that these rights are valid and enforceable
        against you.</p>
      <p>Except as expressly set forth in these Terms of Use, you may not
        copy, reproduce, modify, adapt, translate, republish, upload, post,
        transmit, distribute, sub-license, sell, reverse engineer, decompile,
        create derivate works of, or disassemble any part of the Website without
        the prior written permission of ANALOGr. You acknowledge that you do not
        acquire any ownership rights by using the Website. The Website shall be
        used solely in accordance with the terms herein. The Website is provided
        solely for the purposes provided in these Terms of Use. Use of the
        Website for any other purpose is strictly prohibited. At all times,
        access and use of the Website is restricted to only lawful purposes.</p>
      <p>ANALOGr reserves the right at any time and from time to time to
        modify, discontinue, temporarily or permanently disable or discontinue,
        the Website, or any part or portion thereof, with or without notice to
        you. You agree that ANALOGr shall not be liable to you or to any third
        party for any modification, suspension or discontinuance of the Website,
        or any part of portion thereof. Nothing in these Terms of Use shall be
        construed to obligate ANALOGr to maintain and support the Website, or
        any part or portion thereof, during the term of these Terms of Use.</p>
      <p>The trademarks, logos, and service marks displayed on the Website
        (collectively, the “Trademarks”) are the registered and unregistered
        trademarks of ANALOGr and/or others. Nothing contained in these Terms of
        Use or the Website should be construed as granting, by implication,
        estoppel, or otherwise, any license or right to use any Trademark(s)
        without the express written permission of ANALOGr or the third party
        owner of any such Trademark.</p>
      <h2>Membership Eligibility.</h2>
      <ul className={css.listTerm}>
        <li><p>You must be 18 years or older to access the Website. Any persons
          under the age of 18 are not permitted to access the Service or
          Website.</p></li>
        <li><p>You may not have more than one active account and information
          input by you relating to your account must be true and
          accurate.</p></li>
        <li><p>You represent and warrant that you are of legal age to form a
          binding contract and are not a person barred from receiving services
          under the laws of the United States or any other applicable
          jurisdiction.</p></li>
      </ul>
      <p>If you do not meet the foregoing eligibility requirements, you may
        not use the Service or the Website.</p>
      <h2>Your Personal Account.</h2>
      <p>Access to certain functionalities of the Website will require you to
        register with and provide certain information to ANALOGr. Your account
        is personal to you, and you may not share your account information with,
        or allow access to your account by, any third party. We reserve the
        right to decline to provide access to the Website to any person for any
        or no reason. If and when you provide information to ANALOGr, you agree
        to (a) provide accurate, current and complete information about yourself
        as prompted (including your email address) and (b) maintain and update
        your information (including your email address) to keep it accurate,
        current and complete. You acknowledge that, if any information provided
        by you is untrue, inaccurate, not current or incomplete, or ANALOGr has
        grounds to suspect that such information is untrue, inaccurate, not
        current or incomplete, ANALOGr has the right to suspend or terminate
        your account and refuse any and all current or future use of the Website
        and Service (or any portion thereof).</p>
      <p>You understand and acknowledge that you are solely responsible for
        maintaining the confidentiality of your access credentials and other
        account information, and for restricting access to your computer or
        device. You acknowledge and agree that you are and will be solely liable
        for any and all acts and omissions under your account, including any
        content that is posted or transmitted using the Website. Without
        limiting the foregoing, you agree to use reasonable efforts to prevent
        unauthorized access to or use of your account and to preserve the
        confidentiality of your username and password, and any device that you
        use to access your account. For this reason, we recommend that you exit
        from your account at the end of each session. You agree to notify us
        immediately of any unauthorized use of your account or any other breach
        of security of which you become aware. You will be solely responsible
        for losses incurred by ANALOGr and others due to any unauthorized use of
        your account.</p>
      <h2>Limited License and Acceptable Use Policy.</h2>
      <p>Subject to the restrictions and limitations set forth in these Terms
        of Use, ANALOGr grants you a limited nonexclusive, nontransferable,
        individual license to access and use the Website for purposes of (a)
        accessing and viewing the content on the Website, via your account, on
        your computer or other Internet compatible device, including mobile
        devices and tablets, and (b) placing bids or orders (as applicable and
        in accordance with the terms herein and any Additional Terms set forth
        by ANALOGr) for products offered for auction or sale on the Website, (c)
        communicating with ANALOGr as provided for through the Website. The
        Website and your account may only be used in the limited manner provided
        above. Any other use of the Website is strictly prohibited.</p>
      <p>You agree to abide by all applicable local, state, national and
        foreign laws, rules and regulations in connection with your use of the
        Website and not to access or use the Website from a jurisdiction where
        such access or use illegal or unauthorized.</p>
      <h2>Prohibited Activity.</h2>
      <p>Without limitation to any obligations or prohibitions arising
        expressly or by necessary application here from, you specifically agree
        that you will not engage in, solicit, facilitate, or effectuate any of
        the following prohibited activities, whether or not such misconduct
        and/or misuse is accomplished while actively using or accessing the
        Website:</p>
      <ul className={css.listTerm}>
        <li><p>Disable, hack, circumvent or otherwise interfere with
          security-related features of the Website or with any features that
          prevent or restrict use or copying of any ANALOGr content or
          materials;</p></li>
        <li><p>Use any metadata, meta tags or other hidden text utilizing a
          ANALOGr name, trademark, URL, service, program, platform, or product
          name;</p></li>
        <li><p>In any way use the Website to send altered, deceptive or false
          source-identifying information, or use information obtained by or
          through the website, including reference information provided or
          published thereon, to communicate incomplete, misleading, or incorrect
          source-identifying, attribution, affiliation, or endorsement-related
          statements through any public or private medium of information
          communication or publication;</p></li>
        <li><p>Use any scripts, programs or other automated means to access the,
          or otherwise engage in any ‘data scraping’ or data gathering practices
          with respect to the Website;</p></li>
        <li><p>Upload, submit, post, email, or otherwise transmit, via the
          Website, any content or materials that are unlawful, harmful, invade the
          privacy of any third party, or are otherwise objectionable in the sole
          opinion of ANALOGr;</p></li>
        <li><p>Destroy, interfere with or disrupt (or attempt to interfere with
          or disrupt) any web pages available at the Website, servers or networks
          connected to the Website or the technical delivery systems of ANALOGr’s
          providers or break any requirements, procedures, policies or regulations
          of networks connected to the Website;</p></li>
        <li><p>Attempt to scan, probe or test the vulnerability of any ANALOGr
          system or network or breach or impair or circumvent any security or
          authentication measures protecting and providing security for the
          Website;</p></li>
        <li><p>Attempt to decompile, disassemble, decipher or reverse engineer
          any of the software used to provide the Website;</p></li>
        <li><p>Translate, reverse engineer, decompile, decrypt, disassemble, or
          convert into human readable form the Website or any element thereof
          (including any content, software, code, data or materials used in or
          available on the Website) not intended to be so read (this includes
          using or directly viewing the underlying HTML or other code from the
          Website except as interpreted and displayed in a web browser);</p></li>
        <li><p>Attempt to search, meta-search or access the Website with any
          engine, software, tool, agent, device or mechanism other than software
          and/or search agents provided by ANALOGr or other generally available
          third-party web browsers (such as Microsoft Internet Explorer, Mozilla
          Firefox, Safari, Chrome), including any software that sends queries to
          the Website to determine how a website or web page ranks;</p></li>
        <li><p>Download, publish, perform, display, distribute, copy, imitate,
          mirror, reproduce, post, transmit, modify, adapt, edit, create
          derivative works of, transfer, sell, license, rent or otherwise exploit
          or make commercial use or availability of the Website or any element
          thereof (including any content, software, code, data or materials used
          in or available on the Website);</p></li>
        <li><p>Upload, post, or transmit any material that contain software
          viruses or any other computer code, files or programs designed to
          interrupt, destroy or limit the functionality of any computer software
          or hardware or telecommunications equipment; any action that imposes or
          may impose (in ANALOGr’s sole discretion) an unreasonable or
          disproportionately large load on ANALOGr’s infrastructure, or interferes
          with any other party's use and enjoyment of the Website; or any use of
          data mining, robots, or similar data gathering and extraction
          tools.</p></li>
        <li><p>Bypass or attempt to bypass any measures used by ANALOGr to
          disrupt or prevent or restrict access to the Website or Service. Any
          unauthorized use by you shall terminate the permission or license
          granted to you by ANALOGr.</p></li>
        <li><p>Insert your own or a third party's advertising, branding or other
          promotional content into any of the any services, content, functions,
          information, materials or products available through the Website; use,
          redistribute, republish or exploit such content or service for any
          further commercial or promotional purposes; or otherwise engage in
          unauthorized or unsolicited advertising or marketing</p></li>
        <li><p>Engage in data mining, spidering, "screen scraping," "database
          scraping," harvesting of catalogue information, e-mail addresses, IP
          addresses or other contact or personal information, or any other
          automatic means of obtaining information from the Website or through the
          Website or the services offered on or through the Website;</p></li>
        <li><p>Use the Website or any services, content, functions, information,
          materials or products available through the Website in violation of
          ANALOGr or any third party's intellectual property or other proprietary
          or legal rights;</p></li>
        <li><p>Frame or link to the Website without our express written
          permission;</p></li>
        <li><p>Use the Website or any services, content, functions, information,
          materials or products available through the Website for purposes of
          money laundering, bid rigging, price fixing or other unlawful collusion,
          price signaling or exchange of competitively sensitive data or
          information;</p></li>
        <li><p>Collect or store personal data about users of the
          Website;</p></li>
        <li><p>Misrepresent or impersonate your affiliation with any person or
          entity, or otherwise commit fraud;</p></li>
        <li><p>Use the Website for any commercial distribution, publishing, use
          or exploitation of the Website or any element thereof (including any
          content, software, code, data or materials used in or available on the
          Website), unless you have received the express prior written permission
          of ANALOGr or the applicable rights holder.</p></li>
        <li><p>Use the Website in any manner not permitted by these Terms of
          Use; or</p></li>
        <li><p>Instruct or encourage any other individual to do any of the
          foregoing or to break and/or violate any term of these Terms of
          Use.</p></li>
      </ul>
      <h2>Submitted Content.</h2>
      <p>Unless specifically requested, ANALOGr does not solicit nor does it
        wish to receive any confidential, secret or proprietary information
        through the Website, by e-mail or in any other way. Subject only to the
        Privacy Policy, you agree that any ideas, feedback, information, photos,
        materials or content that you provide, submit, upload or otherwise
        transmit to ANALOGr or on the Website (“User Content”) will be available
        for our use free of any obligations to you, and with complete and
        unencumbered downstream permissioning rights, including the unrestricted
        freedom to assign or transfer all applicable reproduction,
        republication, use, access, presentation, and other content-related
        rights in whole or part to third-party recipients without further action
        or approval by you or any other person. You hereby (a) grant to ANALOGr
        a royalty-free, perpetual, irrevocable, transferable, non-exclusive,
        worldwide license to exploit, assign, transfer, or otherwise make use of
        any and all rights in or arising from the User Content including all
        copyrights therein, for any exploitation by ANALOGr and/or by any person
        authorized by ANALOGr, by any means and in all media now known or
        hereafter devised, without payment, permission, notice, or other
        communication or action involving you or any third party, and to
        advertise and promote any and all such uses, alienations, or
        exploitations, for the full period of protection, enforceability, or
        justiciability applicable to any and all aspects of all such rights
        (together with any extensions and renewals, including such equitable
        tolling or other limitation-modifying doctrines applicable to any legal
        rights or claims) and insofar as possible in perpetuity; (b) waive all
        moral rights in the User Content that may be available to you; (c)
        represent and warrant that you are the unqualified owner of all content,
        expression, property, quasiproperty, material, visual assets, or
        rights-protected assets of any kind comprising or included within the
        User Content and are entitled and have all rights and permissions
        necessary to truthfully warrant, enter into and fully comply with the
        express and implied conditions, and all other provisions of these Terms
        of Use, without further action by any person; (e) confirm that no such
        User Content is subject to any obligation, whether negative, positive,
        or procedural, of notice, confidence, attribution, completeness, or
        otherwise, imposed on or requiring compliance by or from ANALOGr or any
        third party, whether such obligation or imposition is due to you or any
        third party, such that, without limiting the foregoing restrictions,
        ANALOGr shall not be liable for any use or disclosure of such User
        Content, or for any action or inaction relating to, imposed, or claimed
        by virtue of any predicate, contingent, or other causal relationship
        thereto, whether by you or any other predicate-right claimant disclaimed
        hereby. Except as otherwise described in the Website’s posted Privacy
        Policy, you understand and agree that your User Content will be treated
        as non-confidential and non-proprietary and will not be returned or
        subject to non-retention or expungement, or any other custodial
        limitations, obligations, or management by or on ANALOGr. Upon ANALOGr’s
        request, you will furnish ANALOGr any documentation, substantiation or
        releases necessary to verify your compliance with these Terms of
        Use.</p>
      <h2>Mobile Features.</h2>
      <p>The Website may offer features and services that are available to you
        via your mobile device. These features and services may, include,
        without limitation, the ability to receive messages from the Website,
        access your account information, download applications to your mobile
        device or access the Website’s features (collectively, the “Mobile
        Features”). Standard messaging, data and other fees may be charged by
        your carrier to participate in Mobile Features. Such fees and charges
        will appear on the bill you receive from your carrier or be deducted
        from your pre-paid balance. Your carrier may prohibit or restrict
        certain Mobile Features and certain Mobile Features may be incompatible
        with your carrier or mobile device. Contact your carrier with questions
        regarding these issues.</p>
      <h2>Third-Party Links.</h2>
      <p>The Website may contain links to third-party websites for your
        convenience. ANALOGr does not control such linked websites or the
        content provided through such websites, and ANALOGr has not reviewed, in
        their entirety, such websites. Your use of such linked websites is
        subject to the privacy practices and terms of use established by each
        such linked website, and ANALOGr disclaims all liability for such use.
        Such links do not indicate any approval or endorsement by ANALOGr of any
        such linked website or any material contained on any such linked
        website, and ANALOGr disclaims any such approval or endorsement.
        Further, descriptions of, or references to, third-party products,
        services or publications within the Website do not imply endorsement of,
        and ANALOGr is not responsible for and disclaims any endorsement of, any
        such product, service or publication.</p>
      <h2>WARRANTY DISCLAIMER</h2>
      <p>EXCEPT AS SPECIFICALLY STATED BY ANALOGr BY EXPRESS REFERENCE HERETO,
        THE WEBSITE IS PROVIDED “AS IS” AND WITHOUT WARRANTIES OF ANY KIND,
        EITHER EXPRESS OR IMPLIED, AND ALL WARRANTIES, EXPRESS OR IMPLIED,
        INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF TITLE,
        NON-INFRINGEMENT, ACCURACY, COMPLETENESS, MERCHANTABILITY, FITNESS FOR A
        PARTICULAR PURPOSE, ANY WARRANTIES THAT MAY ARISE FROM COURSE OF
        DEALING, COURSE OF PERFORMANCE OR USAGE OF TRADE, AND ANY WARRANTIES
        THAT THE INFORMATION AND WEBSITE IS CURRENT AND/OR UP-TO-DATE ARE HEREBY
        EXPRESSLY DISCLAIMED TO THE FULLEST EXTENT PERMISSIBLE UNDER APPLICABLE
        LAW.</p>
      <p>There is no warranty, representation or guarantee that the Website,
        or your use of the Website, will be uninterrupted, complete, accurate,
        current, reliable, error-free, secure, or that any problems will be
        corrected, or that the Website, or any information, software or other
        material accessible from the Website, is free of viruses or other
        harmful components. ANALOGr does not by virtue of hosting the Website or
        licensing its access and use, or otherwise without specific, formal, and
        narrowly-tailored provision by ANALOGr with express reference hereto,
        warrant, guarantee, or make any representation regarding the use of, or
        the results of the use of the Website either in terms of its
        COMPATIBILITY WITH or EFFECT ON hardware or other software or equipment,
        and you assume all responsibility and risk for your use of the Website
        and Information and your reliance thereon.</p>
      <h2>Authentication.</h2>
      <p>You acknowledge and agree that ANALOGr’s authentication process is
        proprietary, confidential, in-house and independent. Brands identified
        on the Website are not involved in the authentication of the products
        being sold, and none of the brands sold assumes any responsibility for
        any products purchased from or through the Website. Brands sold on the
        Site are not partnered or affiliated with ANALOGr in any manner unless
        and only to the extent specifically so stated by ANALOGr, and only as
        and to the extent specifically designated by ANALOGr under the narrowest
        construction reasonable of the affiliation or partnership as described.
        .</p>
      <h2>LIMITATION OF LIABILITY</h2>
      <p>UNLESS OTHERWISE PROVIDED BY ANALOGr FOR SPECIFIC PERSONS BY WRITTEN
        EXECUTED AGREEMENT WITHIN THE CONTEXT OF INDEPENDENT CONTRACTUAL
        RELATIONSHIPS BETWEEN ANALOGr AND SPECIFICALLY IDENTIFIED PARTIES FOR
        CONTRACTUAL PURPOSES ESTABLISHED BY LEGAL INSTRUMENTS THAT SPECIFICALLY
        PROVIDE OTHERWISE, INCLUDING AS SET FORTH UNDER THE ACCEPTANCE OF TERMS
        ABOVE, ALL CONTENT, PRODUCTS, AND SERVICES ON THE WEBSITE, OR AVAILABLE
        THROUGH THE SERVICE, OR OBTAINED FROM A WEBSITE TO WHICH THE WEBSITE IS
        LINKED (A "LINKED SITE") ARE PROVIDED TO YOU "AS IS" WITHOUT WARRANTY OF
        ANY KIND EITHER EXPRESS OR IMPLIED INCLUDING, BUT NOT LIMITED TO, THE
        IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
        PURPOSE, TITLE, NON-INFRINGEMENT, SECURITY OR ACCURACY. NEITHER ANALOGr
        NOR ANY OF ITS LICENSORS, SPONSORS, AGENTS, SUCCESSORS, ASSIGNS,
        DIRECTORS, OFFICERS, EMPLOYEES, CONSULTANTS, OR OTHER REPRESENTATIVES
        ENDORSE OR ARE RESPONSIBLE OR LIABLE FOR ANY INDIRECT, INCIDENTAL,
        CONSEQUENTIAL, SPECIAL, EXEMPLARY OR PUNITIVE DAMAGES ARISING OUT OF OR
        RELATING IN ANY MANNER TO (A) THE WEBSITE, (B) ANY LINKED WEBSITE, (C)
        USER CONTENT; (D) YOUR USE OF, INABILITY TO USE, OR THE PERFORMANCE OF
        THE WEBSITE; (E) THE ACCURACY OR RELIABILITY OF ANY OPINION, ADVICE OR
        STATEMENT MADE THROUGH THE WEBSITE OR SERVICE BY ANY PARTY , (F) THE
        CAPABILITIES OR RELIABILITY OF ANY PRODUCT OR SERVICE OBTAINED FROM THE
        WEBSITE OR A LINKED SITE; (G) ANY ACTION TAKEN IN CONNECTION WITH AN
        INVESTIGATION BY ANALOGr OR LAW ENFORCEMENT AUTHORITIES REGARDING YOUR
        USE OF THE WEBSITE; (H) ANY ACTION TAKEN IN CONNECTION WITH COPYRIGHT OR
        OTHER INTELLECTUAL PROPERTY OWNERS; (I) ANY ERRORS OR OMISSIONS IN THE
        WEBSITE’S TECHNICAL OPERATION OR DESCRIPTIONS OF EQUIPMENT OR
        MEMORABILIA; OR (J) ANY DAMAGE TO ANY USER’S COMPUTER, HARDWARE,
        COMPUTER SOFTWARE, WIRELESS DEVICES, CELLULAR PHONE, MODEM OR OTHER
        EQUIPMENT OR TECHNOLOGY, INCLUDING, WITHOUT LIMITATION, DAMAGE FROM ANY
        SECURITY BREACH OR FROM ANY VIRUS, BUGS, TAMPERING, FRAUD, ERROR,
        OMISSION, INTERRUPTION, DEFECT, DELAY IN OPERATION OR TRANSMISSION,
        COMPUTER LINE OR NETWORK FAILURE OR ANY OTHER TECHNICAL OR OTHER
        MALFUNCTION, INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOST PROFITS,
        LOSS OF GOODWILL, LOSS OF DATA, WORK STOPPAGE, ACCURACY OF RESULTS, OR
        COMPUTER FAILURE OR MALFUNCTION, EVEN IF FORESEEABLE OR EVEN IF THE
        ANALOGr HAS BEEN ADVISED OF OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF
        SUCH DAMAGES, WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE, STRICT
        LIABILITY OR TORT (INCLUDING, WITHOUT LIMITATION, WHETHER CAUSED, IN
        WHOLE OR IN PART, BY NEGLIGENCE, ACTS OF GOD, TELECOMMUNICATIONS
        FAILURE, OR THEFT OR DESTRUCTION OF THE WEBSITE).</p>
      <p>IT IS THE RESPONSIBILITY OF THE USER TO EVALUATE THE ACCURACY,
        COMPLETENESS OR USEFULNESS OF ANY OPINION, ADVICE, INFORMATION PRODUCT,
        DESCRIPTION, SERVICE, OR OTHER CONTENT PUBLISHED OR OTHERWISE MADE
        AVAILABLE ON OR THROUGH THE WEBSITE, OR SUSCEPTIBLE OF BEING OBTAINED
        DIRECTLY OR INDIRECTLY FROM OR WITH THE VALID AND AUTHORIZED IMPRIMATUR
        OF A LINKED SITE. YOU ARE RESPONSIBLE FOR SEEKING ANY INDEPENDENT
        GUIDANCE AVAILABLE TO GUIDE YOUR EVALUATION OF ANY ASPECT OF THE
        WEBSITE, OR ANY DECISION RELATING TO ANY INFORMATION, GOOD, OR SERVICE
        PRESENTED OR PROVIDED THEREBY.</p>
      <p>YOUR SOLE REMEDY WITH RESPECT TO THIS WEBSITE OR ANY LINKED WEBSITE
        IS TO STOP USING THE WEBSITE OR LINKED WEBSITE, AS APPLICABLE. THE SOLE
        AND EXCLUSIVE MAXIMUM LIABILITY OF ANALOGr TO YOU FOR ALL DAMAGES,
        LOSSES, AND CAUSES OF ACTION, WHETHER IN CONTRACT, TORT (INCLUDING
        NEGLIGENCE), OR OTHERWISE ARISING OUT OF OR RELATING TO THE WEBSITE OR
        ANY LINKED WEBSITE SHALL BE $100.</p>
      <h2>WAIVER OF UNKNOWN CLAIMS.</h2>
      <p>BY ACCESSING THE WEBSITE, YOU UNDERSTAND THAT YOU MAY BE WAIVING
        RIGHTS WITH RESPECT TO CLAIMS THAT ARE AT THIS TIME UNKNOWN OR
        UNSUSPECTED, AND IN ACCORDANCE WITH SUCH WAIVER, YOU ACKNOWLEDGE THAT
        YOU HAVE READ AND UNDERSTAND, AND HEREBY EXPRESSLY WAIVE, THE BENEFITS
        OF SECTION 1542 OF THE CIVIL CODE OF CALIFORNIA, AND ANY SIMILAR LAW OF
        ANY STATE, WHICH PROVIDES AS FOLLOWS:</p>
      <p>“A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES
        NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF
        EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY
        AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR.”</p>
      <h2>Indemnification.</h2>
      <p>You agree that you will indemnify and hold harmless, and upon
        ANALOGr’s request, defend, ANALOGr and its directors, officers,
        shareholders, employees and agents from and against any and all losses,
        liabilities, damages, costs, expenses (including reasonable counsel
        fees), actions, suits and claims arising from any breach of any of your
        obligations, representations or warranties in these Terms of Use. If
        ANALOGr asks you to defend any such action, suit or claim, ANALOGr will
        have the right, at its own expense, to participate in the defense
        thereof. You will not settle any third-party claims for which ANALOGr is
        entitled to indemnification without the prior written approval of
        ANALOGr.</p>
      <h2>Non U.S. Users.</h2>
      <p>ANALOGr makes no representation that the Website operates (or is
        legally permitted to operate) in all geographic areas, or that the
        Website are appropriate or available for use in other locations.
        Accessing the Website from territories where the Website, or any content
        or functionality of the Website or portion thereof, is illegal is
        expressly prohibited. If you choose to access the Website, you agree and
        acknowledge that you do so on your own initiative and at your own risk
        and that you are solely responsible for compliance with all applicable
        laws.</p>
      <h2>Term and Termination.</h2>
      <p>These Terms of Use are effective from the date that you first access
        the Website or submit any information to ANALOGr via the Website,
        whichever is earlier, and shall remain effective until terminated in
        accordance with these Terms of Use. ANALOGr may immediately terminate
        these Terms of Use, and/or your access to and use of the Website, or any
        portion thereof, at any time and for any reason, with or without cause,
        without prior notice. ANALOGr may also terminate these Terms of Use
        immediately if you fail to comply with any term or provision of these
        Terms of Use. Upon termination of these Terms of Use by either party,
        your right to use the Website shall immediately cease, and you shall
        destroy all copies of information that you have obtained from the
        Website, whether made under the terms of these Terms of Use or
        otherwise. All disclaimers and all limitations of liability, all
        restrictions and obligations relating to or arising from content made
        available on the Website, and all ANALOGr rights of ownership or
        otherwise in the Website, its content, and all related assets, shall
        survive any termination or expiration of these Terms of Use.</p>
      <h2>ARBITRATION/GOVERNING LAW</h2>
      <p>These Terms of Use shall be governed by and construed in accordance
        with the laws of the State of California, excluding that body of law
        relating to conflict of laws. You agree that any pursuit by you,
        including any allegation, prosecution, pleading, presentation, or other
        communication announcing, describing, or otherwise raising with express
        or implied claim for resolution any form of dispute over any claim of
        right or for any cognizable relief arising out of or relating to any
        aspect of these Terms of Use, will be exclusively and confidentially
        raised, pursued, and settled by binding arbitration in accordance with
        California Code of Civil Procedure Section 1280 et seq., and you agree
        that any dispute or allegation raised or joined by you shall be
        prosecuted with maximum available considerations of confidentiality
        under the then-current rules and procedures of JAMS. The arbitration
        shall be a confidential proceeding, closed to the general public and
        subject to strict confidentiality obligations as to papers, pleadings,
        allegations, arguments, and adjudication. The arbitration will take
        place in Los Angeles County, California and be conducted in the English
        language. The decision rendered by the arbitrator will be binding upon
        the parties hereto, and any judgment upon the award rendered by the
        arbitrator may be entered in any court having jurisdiction thereof. For
        the sake of clarity, nothing in this paragraph shall affect ANALOGr’s
        ability to seek from a court injunctive or equitable relief at any time,
        including as set forth separately below. You agree that any arbitration
        will be conducted only on an individual basis between You and ANALOGr,
        and not in a class, consolidated or representative action or
        arbitration.</p>
      <p>For all claims or allegations raised or pursued against you or by
        ANALOGr arising in relation to or to enforce any aspect of these Terms
        of Use, you consent to the personal and subject-matter jurisdiction of
        all federal and state courts in California, and specifically agree not
        to allege any deficiency in subject-matter or personal jurisdiction, or
        venue-related challenge to adjudication by a state or federal court
        located in Los Angeles County, California.</p>
      <p>If any arbitration or other proceeding is brought to enforce or
        interpret these Terms of Use or matters relating to it pursuant to
        either of the foregoing paragraphs, and ANALOGr is the substantially
        prevailing party upon the conclusion of an applicable dispute-resolution
        proceeding, as determined by the presiding authority, judgment, or
        award,, ANALOGr will be entitled to recover reasonable attorneys’ fees
        and other costs and expenses incurred in such arbitration or proceeding
        from you, in addition to any other relief to which such prevailing party
        is entitled; provided that in no event will the arbitrator have the
        authority to award punitive damages.</p>
      <h2>Electronic Communication.</h2>
      <p>You consent to receive communications electronically from ANALOGr.
        ANALOGr will communicate with you by email or by posting notices on the
        Website. You agree that all agreements, notices, disclosures and other
        communications that are provided to you electronically satisfy any legal
        requirement that such communications be in writing.</p>
      <h2>Modification and Changes.</h2>
      <p>ANALOGr reserves the right to make changes to the Service, Website,
        related policies and agreements, these Terms of Use and the Privacy
        Policy at any time. It is your responsibility to review the Terms of Use
        from time to time for updates.</p>
      <p>You affirm that you are fully able and competent to enter into the
        terms, conditions, obligations, affirmations, representations, and
        warranties set forth in these Terms of Use, and to abide by and comply
        with these Terms of Use. If any provision of these Terms of Use is found
        to be unlawful, void, or for any reason unenforceable, then that
        provision shall be deemed severable from these Terms of Use and shall
        not affect the validity and enforceability of any remaining provisions.
        These Terms of Use constitute the entire agreement between you and
        ANALOGr relating to the subject matter herein and supersedes any and all
        prior or contemporaneous written or oral agreements between you and
        ANALOGr with respect to such subject matter. These Terms of Use may not
        be changed, waived or modified except by ANALOGr as provided herein or
        otherwise by written instrument signed by ANALOGr. These Terms of Use or
        any right, obligation, or remedy hereunder is not assignable,
        transferable, delegable, or sublicensable by you except with ANALOGr’s
        prior written consent, and any attempted assignment, transfer,
        delegation, or sublicense shall be null and void. ANALOGr may assign,
        transfer, or delegate these Terms of Use or any right or obligation or
        remedy hereunder in its sole discretion. No waiver by either party of
        any breach or default hereunder shall be deemed to be a waiver of any
        preceding or subsequent breach or default. ANALOGr shall have no
        liability under these Terms of Use to the extent arising from any
        failure of ANALOGr to perform any of its obligations under these Terms
        of Use due to any fire, flood, earthquakes, other acts of God, war,
        civil unrest, terrorism, Internet failures, governmental act or court
        order, national emergency, pandemic or public health emergency, strikes
        or labor disputes, or any other event not within ANALOGr reasonable
        control.</p>
      <h2 className={css.alignCenter}>CONDITIONS OF BIDDING and SALE AND DESCRIPTION OF MUSICAL EQUIPMENT
        AND MEMORABILIA</h2>
      <p>Unless and to the extent specifically so stated by ANALOGr in
        immediate contextual proximity, or provided by specific written
        instrument to specific individual parties within the context of an
        independent commercial relationship or transaction, ANALOGr’s knowledge
        in relation to each piece of musical equipment, or memorabilia may be
        partially or wholly dependent on information provided by third parties
        without specific further research or review by ANALOGr, including by a
        Consignor, and ANALOGr does not carry out exhaustive due diligence on
        each piece of equipment or memorabilia except as indicated by
        certification, authentication designation, or Collection accession.
        ANALOGr shall exercise reasonable care when making express statements in
        descriptions or condition reports consistent in its role as vendor
        considering (i) information provided to ANALOGr by a Consignor (or other
        party as applicable), (ii) technical knowledge exercised by in-house
        ANALOGr staff, and (iii) generally accepted opinions of relevant experts
        solely at the time any such express statement is made.</p>
      <p>Unless and to the extent specifically stated by ANALOGr as set forth
        in the Acceptance of Terms above, (1) content on the Website pertaining
        to goods and services, including descriptions of equipment or
        memorabilia, are not warranties and each piece of equipment or
        memorabilia is sold "as is”; and (2) aRepresentations or statements made
        by ANALOGr as to the authorship, origin, date, age, size, medium,
        attribution, genuineness, condition or estimated selling price of any
        equipment, or memorabilia reflect the factual content conveyed without
        obvious inconsistency or evident need for further review, and
        evaluations as to the veracity of such pass-through statements or
        assessments are statementsof opinion only. All photographic
        representations and other illustrations presented online or elsewhere
        are solely for guidance and are not to be relied upon in terms of
        revealing or describing any imperfections in the equipment. You
        understand and acknowledge that much of the equipment or memorabilia
        depicted on the Website is of an age or nature that precludes being in
        pristine condition and may make reference to damage and/or restoration.
        Such information is given for guidance only and the absence of such a
        reference does not imply that a work is free from defects nor does any
        reference to particular defects imply the absence of others.</p>
      <h2>LIMITATION OF LIABILITY</h2>
      <p>Neither the Consignor nor we, nor any of our officers, employees or
        agents, are responsible for the correctness of any statement of whatever
        kind concerning any equipment or memorabilia whether written or oral nor
        for any other errors or omissions in description or for any faults or
        defects in any equipment, or memorabilia. Neither the Consignor,
        ourselves, our officers, employees or agents, give any representation,
        warranty or guarantee or assume any liability of any kind in respect of
        any piece of equipment, or memorabilia with regard to merchantability,
        fitness for particular purpose, description, size quality, condition,
        attribution, authenticity, rarity, importance, medium, history, or
        historical relevance. Except as required by local law any warranty of
        any kind whatsoever is excluded by this paragraph.</p>
      <p>In the event you discover that the equipment or memorabilia sold on
        this website is not of the origin or historical use described, then upon
        three (3) months from the date of sale you may contact ANALOGr in
        writing specifying the sale in which the property was included, the item
        number on the order confirmation and the reasons why the origin or
        historical use of the equipment or memorabilia is being questioned. Upon
        receiving such notice, ANALOGr may rescind the sale and return the
        purchase price to you in its sole discretion, provided that ANALOGr
        reserves the right, as a condition to rescind any sale, to require the
        buyer to provide to us at the buyer's expense the written opinions of
        two recognized experts in the field, mutually acceptable to ANALOGr and
        the buyer, before ANALOGr determines whether or not to cancel the sale.
        We shall not be bound by any expert report produced by the buyer and
        reserve the right to consult our own experts at our expense. Please note
        that any rescission or cancellation of a sale is in ANALOGr's sole
        discretion and is not guaranteed nor an automatic remedy provided.</p>
      <p>Additionally, this remedy, if so provided by ANALOGr, shall
        constitute the sole remedy and recourse of the buyer against ANALOGr,
        and any of our affiliated companies and the seller and is in lieu of any
        other remedy available as a matter of law or equity. None of ANALOGr,
        any of our affiliated companies or the seller shall be liable for loss
        or damage beyond the remedy, whether such loss or damage is
        characterized as direct, indirect, special, incidental or consequential,
        or for the payment of interest on the original purchase price.</p>
      <h2>PURCHASES AND PAYMENTS</h2>
      <p>All sales of musical equipment, memorabilia and/or other products
        purchased on the Website are for final sale and once purchased there
        will be no refunds or replacements except as expressly provided for
        herein. Purchases, installments, deposits, and any other
        purchase-related charges or payments incurred or elected by you in
        connection with your use of the Website and any purchases made thereon
        shall be automatically processed by ANALOGr using the credit card or
        debit card registered to your personal account. YOU AUTHORIZE AUTOMATIC
        PROCESSING BY ANALOGr of all such charges, credits, debits, or other
        payments for transactions arising from your use of the Website,
        including any purchases made on the Website, and any deposits, partial
        payments, installments, or other charges incurred as a result thereof.
        Payments for any purchase, reservation, exercised option, or consummated
        offer are pre-authorized by you and shall be processed immediately
        without additional notice upon ANALOGr’s approval of a purchase,
        automatically without additional notice for installment purchases
        approved at ANALOGr’s discretion, or otherwise as ANALOGr may establish
        by separate agreement, or elect in its sole discretion, for purchases
        made at any time on the Website through your personal account.</p>
      <p>If you wish to purchase an item or product via wire transfer or
        crypto currency, your request for purchase will first need to be
        approved by the bank, or crypto wallet provider. If approved, you will
        receive a notification on the Website in your personal account, or email
        from us with a link to consummate and finish the sale (“Purchase Link”).
        Once you receive the Purchase Link, you will have up to twenty-four (24)
        hours to consummate and finish the sale. If you do not consummate and
        finish the sale through the Purchase Link within twenty-four (24) hours
        from the time the Purchase Link is sent to you, ANALOGr may, in its sole
        discretion, cancel the sale and deem the purchase null and void. For
        this reason, we strongly suggest you review your personal ANALOGr
        account and email account frequently after the decision to purchase a
        product from our Website in this manner as the sale is not final or
        binding until you have provided payment through the provided for
        Purchase Link.</p>
      <p>If you wish to purchase an item or product available for payment by
        periodic installment, you will need to acknowledge the repayment terms
        (including the non-refundable deposit) supplied on the Website when
        submitting your bid, which will become a binding sales contract upon
        ANALOGr’s decision, if any, to accept your bid and provide notice to
        you. You may also, ANALOGr’s discretion, be required to sign a further
        acknowledgment of our Installment-Payment Agreement before any sale or
        obligation on ANALOGr’s part is final or binding. The
        Installment-Payment Agreement shall confirm your understanding and
        agreement to the specific terms and conditions you agree will govern
        your payment of any item purchased through the “Pay by Installment”
        radio button option on the Website, and will be required if ANALOGr, in
        its discretion, elects to offer you repayment terms that vary from the
        standard options provided by default through the checkout process, but
        shall not be necessary in all circumstances unless ANALOGr so requires
        and informs you through your Account. For all installment-payment
        options, you understand and agree that:</p>
      <ol type="1">
        <li><p>No items paid by installment shall be delivered until payment is
          completed in full by monthly automatic charges to the card registered to
          your Account, which you authorize ANALOGr to assess without further
          notice to you in the amounts designated by ANALOGr and confirmed by you
          in the checkout procedure by which you submit your bid; and</p></li>
        <li><p>A portion of your initial deposit payment shall be nonrefundable,
          reflecting the damage to ANALOGr’s inventory value for items withdrawn
          from auction or offer and subsequently reintroduced for sale. This
          portion shall constitute a cancellation fee, fixed at the percentage
          identified by ANALOGr and confirmed by you in the bid-submission
          checkout process, and shall be retained by ANALOGr and waived by you at
          such time as ANALOGr may notify you that any payment is in arrears or in
          default, or after two automatic payments of scheduled installments fail
          to be processed or paid, or if payment is not completed in full by the
          end of the installment period. The portion and timing of this
          nonrefundable cancellation fee will be disclosed to you for confirmation
          before you authorize submission of your bid, unless altered by
          subsequent arrangement with ANALOGr, and you agree and authorize the
          automatic retention by ANALOGr of the nonrefundable cancellation fee at
          such time as ANALOGr provides notice of cancellation for
          default.</p></li>
      </ol>
      <p>If you select the “Pay by Installment” radio button, you will receive
        a notification on the Website in your personal account with the current
        version of ANALOGr’s Installment Terms that shall govern your payment
        obligations for the purchase you selected on the Website. The default
        terms and conditions for ANALOGr’s installment options may change
        without other notice to you from time to time, and to any extent they
        conflict with these Terms of Use, your payment for purchases by
        installment shall be governed by the specific Installment-Payment
        Agreement provided for your purchase, so we strongly suggest you review
        your personal ANALOGr account and email account frequently after the
        decision to purchase a product from our Website in this manner.</p>
      <h3>Bidding.</h3>
      <p>When using the Website you agree to the following:</p>
      <ul className={css.listTerm}>
        <li><p>You are responsible for reading the full item listing before
          making a bid or committing to buying.</p></li>
        <li><p>You enter into a legally binding contract to purchase an item
          when you commit to buy an item, your offer for an item is accepted, or
          if you have the winning bid (or your bid is otherwise
          accepted).</p></li>
        <li><p>If you bid on an item you represent and warrant that you have and
          will continue to have the full amount of funds necessary to either (1)
          satisfy your installment payment obligations, beginning with the initial
          deposit immediately upon ANALOGr’s acceptance, if any, and continuing
          through such subsequent installments as scheduled, by automatic draws on
          the card linked to your account at the time your bid was accepted; or
          (2) complete the purchase at the full price bid, and to complete payment
          of any successive bids you authorize to be placed on your behalf to
          complete the purchase. All funds required to be available to satisfy
          obligations hereunder must be and remain available for immediate draw by
          remote processing by credit or debit card in the debit or credit account
          from which the card registered to your account draws, and susceptible of
          automatic processing immediately (or immediately as scheduled, for
          installment payments) within any applicable payment-sum limitations or
          authorization measures by ANALOGr without further action by
          you.</p></li>
      </ul>
      <h3>Sales Tax.</h3>
      <p>Purchases may be subject to sales, use, excise and other tax in some
        jurisdictions. It is the buyer's responsibility to ascertain and pay all
        taxes due. Buyers claiming exemption from sales tax must have the
        appropriate documentation on file with ANALOGr prior to the release of
        the property.</p>
      <h3>Package and Shipping.</h3>
      <p>Buyers are responsible for all shipping costs and fees. ANALOGr may
        require a copy of any government issued identification prior shipping
        any item to you. At the buyer’s expense ANALOGr will either provide
        packing, handling, insurance and shipping services or coordinate with
        shipping agents instructed by the buyer in order to facilitate such
        services for equipment or memorabilia purchased from ANALOGr. ANALOGr
        will not be liable for acts or omissions of third party packers or
        shippers.</p>
    </div>
  );
};

TermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfService.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfService;
