import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { Form, Button, FieldTextInput } from '../../components';
import { nonEmptyArray, composeValidators, validYoutubeUrl } from '../../util/validators';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import RemoveFieldButton from '../../components/RemoveFieldButton/RemoveFieldButton';

import css from './EditListingHyperlinkForm.module.css';

const MAX_URL_QTY = 3;

export const EditListingHyperlinkFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        form,
        values,
        errors
      } = formRenderProps;
      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDetailsForm.updateFailed" />
        </p>
      ) : null;
      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDetailsForm.showListingFailed" />
        </p>
      ) : null;

      const linkYoutubeInvalidUrlMessage = intl.formatMessage({
        id: 'EditListingHyperlinkForm.youtubelinkInvalidUrl',
      });
    
      const linkYoutubeValidators = composeValidators(validYoutubeUrl(linkYoutubeInvalidUrlMessage));
      
      const linkYoutubeLabel = (
        <span>
          <FormattedMessage id='EditListingConsignForm.linkYoutubeLabel' />
          <span className={css.optionalLabel}><FormattedMessage id='EditListingConsignForm.optionalLabel' /></span>
        </span>
      )

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      const { push } = form.mutators;

      const canAddYoutubeLinkField = (length, currentIndex) => {
        return length < MAX_URL_QTY && length - currentIndex === 1;
      }

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}

          <FieldArray name="youtubeLinks" >
            {(fieldProps) => {
                const { fields } = fieldProps;
                return fields.map((name, index) => {
                  return (
                    <div className={css.youtubeField} key={index}>
                      <FieldTextInput
                        id={name}
                        name={name}
                        type="text"
                        className={css.hyperlink}
                        label={linkYoutubeLabel}
                        validate={linkYoutubeValidators}
                        onChange={(e) => {
                          form.change(name, e.target.value);
                          if (canAddYoutubeLinkField(fields.length, index) && e.target.value !== '') {
                            // Set to 'undefined' to create an empty value
                            push("youtubeLinks", undefined); 
                          }
                        }}
                      />
                      {fields.length > 1 && 
                        (<RemoveFieldButton 
                          className={css.removeTextFieldButton} 
                          onClick={() => fields.remove(index)} 
                        />)
                      }
                    </div>
                  );
                })
              }
            }
          </FieldArray>

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingHyperlinkFormComponent.defaultProps = {
  selectedPlace: null,
  updateError: null,
};

EditListingHyperlinkFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingHyperlinkFormComponent);
