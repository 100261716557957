import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { propTypes } from '../../util/types';

import css from './BookingBreakdown.module.css';

const LineItemBasePriceMaybe = props => {
  const { transaction, intl } = props;

  // Find correct line-item for given unitType prop.
  // It should be one of the following: 'line-item/night, 'line-item/day', 'line-item/units', or 'line-item/time'
  // These are defined in '../../util/types';

  const offerLabel = <FormattedMessage id="BookingBreakdown.offer" />
  const offerPrice = transaction.attributes.payinTotal;
  const formattedTotalPrice = formatMoney(intl, offerPrice);

  return (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        {offerLabel}
      </span>
      <span className={css.itemValue}>{formattedTotalPrice}</span>
    </div>
  );
};

LineItemBasePriceMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemBasePriceMaybe;
