import React, { useEffect, useState } from 'react'
import { calculateTimeLeft, formatDisplayTime } from '../../util/dates';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import isEmpty from 'lodash/isEmpty';
import classnames from 'classnames';

import css from './CountdownTimer.module.css';

const isTimeUp = (time) => {
  const { days, hours, minutes, seconds } = time;

  return (days === 0 && hours === 0 && minutes === 0 && seconds === 0) || isEmpty(time);
}

const formatTime = (intl, time) => {
  const { days, hours, minutes, seconds } = formatDisplayTime(time);

  return intl.formatMessage({ 
    id: "CountdownTimer.format", 
    }, { 
      days,
      hours,
      minutes,
      seconds
  });
}

const CountdownTimer = (props) => {
  const { intl, endDate, onContinue, rootClassName, className } = props;
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(endDate));
  const classes = classnames(rootClassName || className);
  useEffect(() => {
    const interval = setInterval(() => setTimeLeft(
      (prevTimeLeft) => {
        // Check whether the time is over or not
        if (!isTimeUp(prevTimeLeft)) {
          return calculateTimeLeft(endDate)
        }
        clearInterval(interval);
        onContinue(false);
        return {};
      }), 1000);

    return () => {
      clearInterval(interval);
    }
  }, [endDate]);

  const formattedTime = formatTime(intl, timeLeft);

  return (
    <p className={classes}>{formattedTime}</p>
  )
}

export default compose(injectIntl)(CountdownTimer);