import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { parse } from '../../util/urlHelpers';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  Page,
  PaginationLinks,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  ListingTable,
} from '../../components';
import { TopbarContainer } from '..';

import {
  getOwnWatchlistById,
  queryOwnWatchlist,
} from './WatchlistPage.duck';
import css from './WatchlistPage.module.css';
import { updateCurrentWatchlist } from '../WatchlistPage/WatchlistPage.duck';
import { REMOVE_WATCHERS } from '../../util/types';

const RESULT_PAGE_SIZE = 10;

export class WatchlistPageComponent extends Component {
  constructor(props) {
    super(props);
    this.onRemoveFromWatchlist = this.onRemoveFromWatchlist.bind(this);
  }

  onRemoveFromWatchlist(listingId) {
    const { currentUser, onUpdateWatchlist, params, search } = this.props;
    const userId = currentUser.id;
    const queryUpdateWatchlist = {listingId, userId, action: REMOVE_WATCHERS};
    const queryUpdatePagination = {params, search, perPage: RESULT_PAGE_SIZE};
    onUpdateWatchlist(queryUpdateWatchlist, queryUpdatePagination);
  }

  render() {
    const {
      listings,
      pagination,
      queryInProgress,
      queryListingsError,
      updateWatchlistInProgress,
      updateWatchlistError,
      queryParams,
      scrollingDisabled,
      intl,
    } = this.props;
    const hasPaginationInfo = !!pagination && pagination.totalItems !== null;
    const listingsAreLoaded = (!queryInProgress && !updateWatchlistInProgress) && hasPaginationInfo;

    const loadingResults = (
      <h2>
        <FormattedMessage id="WatchlistPage.loadingOwnListings" />
      </h2>
    );

    const queryError = (
      <h2 className={css.error}>
        <FormattedMessage id="WatchlistPage.queryError" />
      </h2>
    );

    const noResults =
      listingsAreLoaded && pagination.totalItems === 0 ? (
        <h1 className={css.title}>
          <FormattedMessage id="WatchlistPage.noResults" />
        </h1>
      ) : null;

    const heading =
      listingsAreLoaded && pagination.totalItems > 0 ? (
        <h1 className={css.title}>
          <FormattedMessage
            id="WatchlistPage.youHaveListings"
            values={{ count: pagination.totalItems }}
          />
        </h1>
      ) : (
        noResults
      );

    const page = queryParams ? queryParams.page : 1;
    const paginationLinks =
      listingsAreLoaded && pagination && pagination.totalPages > 1 ? (
        <PaginationLinks
          className={css.pagination}
          pageName="WatchlistPage"
          pageSearchParams={{ page }}
          pagination={pagination}
        />
      ) : null;

    const title = intl.formatMessage({ id: 'WatchlistPage.title' });

    return (
      <Page title={title} scrollingDisabled={scrollingDisabled}>
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer currentPage="WatchlistPage" />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            {queryInProgress || updateWatchlistInProgress ? loadingResults : null}
            {queryListingsError || updateWatchlistError ? queryError : null}
            <div className={css.listingPanel}>
              {heading}
              {(!queryInProgress && !updateWatchlistInProgress) && listings.length > 0 && <div className={css.listingTable}>
                <ListingTable listings={listings} onRemoveFromWatchlist={this.onRemoveFromWatchlist}/>
              </div>}
              {paginationLinks}
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

WatchlistPageComponent.defaultProps = {
  listings: [],
  pagination: null,
  queryListingsError: null,
  updateWatchlistError: null,
  queryParams: null,
};

const { arrayOf, bool, object, shape } = PropTypes;

WatchlistPageComponent.propTypes = {
  listings: arrayOf(propTypes.listing),
  pagination: propTypes.pagination,
  queryInProgress: bool.isRequired,
  queryListingsError: propTypes.error,
  updateWatchlistInProgress: bool.isRequired,
  updateWatchlistError: propTypes.error,
  queryParams: object,
  scrollingDisabled: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    currentPageResultIds,
    pagination,
    queryInProgress,
    queryListingsError,
    updateWatchlistInProgress,
    updateWatchlistError,
    queryParams,
  } = state.WatchlistPage;
  const { currentUser } = state.user;
  const listings = getOwnWatchlistById(state, currentPageResultIds);
  return {
    currentPageResultIds,
    currentUser,
    listings,
    pagination,
    queryInProgress,
    queryListingsError,
    updateWatchlistInProgress,
    updateWatchlistError,
    queryParams,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onUpdateWatchlist: (listingId, userId, action) => dispatch(updateCurrentWatchlist(listingId, userId, action)),
});

const WatchlistPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(WatchlistPageComponent);

WatchlistPage.loadData = (params, search) => {
  const userId = params.id;
  const queryParams = parse(search);
  const page = queryParams.page || 1;
  return queryOwnWatchlist({
    ...queryParams,
    userId,
    page,
    perPage: RESULT_PAGE_SIZE,
    include: ['images'],
    'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    'limit.images': 1,
  });
};

export default WatchlistPage;
