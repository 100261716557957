import React from 'react';
import config from '../../config';
import { twitterPageURL } from '../../util/urlHelpers';
import { StaticPage, TopbarContainer } from '../../containers';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer
} from '../../components';
import image from './AboutAnalogr.jpg'

import css from './AboutPage.module.css';

const AboutPage = () => {
  const { siteTwitterHandle, siteFacebookPage } = config;
  const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  // prettier-ignore
  return (
    <StaticPage
      title="About Us"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'AboutPage',
        description: 'About Saunatime',
        name: 'About page',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
        <h1 className={css.pageTitle}>About us</h1>
          <img className={css.coverImage} src={image} alt="My first ice cream." />
          <div className={css.headerWrapper}>
            <h2>
              ANALOGr is the destination for highly curated, music-related assets of pop culture's most important moments.
            </h2>
          </div>
          <div className={css.contentWrapper}>
            <div className={css.contentMain}>
              <p>
                ANALOGr partners with the world's leading artists, collectors, and estates to tell the inside stories of their <em>unobtainium</em>. We travel the globe in search of these one-of-a-kind artifacts to present them to the world. Our platform connects artists, collectors, and fans with a mutual love for the iconic assets of music history.
              </p>
            </div>
            <div className={css.contentSide}>
              <div>
                <h5>FOUNDED</h5>
                <span>2020</span>
              </div>
              <div>
                <h5>HEADQUARTERS</h5>
                <span>Los Angeles</span>
              </div>
            </div>
          </div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default AboutPage;
