import React, { useMemo, useCallback, useState, Component } from 'react';
import { element, func, object, oneOfType, shape, string } from 'prop-types';
import { createEditor } from 'slate';
import { Slate, Editable, withReact } from 'slate-react';
import { withHistory } from 'slate-history';
import { ValidationError } from '../../components';
import { AlignButton, BlockButton, FontButton, ImageButton, MarkButton, Toolbar, WrapTextButton } from './helpers/RichTextComponents';
import { withHtml, withImages } from './helpers/RichTextFeatures';
import { HOTKEYS, Element, Leaf, toggleMark } from './helpers/RichTextConfig';
import { Field } from 'react-final-form';

import isHotkey from 'is-hotkey';
import css from './FieldRichText.module.css';
import { intlShape, injectIntl } from '../../util/reactIntl';
import { compose } from 'redux';

const RichTextComponent = props => {
  const { id, label, input, meta, customErrorText, intl } = props;
  const { value } = input;
  const { invalid, touched, error } = meta;

  const errorText = customErrorText || error;
  const hasError = !!customErrorText || !!(touched && invalid && error);
  const fieldMeta = { touched: hasError, error: errorText };
  const initialValue = getInitialValue(intl);

  const [slateValue, setValue] = useState(value ? JSON.parse(value) : initialValue);

  const editor = useMemo(
    () => withImages(withHtml(withReact(withHistory(createEditor())))), []
  );

  const renderElement = useCallback(eprops => <Element {...eprops} />, []);
  const renderLeaf = useCallback(eprops => <Leaf {...eprops} />, []);

  return (
    <div>
      {label ? <label htmlFor={id}>{label}</label> : null}
      <Slate
        id={id}
        editor={editor}
        value={slateValue}
        onChange={newValue => {
          setValue(newValue);
          input.onChange(newValue && newValue.length ? JSON.stringify(newValue) : "");
        }}>
        <Toolbar>
          <MarkButton format="bold" icon="format_bold" title={intl.formatMessage({ id: 'FieldRichText.boldButton' })} />
          <MarkButton format="italic" icon="format_italic" title={intl.formatMessage({ id: 'FieldRichText.italicButton' })} />
          <MarkButton format="underline" icon="format_underlined" title={intl.formatMessage({ id: 'FieldRichText.underlineButton' })} />
          <MarkButton format="code" icon="code" title={intl.formatMessage({ id: 'FieldRichText.codeButton' })} />
          <FontButton format="font-family" fontFamily="font_default" title={intl.formatMessage({ id: 'FieldRichText.fontDefault' })} />
          <FontButton format="font-family" fontFamily="font_arsenal" title={intl.formatMessage({ id: 'FieldRichText.fontArsenal' })} />
          <FontButton format="font-family" fontFamily="font_pt_serif" title={intl.formatMessage({ id: 'FieldRichText.fontPTSerif' })} />
          <BlockButton format="heading-one" icon="heading_one" title={intl.formatMessage({ id: 'FieldRichText.headingOneButton' })} />
          <BlockButton format="heading-two" icon="heading_two" title={intl.formatMessage({ id: 'FieldRichText.headingTwoButton' })} />
          <BlockButton format="heading-three" icon="heading_three" title={intl.formatMessage({ id: 'FieldRichText.headingThreeButton' })} />
          <BlockButton format="block-quote" icon="format_quote" title={intl.formatMessage({ id: 'FieldRichText.blockquoteButton' })} />
          <BlockButton format="numbered-list" icon="format_list_numbered" title={intl.formatMessage({ id: 'FieldRichText.numberListButton' })} />
          <BlockButton format="bulleted-list" icon="format_list_bulleted" title={intl.formatMessage({ id: 'FieldRichText.bulletListButton' })} />
          <ImageButton title={intl.formatMessage({ id: 'FieldRichText.imageButton' })} />
          <AlignButton align="left" icon="align_left" title={intl.formatMessage({ id: 'FieldRichText.alignLeftButton' })} />
          <AlignButton align="center" icon="align_center" title={intl.formatMessage({ id: 'FieldRichText.alignCenterButton' })} />
          <AlignButton align="right" icon="align_right" title={intl.formatMessage({ id: 'FieldRichText.alignRightButton' })} />
          <WrapTextButton wrapAlign="wrap-text-align-left" icon="wrap_text_left" title={intl.formatMessage({ id: 'FieldRichText.wrapTextAlignLeftButton' })} />
          <WrapTextButton wrapAlign="wrap-text-align-right" icon="wrap_text_right" title={intl.formatMessage({ id: 'FieldRichText.wrapTextAlignRightButton' })} />
        </Toolbar>
        <Editable
          className={css.slateEditor}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          spellCheck
          onKeyDown={event => {
            for (const hotkey in HOTKEYS) {
              if (isHotkey(hotkey, event)) {
                event.preventDefault();

                const mark = HOTKEYS[hotkey];
                toggleMark(editor, mark);
              }
            }
          }}
        />
      </Slate>
      <ValidationError fieldMeta={fieldMeta} />
    </div>
  )
};

const getInitialValue = (intl) => {
  return [
    {
      type: 'paragraph',
      children: [
        { text: intl.formatMessage({ id: "FieldRichText.intro1" }) },
        { text: intl.formatMessage({ id: "FieldRichText.intro2" }), bold: true },
        { text: intl.formatMessage({ id: "FieldRichText.intro3" }) },
        { text: intl.formatMessage({ id: "FieldRichText.intro4" }), italic: true },
        { text: intl.formatMessage({ id: "FieldRichText.intro5" }) },
        { text: intl.formatMessage({ id: "FieldRichText.intro6" }), code: true },
        { text: intl.formatMessage({ id: "FieldRichText.intro7" }) },
      ],
    },
    {
      type: 'paragraph',
      children: [
        { text: intl.formatMessage({ id: "FieldRichText.explainFirstPart" }) },
        { text: intl.formatMessage({ id: "FieldRichText.bold" }), bold: true },
        { text: intl.formatMessage({ id: "FieldRichText.explainSecondPart" }) },
      ],
    },
    {
      type: 'block-quote',
      children: [{ text: intl.formatMessage({ id: 'FieldRichText.blockquote' }) }],
    },
    {
      type: 'paragraph',
      children: [{ text: intl.formatMessage({ id: 'FieldRichText.tryit' }) }],
    },
  ];
}

RichTextComponent.defaultProps = {
  onUnmount: null,
  customErrorText: null,
  id: null,
  label: null
};

RichTextComponent.propTypes = {
  onUnmount: func,
  customErrorText: string,
  id: string,
  label: oneOfType([string, element]),
  input: shape({
    onChange: func.isRequired,
  }).isRequired,
  meta: object.isRequired,
  intl: intlShape.isRequired
}

class FieldRichText extends Component {
  componentWillUnmount() {
    // Unmounting happens too late if it is done inside Field component
    // (Then Form has already registered its (new) fields and
    // changing the value without corresponding field is prohibited in Final Form
    if (this.props.onUnmount) {
      this.props.onUnmount();
    }
  }

  render() {
    return <Field component={RichTextComponent} {...this.props} />;
  }
}

export default compose(injectIntl)(FieldRichText);