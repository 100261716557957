import React from 'react';
import { FormattedMessage } from '../../../util/reactIntl';
import config from '../../../config';
import { findOptionsForSelectFilter } from '../../../util/search';
import { NamedLink } from '../../../components';
import EditIcon from './EditIcon';
import { string, bool, func, shape } from 'prop-types';

import css from '../ListingPage.module.css';

const SectionHeading = props => {
  const {
    category,
    ensuredAuthor,
    title,
    authorDisplayName,
    showContactUser,
    onContactUser,
    isSold,
    editListingParams,
    estimatePrice
  } = props;

  const richTitle = (
    <span className={css.longWord}>
      {title}
    </span>
  );

  const hostLink = (
    <NamedLink
      className={css.authorNameLink}
      name="ProfilePage"
      params={{ id: ensuredAuthor.id.uuid }}
      to={{ hash: '#host' }}
    >
      {authorDisplayName}
    </NamedLink>
  );

  // Category
  const categoryOptions = findOptionsForSelectFilter('category', config.custom.filters);

  const categoryLabel = (categories, key) => {
    const cat = categories.find(c => c.key === key);
    return cat ? cat.label : key;
  };  

  return (
    <div className={css.sectionHeading}>
      <div className={css.heading}>
        <h1 className={css.title}>
          {richTitle}
          {isSold && 
            <span className={css.soldText}>
              <FormattedMessage id='ListingPage.sold' />
            </span>
          }
        </h1>
        <div className={css.author}>
          {category && 
            <>
              <FormattedMessage id={categoryLabel(categoryOptions, category)} />
              <span className={css.separator}>•</span>
            </>
          }
          <FormattedMessage id="ListingPage.hostedBy" values={{ name: hostLink }} />
          {showContactUser 
            ?  
              (<span>
                <span className={css.separator}>•</span>
                <span className={css.messageSellerLink} onClick={onContactUser}>
                  <FormattedMessage id="ListingPage.ctaButtonMessageSeller" />
                </span>
              </span>)
            : 
              (<span>
                <span className={css.separator}>•</span>
                <span className={css.editListingLink} >
                  <EditIcon className={css.editIcon} />
                  <NamedLink name="EditListingPage" params={editListingParams} >
                    <FormattedMessage id="ListingPage.editListing" />
                  </NamedLink>
                </span>
              </span>)
          }
        </div>
      </div>
      {estimatePrice && (
        <div className={css.estPrice}>
          <span className={css.estPriceLabel}>{estimatePrice.estimatePriceLabel}</span>
          <span className={css.estPriceValue}>{estimatePrice.formattedEstimatePrice}</span>
        </div>
      )}
    </div>
  );
};

SectionHeading.propTypes = {
  title: string.isRequired,
  authorDisplayName: string.isRequired,
  showContactUser: bool,
  onContactUser: func,
  isSold: bool,
  estimatePrice: shape({
    id: string,
    slug: string,
    type: string,
    tab: string,
  }),
  estimatePrice: shape({
    formattedEstimatePrice: string,
    estimatePriceLabel: string
  })
}

export default SectionHeading;
