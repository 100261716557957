import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { PrimaryButton, SecondaryButton } from '..';

import css from './TransactionPanel.module.css';

// Functional component as a helper to build ActionButtons for
// provider when state is preauthorized
const CounterOfferActionButtonsMaybe = props => {
  const {
    className,
    rootClassName,
    showButtons,
    makeNewBidInProgress,
    makeNewBidError,
    handleAcceptCounterOffer,
    handleMakeAnotherOffer,
    acceptCounterOfferInProgress,
    acceptCounterOfferError
  } = props;

  const buttonsDisabled = makeNewBidInProgress || acceptCounterOfferInProgress;

  const makeNewBidErrorMessage = makeNewBidError ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.makeNewBidFailed" />
    </p>
  ) : null;

  const acceptCounterOfferErrorMessage = acceptCounterOfferError ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.acceptCounterOfferFailed" />
    </p>
  ) : null;

  const classes = classNames(rootClassName || css.actionButtons, className);

  return showButtons ? (
    <div className={classes}>
      <div className={css.actionErrors}>
        {acceptCounterOfferErrorMessage}
        {makeNewBidErrorMessage}
      </div>
      <div className={css.actionButtonWrapper}>
        <PrimaryButton
          className={css.acceptButtonHover}
          inProgress={acceptCounterOfferInProgress}
          disabled={buttonsDisabled}
          onClick={handleAcceptCounterOffer}
        >
          <FormattedMessage id="TransactionPanel.acceptCounterOfferButton" />
        </PrimaryButton>
        <SecondaryButton
          className={css.secondaryButtonHover}
          inProgress={makeNewBidInProgress}
          disabled={buttonsDisabled}
          onClick={handleMakeAnotherOffer}
        >
          <FormattedMessage id="TransactionPanel.makeNewBidButton" />
        </SecondaryButton>
      </div>
    </div>
  ) : null;
};

export default CounterOfferActionButtonsMaybe;
