import React from 'react';
import { FieldSelect } from '../../components';

import css from './EditListingMicDetailsForm.module.css';

const CustomIssueSelect = props => {
  const { name, id, issue, issueMicPairA, issueMicPairB, intl } = props;
  const issueLabel = intl.formatMessage({
    id: 'EditListingDetailsForm.issueLabel',
  });
  return issue ? (
    <FieldSelect className={css.condition} name={name} id={id} label={issueLabel}>
      <option disabled value=""></option>
      {issue.map(c => (
        <option key={c.key} value={c.key}>
          {intl.formatMessage({
            id: c.label
          })}
        </option>
      ))}
    </FieldSelect>
  ) : issueMicPairA ? (
    <FieldSelect className={css.condition} name={name} id={id} label={issueLabel}>
      <option disabled value=""></option>
      {issueMicPairA.map(c => (
        <option key={c.key} value={c.key}>
          {intl.formatMessage({
            id: c.label
          })}
        </option>
      ))}
    </FieldSelect>
  ) : issueMicPairB ? (
    <FieldSelect className={css.condition} name={name} id={id} label={issueLabel}>
      <option disabled value=""></option>
      {issueMicPairB.map(c => (
        <option key={c.key} value={c.key}>
          {intl.formatMessage({
            id: c.label
          })}
        </option>
      ))}
    </FieldSelect>
  ) : null;
};

export default CustomIssueSelect;
