import React from 'react';
import { array, string } from 'prop-types';
import { FormattedMessage } from '../../../util/reactIntl';
import classNames from 'classnames';

import css from '../ListingPage.module.css';
import { ExternalLink } from '../../../components';

const SectionFeaturedRecordsMaybe = props => {
  const { className, rootClassName, featuredRecords, intl } = props;
  const classes = classNames(rootClassName || css.root, className);

  const viewOnDiscogsText = intl.formatMessage({
    id: "ListingPage.viewOnDiscogs"
  })

  const featuredRecordList =
    featuredRecords.length > 0
    && (
      <div className={css.featuredRecords}>
        {featuredRecords.map((record, index) => {
          const name = record.imgUrl ? record.imgUrl.split('/').pop() : '';
          const actualUrl = record.url.includes("https://") ? record.url : `https://${record.url}`;
          return (
            <div key={index} className={css.featuredRecord}>
              <img src={record.imgUrl} alt={name} />

              <ExternalLink href={actualUrl} className={css.url}>
                {viewOnDiscogsText}
              </ExternalLink>
            </div>
          )
        })}
      </div>
    );

  return (
    <div className={css.otherSection}>
      <h2 className={css.sectionTitle}>
        <FormattedMessage id="ListingPage.featuredRecordsTitle" />
      </h2>
      <div className={css.recordsCarousel}>
        {featuredRecordList}
      </div>
    </div>
  );
};

SectionFeaturedRecordsMaybe.defaultProps = { className: null, rootClassName: null };

SectionFeaturedRecordsMaybe.propTypes = {
  className: string,
  rootClassName: string,
  featuredRecords: array
};

export default SectionFeaturedRecordsMaybe;
