import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  Button,
  Form,
  FieldCurrencyInput,
  FieldCheckbox,
  FieldDateInput,
  FieldTextInput,
} from '../../components';
import css from './EditListingTimedAuctionForm.module.css';

const { Money } = sdkTypes;

export const EditListingTimedAuctionFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        values,
        timeSlots,
        isReservePriceMet
      } = formRenderProps;

      const { isTimedAuction, isAutomated, hasStartDate } = values;

      const reservePriceLabel = intl.formatMessage({
        id: 'EditListingTimedAuctionForm.reservePriceLabel',
      });

      const priceRequired = validators.required(
        intl.formatMessage({
          id: 'EditListingTimedAuctionForm.reservePriceRequired',
        })
      );
      const timeRequired = validators.required(
        intl.formatMessage({
          id: 'EditListingTimedAuctionForm.timeRequired',
        })
      );
      const durationRequired = validators.required(
        intl.formatMessage({
          id: 'EditListingTimedAuctionForm.durationRequired',
        })
      );
      const durationValid = validators.validQuantity(
        intl.formatMessage({
          id: 'EditListingTimedAuctionForm.durationRequired',
        })
      );
      const durationValidators = validators.composeValidators(durationRequired, durationValid);
      const minPrice = new Money(config.listingMinimumPriceSubUnits, config.currency);
      const minPriceRequired = validators.moneySubUnitAmountAtLeast(
        intl.formatMessage(
          {
            id: 'EditListingTimedAuctionForm.priceTooLow',
          },
          {
            minPrice: formatMoney(intl, minPrice),
          }
        ),
        config.listingMinimumPriceSubUnits
      );
     
      const priceValidators = config.listingMinimumPriceSubUnits
        ? validators.composeValidators(priceRequired, minPriceRequired)
        : priceRequired;

      const isTimedAuctionLabel = intl.formatMessage({
        id: 'EditListingTimedAuctionForm.isTimedAuctionLabel',
      });
      const isAutomatedLabel = intl.formatMessage({
        id: 'EditListingTimedAuctionForm.isAutomatedLabel',
      });
      const hasStartDateLabel = intl.formatMessage({
        id: 'EditListingTimedAuctionForm.hasStartDateLabel',
      });
      const startDateLabel = intl.formatMessage({
        id: 'EditListingTimedAuctionForm.startDateLabel',
      });
      const startTimeLabel = intl.formatMessage({
        id: 'EditListingTimedAuctionForm.startTimeLabel',
      });
      const endDateLabel = intl.formatMessage({ id: 'EditListingTimedAuctionForm.endDateLabel' });
      const endTimeLabel = intl.formatMessage({ id: 'EditListingTimedAuctionForm.endTimeLabel' });
      const durationLabel = intl.formatMessage({ id: 'EditListingTimedAuctionForm.durationLabel' });

      const durationNote = intl.formatMessage({ id: 'EditListingTimedAuctionForm.durationNote' });
      const startDateNote = intl.formatMessage({ id: 'EditListingTimedAuctionForm.startDateNote' });

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      const { updateListingError, showListingsError } = fetchErrors || {};

      const startDateFields = (
        <>
          <div className={css.dateField}>
            <FieldDateInput
              className={css.dateSelect}
              id="startDate"
              name="startDate"
              label={startDateLabel}
              format={v => (v && v.date ? { date: v.date } : v)}
              parse={v => (v && v.date ? { date: v.date } : v)}
              timeSlots={timeSlots}
              disabled={isReservePriceMet}
            />
          </div>
          <div className={css.timeField}>
            <FieldTextInput
              className={css.dateSelect}
              id="startTime"
              name="startTime"
              label={startTimeLabel}
              type="time"
              validate={timeRequired}
              disabled={isReservePriceMet}
            />
          </div>
          <p className={css.note}>{startDateNote}</p>
        </>
      );

      const endDateFields = (
        <>
          <div className={css.dateField}>
            <FieldDateInput
              className={css.dateSelect}
              id="endDate"
              name="endDate"
              label={endDateLabel}
              format={v => (v && v.date ? { date: v.date } : v)}
              parse={v => (v && v.date ? { date: v.date } : v)}
              timeSlots={timeSlots}
            />
          </div>
          <div className={css.timeField}>
            <FieldTextInput
              className={css.dateSelect}
              id="endTime"
              name="endTime"
              label={endTimeLabel}
              type="time"
              validate={timeRequired}
            />
          </div>
        </>
      );

      const durationField = (
        <div className={css.durationField}>
          <FieldTextInput
            inputRootClass={css.dateSelect}
            id="duration"
            name="duration"
            label={durationLabel}
            type="number"
            validate={durationValidators}
            disabled={isReservePriceMet}
          />
          <p className={css.note}>{durationNote}</p>
        </div>
      );

      const automatedFields = (
        <>
          {durationField}
          <FieldCheckbox
            id="hasStartDate"
            name="hasStartDate"
            label={hasStartDateLabel}
            disabled={isReservePriceMet}
          />
          {!!hasStartDate && startDateFields}
        </>
      );

      return (
        <Form onSubmit={handleSubmit} className={classes}>
          {updateListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingTimedAuctionForm.updateFailed" />
            </p>
          ) : null}
          {showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingTimedAuctionForm.showListingFailed" />
            </p>
          ) : null}

          <FieldCheckbox id="isTimedAuction" name="isTimedAuction" label={isTimedAuctionLabel} />

          {isTimedAuction && (
            <>
              <FieldCurrencyInput
                id="reservePrice"
                name="reservePrice"
                className={css.priceInput}
                label={reservePriceLabel}
                currencyConfig={config.currencyConfig}
                validate={priceValidators}
              />
              <FieldCheckbox
                className={css.automatedField}
                id="isAutomated"
                name="isAutomated"
                label={isAutomatedLabel}
              />
              {isAutomated ? automatedFields : endDateFields}
            </>
          )}

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingTimedAuctionFormComponent.defaultProps = { fetchErrors: null };

EditListingTimedAuctionFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingTimedAuctionFormComponent);
