import React from 'react';
import { bool, func, shape, string, array } from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { injectIntl, FormattedMessage, FormattedHTMLMessage } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import { propTypes, SESSION_FORM_KEYS } from '../../util/types';
import config from '../../config';
import { useDispatch } from 'react-redux';
import { Button, Form, FieldTextInput, IconSpinner, ValidationError } from '../../components';
import RemoveFieldButton from '../../components/RemoveFieldButton/RemoveFieldButton';
import { compose } from 'redux';
import * as validators from '../../util/validators';
import { FieldArray } from 'react-final-form-arrays';
import { requestDocumentRemove, removeRecordImage, requestRecordImageUpload } from '../../containers/EditListingPage/EditListingPage.duck';

import css from './EditListingFeaturedRecordsForm.module.css';

const ACCEPT_IMAGES = 'image/*';
const MAX_FIELD_QTY = 5;

const EditListingFeaturedRecordsFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        disabled,
        invalid,
        ready,
        rootClassName,
        className,
        name,
        handleSubmit,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        form,
        values,
        intl,
        saveDataToSessionStorage,
        recordImages
      } = formRenderProps;
      const classes = classNames(rootClassName || css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = disabled || submitInProgress || invalid;
      const dispatch = useDispatch();
      const { push } = form.mutators;
      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturedRecordsForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturedRecordsForm.showListingFailed" />
        </p>
      ) : null;

      const linkRequiredMessage = intl.formatMessage({
        id: 'EditListingConsignForm.linkRequired',
      });
    
      const linkValidationMessage = intl.formatMessage({
        id: 'EditListingConsignForm.linkValidation',
      });
    
      const imageRequiredMessage = intl.formatMessage({
        id: 'EditListingConsignForm.imageRequiredMessage',
      });
    
      const linkRequired = validators.required(linkRequiredMessage);
      const linkValidation = validators.validDiscogsUrl(linkValidationMessage);
      const validDiscogsLinkValidator = validators.composeValidators(linkValidation, linkRequired);

      const linkLabel = (
        <span>
          <FormattedMessage id='EditListingConsignForm.featuredRecordsLinkLabel' />
          <span className={css.optionalLabel}><FormattedMessage id='EditListingConsignForm.optionalLabel' /></span>
        </span>
      );

      const chooseImageText = (
        <span className={css.chooseImageText}>
          <span className={css.chooseImage}>
            <FormattedMessage id='EditListingPhotosForm.chooseImage' />
          </span>
          <span className={css.featuredImageTypes}>
            <FormattedHTMLMessage id='EditListingPhotosForm.featuredImageTypes' />
          </span>
        </span>
      );

      const loadingSpinner = updateInProgress && 
        (<div className={css.loadingScreen}>
            <div className={css.loader}>
              <IconSpinner/>
            </div>
          </div>
        );

      const canAddFeaturedRecordField = (length, currentIndex) => {
        return length < MAX_FIELD_QTY && length - currentIndex === 1;
      }

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}
          {loadingSpinner}
          <FieldArray name="featuredRecords" validate={validators.composeValidators(validators.nonEmptyArray(imageRequiredMessage))}>
            {(fieldProps) => {
                const { fields } = fieldProps;
                return fields.map((name, index) => {
                  return (
                    <div className={css.recordField} key={index}>
                      <div className={css.recordImageField}>
                        <label>
                          <FormattedMessage id='EditListingConsignForm.recordImageLabel' />
                          <span className={css.optionalLabel}><FormattedMessage id='EditListingConsignForm.optionalLabel' /></span>
                        </label>
                        
                        {recordImages[index] &&
                          <div className={css.featuredImageField}>
                            <img src={recordImages[index]} alt={`image${index}`} />
                            <RemoveFieldButton 
                              className={css.removeRecordImageButton} 
                              onClick={() => {
                                dispatch(removeRecordImage(index));
                                dispatch(requestDocumentRemove(recordImages[index]));
                              }}
                              hasText={false} 
                            />
                          </div>
                        }

                        {!recordImages[index] &&
                          (<Field
                            id={`${name}.image`}
                            name={`${name}.image`}
                            accept={ACCEPT_IMAGES}
                            form={null}
                            parse={x => x}
                            label={chooseImageText}
                            type="file"
                            disabled={submitInProgress}
                          >
                            {fieldprops => {
                              const { accept, input, label, disabled: fieldDisabled } = fieldprops;
                              const { name, type } = input;
                              const onChange = e => {
                                const file = e.target.files[0];
                                if (file) {
                                  dispatch(requestRecordImageUpload(file, index))
                                  form.blur(`featuredRecords[${index}].image`);
                                  saveDataToSessionStorage(SESSION_FORM_KEYS.EDIT_LISTING_FEATURED_RECORDS, values);
                                }
                              };
                              const inputProps = { accept, id: name, name, onChange, type };
                              return (
                                <div className={css.addSquareImageWrapper}>
                                  <div className={css.aspectRatioSquareWrapper}>
                                    {fieldDisabled ? null : (
                                      <input {...inputProps} className={css.addImageInput} />
                                    )}
                                    <label htmlFor={name} className={css.addImage}>
                                      {label}
                                    </label>
                                  </div>
                                </div>
                              );
                            }}
                          </Field>)
                        }
                        
                        { values.featuredRecords[index].url &&
                          <Field
                            component={props => {
                              const { input, meta } = props;
                              return (
                                <div className={css.imageRequiredWrapper}>
                                  <input {...input} />
                                  <ValidationError fieldMeta={meta} />
                                </div>
                              );
                            }}
                            name={`recordImages[${index}]`}
                            type="hidden"
                            validate={validators.composeValidators(validators.required(imageRequiredMessage))}
                          />
                        }
                      </div>
                      <FieldTextInput
                        id={`${name}.url`}
                        name={`${name}.url`}
                        type="text"
                        className={css.featuredHyperlink}
                        label={linkLabel}
                        validate={recordImages[index] ? validDiscogsLinkValidator : null}
                        onChange={(e) => {
                          form.change(`featuredRecords[${index}].url`, e.target.value);
                          if (e.target.value && canAddFeaturedRecordField(fields.length, index)) {
                            push("featuredRecords", {});
                          }
                        }}
                      />
                      {fields.length > 1 &&
                        (<RemoveFieldButton 
                          className={css.removeFeaturedRecordButton} 
                          onClick={() => {
                            fields.remove(index);
                            if (recordImages[index]) {
                              dispatch(requestDocumentRemove(recordImages[index]));
                              recordImages.splice(index, 1);
                            }
                          }} 
                        />)
                      }
                    </div>
                  )
                })
              }
            }
          </FieldArray>

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingFeaturedRecordsFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingFeaturedRecordsFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  name: string.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  saveDataToSessionStorage: func.isRequired,
  recordImages: array.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

const EditListingFeaturedRecordsForm = EditListingFeaturedRecordsFormComponent;

export default compose(injectIntl)(EditListingFeaturedRecordsForm);
