import omit from 'lodash/omit';
import { types as sdkTypes } from '../../util/sdkLoader';
import { storableError } from '../../util/errors';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { submitConsignment } from '../../util/api';
import { uploadFile, removeFile } from '../../util/custom-api';
import { AUTHENTICITY_DOCUMENT_UPLOAD, SERVICE_DOCUMENT_UPLOAD } from '../../util/types';

const { UUID } = sdkTypes;

const requestAction = actionType => params => ({ type: actionType, payload: { params } });

const successAction = actionType => result => ({ type: actionType, payload: result.data });

const errorAction = actionType => error => ({ type: actionType, payload: error, error: true });

// ================ Action types ================ //
export const RESET_DATA = 'app/EditListingPage/RESET_DATA';

export const UPLOAD_IMAGE_REQUEST = 'app/EditConsignListingPage/UPLOAD_IMAGE_REQUEST';
export const UPLOAD_IMAGE_SUCCESS = 'app/EditConsignListingPage/UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_ERROR = 'app/EditConsignListingPage/UPLOAD_IMAGE_ERROR';

export const UPDATE_IMAGE_ORDER = 'app/EditConsignListingPage/UPDATE_IMAGE_ORDER';

export const REMOVE_LISTING_IMAGE = 'app/EditConsignListingPage/REMOVE_LISTING_IMAGE';

export const UPLOAD_RECORD_IMAGE_REQUEST = 'app/EditConsignListingPage/UPLOAD_RECORD_IMAGE_REQUEST';
export const UPLOAD_RECORD_IMAGE_SUCCESS = 'app/EditConsignListingPage/UPLOAD_RECORD_IMAGE_SUCCESS';
export const UPLOAD_RECORD_IMAGE_ERROR = 'app/EditConsignListingPage/UPLOAD_RECORD_IMAGE_ERROR';

export const UPLOAD_DOCUMENT_REQUEST = 'app/EditConsignListingPage/UPLOAD_DOCUMENT_REQUEST';
export const UPLOAD_DOCUMENT_SUCCESS = 'app/EditConsignListingPage/UPLOAD_DOCUMENT_SUCCESS';
export const UPLOAD_DOCUMENT_ERROR = 'app/EditConsignListingPage/UPLOAD_DOCUMENT_ERROR';

export const REMOVE_SERVICE_DOCUMENT = 'app/EditConsignListingPage/REMOVE_SERVICE_DOCUMENT';
export const REMOVE_RECORD_IMAGE = 'app/EditConsignListingPage/REMOVE_RECORD_IMAGE';

// ================ Reducer ================ //

const initialState = {
  // Error instance placeholders for each endpoint
  uploadImageError: null,
  uploadRecordImageError: null,
  uploadRecordImageInProgress: false,
  submittedListingId: null,
  redirectToListing: false,
  images: {},
  recordImages: [],
  imageOrder: [],
  removedImageIds: [],
  listingDraft: null,
  updatedTab: null,
  updateInProgress: false,
  uploadDocumentError: null,
  uploadAuthDocumentInProgress: false,
  uploadServiceDocumentInProgress: false,
  serviceDocuments: [],
  authDocuments: []
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case RESET_DATA: {
      return { ...initialState };
    }
    case UPLOAD_IMAGE_REQUEST: {
      // payload.params: { id: 'tempId', file }
      const images = {
        ...state.images,
        [payload.params.id]: { ...payload.params },
      };
      return {
        ...state,
        images,
        uploadImageError: null,
      };
    }
    case UPLOAD_IMAGE_SUCCESS: {
      // payload.params: { id: 'tempId', imageId: 'some-real-id'}
      const { id, imageId } = payload;
      const file = state.images[id].file;
      const images = { ...state.images, [id]: { id, imageId, file } };
      return { ...state, images, imageOrder: state.imageOrder.concat([imageId.uuid]) };
    }
    case UPLOAD_IMAGE_ERROR: {
      const { id, error } = payload;
      const images = omit(state.images, id);
      return { ...state, images, uploadImageError: error };
    }
    case UPDATE_IMAGE_ORDER:
      return { ...state, imageOrder: payload.imageOrder };

    case REMOVE_LISTING_IMAGE: {
      const id = payload.imageId;

      const removedImageIds = state.removedImageIds.concat(id);

      // Always remove from the draft since it might be a new image to
      // an existing listing.
      const currentImages = Object.keys(state.images).map(key => state.images[key]);
      const targetImage = currentImages.find(img => img.imageId.uuid === id);

      const images = omit(state.images, targetImage.id);
      const imageOrder = state.imageOrder.filter(i => i !== id);

      return { ...state, images, imageOrder, removedImageIds };
    }

    case UPLOAD_RECORD_IMAGE_REQUEST: {
      return {
        ...state,
        uploadRecordImageError: null,
        uploadRecordImageInProgress: true
      };
    }
    case UPLOAD_RECORD_IMAGE_SUCCESS: {
      // payload.params: { id: 'tempId', imageId: 'some-real-id'}
      const { imgUrl, uploadIndex } = payload;
      const recordImages = [...state.recordImages];
      recordImages[uploadIndex] = imgUrl;
      return { ...state, recordImages, uploadRecordImageInProgress: false };
    }
    case UPLOAD_RECORD_IMAGE_ERROR: {
      // eslint-disable-next-line no-console
      const { error } = payload;
      return { ...state, uploadImageError: error };
    }
    case UPLOAD_DOCUMENT_REQUEST: {
      const { params: type } = payload;
      switch (type) {
        case AUTHENTICITY_DOCUMENT_UPLOAD: {
          return { ...state, uploadDocumentError: null, uploadAuthDocumentInProgress: true };
        }
        case SERVICE_DOCUMENT_UPLOAD: {
          return { ...state, uploadDocumentError: null, uploadServiceDocumentInProgress: true };
        }
      }
    }
    case UPLOAD_DOCUMENT_SUCCESS: {
      // payload.params: { id: 'tempId', imageId: 'some-real-id'}
      const { name, type, docUrl } = payload;
      switch (type) {
        case AUTHENTICITY_DOCUMENT_UPLOAD: {
          const authDocuments = state.authDocuments.concat({ name, url: docUrl });
          return { ...state, authDocuments, uploadAuthDocumentInProgress: false };
        }
        case SERVICE_DOCUMENT_UPLOAD: {
          const serviceDocuments = state.serviceDocuments.concat({ name, url: docUrl });
          return { ...state, serviceDocuments, uploadServiceDocumentInProgress: false };
        }
      }
    }
    case UPLOAD_DOCUMENT_ERROR: {
      // eslint-disable-next-line no-console
      const { error } = payload;
      return { ...state, uploadDocumentError: error };
    }
    case REMOVE_RECORD_IMAGE: {
      const { removeIndex } = payload;
      const recordImages = state.recordImages.map((image, index) => index === removeIndex ? "" : image);
      return { ...state, recordImages, uploadRecordImageInProgress: false };
    }
    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //
export const resetData = () => ({
  type: RESET_DATA
});

export const updateImageOrder = imageOrder => ({
  type: UPDATE_IMAGE_ORDER,
  payload: { imageOrder },
});

export const removeListingImage = imageId => ({
  type: REMOVE_LISTING_IMAGE,
  payload: { imageId },
});

export const removeRecordImage = removeIndex => ({
  type: REMOVE_RECORD_IMAGE,
  payload: { removeIndex }
});

// All the action creators that don't have the {Success, Error} suffix
// take the params object that the corresponding SDK endpoint method
// expects.

// SDK method: images.upload
export const uploadImage = requestAction(UPLOAD_IMAGE_REQUEST);
export const uploadImageSuccess = successAction(UPLOAD_IMAGE_SUCCESS);
export const uploadImageError = errorAction(UPLOAD_IMAGE_ERROR);

export const uploadRecordImageRequest = requestAction(UPLOAD_RECORD_IMAGE_REQUEST);
export const uploadRecordImageSuccess = successAction(UPLOAD_RECORD_IMAGE_SUCCESS);
export const uploadRecordImageError = errorAction(UPLOAD_RECORD_IMAGE_ERROR);

export const uploadDocumentRequest = requestAction(UPLOAD_DOCUMENT_REQUEST);
export const uploadDocumentSuccess = successAction(UPLOAD_DOCUMENT_SUCCESS);
export const uploadDocumentError = errorAction(UPLOAD_DOCUMENT_ERROR);
// ================ Thunk ================ //

// Images return imageId which we need to map with previously generated temporary id
export function requestImageUpload(actionPayload) {
  return (dispatch, getState, sdk) => {
    const id = actionPayload.id;
    dispatch(uploadImage(actionPayload));
    return sdk.images
      .upload({ image: actionPayload.file })
      .then(resp => dispatch(uploadImageSuccess({ data: { id, imageId: resp.data.data.id } })))
      .catch(e => dispatch(uploadImageError({ id, error: storableError(e) })));
  };
}

export function requestRecordImageUpload(file, uploadIndex) {
  return (dispatch, getState, sdk) => {
    const id = new Date().getTime();
    dispatch(uploadRecordImageRequest());
    return uploadFile({ file })
      .then(resp => dispatch(uploadRecordImageSuccess({ data: { id, imgUrl: resp.data.data.url, uploadIndex } })))
      .catch(e => dispatch(uploadRecordImageError({ id, error: storableError(e) })));
  };
}

export function requestDocumentUpload({ file, type }) {
  return (dispatch, getState, sdk) => {
    const id = new Date().getTime();
    dispatch(uploadDocumentRequest(type));
    return uploadFile({ file })
      .then(resp => dispatch(uploadDocumentSuccess({ data: { id, name: file.name, type, docUrl: resp.data.data.url } })))
      .catch(e => dispatch(uploadDocumentError({ id, error: storableError(e) })));
  };
}

export function requestDocumentRemove(url) {
  return (dispatch, getState, sdk) => {
    const name = url.split('/').slice(-1)[0];
    return removeFile({ name });
  }
}
