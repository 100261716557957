import { jsx } from 'slate-hyperscript';
import { Editor, Transforms } from 'slate';
import { useSelected, useFocused } from 'slate-react';

import classNames from 'classnames';
import css from '../FieldRichText.module.css';

export const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
};

const LIST_TYPES = ['numbered-list', 'bulleted-list'];

const ELEMENT_TAGS = {
  A: el => ({ type: 'link', url: el.getAttribute('href') }),
  BLOCKQUOTE: () => ({ type: 'quote' }),
  H1: () => ({ type: 'heading-one' }),
  H2: () => ({ type: 'heading-two' }),
  H3: () => ({ type: 'heading-three' }),
  H4: () => ({ type: 'heading-four' }),
  H5: () => ({ type: 'heading-five' }),
  H6: () => ({ type: 'heading-six' }),
  IMG: el => ({ type: 'image', url: el.getAttribute('src'), children: [{ text: '' }] }),
  LI: () => ({ type: 'list-item' }),
  OL: () => ({ type: 'numbered-list' }),
  P: () => ({ type: 'paragraph' }),
  PRE: () => ({ type: 'code' }),
  UL: () => ({ type: 'bulleted-list' }),
  TABLE: () => ({ type: 'table' }),
  TBODY: () => ({ type: 'tbody' }),
  THEAD: () => ({ type: 'thead' }),
  TR: () => ({ type: 'table-row' }),
  TD: () => ({ type: 'table-cell' }),
  TH: () => ({ type: 'table-cell-header' }),
  HEADER: () => ({ type: 'header' }),
  SECTION: () => ({ type: 'section' }),
}

const TEXT_TAGS = {
  CODE: () => ({ code: true }),
  DEL: () => ({ strikethrough: true }),
  EM: () => ({ italic: true }),
  I: () => ({ italic: true }),
  S: () => ({ strikethrough: true }),
  STRONG: () => ({ bold: true }),
  U: () => ({ underline: true }),
}

const getClassNames = (element, className = '') => {
  return { className: classNames(className ? css[className] : '', element.align ? css[element.align] : '') };
}

export const Element = props => {
  const { attributes, children, element } = props;

  switch (element.type) {
    case 'thead':
      return (
        <thead {...attributes} {...getClassNames(element)}>{children}</thead>
      )
    case 'tbody':
      return (
        <tbody {...attributes} {...getClassNames(element)}>{children}</tbody>
      )
    case 'table':
      return (
        <table {...attributes} {...getClassNames(element)}>{children}</table>
      )
    case 'table-row':
      return <tr {...attributes} {...getClassNames(element)}>{children}</tr>
    case 'table-cell':
      return <td {...attributes} {...getClassNames(element)}>{children}</td>
    case 'table-cell-header':
      return <th {...attributes} {...getClassNames(element)}>{children}</th>
    case 'header':
      return <header {...attributes} {...getClassNames(element)}>{children}</header>
    case 'section':
      return <section {...attributes} {...getClassNames(element)}>{children}</section>
    case 'block-quote':
      return <blockquote {...attributes} {...getClassNames(element, 'blockquote')}>{children}</blockquote>
    case 'code':
      return (
        <pre>
          <code {...attributes} {...getClassNames(element, 'code')}>{children}</code>
        </pre>
      )
    case 'bulleted-list':
      return <ul {...attributes} {...getClassNames(element, 'ul')}>{children}</ul>
    case 'heading-one':
      return <h1 {...attributes} {...getClassNames(element)}>{children}</h1>
    case 'heading-two':
      return <h2 {...attributes} {...getClassNames(element)}>{children}</h2>
    case 'heading-three':
      return <h3 {...attributes} {...getClassNames(element)}>{children}</h3>
    case 'heading-four':
      return <h4 {...attributes} {...getClassNames(element)}>{children}</h4>
    case 'heading-five':
      return <h5 {...attributes} {...getClassNames(element)}>{children}</h5>
    case 'heading-six':
      return <h6 {...attributes} {...getClassNames(element)}>{children}</h6>
    case 'list-item':
      return <li {...attributes} {...getClassNames(element)}>{children}</li>
    case 'numbered-list':
      return <ol  {...attributes} {...getClassNames(element)}>{children}</ol>
    case 'link':
      return (
        <a href={element.url} {...attributes} {...getClassNames(element)}>
          {children}
        </a>
      )
    case 'image':
      return <ImageElement {...props} />
    default:
      return <p {...attributes} {...getClassNames(element)}>{children}</p>
  }
}

export const ImageElement = ({ attributes, children, element }) => {
  const selected = useSelected();
  const focused = useFocused();
  const boxShadow = selected && focused ? 'boxShadowOnFocus' : 'boxShadowOff';
  return (
    <div {...attributes} className={classNames(css.imageContainer, element.align ? css[element.align] : '', element.wrapAlign ? css[element.wrapAlign] : '')}>
      {children}
      <img
        src={element.url}
        className={classNames(css.image, css[boxShadow], element.align ? css[element.align] : '')}
      />
    </div>
  )
}

export const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong {...getClassNames(leaf)}>{children}</strong>;
  }

  if (leaf.code) {
    children = <code {...getClassNames(leaf, 'code')}>{children}</code>;
  }

  if (leaf.italic) {
    children = <em {...getClassNames(leaf)}>{children}</em>;
  }

  if (leaf.underline) {
    children = <u {...getClassNames(leaf)}>{children}</u>;
  }

  if (leaf.strikethrough) {
    children = <del {...getClassNames(leaf)}>{children}</del>;
  }

  if (leaf['font-family']) {
    children = <span {...getClassNames(leaf, leaf['font-family'])}>{children}</span>;
  }

  return <span {...attributes} {...getClassNames(leaf)}>{children}</span>;
}

export const deserialize = (el, mAttrs = {}) => {
  if (el.nodeType === 3) {
    return el.textContent;
  } else if (el.nodeType !== 1) {
    return null;
  } else if (el.nodeName === 'BR') {
    return '\n';
  }

  const { nodeName } = el;
  let parent = el;
  let children = [];

  if (nodeName === 'PRE') {
    if (el.childNodes[0] && el.childNodes[0].nodeName === 'CODE') {
      parent = el.childNodes[0];
    }

    children = Array.from(parent.childNodes)
      .map((e) => {
        return {
          text: e.textContent,
          code: true,
        }
      });
  } else {
    children = Array.from(parent.childNodes)
      .map(deserialize)
      .flat();
  }

  if (children.length === 0) {
    children = [{ text: '' }];
  }

  if (el.nodeName === 'BODY') {
    return jsx('fragment', {}, children);
  }

  if (ELEMENT_TAGS[nodeName]) {
    const attrs = ELEMENT_TAGS[nodeName](el);

    return jsx('element', attrs, children);
  }

  if (TEXT_TAGS[nodeName]) {
    const attrs = TEXT_TAGS[nodeName](el);
    return children.map(child => jsx('text', attrs, child));
  }

  return children;
}

export const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: n => LIST_TYPES.includes(n.type),
    split: true,
  });

  Transforms.setNodes(editor, {
    type: isActive ? 'paragraph' : isList ? 'list-item' : format,
  });

  if (!isActive && isList) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block);
  }
}

export const addMark = (editor, format, value) => {
  Editor.addMark(editor, format, value ?? true);
}

export const removeMark = (editor, format) => {
  Editor.removeMark(editor, format);
}

export const toggleMark = (editor, format, value) => {
  const isActive = isMarkActive(editor, format);
  if (isActive) {
    removeMark(editor, format);
  } else {
    addMark(editor, format, value ?? true);
  }
}

export const isBlockActive = (editor, format) => {
  const [match] = Editor.nodes(editor, {
    match: n => n.type === format,
  })
  return !!match;
}

export const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? !!marks[format] : false;
}

export const isAlignActive = (editor, foundAlign) => {
  const [match] = Editor.nodes(editor, {
    match: n => n.align === foundAlign,
  })
  return !!match;
}

export const toggleAlignStrategy = (editor, align) => {
  const isActive = isAlignActive(editor, align);

  Transforms.setNodes(editor, {
    align: isActive ? null : align
  });
}

export const isWrapTextActive = (editor, foundAlign) => {
  const [match] = Editor.nodes(editor, {
    match: n => n.wrapAlign === foundAlign,
  })
  return !!match;
}

export const toggleWrapTextAlignForImage = (editor, align) => {
  const isActive = isWrapTextActive(editor, align);

  Transforms.setNodes(editor, {
    wrapAlign: isActive ? null : align
  });
}