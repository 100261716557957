const IS_CLOSE_INFOMATION_MODAL = 'landingPage/IS_CLOSE_INFOMATION_MODAL';

export const saveCloseInformationModalToSession = data => saveDataToSession(
  IS_CLOSE_INFOMATION_MODAL,
  data
);
export const getCloseInformationModalToSession = () => getDataSession(IS_CLOSE_INFOMATION_MODAL);
export const removesCloseInformationModalToSession = () => removeDataSession(IS_CLOSE_INFOMATION_MODAL);

const saveDataToSession = (key, data) => {
  if (typeof window !== 'undefined' && window.sessionStorage) {
    window.sessionStorage.setItem(key, JSON.stringify(data));
  }
}

const getDataSession = (key) => {
  if (typeof window !== 'undefined' && window.sessionStorage) {
    const data = window.sessionStorage.getItem(key);
    return data && JSON.parse(data);
  }
}

const removeDataSession = (key) => {
  if (typeof window !== 'undefined' && window.sessionStorage) {
    window.sessionStorage.removeItem(key);
  }
}