import React from 'react';
import PropTypes, { func } from 'prop-types';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { ListingCard, PaginationCustom } from '..';
import { FormattedMessage } from 'react-intl';

import css from './CollectionResultsPanel.module.css';

const CollectionResultsPanel = props => {
  const { className, rootClassName, listings, pagination, onChangePagination } = props;
  const classes = classNames(rootClassName || css.root, className);
  

  const paginationLinks =
    pagination && pagination.totalPages > 1 ? (
      <PaginationCustom
        className={css.pagination}
        pagination={pagination}
        onChangePagination={onChangePagination}
      />
    ) : null;

  // Panel width relative to the viewport
  const panelMediumWidth = 50;
  const panelLargeWidth = 62.5;
  const cardRenderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) ${panelMediumWidth}vw`,
    `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
    `${panelLargeWidth / 3}vw`,
  ].join(', ');

  const resultsCount = pagination ? pagination.totalItems : 0;

  const noResults = <FormattedMessage id="SectionCollection.noResults" />

  return (
    <div className={classes}>
      {resultsCount > 0 ? (
        <React.Fragment>
          <div className={css.listingCards}>
            {listings.map(l => (
              <ListingCard
                className={css.listingCard}
                key={l.id.uuid}
                listing={l}
                renderSizes={cardRenderSizes}
              />
            ))}
          </div>
          {paginationLinks}
        </React.Fragment>
      ) : (
        noResults
      )}
    </div>
  );
};

CollectionResultsPanel.defaultProps = {
  children: null,
  className: null,
  listings: [],
  pagination: null,
  rootClassName: null,
  search: null,
};

const { array, node, object, string } = PropTypes;

CollectionResultsPanel.propTypes = {
  children: node,
  className: string,
  listings: array,
  pagination: propTypes.pagination,
  rootClassName: string,
  onFetchCollections: func
};

export default CollectionResultsPanel;
