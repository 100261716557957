import React from 'react';
import { FormattedMessage } from '../../../util/reactIntl';
import classnames from 'classnames';
import documentIcon from '../../../assets/documentIcon.png';
import { findOptionsForSelectFilter } from '../../../util/search';
import config from '../../../config';
import { ExternalLink } from '../../../components';

import css from '../ListingPage.module.css';
import SectionWrapper from './SectionWrapper';

const SectionAuthenticityMaybe = props => {
  const { className, rootClassName, authenticity, intl } = props;
  const classes = classnames(rootClassName || css.root, className);
  const authLabels = {};
  
  findOptionsForSelectFilter('authenticity', config.custom.filters)
    .forEach((auth) => {
      authLabels[auth.key] = auth.label;
    });

  const title = intl.formatMessage({ id: 'ListingPage.authenticityTitle'});

  const authenticityDocs = 
    authenticity.length > 0
    && (
        <div className={css.list}> 
          {authenticity.map((doc, index) => {
            const fileType = intl.formatMessage({
              id: authLabels[doc.type]
            });

            return (
              <div key={index} className={css.listItem}>
                <ExternalLink href={doc.file.url} className={css.url}>
                  <img src={documentIcon} alt='documentIcon'/>
                  <span className={css.docName}>{`${index + 1}. ${fileType}`}</span>
                </ExternalLink>
              </div>
            )
          })}
        </div>
    );

  return (
    <SectionWrapper title={title}>
      {authenticityDocs}
    </SectionWrapper>
  );
};

export default SectionAuthenticityMaybe;
