import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT, SESSION_FORM_KEYS, SERVICE_NAME } from '../../util/types';
import { ensureListing } from '../../util/data';
import { EditListingServiceForm } from '../../forms';
import { ListingLink } from '../../components';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';

import css from './EditListingServicePanel.module.css';

const EditListingServicePanel = props => {
  const {
    rootClassName,
    className,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    saveDataToSessionStorage,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const { publicData } = currentListing.attributes;
  const initialData = JSON.parse(sessionStorage.getItem(SESSION_FORM_KEYS.EDIT_LISTING_SERVICE));
  const service = (initialData && initialData.service) || (publicData && publicData.service);
  const serviceDocuments = useSelector(state => state.EditListingPage.serviceDocuments);
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const collectionListingWarning = <FormattedMessage id="EditListingPanels.collectionListingWarning" />;
  const isCollection = publicData && publicData.category === 'collection';
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingServicePanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingServicePanel.createListingTitle" />
  );
  const form = <EditListingServiceForm
    className={css.form}
    name={SERVICE_NAME}
    initialValues={{ 
      service: {
        ...service,
        dateTime: service && service.dateTime && service.dateTime.date
        ? { date: new Date(service.dateTime.date) }
        : { date: new Date(service.dateTime) }
      },
      serviceDocuments: serviceDocuments.length > 0 ? [...serviceDocuments, ''] : ['']
    }}
    onSubmit={values => {
      const { service = {} } = values;
      const updatedValues = {
        publicData: {
          service: {
            ...service,
            documents: serviceDocuments,
            dateTime: service && service.dateTime
            ? moment(service.dateTime.date).format('MM/DD/YYYY')
            : null
          }
        },
      };

      // The form will be re-rendered so that the data must be saved for initial values 
      saveDataToSessionStorage(SESSION_FORM_KEYS.EDIT_LISTING_SERVICE, values);
      onSubmit(updatedValues);
    }}
    onChange={onChange}
    saveActionMsg={submitButtonText}
    disabled={disabled}
    ready={ready}
    updated={panelUpdated}
    updateInProgress={updateInProgress}
    saveDataToSessionStorage={saveDataToSessionStorage}
    fetchErrors={errors}
    serviceDocuments={serviceDocuments}
  />;

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      {isCollection ? collectionListingWarning : form}
    </div>
  );
};

EditListingServicePanel.defaultProps = {
  rootClassName: null,
  className: null,
  listing: null,
};

const { bool, func, object, string } = PropTypes;

EditListingServicePanel.propTypes = {
  rootClassName: string,
  className: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingServicePanel;
