import React, { Fragment, useEffect } from 'react';
import { FieldTextInput, FieldDateInput, IconSpinner } from '../../../components';
import { intlShape, FormattedMessage } from '../../../util/reactIntl';
import moment from 'moment-timezone';
import { Field } from 'react-final-form';
import RemoveFieldButton from './RemoveFieldButton';
import { object, func } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { SERVICE_DOCUMENT_UPLOAD } from '../../../util/types';
import { requestDocumentUpload, requestDocumentRemove } from '../../../containers/EditConsignListingPage/EditConsignListingPage.duck';
import truncate from 'lodash/truncate';
import { FieldArray } from 'react-final-form-arrays';

import css from '../EditListingConsignForm.module.css';

const MAX_FIELD_QTY = 5;
const ACCEPT_DOCS = 'application/pdf, application/msword, image/*';

const FieldSetServiceInformation = props => {
  const { intl, service, form, saveDataToSessionStorage }  = props;
  const dispatch = useDispatch();
  const serviceDocuments = useSelector(state => state.EditConsignListingPage.serviceDocuments);
  const uploadServiceDocumentInProgress = useSelector(state => state.EditConsignListingPage.uploadServiceDocumentInProgress);
  const { push } = form.mutators;
  const datePlaceholder = moment().format('MM/DD/YYYY');
  const lastServiceLabel = (
    <span>
      <FormattedMessage id='EditListingConsignForm.lastServiceLabel' />
      <span className={css.optionalLabel}><FormattedMessage id='EditListingConsignForm.optionalLabel' /></span>
    </span>
  );
  const serviceTechnicianLabel = (
    <span>
      <FormattedMessage id='EditListingConsignForm.serviceTechnicianLabel' />
      <span className={css.optionalLabel}><FormattedMessage id='EditListingConsignForm.optionalLabel' /></span>
    </span>
  );
  const serviceDocumentLabel = (
    <span>
      <FormattedMessage id='EditListingConsignForm.serviceDocumentLabel' />
      <span className={css.optionalLabel}><FormattedMessage id='EditListingConsignForm.optionalLabel' /></span>
    </span>
  );
  const chooseFileText = (
    <span className={css.chooseImageText}>
      <span className={css.chooseImage}>
        <FormattedMessage id="EditListingConsignForm.chooseFile" />
      </span>
    </span>
  );

  const onUploadServiceDocument = (doc) => dispatch(requestDocumentUpload(doc));
  const onRemoveServiceDocument = (url, removeIndex) => {
    form.change(`serviceDocuments[${removeIndex}]`, null);
    serviceDocuments.splice(removeIndex, 1);
    dispatch(requestDocumentRemove(url));
  }

  const canDisplayServiceDocField = (index) => {
    return index < MAX_FIELD_QTY;
  }

  const loadingSpinner = uploadServiceDocumentInProgress && 
  (<div className={css.loadingScreen}>
      <div className={css.loader}>
        <IconSpinner/>
      </div>
    </div>
  );
  useEffect(() => {
    if (uploadServiceDocumentInProgress)
      document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'unset';
  }, [uploadServiceDocumentInProgress]);
  return (
    <Fragment>
      {loadingSpinner}
      <h2>
        <FormattedMessage id="EditListingConsignForm.serviceDocumentTitle" />
      </h2>
      <FieldDateInput
        id="serviceDateTime"
        name="serviceDateTime"
        label={lastServiceLabel}
        placeholderText={datePlaceholder}
        className={css.dateServiceInput}
      />
      <FieldTextInput
        id="service.technician"
        name="service.technician"
        label={serviceTechnicianLabel}
        className={css.features}
        type="textarea"
      />
      <FieldArray name="serviceDocuments" >
        {(fieldProps) => {
          const { fields } = fieldProps;
          return fields.map((name, index) => {
            return (
              <Field
                key={index}
                id={name}
                name={name}
                accept={ACCEPT_DOCS}
                parse={x => x}
                label={chooseFileText}
                type="file"
              >
                {fieldprops => {
                  const { accept, input, label, disabled: fieldDisabled } = fieldprops;
                  const { name, type } = input;
                  const onChange = e => {
                    const file = e.target.files[0];
                    if (file) {
                      saveDataToSessionStorage();
                      onUploadServiceDocument({ file, type: SERVICE_DOCUMENT_UPLOAD });
                      form.change(name, true);
                      form.blur(name);
                    }
                  };
                  const inputProps = { accept, id: name, name, onChange, type };
                  return (
                    canDisplayServiceDocField(index) && (
                      <Fragment>
                        <label>{serviceDocumentLabel}</label>
                        <div className={css.addFileFullWidthWrapper}>
                          <div className={css.aspectRatioFileWrapper}>
                            {fieldDisabled ? null : (
                              <input {...inputProps} className={css.addFileInput} />
                            )}
                            {serviceDocuments && serviceDocuments[index] && serviceDocuments[index].url
                            ? (
                              <div className={css.uploadedFileName}>
                                <span>{truncate(serviceDocuments[index].name, { length: 20 })}</span>
                                <RemoveFieldButton 
                                  className={css.removeFileUploadRowButton} 
                                  onClick={() => {
                                    fields.remove(index);
                                    onRemoveServiceDocument(serviceDocuments[index].url, index)}
                                  } 
                                />
                              </div>
                            )
                            : (
                              <label htmlFor={name} className={css.addFile}>
                                {label}
                              </label>
                            )}
                          </div>
                        </div>
                      </Fragment>
                    )
                  );
                }}
              </Field>
              );
            })
          }
        }
      </FieldArray>
      
    </Fragment>
  );
}

FieldSetServiceInformation.propTypes = {
  intl: intlShape.isRequired,
  service: object,
  saveDataToSessionStorage: func.isRequired
};

export default FieldSetServiceInformation;