import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconShareWhatsapp.module.css';

const IconShareWhatsapp = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}></div>
  );
};

IconShareWhatsapp.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconShareWhatsapp.propTypes = { rootClassName: string, className: string };

export default IconShareWhatsapp;
