/**
 * Creates a sortable image grid with children added to the end of the created grid.
 *
 * Example:
 * // images = [{ id: 'tempId', imageId: 'realIdFromAPI', file: File }];
 * <AddImages images={images}>
 *   <input type="file" accept="images/*" onChange={handleChange} />
 * </AddImages>
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ImageFromFile, ResponsiveImage, IconSpinner } from '../../components';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import css from './AddImages.module.css';
import RemoveImageButton from './RemoveImageButton';

const { array, func, node, string } = PropTypes;

const SortableItem = SortableElement(props => {
  const { thumbnailClassName, image, savedImageAltText, onRemoveImage } = props;
  const handleRemoveClick = e => {
    e.stopPropagation();
    onRemoveImage(image.imageId?.uuid || image.id?.uuid);
  };

  if (image.file) {
    // Add remove button only when the image has been uploaded and can be removed
    const removeButton = image.imageId ? <RemoveImageButton onClick={handleRemoveClick} /> : null;

    // While image is uploading we show overlay on top of thumbnail
    const uploadingOverlay = !image.imageId ? (
      <div className={css.thumbnailLoading}>
        <IconSpinner />
      </div>
    ) : null;

    return (
      <ImageFromFile
        id={image.id}
        className={thumbnailClassName}
        rootClassName={css.thumbnail}
        file={image.file}
      >
        {removeButton}
        {uploadingOverlay}
      </ImageFromFile>
    );
  } else {
    const classes = classNames(css.thumbnail, thumbnailClassName);
    return (
      <div className={classes}>
        <div className={css.threeToTwoWrapper}>
          <div className={css.aspectWrapper}>
            <ResponsiveImage
              rootClassName={css.rootForImage}
              image={image}
              alt={savedImageAltText}
              variants={['landscape-crop', 'landscape-crop2x']}
            />
          </div>
          <RemoveImageButton onClick={handleRemoveClick} />
        </div>
      </div>
    );
  }
});

const SortableList = SortableContainer(props => {
  const {
    images,
    className,
    onRemoveImage,
    savedImageAltText,
    thumbnailClassName,
  } = props;

  return (
    <div className={className}>
      {images.map((image, index) => {
        return (
          <SortableItem
            image={image}
            index={index}
            key={image.id.uuid || image.id}
            thumbnailClassName={thumbnailClassName}
            savedImageAltText={savedImageAltText}
            onRemoveImage={onRemoveImage}
          />
        );
      })}
    </div>
  );
});


const AddImages = props => {
  const {
    children,
    label,
    className,
    thumbnailClassName,
    images,
    savedImageAltText,
    onRemoveImage,
    onUpdateImageOrder,
  } = props;
  const classes = classNames(css.root, className);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const startIndex = oldIndex < 0 ? images.length + oldIndex : oldIndex;

    if (startIndex >= 0 && startIndex < images.length) {
      const endIndex = newIndex < 0 ? images.length + newIndex : newIndex;

      const [item] = images.splice(oldIndex, 1);
      images.splice(endIndex, 0, item);
    }

    const imageOrder = images.map(image => {
      return image.id.uuid || image.imageId.uuid;
    });
    onUpdateImageOrder(imageOrder);
  };

  return (
    <div className={classes}>
      {label ? <label>{label}</label> : null}
      <SortableList
        images={images}
        onSortEnd={onSortEnd}
        onRemoveImage={onRemoveImage}
        className={classes}
        axis="xy"
        distance={1}
        savedImageAltText={savedImageAltText}
        thumbnailClassName={thumbnailClassName}
      />
      {children}
    </div>
  );
};

AddImages.defaultProps = { className: null, thumbnailClassName: null, images: [] };

AddImages.propTypes = {
  images: array,
  children: node.isRequired,
  className: string,
  thumbnailClassName: string,
  savedImageAltText: string.isRequired,
  onRemoveImage: func.isRequired,
};

export default AddImages;
