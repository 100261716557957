import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT, SESSION_FORM_KEYS } from '../../util/types';
import { ensureListing } from '../../util/data';
import { EditListingFeaturedRecordsForm } from '../../forms';
import { ListingLink } from '..';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import css from './EditListingFeaturedRecordsPanel.module.css';

const EditListingFeaturedRecordsPanel = props => {
  const {
    rootClassName,
    className,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    saveDataToSessionStorage,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const { publicData } = currentListing.attributes;
  const initialData = JSON.parse(sessionStorage.getItem(SESSION_FORM_KEYS.EDIT_LISTING_FEATURED_RECORDS));
  // If the data is from sessionStorage, no need to add an extra field
  const featuredRecords = initialData 
                       ? initialData.featuredRecords
                       : publicData 
                         ? [...publicData.featuredRecords, {}]
                         : []
  const recordImages = useSelector(state => state.EditListingPage.recordImages);
  
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingFeaturedRecordsPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingFeaturedRecordsPanel.createListingTitle" />
  );

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingFeaturedRecordsForm
        className={css.form}
        name='featured'
        initialValues={{ 
          featuredRecords,
          recordImages
        }}
        onSubmit={values => {
          const { featuredRecords = [] } = values;
          
          const newFeaturedRecords = featuredRecords.filter(record => !isEmpty(record) && record.url);
          newFeaturedRecords.forEach((record, index) => {
            record['imgUrl'] = recordImages[index];
          })

          const updatedValues = {
            publicData: {
              featuredRecords: newFeaturedRecords
            },
          };
          // The form will be re-rendered so that the data must be saved for initial values 
          saveDataToSessionStorage(SESSION_FORM_KEYS.EDIT_LISTING_FEATURED_RECORDS, values);
          onSubmit(updatedValues);
        }}
        onChange={onChange}
        saveActionMsg={submitButtonText}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        saveDataToSessionStorage={saveDataToSessionStorage}
        fetchErrors={errors}
        recordImages={recordImages}
      />
    </div>
  );
};

EditListingFeaturedRecordsPanel.defaultProps = {
  rootClassName: null,
  className: null,
  listing: null,
};

const { bool, func, object, string } = PropTypes;

EditListingFeaturedRecordsPanel.propTypes = {
  rootClassName: string,
  className: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingFeaturedRecordsPanel;
