import React, { Fragment } from 'react';
import { array } from 'prop-types';
import * as validators from '../../../util/validators';
import { FieldRichText, FieldTextInput } from '../../../components';
import CustomCategorySelectField from '../../EditListingCategoryForm/CustomCategorySelectField';
import { intlShape } from '../../../util/reactIntl';

import css from '../EditListingConsignForm.module.css';

const TITLE_MAX_LENGTH = 60;
const DESCRIPTION_MAX_LENGTH = 30000;

const FieldSetBasicInformation = props => {
  const { intl, categoryOptions, isCollection } = props;

  // Title
  const titleMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.title' });
  const titleRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.titleRequired',
  });
  const maxLengthMessage = intl.formatMessage(
    { id: 'EditListingDescriptionForm.maxLength' },
    {
      maxLength: TITLE_MAX_LENGTH,
    }
  );
  const titleRequired = validators.required(titleRequiredMessage);
  const titleMaxlength = validators.maxLength(maxLengthMessage, TITLE_MAX_LENGTH);
  const titleValidators = validators.composeValidators(titleRequired, titleMaxlength);

  // Description
  const descriptionMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.description',
  });
  const descriptionRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.descriptionRequired',
  });
  const descriptionMaxLengthMessage = intl.formatMessage(
    { id: 'EditListingDescriptionForm.maxLength' },
    {
      maxLength: DESCRIPTION_MAX_LENGTH,
    }
  );
  const descriptionRequired = validators.required(descriptionRequiredMessage);
  const descriptionMaxlength = validators.required(descriptionMaxLengthMessage, DESCRIPTION_MAX_LENGTH);
  const descriptionValidators = validators.composeValidators(descriptionRequired, descriptionMaxlength);

  const collectionNameLabel = intl.formatMessage({
    id: 'EditListingDescriptionForm.collectionNameLabel',
  });

  return (
    <Fragment>
      <FieldTextInput
        id="title"
        name="title"
        className={css.title}
        type="text"
        label={titleMessage}
        maxLength={TITLE_MAX_LENGTH}
        validate={titleValidators}
      />

      <FieldRichText
        id="description"
        name="description"
        className={css.description}
        label={descriptionMessage}
        validate={descriptionValidators}
      />

      <CustomCategorySelectField
        id="category"
        name="category"
        categories={categoryOptions}
        intl={intl}
      />

      {!isCollection && 
        <FieldTextInput
          id="collectionName"
          name="collectionName"
          className={css.title}
          type="text"
          label={collectionNameLabel}
        />
      }
      
    </Fragment>
  );
}

FieldSetBasicInformation.defaultProps = {
  categoryOptions: []
}

FieldSetBasicInformation.propTypes = {
  intl: intlShape.isRequired,
  categoryOptions: array.isRequired
};

export default FieldSetBasicInformation;