import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ListingLink } from '..';
import { ensureOwnListing } from '../../util/data';
import { EditListingTimedAuctionForm } from '../../forms';
import { types as sdkTypes } from '../../util/sdkLoader';
import { createTimeSlots } from '../../util/dates';
import moment from 'moment-timezone';
import { createMoney } from '../../util/currency';

import css from './EditListingTimedAuctionPanel.module.css';

const MAXIMUM_NUMBER_OF_DAYS = 1000;

const EditListingTimedAuctionPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { publicData } = currentListing.attributes;
  const { timedAuction = {} , category, isTimedAuction, highestOffer } = publicData;
  const { reservePrice, endDate, startDate, isAutomated, duration, isReservePriceMet, hasStartDate } = timedAuction;
  const nextDay = moment().add(1, 'days').toDate();
  const timeSlots = createTimeSlots(nextDay, MAXIMUM_NUMBER_OF_DAYS);
  const hasEndDatePassed = moment().isSameOrAfter(moment(endDate));
  const hasStartDateBegun = moment().isSameOrAfter(moment(startDate));
  const panelTitle =  (
    <FormattedMessage
      id="EditListingTimedAuctionPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  );
  const collectionListingWarning = <FormattedMessage id="EditListingPanels.collectionListingWarning" />;
  const isCollection = category === 'collection';

  const initialReservePrice = reservePrice && createMoney(reservePrice);
  const initialEndDate = { date: endDate && !hasEndDatePassed ? new Date(endDate) : nextDay };
  const initialEndTime = endDate ? moment(endDate).format("HH:mm") : moment().format("HH:mm");
  const initialStartDate = { date: startDate && !hasStartDateBegun ? new Date(startDate) : nextDay };
  const initialStartTime = startDate ? moment(startDate).format("HH:mm") : moment().format("HH:mm");
  const initialValues = { isAutomated, reservePrice: initialReservePrice, endDate: initialEndDate, startDate: initialStartDate, isTimedAuction, endTime: initialEndTime, duration, startTime: initialStartTime, hasStartDate }
  const form = (
    <EditListingTimedAuctionForm
      className={css.form}
      initialValues={initialValues}
      initialValuesEqual={(prev, current) => prev.isTimedAuction === current.isTimedAuction }
      onSubmit={values => {
        const { isAutomated, reservePrice, isTimedAuction, endDate, endTime, hasStartDate, startDate, startTime, duration } = values;

        const endTimeArray = isAutomated ? null : endTime.split(":");
        const startTimeArray = isAutomated && hasStartDate ? startTime.split(":") : null;
        const formattedStartDate =
          startTimeArray &&
          startDate && 
          moment(startDate.date).set({ hours: startTimeArray[0], minutes: startTimeArray[1] }).valueOf();
        const formattedEndDate =
          endTimeArray &&
          endDate && 
          moment(endDate.date).set({ hours: endTimeArray[0], minutes: endTimeArray[1] }).valueOf();
        const updatedTimeData = formattedEndDate ? { endDate: formattedEndDate } : { duration };
        const updatedStartDateData = hasStartDate && formattedStartDate ? { startDate: formattedStartDate } : { startDate: null };
        const updatedTimedAuctionValue = isTimedAuction ? { 
          reservePrice: { amount: reservePrice.amount, currency: reservePrice.currency },     
          isTimeExtended: false,
          isReservePriceMet: highestOffer >= reservePrice.amount,
          isAutomated,
          hasStartDate: isAutomated && !!hasStartDate,
          ...updatedTimeData,
          ...updatedStartDateData,
        } : null;
        const updateValues = {
          publicData: {
            isTimedAuction,
            isPendingAutomatedTimedAuction: isTimedAuction && isAutomated && !updatedTimedAuctionValue.isReservePriceMet,
            timedAuction: updatedTimedAuctionValue,
            sortTimedAuctionEndDate: isTimedAuction && !isAutomated ? formattedEndDate : null //For sorting while querying listings
          },
        };
        onSubmit(updateValues);
      }}
      onChange={onChange}
      saveActionMsg={submitButtonText}
      disabled={disabled}
      ready={ready}
      updated={panelUpdated}
      updateInProgress={updateInProgress}
      fetchErrors={errors}
      timeSlots={timeSlots}
      isReservePriceMet={isReservePriceMet}
    />
  );

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      {isCollection ? collectionListingWarning : form}
    </div>
  );
};

const { func, object, string, bool } = PropTypes;

EditListingTimedAuctionPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingTimedAuctionPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingTimedAuctionPanel;
