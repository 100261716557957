import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT, YES, NO } from '../../util/types';
import { ListingLink } from '../../components';
import { EditListingPricingForm } from '../../forms';
import { ensureOwnListing } from '../../util/data';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';
import { findOptionsForSelectFilter } from '../../util/search';
import { createMoney, createCurrencyObject } from '../../util/currency';

import css from './EditListingPricingPanel.module.css';

const MAX_BUYER_PREMIUM_FEE = 100, MIN_BUYER_PREMIUM_FEE = 0;

const { Money } = sdkTypes;

const EditListingPricingPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { price, publicData } = currentListing.attributes;
  const { pricing, buyerPremiumFee } = publicData;
  const { minEstPrice, maxEstPrice } = pricing;
  const initialMinEstPrice = minEstPrice && createMoney(minEstPrice);
  const initialMaxEstPrice = maxEstPrice && createMoney(maxEstPrice);
  const initialPricing = { ...pricing, minEstPrice: initialMinEstPrice, maxEstPrice: initialMaxEstPrice };
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingPricingPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingPricingPanel.createListingTitle" />
  );
  const collectionListingWarning = <FormattedMessage id="EditListingPanels.collectionListingWarning" />;
  const isCollection = publicData && publicData.category === 'collection';

  const askingPriceOptions = findOptionsForSelectFilter('askingPrice', config.custom.filters);
  const acceptOfferOptions = findOptionsForSelectFilter('acceptOffer', config.custom.filters);
  const acceptBuyItNowOptions = findOptionsForSelectFilter('acceptBuyItNow', config.custom.filters);
  const highestOfferOptions = findOptionsForSelectFilter('highestOffer', config.custom.filters);
  const estimatePriceOptions = findOptionsForSelectFilter('showEstimatePrice', config.custom.filters);

  const priceCurrencyValid = price instanceof Money ? price.currency === config.currency : true;
  const initialMinReservePrice = pricing.minReservePrice && createMoney(pricing.minReservePrice);
  const form = priceCurrencyValid ? (
    <EditListingPricingForm
      className={css.form}
      initialValues={{ price, pricing: initialPricing, minReservePrice: initialMinReservePrice, buyerPremiumFee: buyerPremiumFee ?? 0 }}
      onSubmit={values => {
        const { price , pricing, minReservePrice, buyerPremiumFee } = values;
        const { minEstPrice, maxEstPrice } = pricing;
        const formattedReservePrice = minReservePrice && createCurrencyObject(minReservePrice)
        const updateValues = {
          price,
          publicData: {
            pricing: {
              ...pricing,
              acceptBuyItNow: pricing.askingPrice === YES ? pricing.acceptBuyItNow : NO,
              displayHighestOffer: pricing.acceptOffer === YES ? pricing.displayHighestOffer : NO,
              minReservePrice: formattedReservePrice,
              minEstPrice: minEstPrice && createCurrencyObject(minEstPrice),
              maxEstPrice: maxEstPrice && createCurrencyObject(maxEstPrice),
            },
            buyerPremiumFee: Math.max(MIN_BUYER_PREMIUM_FEE, Math.min(buyerPremiumFee, MAX_BUYER_PREMIUM_FEE))
          },
        };
        onSubmit(updateValues);
      }}
      onChange={onChange}
      saveActionMsg={submitButtonText}
      disabled={disabled}
      ready={ready}
      updated={panelUpdated}
      updateInProgress={updateInProgress}
      fetchErrors={errors}
      askingPriceOptions={askingPriceOptions}
      acceptOfferOptions={acceptOfferOptions}
      acceptBuyItNowOptions={acceptBuyItNowOptions}
      highestOfferOptions={highestOfferOptions}
      estimatePriceOptions={estimatePriceOptions}
    />
  ) : (
    <div className={css.priceCurrencyInvalid}>
      <FormattedMessage id="EditListingPricingPanel.listingPriceCurrencyInvalid" />
    </div>
  );

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      {isCollection ? collectionListingWarning : form}
    </div>
  );
};

const { func, object, string, bool } = PropTypes;

EditListingPricingPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingPricingPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingPricingPanel;
