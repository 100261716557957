import React, { Fragment, useState, useEffect } from 'react';
import * as validators from '../../../util/validators';
import { intlShape, FormattedMessage } from '../../../util/reactIntl';
import { FieldSelect, IconSpinner } from '../../../components';
import { types as sdkTypes } from '../../../util/sdkLoader';
import { string, array, func } from 'prop-types';
import { Field } from 'react-final-form';
import truncate from 'lodash/truncate';
import RemoveFieldButton from './RemoveFieldButton';
import { FieldArray } from 'react-final-form-arrays';
import { requestDocumentUpload, requestDocumentRemove } from '../../../containers/EditConsignListingPage/EditConsignListingPage.duck';
import { useDispatch, useSelector } from 'react-redux';

import css from '../EditListingConsignForm.module.css';
import { AUTHENTICITY_DOCUMENT_UPLOAD } from '../../../util/types';

const { UUID } = sdkTypes;
const ACCEPT_DOCS = 'application/pdf, application/msword, image/*';

const FieldSetAuthenticity = props => {
  const { intl, authenticityDocsOptions, form, authenticity, saveDataToSessionStorage }  = props;
  const { push } = form.mutators;
  const uploadAuthDocumentInProgress = useSelector(state => state.EditConsignListingPage.uploadAuthDocumentInProgress);
  const dispatch = useDispatch();
  const authDocuments = useSelector(state => state.EditConsignListingPage.authDocuments);
  
  const authenticityDocumentsLabel = (
    <span>
      <FormattedMessage id='EditListingConsignForm.authenticityDocumentsLabel' />
      <span className={css.optionalLabel}><FormattedMessage id='EditListingConsignForm.optionalLabel' /></span>
    </span>
  )

  const chooseFileText = (
    <span className={css.chooseImageText}>
      <span className={css.chooseImage}>
        <FormattedMessage id="EditListingConsignForm.chooseFile" />
      </span>
    </span>
  );

  const onUploadAuthenticityDocument = (doc) => dispatch(requestDocumentUpload(doc));

  const loadingSpinner = uploadAuthDocumentInProgress && 
  (<div className={css.loadingScreen}>
      <div className={css.loader}>
        <IconSpinner/>
      </div>
    </div>
  );

  useEffect(() => {
    if (uploadAuthDocumentInProgress)
      document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'unset';
  }, [uploadAuthDocumentInProgress]);

  return (
    <Fragment>
      {loadingSpinner}
      <h2>
        <FormattedMessage id="EditListingConsignForm.authenticityDocumentsTitle" />
      </h2>
      <FieldArray name="authenticity" >
        {(fieldProps) => {
            const { fields } = fieldProps;
            return fields.map((name, index) => {
              return (
              <div className={css.authDocField} key={name}>
                <FieldSelect
                  className={css.fillWidth}
                  name={`${name}.type`}
                  id={`${name}.type`}
                  label={authenticityDocumentsLabel}
                  onChange={(e) => {
                    form.change(`authenticity[${index}].type`, e.target.value);
                    if (index === fields.length - 1)
                      push("authenticity", {});
                  }}
                >
                  <option disabled value=""></option>
                  {authenticityDocsOptions.map(doc => (
                    <option key={doc.key} value={doc.key}>
                      {intl.formatMessage({
                        id: doc.label
                      })}
                    </option>
                  ))}
                </FieldSelect>
                {authenticity[index].type &&
                  (<Field
                    id={`${name}.file`}
                    name={`${name}.file`}
                    accept={ACCEPT_DOCS}
                    parse={x => x}
                    label={chooseFileText}
                    type="file"
                  >
                    {fieldprops => {
                      const { accept, input, label, disabled: fieldDisabled } = fieldprops;
                      const { name, type } = input;
                      const onChange = e => {
                        const file = e.target.files[0];
                        if (file) {
                          saveDataToSessionStorage();
                          onUploadAuthenticityDocument({ file, type: AUTHENTICITY_DOCUMENT_UPLOAD });
                          form.blur(`authenticity[${index}].file`);
                        }
                      };
                      const inputProps = { accept, id: name, name, onChange, type };
                      return (
                        <div className={css.addFileWrapper}>
                          <div className={css.aspectRatioFileWrapper}> 
                            {fieldDisabled ? null : (
                              <input {...inputProps} className={css.addFileInput} />
                            )}
                            {authDocuments[index]
                            ? (
                              <div className={css.uploadedFileName}>
                                <span>{truncate(authDocuments[index].name, { length: 25 })}</span>
                                <RemoveFieldButton 
                                  className={css.removeFileUploadRowButton} 
                                  onClick={() => {
                                    dispatch(requestDocumentRemove(authDocuments[index].url));
                                    fields.remove(index);
                                    authDocuments.splice(index, 1);
                                  }} 
                                />
                              </div>
                            )
                            : (
                              <label htmlFor={name} className={css.addFile}>
                                {label}
                              </label>
                            )}
                          </div>
                        </div>
                      );
                    }}
                  </Field>)}
              </div>
              )
            })
          }
        }
      </FieldArray>
    </Fragment>
  );
}

FieldSetAuthenticity.defaultProps = {
  authenticityDocsOptions: [],
}

FieldSetAuthenticity.propTypes = {
  intl: intlShape.isRequired,
  authenticityDocsOptions: array,
  name: string,
  authenticity: array,
  saveDataToSessionStorage: func.isRequired
};

export default FieldSetAuthenticity;