import React, { Component } from 'react';
import { bool, func, instanceOf, object, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape, FormattedHTMLMessage } from '../../util/reactIntl';
import { withRouter } from 'react-router-dom';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { pathByRouteName, findRouteByRouteName } from '../../util/routes';
import { propTypes, LINE_ITEM_NIGHT, LINE_ITEM_DAY, DATE_TYPE_DATE, NEW_ADDRESS, EXISTING_ADDRESS, PICKUP_SALES_TAX } from '../../util/types';
import {
  ensureListing,
  ensureUser,
  ensureTransaction,
  denormalisedCustomAPIResponseEntities,
} from '../../util/data';
import { minutesBetween, calculateTimeLeft, formatDisplayTime } from '../../util/dates';
import { createSlug } from '../../util/urlHelpers';
import {
  isTransactionInitiateAmountTooLowError,
  isTransactionInitiateListingNotFoundError,
  isTransactionInitiateMissingStripeAccountError,
  isTransactionInitiateBookingTimeNotAvailableError,
  isTransactionChargeDisabledError,
  isTransactionZeroPaymentError,
  transactionInitiateOrderStripeErrors,
} from '../../util/errors';
import { formatMoney } from '../../util/currency';
import { TRANSITION_ENQUIRE, txIsPaymentPending, txIsPaymentExpired } from '../../util/transaction';
import {
  BookingBreakdown,
  Logo,
  NamedLink,
  NamedRedirect,
  Page,
  ResponsiveImage,
  Modal,
  IconBannedUser,
  IconSpinner
} from '../../components';
import { TimedAuctionCheckoutForm } from '../../forms';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { types as sdkTypes } from '../../util/sdkLoader';

import {
  initiateTimedAuctionOffer,
  setInitialValues,
  speculateTransaction,
  sendMessage,
  saveShippingAddress,
  initiateOrderAutomaticDeclined,
} from './TimedAuctionCheckoutPage.duck';
import { storeData, storedData, clearData } from './TimedAuctionCheckoutPageSessionHelpers';
import isEmpty from 'lodash/isEmpty';
import AgreementParagraph from './AgreementParagraph';
import moment from 'moment-timezone';

import css from './TimedAuctionCheckoutPage.module.css';

const { Money } = sdkTypes

const STORAGE_KEY = 'TimedAuctionCheckoutPage';

const MIN_MINUTES_LEFT = 20 * 60 * 1000; //20 mins in milliseconds
const INCREASE_MINUTES = 10; //10 mins
const SALES_TAX_COUNTRY = "US";

// Stripe PaymentIntent statuses, where user actions are already completed
// https://stripe.com/docs/payments/payment-intents/status


const initializeOrderPage = (initialValues, routes, dispatch) => {
  const OrderPage = findRouteByRouteName('OrderDetailsPage', routes);

  // Transaction is already created, but if the initial message
  // sending failed, we tell it to the OrderDetailsPage.
  dispatch(OrderPage.setInitialValues(initialValues));
};

const checkIsPaymentExpired = existingTransaction => {
  return txIsPaymentExpired(existingTransaction)
    ? true
    : txIsPaymentPending(existingTransaction)
    ? minutesBetween(existingTransaction.attributes.lastTransitionedAt, new Date()) >= 15
    : false;
};

export class TimedAuctionCheckoutPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageData: {},
      dataLoaded: false,
      submitting: false,
      selectedShippingOption: null,
      openWarningModal: false,
      currentSubmittedValues: null,
      currentShippingAddressType: NEW_ADDRESS,
      currentPostalCode: null,
      currentShippingCountry: null,
      warningTimedAuctionHasEnded: false
    };
    this.stripe = null;

    this.loadInitialData = this.loadInitialData.bind(this);
    this.handleInitiateTimedAuctionOffer = this.handleInitiateTimedAuctionOffer.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getOfferDifferenceLineItem = this.getOfferDifferenceLineItem.bind(this);
    this.getShippingLineItem = this.getShippingLineItem.bind(this);
    this.changeShippingOption = this.changeShippingOption.bind(this);
    this.handleOfferBelowReservePrice = this.handleOfferBelowReservePrice.bind(this);
    this.changeShippingAddress = this.changeShippingAddress.bind(this);
  }

  componentDidMount() {
    if (window) {
      this.loadInitialData();
    }
  }

  changeShippingOption(option) {
    this.setState({ selectedShippingOption: option });
  }

  changeShippingAddress(changedTo) {
    this.setState({ currentShippingAddressType: changedTo, currentPostalCode: null });
  }

  getShippingLineItem() {
    const option = this.state.selectedShippingOption;
    
    if (!option || option === "collect" ) {
      return [];
    }
    const { shipping } = this.props.listing.attributes.publicData;
    const amount = (option === 'domestic') 
                    ? shipping.domestic.amount 
                    : shipping.international.amount;

    const currency = (option === 'domestic') 
                    ? shipping.domestic.currency 
                    : shipping.international.currency;

    return [{
      code: 'line-item/shipping',
      unitPrice: new Money(amount, currency),
      quantity: 1,
      includeFor: ['customer', 'provider']
    }];
  }

  getOfferDifferenceLineItem() {
    let lineItem = []
    if (this.props.offer) {
      const offerDifference = this.props.listing.attributes.price.amount - this.props.offer.amount
      lineItem = [{
        code: 'line-item/offer-difference',
        unitPrice: new Money(Math.abs(offerDifference), "USD"),
        // If they offer MORE than the Asking... add it instead of subtract
        percentage: offerDifference > 0 ? -100 : 100,
        includeFor: ['customer', "provider"]
      }]
    }
    return lineItem
  }

  /**
   * Load initial data for the page
   *
   * Since the data for the checkout is not passed in the URL (there
   * might be lots of options in the future), we must pass in the data
   * some other way. Currently the ListingPage sets the initial data
   * for the CheckoutPage's Redux store.
   *
   * For some cases (e.g. a refresh in the CheckoutPage), the Redux
   * store is empty. To handle that case, we store the received data
   * to window.sessionStorage and read it from there if no props from
   * the store exist.
   *
   * This function also sets of fetching the speculative transaction
   * based on this initial data.
   */
  async loadInitialData() {
    const {
      listing,
      transaction,
      fetchSpeculatedTransaction,
      fetchStripeCustomer,
      history,
      currentUser
    } = this.props;

    // Browser's back navigation should not rewrite data in session store.
    // Action is 'POP' on both history.back() and page refresh cases.
    // Action is 'PUSH' when user has directed through a link
    // Action is 'REPLACE' when user has directed through login/signup process
    const hasNavigatedThroughLink = history.action === 'PUSH' || history.action === 'REPLACE';

    // const hasDataInProps = !!(bookingData && bookingDates && listing) && hasNavigatedThroughLink;
    const hasDataInProps = !!(listing) && hasNavigatedThroughLink;
    if (hasDataInProps) {
      // Store data only if data is passed through props and user has navigated through a link.
      // storeData(bookingData, bookingDates, listing, transaction, STORAGE_KEY);
      storeData(listing, transaction, STORAGE_KEY);
    }

    // NOTE: stored data can be empty if user has already successfully completed transaction.
    // const pageData = hasDataInProps
    //   ? { bookingData, bookingDates, listing, transaction }
    //   : storedData(STORAGE_KEY);

    const pageData = hasDataInProps
      ? { listing, transaction }
      : storedData(STORAGE_KEY);


    // Check if a booking is already created according to stored data.
    const tx = pageData ? pageData.transaction : null;
    const isBookingCreated = tx && tx.booking && tx.booking.id;

    const shouldFetchSpeculatedTransaction =
      pageData &&
      pageData.listing &&
      pageData.listing.id &&
      !isBookingCreated;

    if (shouldFetchSpeculatedTransaction) {
      const listingId = pageData.listing.id;
      const transactionId = tx ? tx.id : null;

      // Convert picked date to date that will be converted on the API as
      // a noon of correct year-month-date combo in UTC
      // const bookingStartForAPI = dateFromLocalToAPI(bookingStart);
      // const bookingEndForAPI = dateFromLocalToAPI(bookingEnd);

      // Fetch speculated transaction for showing price in booking breakdown
      // NOTE: if unit type is line-item/units, quantity needs to be added.
      // The way to pass it to checkout page is through pageData.bookingData
      fetchSpeculatedTransaction(
        {
          listingId,
          lineItems: [
            ...this.getOfferDifferenceLineItem(),
          ],
        },
        transactionId
      );
    }

    const currentShippingAddress = currentUser?.attributes?.profile?.publicData?.shippingAddress || {};

    const isShippingAddressExisting = !isEmpty(currentShippingAddress);
    
    this.setState({ 
      pageData: pageData || {}, 
      dataLoaded: true, 
      currentShippingAddressType: isShippingAddressExisting ? EXISTING_ADDRESS : NEW_ADDRESS,
      currentShippingCountry: isShippingAddressExisting && currentShippingAddress.country
    });
  }

  handleInitiateTimedAuctionOffer(handlePaymentParams) {
    const {
      onInitiateTimedAuctionOffer,
      onSendMessage,
      offer,
      intl
    } = this.props;
    const {
      pageData,
      message,
      otherPayment
    } = handlePaymentParams;

    const storedTx = ensureTransaction(pageData.transaction);

    // Step 1: initiate order by make a timed auction offer
    const fnMakeTimedAuctionOffer = fnParams => {
      return onInitiateTimedAuctionOffer(fnParams);
    };

    // Step 2: send initial message
    const fnSendMessage = fnParams => {
      return onSendMessage({ ...fnParams, message });
    };

    // Here we create promise calls in sequence
    // This is pretty much the same as:
    // fnRequestPayment({...initialParams})
    //   .then(result => fnSendMessage({...result}))
    const applyAsync = (acc, val) => acc.then(val);
    const composeAsync = (...funcs) => x => funcs.reduce(applyAsync, Promise.resolve(x));
    const handleTimedAuctionOfferCreation = composeAsync(
      fnMakeTimedAuctionOffer,
      fnSendMessage
    );

    const offerPrice = offer ? offer : pageData.listing.attributes.price;
    const { timedAuction = {}, isPendingAutomatedTimedAuction } = pageData?.listing?.attributes?.publicData || {};
    const { days, hours, minutes } = formatDisplayTime(calculateTimeLeft(timedAuction.endDate));
    const { isReservePriceMet, endDate, duration } = timedAuction;
    const shouldTimedAuctionBeExtended = endDate && endDate - moment().valueOf() <= MIN_MINUTES_LEFT;
    const newMinutes = shouldTimedAuctionBeExtended ? +minutes + INCREASE_MINUTES : minutes;
    const isAutomatedReservePriceMetFirstTime = isPendingAutomatedTimedAuction && !!duration;
    const remainingTime =
      isAutomatedReservePriceMetFirstTime 
      ? intl.formatMessage({ id: "TimedAuctionCheckoutPage.remainingTimeInTxAutomatedFirstMet" }, { days: duration })
      : intl.formatMessage({ id: "TimedAuctionCheckoutPage.remainingTimeInTx" }, { days, hours, minutes: newMinutes })
    
    const offerParams = {
      listingId: pageData.listing.id,
      lineItems: [
        ...this.getOfferDifferenceLineItem(),
        ...this.getShippingLineItem(),
      ],
      otherPayment,
      offer: {
        amount: offerPrice.amount,
        currency: offerPrice.currency
      },
      isTimedAuction: true,
      remainingTime,
      isReservePriceMet
    };

    return handleTimedAuctionOfferCreation(offerParams);
  }

  handleOfferBelowReservePrice(handlePaymentParams) {
    const {
      onInitiateOrderAutomaticDeclined,
      onSendMessage,
      offer,
      intl
    } = this.props;
    const {
      pageData,
      message,
    } = handlePaymentParams;

    const storedTx = ensureTransaction(pageData.transaction);

    // Step 1: initiate order that will be automatic declined
    const fnDeclineOffer = fnParams => {
      return onInitiateOrderAutomaticDeclined(fnParams, storedTx.id);
    };

    // Step 2: send initial message
    const fnSendMessage = fnParams => {
      return onSendMessage({ ...fnParams, message });
    };

    // Here we create promise calls in sequence
    // This is pretty much the same as:
    // fnDeclineOffer({...initialParams})
    //   .then(result => fnSendMessage({...result}))
    const applyAsync = (acc, val) => acc.then(val);
    const composeAsync = (...funcs) => x => funcs.reduce(applyAsync, Promise.resolve(x));
    const handleDeclineOffer = composeAsync(
      fnDeclineOffer,
      fnSendMessage
    );

    const offerPrice = offer ? offer : pageData.listing.attributes.price;
    const { timedAuction = {}, isPendingAutomatedTimedAuction } = pageData?.listing?.attributes?.publicData || {};
    const { duration, endDate } = timedAuction;
    const { days, hours, minutes } = formatDisplayTime(calculateTimeLeft(endDate));
    const shouldTimedAuctionBeExtended = endDate && endDate - moment().valueOf() <= MIN_MINUTES_LEFT;
    const newMinutes = shouldTimedAuctionBeExtended ? +minutes + INCREASE_MINUTES : minutes;
    const isAutomatedReservePriceMetFirstTime = isPendingAutomatedTimedAuction && !!duration;
    const remainingTime =
      isAutomatedReservePriceMetFirstTime 
      ? intl.formatMessage({ id: "TimedAuctionCheckoutPage.remainingTimeInTxAutomatedFirstMet" }, { days: duration })
      : intl.formatMessage({ id: "TimedAuctionCheckoutPage.remainingTimeInTx" }, { days, hours, minutes: newMinutes })

    const orderParams = {
      listingId: pageData.listing.id,
      lineItems: [
        ...this.getOfferDifferenceLineItem(),
        ...this.getShippingLineItem()
      ],
      offer: {
        amount: offerPrice.amount,
        current: offerPrice.currency
      },
      isTimedAuction: true,
      remainingTime
    };

    return handleDeclineOffer(orderParams);
  }



  handleSubmit(values) {
    if (this.state.submitting) {
      return;
    }
    this.setState({ submitting: true });

    const { listing } = this.state.pageData;
    const { reservePrice, endDate } = listing.attributes?.publicData?.timedAuction || {};
    const isTimedAuctionEnd = endDate <= moment().valueOf();

    if (isTimedAuctionEnd) {
      this.setState({ submitting: false, warningTimedAuctionHasEnded: true });
      return;
    }

    const { history, speculatedTransaction, currentUser, dispatch, onSaveShippingAddress } = this.props;
    const { message, formValues } = values;
    const {
      saveShippingAddress, 
      shipping,
      isSameShippingAddress,
      currentShippingAddress,
      isShippingAddressExisting,
      isPickupOnly
    } = formValues;

    // Billing address is recommended.
    // However, let's not assume that <StripePaymentAddress> data is among formValues.
    // Read more about this from Stripe's docs
    // https://stripe.com/docs/stripe-js/reference#stripe-handle-card-payment-no-element
    const shippingAddress = isPickupOnly
      ? null
      : isSameShippingAddress
        ? currentShippingAddress
        : {
          city: shipping?.city,
          country: shipping?.country,
          addressLine1: shipping?.addressLine1,
          addressLine2: shipping?.addressLine2,
          postal: shipping?.postal,
          state: shipping?.state,
          recipientName: shipping?.recipientName,
          phoneNumber: shipping?.phoneNumber
        };

    const params = {
      pageData: this.state.pageData,
      speculatedTransaction,
      message,
    };


    const offerPrice = this.props.offer || listing.attributes.price;
    const isBelowReservePrice = reservePrice && offerPrice?.amount < reservePrice.amount;

    const handleProcessCheckout = isBelowReservePrice 
                                      ? this.handleOfferBelowReservePrice
                                      : this.handleInitiateTimedAuctionOffer 

    const allPromiseCalls = [handleProcessCheckout(params)];
    
    if (!isShippingAddressExisting || (saveShippingAddress && saveShippingAddress[0])) {
      allPromiseCalls.push(onSaveShippingAddress(shippingAddress));
    }
    
    Promise.all(allPromiseCalls).then((res) => {
      const { orderId, messageSuccess } = res[0];
      this.setState({ submitting: false, openWarningModal: isBelowReservePrice });

      const routes = routeConfiguration();
      if (!isBelowReservePrice) {
        const initialMessageFailedToTransaction = messageSuccess ? null : orderId;
        const offerDetailsPath = pathByRouteName('TimedAuctionOfferDetailsPage', routes, { id: orderId.uuid });
        const initialValues = {
          initialMessageFailedToTransaction
        };
  
        initializeOrderPage(initialValues, routes, dispatch);
        clearData(STORAGE_KEY);
        history.push(offerDetailsPath);
      }
    })
    .catch(err => {
      console.error(err);
      this.setState({ submitting: false });
    });
  }

  render() {
    const {
      scrollingDisabled,
      speculateTransactionInProgress,
      speculateTransactionError,
      speculatedTransaction: speculatedTransactionMaybe,
      initiateOrderError,
      intl,
      params,
      currentUser,
    } = this.props;

    // Since the listing data is already given from the ListingPage
    // and stored to handle refreshes, it might not have the possible
    // deleted or closed information in it. If the transaction
    // initiate or the speculative initiate fail due to the listing
    // being deleted or closec, we should dig the information from the
    // errors and not the listing data.
    const listingNotFound =
      isTransactionInitiateListingNotFoundError(speculateTransactionError) ||
      isTransactionInitiateListingNotFoundError(initiateOrderError);

    const isLoading = !this.state.dataLoaded || speculateTransactionInProgress;

    const { listing, transaction } = this.state.pageData;
    const existingTransaction = ensureTransaction(transaction);
    const speculatedTransaction = ensureTransaction(speculatedTransactionMaybe, {}, null);
    const currentListing = ensureListing(listing);
    const currentAuthor = ensureUser(currentListing.author);

    const listingTitle = currentListing.attributes.title;
    const title = intl.formatMessage({ id: 'TimedAuctionCheckoutPage.title' }, { listingTitle });

    const pageProps = { title, scrollingDisabled };
    const topbar = (
      <div className={css.topbar}>
        <NamedLink className={css.home} name="LandingPage">
          <Logo
            className={css.logoMobile}
            title={intl.formatMessage({ id: 'TimedAuctionCheckoutPage.goToLandingPage' })}
            format="mobile"
          />
          <Logo
            className={css.logoDesktop}
            alt={intl.formatMessage({ id: 'TimedAuctionCheckoutPage.goToLandingPage' })}
            format="desktop"
          />
        </NamedLink>
      </div>
    );

    if (isLoading) {
      return <Page {...pageProps}>{topbar}</Page>;
    }

    const isOwnListing =
      currentUser &&
      currentUser.id &&
      currentAuthor &&
      currentAuthor.id &&
      currentAuthor.id.uuid === currentUser.id.uuid;

    const hasListingAndAuthor = !!(currentListing.id && currentAuthor.id);
    const hasRequiredData = hasListingAndAuthor;
    const canShowPage = hasRequiredData && !isOwnListing;
    const shouldRedirect = !isLoading && !canShowPage;

    // Redirect back to ListingPage if data is missing.
    // Redirection must happen before any data format error is thrown (e.g. wrong currency)
    if (shouldRedirect) {
      // eslint-disable-next-line no-console
      console.error('Missing or invalid data for checkout, redirecting back to listing page.', {
        transaction: speculatedTransaction,
        listing,
      });
      return <NamedRedirect name="ListingPage" params={params} />;
    }

    // Get the shipping address if any
    const currentShippingAddress =
    currentUser && currentUser.attributes && currentUser.attributes.profile.publicData.shippingAddress
      ? currentUser.attributes.profile.publicData.shippingAddress
      : {};

    const isShippingAddressExisting = !isEmpty(currentShippingAddress);
    const shippingOptions = currentListing.attributes.publicData.shipping;
    const isPickupOnly = !shippingOptions.domestic && !shippingOptions.international;
    // Show breakdown only when speculated transaction and booking are loaded
    // (i.e. have an id)
    const tx = existingTransaction.booking ? existingTransaction : speculatedTransaction;
    const breakdown =
      tx.id ? (
        <BookingBreakdown
          className={css.bookingBreakdown}
          userRole="customer"
          unitType={config.bookingUnitType}
          transaction={tx}
          booking={tx.booking}
          dateType={DATE_TYPE_DATE}
          selectedShippingOption={this.state.selectedShippingOption}
          shippingOptions={shippingOptions}
          isPickupOnly={isPickupOnly}
        />
      ) : null;

    const isPaymentExpired = checkIsPaymentExpired(existingTransaction);

    // Allow showing page when currentUser is still being downloaded,
    // but show payment form only when user info is loaded.
    const showPaymentForm = !!(
      currentUser &&
      hasRequiredData &&
      !listingNotFound &&
      !initiateOrderError &&
      !speculateTransactionError
    );

    const firstImage =
      currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

    const listingLink = (
      <NamedLink
        name="ListingPage"
        params={{ id: currentListing.id.uuid, slug: createSlug(listingTitle) }}
      >
        <FormattedMessage id="TimedAuctionCheckoutPage.errorlistingLinkText" />
      </NamedLink>
    );

    const listingLinkBtn = (
      <NamedLink
        className={css.modalBtn}
        name="ListingPage"
        params={{ id: currentListing.id.uuid, slug: createSlug(listingTitle) }}
      >
        <FormattedMessage id="TimedAuctionCheckoutPage.backToListing" />
      </NamedLink>
    );


    const isAmountTooLowError = isTransactionInitiateAmountTooLowError(initiateOrderError);
    const isChargeDisabledError = isTransactionChargeDisabledError(initiateOrderError);
    const isBookingTimeNotAvailableError = isTransactionInitiateBookingTimeNotAvailableError(
      initiateOrderError
    );
    const stripeErrors = transactionInitiateOrderStripeErrors(initiateOrderError);

    let initiateOrderErrorMessage = null;
    let listingNotFoundErrorMessage = null;

    if (listingNotFound) {
      listingNotFoundErrorMessage = (
        <p className={css.notFoundError}>
          <FormattedMessage id="TimedAuctionCheckoutPage.listingNotFoundError" />
        </p>
      );
    } else if (isAmountTooLowError) {
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="TimedAuctionCheckoutPage.initiateOrderAmountTooLow" />
        </p>
      );
    } else if (isBookingTimeNotAvailableError) {
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="TimedAuctionCheckoutPage.bookingTimeNotAvailableMessage" />
        </p>
      );
    } else if (isChargeDisabledError) {
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="TimedAuctionCheckoutPage.chargeDisabledMessage" />
        </p>
      );
    } else if (stripeErrors && stripeErrors.length > 0) {
      // NOTE: Error messages from Stripes are not part of translations.
      // By default they are in English.
      const stripeErrorsAsString = stripeErrors.join(', ');
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage
            id="TimedAuctionCheckoutPage.initiateOrderStripeError"
            values={{ stripeErrors: stripeErrorsAsString }}
          />
        </p>
      );
    } else if (initiateOrderError) {
      // Generic initiate order error
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="TimedAuctionCheckoutPage.initiateOrderError" values={{ listingLink }} />
        </p>
      );
    }

    const speculateTransactionErrorMessage = speculateTransactionError ? (
      <p className={css.speculateError}>
        <FormattedMessage id="TimedAuctionCheckoutPage.speculateTransactionError" />
      </p>
    ) : null;
    let speculateErrorMessage = null;

    if (isTransactionInitiateMissingStripeAccountError(speculateTransactionError)) {
      speculateErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="TimedAuctionCheckoutPage.providerStripeAccountMissingError" />
        </p>
      );
    } else if (isTransactionInitiateBookingTimeNotAvailableError(speculateTransactionError)) {
      speculateErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="TimedAuctionCheckoutPage.bookingTimeNotAvailableMessage" />
        </p>
      );
    } else if (isTransactionZeroPaymentError(speculateTransactionError)) {
      speculateErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="TimedAuctionCheckoutPage.initiateOrderAmountTooLow" />
        </p>
      );
    } else if (speculateTransactionError) {
      speculateErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="TimedAuctionCheckoutPage.speculateFailedMessage" />
        </p>
      );
    }

    const unitType = config.bookingUnitType;
    const isNightly = unitType === LINE_ITEM_NIGHT;
    const isDaily = unitType === LINE_ITEM_DAY;

    const unitTranslationKey = isNightly
      ? 'TimedAuctionCheckoutPage.perNight'
      : isDaily
      ? 'TimedAuctionCheckoutPage.perDay'
      : 'TimedAuctionCheckoutPage.perUnit';

    const price = currentListing.attributes.price;
    const formattedPrice = formatMoney(intl, price);
    const offerPrice = this.props.offer || price;
    const { reservePrice } = currentListing.attributes?.publicData?.timedAuction || {};

    const showInitialMessageInput = !(
      existingTransaction && existingTransaction.attributes.lastTransition === TRANSITION_ENQUIRE
    );

    // Get first and last name of the current user and use it in the StripePaymentForm to autofill the name field
    const userName =
      currentUser && currentUser.attributes
        ? `${currentUser.attributes.profile.firstName} ${currentUser.attributes.profile.lastName}`
        : null;

    // If your marketplace works mostly in one country you can use initial values to select country automatically
    // e.g. {country: 'FI'}

    const note = intl.formatMessage({ id: "TimedAuctionCheckoutPage.note" });
    const agreement = <AgreementParagraph intl={intl} />
    
    return (
      <Page {...pageProps} >
        {topbar}
        <div className={css.contentContainer}>
          <div className={css.aspectWrapper}>
            <ResponsiveImage
              rootClassName={css.rootForImage}
              alt={listingTitle}
              image={firstImage}
              variants={['scaled-small', 'scaled-medium']}
            />
          </div>
          <div className={css.bookListingContainer}>
            <div className={css.heading}>
              <h1 className={css.title}>
                <FormattedHTMLMessage id="TimedAuctionCheckoutPage.displayTitle" values={{ listingTitle }} />
              </h1>
            </div>

            <p className={css.note}>{note}</p>

            <div className={css.priceBreakdownContainer}>
              {speculateTransactionErrorMessage}
              {breakdown}
            </div>

            <section className={css.paymentContainer}>
              {initiateOrderErrorMessage}
              {listingNotFoundErrorMessage}
              {speculateErrorMessage}
              {showPaymentForm ? (
                <TimedAuctionCheckoutForm
                  className={css.paymentForm}
                  onSubmit={this.handleSubmit}
                  inProgress={this.state.submitting}
                  formId="TimedAuctionCheckoutPagePaymentForm"
                  authorDisplayName={currentAuthor.attributes.profile.displayName}
                  showInitialMessageInput={showInitialMessageInput}
                  initiateOrderError={initiateOrderError}
                  shipping={shippingOptions}
                  isShippingAddressExisting={isShippingAddressExisting}
                  currentShippingAddress={currentShippingAddress}
                  changeShippingOption={this.changeShippingOption}
                  isPickupOnly={isPickupOnly}
                  agreement={agreement}
                  changeShippingAddress={this.changeShippingAddress}
                  currentShippingAddressType={this.state.currentShippingAddressType}
                />
              ) : null}
              {this.state.warningTimedAuctionHasEnded && 
                <p className={css.orderError}>
                  <FormattedMessage
                    id="TimedAuctionCheckoutPage.timedAuctionHasEnded"
                  />
                </p>
              }
              {isPaymentExpired ? (
                <p className={css.orderError}>
                  <FormattedMessage
                    id="TimedAuctionCheckoutPage.paymentExpiredMessage"
                    values={{ listingLink }}
                  />
                </p>
              ) : null}
            </section>
          </div>
          <div className={css.sidebarContainer}>
            <div className={css.detailsContainerDesktop}>
              <div className={css.detailsAspectWrapper}>
                <ResponsiveImage
                  rootClassName={css.rootForImage}
                  alt={listingTitle}
                  image={firstImage}
                  variants={['landscape-crop', 'landscape-crop2x']}
                />
              </div>
              <div className={css.detailsHeadings}>
                <h2 className={css.detailsTitle}>{listingTitle}</h2>
              </div>
              {speculateTransactionErrorMessage}
              {breakdown}
            </div>
            {agreement}
          </div>
        </div>
        <Modal
          id="TimedAuctionCheckoutPage.warningModal"
          containerClassName={css.warningModalContainer}
          isOpen={this.state.openWarningModal}
          usePortal
          hideClose
          onManageDisableScrolling={this.props.onManageDisableScrolling}
        >
          <div className={css.modalTitleContainer}>
            <IconBannedUser/>
            <h2 className={css.modalTitle}>
              <FormattedMessage id="TimedAuctionCheckoutPage.warningTitleBelowReservePrice" />
            </h2>
          </div>
          <FormattedHTMLMessage id="TimedAuctionCheckoutPage.warningInfoBelowReservePrice" />
          {listingLinkBtn}
        </Modal>
      </Page>
    );
  }
}

TimedAuctionCheckoutPageComponent.defaultProps = {
  initiateOrderError: null,
  listing: null,
  bookingData: {},
  bookingDates: null,
  speculateTransactionError: null,
  speculatedTransaction: null,
  transaction: null,
  currentUser: null,
};

TimedAuctionCheckoutPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  listing: propTypes.listing,
  bookingData: object,
  bookingDates: shape({
    bookingStart: instanceOf(Date).isRequired,
    bookingEnd: instanceOf(Date).isRequired,
  }),
  fetchSpeculatedTransaction: func.isRequired,
  speculateTransactionInProgress: bool.isRequired,
  speculateTransactionError: propTypes.error,
  speculatedTransaction: propTypes.transaction,
  transaction: propTypes.transaction,
  currentUser: propTypes.currentUser,
  params: shape({
    id: string,
    slug: string,
  }).isRequired,
  onInitiateTimedAuctionOffer: func.isRequired,
  onInitiateOrderAutomaticDeclined: func.isRequired,
  onSendMessage: func.isRequired,
  initiateOfferError: propTypes.error,

  // from connect
  dispatch: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const mapStateToProps = state => {
  const {
    listing,
    bookingData,
    offer,
    shipping,
    bookingDates,
    stripeCustomerFetched,
    speculateTransactionInProgress,
    speculateTransactionError,
    speculatedTransaction,
    transaction,
    initiateOfferError,
  } = state.TimedAuctionCheckoutPage;
  const { currentUser } = state.user;
  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    stripeCustomerFetched,
    bookingData,
    bookingDates,
    offer,
    shipping,
    speculateTransactionInProgress,
    speculateTransactionError,
    speculatedTransaction,
    transaction,
    listing,
    initiateOfferError,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onSaveShippingAddress: params => dispatch(saveShippingAddress(params)),
  fetchSpeculatedTransaction: (params, transactionId) =>
    dispatch(speculateTransaction(params, transactionId)),
  onInitiateTimedAuctionOffer: (params, transactionId) => dispatch(initiateTimedAuctionOffer(params, transactionId)),
  onSendMessage: params => dispatch(sendMessage(params)),
  onInitiateOrderAutomaticDeclined: (params, transactionId) => dispatch(initiateOrderAutomaticDeclined(params, transactionId)),
});

const TimedAuctionCheckoutPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(TimedAuctionCheckoutPageComponent);

TimedAuctionCheckoutPage.setInitialValues = (initialValues, saveToSessionStorage = false) => {
  if (saveToSessionStorage) {
    const { listing, bookingData, bookingDates } = initialValues;
    storeData(bookingData, bookingDates, listing, null, STORAGE_KEY);
  }

  return setInitialValues(initialValues);
};

TimedAuctionCheckoutPage.displayName = 'TimedAuctionCheckoutPage';

export default TimedAuctionCheckoutPage;
