import React from 'react';
import { string, arrayOf, bool, func, number } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape, FormattedHTMLMessage } from '../../util/reactIntl';
import dropWhile from 'lodash/dropWhile';
import classNames from 'classnames';
import { Avatar, InlineTextButton, UserDisplayName } from '../../components';
import { formatDate } from '../../util/dates';
import { ensureTransaction, ensureUser, ensureListing } from '../../util/data';
import {
  TRANSITION_ACCEPT,
  TRANSITION_CANCEL,
  TRANSITION_COMPLETE,
  TRANSITION_DECLINE,
  TRANSITION_EXPIRE,
  TRANSITION_CONFIRM_PAYMENT,
  txIsDelivered,
  txRoleIsProvider,
  txRoleIsCustomer,
  txRoleIsAdmin,
  getUserTxRole,
  isRelevantPastTransition,
  isRelevantPastTransitionWithoutStripe,
  TRANSITION_BYPASS_STRIPE,
  TRANSITION_BYPASS_STRIPE_AFTER_ENQUIRY,
  TRANSITION_ACCEPT_WITHOUT_STRIPE,
  TRANSITION_DECLINE_WITHOUT_STRIPE,
  TRANSITION_EXPIRE_WITHOUT_STRIPE,
  TRANSITION_COMPLETE_WITHOUT_STRIPE,
  TRANSITION_CANCEL_WITHOUT_STRIPE,
  TRANSITION_DECLINE_BY_OPERATOR,
  TRANSITION_DECLINE_WITHOUT_STRIPE_BY_OPERATOR,
  TRANSITION_SET_COUNTER_OFFER,
  TRANSITION_SET_COUNTER_OFFER_WITHOUT_STRIPE,
  TRANSITION_DECLINE_PREVIOUS_OFFER,
  TRANSITION_ACCEPT_PREVIOUS_OFFER,
  TRANSITION_ACCEPT_PREVIOUS_OFFER_WITHOUT_STRIPE,
  TRANSITION_DECLINE_PREVIOUS_OFFER_BY_OPERATOR,
  TRANSITION_DECLINE_PREVIOUS_OFFER_WITHOUT_STRIPE,
  TRANSITION_DECLINE_PREVIOUS_OFFER_WITHOUT_STRIPE_BY_OPERATOR,
  TRANSITION_EXPIRE_COUNTER_OFFER_WITHOUT_STRIPE,
  TRANSITION_EXPIRE_COUNTER_OFFER,
  TRANSITION_ACCEPT_COUNTER_OFFER,
  TRANSITION_ACCEPT_COUNTER_OFFER_WITHOUT_STRIPE,
  TRANSITION_MAKE_NEW_OFFER,
  TRANSITION_MAKE_NEW_OFFER_WITHOUT_STRIPE,
  TRANSITION_AUTO_DECLINE_BELOW_RESERVE_PRICE,
  TRANSITION_ACCEPT_TIMED_AUCTION_OFFER,
  TRANSITION_DECLINE_TIMED_AUCTION_OFFER,
  TRANSITION_PAY_BY_STRIPE,
  TRANSITION_CONFIRM_STRIPE_PAYMENT,
  TRANSITION_PAY_BY_NON_STRIPE,
  TRANSITION_MAKE_TIMED_AUCTION_OFFER,
  isRelevantPastTransitionTimedAuctionFlow,
} from '../../util/transaction';
import { propTypes, LINE_ITEM_PROVIDER_COMMISSION } from '../../util/types';
import * as log from '../../util/log';
import { formatMoney, createMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';

import css from './ActivityFeed.module.css';

const { Money } = sdkTypes;

const Message = props => {
  const { message, intl } = props;
  const todayString = intl.formatMessage({ id: 'ActivityFeed.today' });
  return (
    <div className={css.message}>
      <Avatar className={css.avatar} user={message.sender} />
      <div>
        <p className={css.messageContent}>{message.attributes.content}</p>
        <p className={css.messageDate}>
          {formatDate(intl, todayString, message.attributes.createdAt)}
        </p>
      </div>
    </div>
  );
};

Message.propTypes = {
  message: propTypes.message.isRequired,
  intl: intlShape.isRequired,
};

const OwnMessage = props => {
  const { message, intl } = props;
  const todayString = intl.formatMessage({ id: 'ActivityFeed.today' });
  return (
    <div className={css.ownMessage}>
      <div className={css.ownMessageContentWrapper}>
        <p className={css.ownMessageContent}>{message.attributes.content}</p>
      </div>
      <p className={css.ownMessageDate}>
        {formatDate(intl, todayString, message.attributes.createdAt)}
      </p>
    </div>
  );
};

OwnMessage.propTypes = {
  message: propTypes.message.isRequired,
  intl: intlShape.isRequired,
};

const resolveTransitionMessage = (
  transaction,
  transition,
  listingTitle,
  ownRole,
  otherUsersName,
  intl,
) => {
  const isOwnTransition = transition.by === ownRole;
  const currentTransition = transition.transition;
  const displayName = otherUsersName;
  const offerItem = transaction.attributes.lineItems.find(item => item.code === LINE_ITEM_PROVIDER_COMMISSION);
  // If there is no offer, assuming it's a direct purchase (Buy It Now)
  const offerAmount = offerItem ? offerItem.unitPrice : transaction.listing.attributes.price;
  const formattedOfferAmount = formatMoney(intl, offerAmount);
  const { counterOffer } = transaction.attributes.protectedData;
  const formattedCounterOfferAmount = counterOffer ? formatMoney(intl, createMoney(counterOffer)) : '';
  const { otherPayment } = transaction.attributes.metadata;
  
  switch (currentTransition) {
    case TRANSITION_CONFIRM_PAYMENT:
      return isOwnTransition ? (
        <FormattedMessage id="ActivityFeed.ownTransitionRequest" values={{ amount: formattedOfferAmount, listingTitle, displayName }} />
      ) : (
        <FormattedMessage
          id="ActivityFeed.transitionRequest"
          values={{ displayName, listingTitle, amount: formattedOfferAmount, type: otherPayment }}
        />
      );
    case TRANSITION_BYPASS_STRIPE:
    case TRANSITION_BYPASS_STRIPE_AFTER_ENQUIRY:
      return isOwnTransition ? (
        <FormattedMessage id="ActivityFeed.ownTransitionRequestWithoutStripe" values={{ amount: formattedOfferAmount, listingTitle, displayName }} />
      ) : (
        <FormattedMessage
          id="ActivityFeed.transitionRequestWithoutStripe"
          values={{ displayName, listingTitle, amount: formattedOfferAmount, type: otherPayment }}
        />
      );
    case TRANSITION_ACCEPT:
    case TRANSITION_ACCEPT_WITHOUT_STRIPE:
    case TRANSITION_ACCEPT_PREVIOUS_OFFER:
    case TRANSITION_ACCEPT_PREVIOUS_OFFER_WITHOUT_STRIPE:
      return isOwnTransition ? (
        <FormattedMessage id="ActivityFeed.ownTransitionAccept" values={{ amount: formattedOfferAmount, listingTitle, displayName, type: otherPayment }}/>
      ) : (
        <FormattedMessage id="ActivityFeed.transitionAccept" values={{ amount: formattedOfferAmount, listingTitle, displayName }} />
      );
    case TRANSITION_DECLINE:
    case TRANSITION_DECLINE_BY_OPERATOR:
    case TRANSITION_DECLINE_WITHOUT_STRIPE:
    case TRANSITION_DECLINE_WITHOUT_STRIPE_BY_OPERATOR:
    case TRANSITION_DECLINE_PREVIOUS_OFFER:
    case TRANSITION_DECLINE_PREVIOUS_OFFER_BY_OPERATOR:
    case TRANSITION_DECLINE_PREVIOUS_OFFER_WITHOUT_STRIPE:
    case TRANSITION_DECLINE_PREVIOUS_OFFER_WITHOUT_STRIPE_BY_OPERATOR:
    case TRANSITION_DECLINE_TIMED_AUCTION_OFFER:
    case TRANSITION_AUTO_DECLINE_BELOW_RESERVE_PRICE:
      return (txRoleIsProvider(ownRole) || txRoleIsAdmin(ownRole)) 
      ? (
        <FormattedMessage id="ActivityFeed.ownTransitionDecline" values={{ displayName, listingTitle, amount: formattedOfferAmount }} />
      ) 
      : (
        <FormattedMessage id="ActivityFeed.transitionDecline" values={{ listingTitle, amount: formattedOfferAmount }} />
      );
    case TRANSITION_ACCEPT_COUNTER_OFFER:
    case TRANSITION_ACCEPT_COUNTER_OFFER_WITHOUT_STRIPE:
      return (txRoleIsCustomer(ownRole)) 
        ? (
          <FormattedMessage id="ActivityFeed.ownTransitionAcceptCounterOfferByCustomer" values={{ listingTitle, amount: formattedCounterOfferAmount }} />
        ) 
        : (
          <FormattedMessage id="ActivityFeed.transitionAcceptCounterOfferByCustomer" values={{ displayName, listingTitle, amount: formattedCounterOfferAmount }} />
        );
    case TRANSITION_MAKE_NEW_OFFER:
    case TRANSITION_MAKE_NEW_OFFER_WITHOUT_STRIPE:
      return (txRoleIsCustomer(ownRole)) 
        ? (
          <FormattedMessage id="ActivityFeed.ownTransitionMakeNewOffer" values={{ listingTitle, amount: formattedCounterOfferAmount }} />
        ) 
        : (
          <FormattedMessage id="ActivityFeed.transitionMakeNewOffer" values={{ displayName, listingTitle, amount: formattedCounterOfferAmount }} />
        );
    case TRANSITION_EXPIRE:
    case TRANSITION_EXPIRE_WITHOUT_STRIPE:
    case TRANSITION_EXPIRE_COUNTER_OFFER:
    case TRANSITION_EXPIRE_COUNTER_OFFER_WITHOUT_STRIPE:
      return (txRoleIsProvider(ownRole) || txRoleIsAdmin(ownRole)) 
      ? (
        <FormattedMessage id="ActivityFeed.ownTransitionExpire" values={{ displayName, listingTitle, amount: formattedOfferAmount }}/>
      ) 
      : (
        <FormattedMessage id="ActivityFeed.transitionExpire" values={{ listingTitle, amount: formattedOfferAmount }} />
      );
    case TRANSITION_SET_COUNTER_OFFER:
    case TRANSITION_SET_COUNTER_OFFER_WITHOUT_STRIPE:
      return (txRoleIsProvider(ownRole))
      ? (
        <FormattedMessage id="ActivityFeed.ownTransitionCounterOffer" values={{ listingTitle, counterOffer: formattedCounterOfferAmount }} />
      )
      : (
        <FormattedMessage id="ActivityFeed.transitionCounterOffer" values={{ displayName, listingTitle, counterOffer: formattedCounterOfferAmount }} />
      )
    case TRANSITION_MAKE_TIMED_AUCTION_OFFER:
      return isOwnTransition
      ? <FormattedMessage id="ActivityFeed.ownTransitionMakeTimedAuctionOffer" values={{ listingTitle, amount: formattedOfferAmount }} />
      : <FormattedMessage id="ActivityFeed.transitionMakeTimedAuctionOffer" values={{ displayName, listingTitle, amount: formattedOfferAmount }} />
    case TRANSITION_PAY_BY_STRIPE:
    case TRANSITION_PAY_BY_NON_STRIPE:
      return isOwnTransition
        ? <FormattedMessage id="ActivityFeed.ownTransitionPay" values={{ listingTitle, type: otherPayment }} />
        : <FormattedMessage id="ActivityFeed.transitionPay" values={{ displayName, type: otherPayment }} />
    case TRANSITION_CONFIRM_STRIPE_PAYMENT:
      return <FormattedMessage id="ActivityFeed.transitionConfirmStripePayment" />
    case TRANSITION_ACCEPT_TIMED_AUCTION_OFFER:
      return (txRoleIsProvider(ownRole) || txRoleIsAdmin(ownRole))
        ? <FormattedMessage id="ActivityFeed.adminTransitionAcceptTimedAuctionOffer" values={{ displayName, listingTitle, amount: formattedOfferAmount }} />
        : <FormattedMessage id="ActivityFeed.winnerTransitionAcceptTimedAuctionOffer" values={{ listingTitle, amount: formattedOfferAmount }} />
    case TRANSITION_CANCEL:
    case TRANSITION_CANCEL_WITHOUT_STRIPE:
      return <FormattedMessage id="ActivityFeed.transitionCancel" />;
    case TRANSITION_COMPLETE:
    case TRANSITION_COMPLETE_WITHOUT_STRIPE:
      return (
        <FormattedMessage
          id="ActivityFeed.transitionComplete"
        />
      );

    default:
      log.error(new Error('Unknown transaction transition type'), 'unknown-transition-type', {
        transitionType: currentTransition,
      });
      return '';
  }
};

const Transition = props => {
  const { transition, transaction, currentUser, intl } = props;

  const currentTransaction = ensureTransaction(transaction);
  const customer = currentTransaction.customer;
  const provider = currentTransaction.provider;

  const deletedListing = intl.formatMessage({
    id: 'ActivityFeed.deletedListing',
  });
  const listingTitle = currentTransaction.listing.attributes.deleted
    ? deletedListing
    : currentTransaction.listing.attributes.title;

  const ownRole = getUserTxRole(currentUser.id, currentTransaction);

  const otherUsersName = (txRoleIsProvider(ownRole) || txRoleIsAdmin(ownRole)) 
  ? (
    <UserDisplayName user={customer} intl={intl} />
  ) 
  : (
    <UserDisplayName user={provider} intl={intl} />
  );

  const transitionMessage = resolveTransitionMessage(
    transaction,
    transition,
    listingTitle,
    ownRole,
    otherUsersName,
    intl,
  );
  const currentTransition = transition.transition;

  const todayString = intl.formatMessage({ id: 'ActivityFeed.today' });

  return (
    <div className={css.transition}>
      <div className={css.bullet}>
        <p className={css.transitionContent}>•</p>
      </div>
      <div>
        <p className={css.transitionContent}>{transitionMessage}</p>
        <p className={css.transitionDate}>{formatDate(intl, todayString, transition.createdAt)}</p>
      </div>
    </div>
  );
};

Transition.propTypes = {
  transition: propTypes.transition.isRequired,
  transaction: propTypes.transaction.isRequired,
  currentUser: propTypes.currentUser.isRequired,
  intl: intlShape.isRequired,
};

const EmptyTransition = () => {
  return (
    <div className={css.transition}>
      <div className={css.bullet}>
        <p className={css.transitionContent}>•</p>
      </div>
      <div>
        <p className={css.transitionContent} />
        <p className={css.transitionDate} />
      </div>
    </div>
  );
};

const isMessage = item => item && item.type === 'message';

// Compare function for sorting an array containing messages and transitions
const compareItems = (a, b) => {
  const itemDate = item => (isMessage(item) ? item.attributes.createdAt : item.createdAt);
  return itemDate(a) - itemDate(b);
};

const organizedItems = (messages, transitions, hideOldTransitions) => {
  const items = messages.concat(transitions).sort(compareItems);
  if (hideOldTransitions) {
    // Hide transitions that happened before the oldest message. Since
    // we have older items (messages) that we are not showing, seeing
    // old transitions would be confusing.
    return dropWhile(items, i => !isMessage(i));
  } else {
    return items;
  }
};

export const ActivityFeedComponent = props => {
  const {
    rootClassName,
    className,
    messages,
    transaction,
    currentUser,
    hasOlderMessages,
    onShowOlderMessages,
    fetchMessagesInProgress,
    intl,
  } = props;
  const classes = classNames(rootClassName || css.root, className);

  const currentTransaction = ensureTransaction(transaction);
  const transitions = currentTransaction.attributes.transitions
    ? currentTransaction.attributes.transitions
    : [];
  const currentCustomer = ensureUser(currentTransaction.customer);
  const currentProvider = ensureUser(currentTransaction.provider);
  const currentListing = ensureListing(currentTransaction.listing);

  const transitionsAvailable = !!(
    currentUser &&
    currentUser.id &&
    currentCustomer.id &&
    currentProvider.id &&
    currentListing.id
  );

  // combine messages and transaction transitions
  const items = organizedItems(messages, transitions, hasOlderMessages || fetchMessagesInProgress);

  const transitionComponent = transition => {
    if (transitionsAvailable) {
      return (
        <Transition
          transition={transition}
          transaction={transaction}
          currentUser={currentUser}
          intl={intl}
        />
      );
    } else {
      return <EmptyTransition />;
    }
  };

  const messageComponent = message => {
    const isOwnMessage =
      message.sender &&
      message.sender.id &&
      currentUser &&
      currentUser.id &&
      message.sender.id.uuid === currentUser.id.uuid;
    if (isOwnMessage) {
      return <OwnMessage message={message} intl={intl} />;
    }
    return <Message message={message} intl={intl} />;
  };

  const messageListItem = message => {
    return (
      <li id={`msg-${message.id.uuid}`} key={message.id.uuid} className={css.messageItem}>
        {messageComponent(message)}
      </li>
    );
  };

  const checkRelevantTransitions = currentTransaction.attributes.metadata?.isTimedAuction
    ? isRelevantPastTransitionTimedAuctionFlow
    : currentTransaction.attributes.metadata?.otherPayment
      ? isRelevantPastTransitionWithoutStripe
      : isRelevantPastTransition
  
  const transitionListItem = transition => {
    if (checkRelevantTransitions(transition.transition)) {
      return (
        <li key={transition.transition} className={css.transitionItem}>
          {transitionComponent(transition)}
        </li>
      );
    } else {
      return null;
    }
  };

  return (
    <ul className={classes}>
      {hasOlderMessages ? (
        <li className={css.showOlderWrapper} key="show-older-messages">
          <InlineTextButton className={css.showOlderButton} onClick={onShowOlderMessages}>
            <FormattedMessage id="ActivityFeed.showOlderMessages" />
          </InlineTextButton>
        </li>
      ) : null}
      {items.map(item => {
        if (isMessage(item)) {
          return messageListItem(item);
        } else {
          return transitionListItem(item);
        }
      })}
    </ul>
  );
};

ActivityFeedComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

ActivityFeedComponent.propTypes = {
  rootClassName: string,
  className: string,

  currentUser: propTypes.currentUser,
  transaction: propTypes.transaction,
  messages: arrayOf(propTypes.message),
  hasOlderMessages: bool.isRequired,
  onShowOlderMessages: func.isRequired,
  fetchMessagesInProgress: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ActivityFeed = injectIntl(ActivityFeedComponent);

export default ActivityFeed;
