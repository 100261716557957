import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { required } from '../../util/validators';
import { Form, PrimaryButton, FieldCurrencyInput, FieldTextInput } from '../../components';
import config from '../../config';

import css from './CounterOfferForm.module.css';

const CounterOfferFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        className,
        rootClassName,
        disabled,
        handleSubmit,
        intl,
        formId,
        invalid,
        setCounterOfferInProgress,
        setCounterOfferError,
        values
      } = fieldRenderProps;

      const setCounterOfferRequiredMessage = intl.formatMessage({
        id: 'CounterOfferForm.setCounterOfferRequired',
      });

      const setCounterOfferRequired = required(setCounterOfferRequiredMessage);

      const offerValidators = config.listingMinimumPriceSubUnits
        ? validators.composeValidators(setCounterOfferRequired, minPriceRequired)
        : setCounterOfferRequired;

      const setCounterOfferLabel = intl.formatMessage({ id: 'CounterOfferForm.setCounterOfferLabel' });
      
      const messageLabel = intl.formatMessage({ id: 'CounterOfferForm.messageLabel' });

      const errorMessage = setCounterOfferError && (
        <p className={css.error}>
          <FormattedMessage id="CounterOfferForm.setCounterOfferFailed" />
        </p>
      );

      const setCounterOfferSubmitMessage = intl.formatMessage({
        id: 'CounterOfferForm.setCounterOfferSubmit',
      });

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = setCounterOfferInProgress;
      const submitDisabled = invalid || disabled || setCounterOfferInProgress || !values.counterOffer;
      
      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <FieldCurrencyInput
            className={css.inputCounterOffer}
            type="text"
            id={formId ? `${formId}.counterOffer` : 'counterOffer'}
            name="counterOffer"
            currencyConfig={config.currencyConfig}
            label={setCounterOfferLabel}
          />

          <FieldTextInput 
            className={css.textarea}
            type="textarea"
            id={formId ? `${formId}.message` : 'message'}
            name="message"
            label={messageLabel}
          />

          {errorMessage}
          <PrimaryButton
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
          >
            {setCounterOfferSubmitMessage}
          </PrimaryButton>
        </Form>
      );
    }}
  />
);

CounterOfferFormComponent.defaultProps = { className: null, rootClassName: null, sendReviewError: null };

const { bool, func, string } = PropTypes;

CounterOfferFormComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  setCounterOfferInProgress: bool,
  setCounterOfferError: propTypes.error
};

const CounterOfferForm = compose(injectIntl)(CounterOfferFormComponent);
CounterOfferForm.displayName = 'CounterOfferForm';

export default CounterOfferForm;
