import React, { useState } from 'react';
import { intlShape } from '../../../util/reactIntl';
import { object } from 'prop-types';
import { CountdownTimer } from '../../../components';
import moment from 'moment-timezone';

import css from '../ListingPage.module.css';

const SectionTimedAuction = props => {
  const { timedAuction, intl } = props;
  const {
    endDate,
    isTimeExtended,
    isReservePriceMet,
    isAutomated,
    hasStartDate,
    startDate,
  } = timedAuction;
  const isEndDateValid = endDate && moment(endDate).isAfter(moment());
  const isEndDateExpired = endDate && moment(endDate).isBefore(moment());
  const hasStartDateBegun = hasStartDate && startDate && moment().isSameOrAfter(moment(startDate));

  const shouldTimerDisplay =
    (!isAutomated || (isAutomated && isReservePriceMet && (!hasStartDate || hasStartDateBegun))) &&
    isEndDateValid;
  const [timerDisplay, setTimerDisplay] = useState(shouldTimerDisplay);

  const reservePriceNotMetText = intl.formatMessage({
    id: 'SectionTimedAuction.reservePriceNotMet',
  });
  const timedAuctionBeginAfterText = intl.formatMessage(
    {
      id: 'SectionTimedAuction.timedAuctionBeginAfterText',
    },
    { date: moment(startDate).format('MM/DD/YYYY') }
  );
  const timedAuctionEndLabel = intl.formatMessage({
    id: 'SectionTimedAuction.timedAuctionEndIn',
  });
  const timedAuctionEndedLabel = intl.formatMessage({
    id: 'SectionTimedAuction.timedAuctionEnded',
  });
  const extendedTimeLabel = intl.formatMessage({
    id: 'SectionTimedAuction.extendedTime',
  });

  return (
    <div className={css.sectionTimedAuction}>
      {!isEndDateExpired && isTimeExtended && (
        <p className={css.extendedTime}>{extendedTimeLabel}</p>
      )}
      {timerDisplay && (
        <div className={css.countdownTimer}>
          <p>{timedAuctionEndLabel}</p>
          <CountdownTimer endDate={endDate} onContinue={setTimerDisplay} />
        </div>
      )}
      {isEndDateExpired && <div className={css.endTimedAuctionLabel}>{timedAuctionEndedLabel}</div>}
      {!isReservePriceMet && <div className={css.reservePriceText}>{reservePriceNotMetText}</div>}
      {hasStartDate && !hasStartDateBegun && (
        <div className={css.informText}>{timedAuctionBeginAfterText}</div>
      )}
    </div>
  );
};

SectionTimedAuction.propTypes = {
  timedAuction: object,
  intl: intlShape
};

export default SectionTimedAuction;