import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { fetchCurrentUser } from '../../ducks/user.duck';
import {
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperAccountSettingsSideNav,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  Page,
  UserNav,
} from '../../components';
import { ShippingAddressForm } from '../../forms';
import { TopbarContainer } from '../../containers';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { removeShippingAddress, saveShippingAddress } from './ShippingAddressPage.duck';

import css from './ShippingAddressPage.module.css';

export const ShippingAddressPageComponent = props => {
  const {
    saveShippingAddressInProgress,
    currentUser,
    scrollingDisabled,
    onSubmitShippingAddress,
    onSubmitRemoveShippingAddress,
    intl,
  } = props;

  const user = ensureCurrentUser(currentUser);
  const publicData = user.attributes.profile.publicData || {};
  const address = publicData.shippingAddress;
  const removeAction = intl.formatMessage({ id: 'ShippingAddressForm.remove' });

  const shippingAddressInfoForm = user.id && !address ? (
    <ShippingAddressForm
      intl={intl}
      onSubmit={values => {
        onSubmitShippingAddress(values)
      }}
      updateInProgress={saveShippingAddressInProgress}
    />
  ) : (
    <div>
      {address ? (
        <div>
          <div className={css.addressResult}>
            {`${address.addressLine1}, ${address.addressLine2 ? address.addressLine2 + ', ' : ''} ${address.city}, ${address.state ? address.state + ', ' : ''} ${address.postal}, ${address.country}`}
          </div>
          <button
            className={css.removeActionBtn}
            onClick={() => { onSubmitRemoveShippingAddress() }}>
            {removeAction}
          </button>
        </div>
      ) : null}
    </div>
  );

  const title = intl.formatMessage({ id: 'ShippingAddressPage.title' });

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer
            currentPage="ShippingAddressPage"
            desktopClassName={css.desktopTopbar}
            mobileClassName={css.mobileTopbar}
          />
          <UserNav selectedPageName="ShippingAddressPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperAccountSettingsSideNav currentTab="ShippingAddressPage" />
        <LayoutWrapperMain>
          <div className={css.content}>
            <h1 className={css.title}>
              <FormattedMessage id="ShippingAddressPage.heading" />
            </h1>
            {shippingAddressInfoForm}
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

ShippingAddressPageComponent.defaultProps = {
  saveShippingAddressError: null,
  removeShippingAddressError: null,
  currentUser: null,
};

const { bool, func } = PropTypes;

ShippingAddressPageComponent.propTypes = {
  saveShippingAddressError: propTypes.error,
  saveShippingAddressInProgress: bool.isRequired,
  removeShippingAddressError: propTypes.error,
  currentUser: propTypes.currentUser,
  onSubmitShippingAddress: func.isRequired,
  scrollingDisabled: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  // Topbar needs user info.
  const { currentUser } = state.user;
  const {
    saveShippingAddressError,
    saveShippingAddressInProgress,
    removeShippingAddressError,
  } = state.ShippingAddressPage;
  return {
    saveShippingAddressError,
    saveShippingAddressInProgress,
    removeShippingAddressError,
    currentUser,
    scrollingDisabled: isScrollingDisabled(state)
  };
};

const mapDispatchToProps = dispatch => ({
  onSubmitShippingAddress: values => dispatch(saveShippingAddress(values)),
  onSubmitRemoveShippingAddress: () => dispatch(removeShippingAddress()),
});

const ShippingAddressPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(ShippingAddressPageComponent);

ShippingAddressPage.loadData = () => {
  return fetchCurrentUser();
};

export default ShippingAddressPage;
