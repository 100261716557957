import React from 'react';

import { Button, PrimaryButton } from '../../../components';
import { FormattedMessage } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import { func, number, bool } from 'prop-types';
import { ADD_WATCHERS, REMOVE_WATCHERS } from '../../../util/types';

import css from '../ListingPage.module.css';

const SectionWatchList = props => {
  const { 
    onClickToUpdateWatchlist, 
    numOfWatchers, 
    updateWatchlistInProgress, 
    updateWatchlistError, 
    isWatched,
    isOwnListing
  } = props;

  const subscribeButton = (
    <PrimaryButton inProgress={updateWatchlistInProgress} disabled={updateWatchlistInProgress} onClick={() => onClickToUpdateWatchlist(ADD_WATCHERS)} className={css.watchButton}>
      <FormattedMessage id="ListingPage.ctaButtonWatchList" />
    </PrimaryButton>
  );

  const unsubscribeButton = (
    <PrimaryButton inProgress={updateWatchlistInProgress} disabled={updateWatchlistInProgress} onClick={() => onClickToUpdateWatchlist(REMOVE_WATCHERS)} className={css.watchedButton}>
      <FormattedMessage id="ListingPage.ctaClickedButtonWatchList" />
    </PrimaryButton>
  );

  return (
    <div className={css.sectionWatchList}>
      <span className={css.numWatchersLabel}>
        <FormattedMessage id="ListingPage.numWatchers" values={{ number: numOfWatchers }}/>
      </span>
      {isOwnListing
        ? null
        : isWatched
          ? unsubscribeButton
          : subscribeButton
      }
    </div>
  );
};

SectionWatchList.defaultProps = {
  updateWatchlistInProgress: false,
  updateWatchlistError: null,
  isOwnListing: false
};

SectionWatchList.propTypes = {
  onClickToUpdateWatchlist: func.isRequired,
  numOfWatchers: number.isRequired, 
  updateWatchlistInProgress: bool.isRequired, 
  updateWatchlistError: propTypes.error, 
  isWatched: bool,
  isOwnListing: bool.isRequired
};

export default SectionWatchList;
