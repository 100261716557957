import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import { propTypes, NO, YES } from '../../util/types';
import * as validators from '../../util/validators';
import { formatMoney, createMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { Button, Form, FieldCurrencyInput, FieldRadioButtonGroup, FieldTextInput } from '../../components';
import css from './EditListingPricingForm.module.css';
import arrayMutators from 'final-form-arrays';

const { Money } = sdkTypes;

const MAX = 'max', MIN = 'min';

export const EditListingPricingFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        askingPriceOptions,
        highestOfferOptions,
        acceptOfferOptions,
        acceptBuyItNowOptions,
        estimatePriceOptions,
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        values,
      } = formRenderProps;

      const { minEstPrice = {}, maxEstPrice = {}, acceptOffer, askingPrice } = values.pricing;

      const priceLabel = intl.formatMessage({
        id: 'EditListingPricingForm.priceLabel',
      });

      const priceRequired = validators.required(
        intl.formatMessage({
          id: 'EditListingPricingForm.priceRequired',
        })
      );
      const minPrice = new Money(config.listingMinimumPriceSubUnits, config.currency);
      const minPriceRequired = validators.moneySubUnitAmountAtLeast(
        intl.formatMessage(
          {
            id: 'EditListingPricingForm.priceTooLow',
          },
          {
            minPrice: formatMoney(intl, minPrice),
          }
        ),
        config.listingMinimumPriceSubUnits
      );
      // Specific labels and placeholders for this section
      const askingPriceLabel = intl.formatMessage({
        id: 'EditListingPricingForm.askingPriceLabel',
      });
      const highestOfferLabel = intl.formatMessage({
        id: 'EditListingPricingForm.highestOfferLabel',
      });
      const acceptOfferLabel = intl.formatMessage({
        id: 'EditListingPricingForm.acceptOfferLabel',
      });
      const acceptBuyItNowLabel = intl.formatMessage({
        id: 'EditListingPricingForm.acceptBuyItNowLabel',
      });
      const minReservePriceLabel = intl.formatMessage({
        id: 'EditListingPricingForm.minReservePriceLabel',
      });
      const priceValidators = config.listingMinimumPriceSubUnits
        ? validators.composeValidators(priceRequired, minPriceRequired)
        : priceRequired;

      const isInvalidChoice =
        acceptOffer === NO && askingPrice === NO;

      // Estimate price input
      const minEstPriceMoney = minEstPrice && createMoney(minEstPrice);
      const maxEstPriceMoney = maxEstPrice && createMoney(maxEstPrice);

      const estPriceLabel = intl.formatMessage({
        id: 'EditListingPricingForm.estPriceLabel'
      });
      const minEstPriceLabel = intl.formatMessage({
        id: 'EditListingPricingForm.minEstPriceLabel'
      });
      const maxEstPriceLabel = intl.formatMessage({
        id: 'EditListingPricingForm.maxEstPriceLabel'
      });
      const minEstPriceRequired = maxEstPriceMoney && validators.moneySubUnitAmountAtMost(
        intl.formatMessage(
          { id: 'EditListingPricingForm.estPriceTooHigh'},
          { maxPrice: formatMoney(intl, maxEstPriceMoney)}
        ),
        maxEstPriceMoney.amount
      );
      const maxEstPriceRequired = minEstPriceMoney && validators.moneySubUnitAmountAtLeast(
        intl.formatMessage(
          { id: 'EditListingPricingForm.estPriceTooLow' },
          { minPrice: formatMoney(intl, minEstPriceMoney)}
        ),
        minEstPriceMoney.amount
      );
      const showEstimatePriceLabel = intl.formatMessage({
        id: 'EditListingPricingForm.showEstimatePriceLabel',
      });

      // Buyer premium fee
      const buyerPremiumFeeLabel = intl.formatMessage({
        id: 'EditListingPricingForm.buyerPremiumFeeLabel'
      });
      
      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress || isInvalidChoice;
      const { updateListingError, showListingsError } = fetchErrors || {};

      return (
        <Form onSubmit={handleSubmit} className={classes}>
          {updateListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPricingForm.updateFailed" />
            </p>
          ) : null}
          {showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPricingForm.showListingFailed" />
            </p>
          ) : null}
          <label>{estPriceLabel}</label>
          <div className={css.estPriceInput}>
            <FieldCurrencyInput 
              id="pricing.minEstPrice"
              name="pricing.minEstPrice"
              label={minEstPriceLabel}
              className={css.estPriceInputField}
              currencyConfig={config.currencyConfig}
              validate={minEstPriceRequired}
              key={maxEstPrice?.amount + MAX}
            />
            <FieldCurrencyInput 
              id="pricing.maxEstPrice"
              name="pricing.maxEstPrice"
              label={maxEstPriceLabel}
              className={css.estPriceInputField}
              currencyConfig={config.currencyConfig}
              validate={maxEstPriceRequired}
              key={minEstPrice?.amount + MIN}
            />
          </div>
          <FieldCurrencyInput
            id="price"
            name="price"
            className={css.priceInput}
            label={priceLabel}
            currencyConfig={config.currencyConfig}
            validate={priceValidators}
          />
          <FieldCurrencyInput
            id="minReservePrice"
            name="minReservePrice"
            className={css.priceInput}
            label={minReservePriceLabel}
            currencyConfig={config.currencyConfig}
          />
          <FieldTextInput
            id="buyerPremiumFee"
            name="buyerPremiumFee"
            className={css.priceInput}
            label={buyerPremiumFeeLabel}
            type="number"
            min="0"
            max="100"
          />
          <FieldRadioButtonGroup
            id="pricing.askingPrice"
            name="pricing.askingPrice"
            label={askingPriceLabel}
            twoColumns={true}
            options={askingPriceOptions}
            intl={intl}
          />
          <FieldRadioButtonGroup
            id="pricing.acceptOffer"
            name="pricing.acceptOffer"
            label={acceptOfferLabel}
            twoColumns={true}
            options={acceptOfferOptions}
            intl={intl}
          />
          {values.pricing.acceptOffer === YES && (
            <FieldRadioButtonGroup
              id="pricing.displayHighestOffer"
              name="pricing.displayHighestOffer"
              label={highestOfferLabel}
              twoColumns={true}
              options={highestOfferOptions}
              intl={intl}
            />
          )}
          {values.pricing.askingPrice === YES && (
            <FieldRadioButtonGroup
              id="pricing.acceptBuyItNow"
              name="pricing.acceptBuyItNow"
              label={acceptBuyItNowLabel}
              twoColumns={true}
              options={acceptBuyItNowOptions}
              intl={intl}
            />
          )}
          {isInvalidChoice && (
            <p className={css.error}>
              <FormattedMessage id="EditListingPricingForm.optionYesRequired" />
            </p>
          )}
          <FieldRadioButtonGroup
            id="pricing.showEstimatePrice"
            name="pricing.showEstimatePrice"
            label={showEstimatePriceLabel}
            twoColumns
            options={estimatePriceOptions}
            intl={intl}
          />
          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingPricingFormComponent.defaultProps = { fetchErrors: null };

EditListingPricingFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingPricingFormComponent);
