import { storableError } from '../../util/errors';
import { unsubscribeListing } from '../../util/custom-api';
import { types as sdkTypes } from '../../util/sdkLoader';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { SHOW_LISTINGS_SUCCESS } from '../EditListingPage/EditListingPage.duck';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const UNSUBSCRIPTION_REQUEST = 'app/UnsubscribeListingPage/UNSUBSCRIPTION_REQUEST';
export const UNSUBSCRIPTION_SUCCESS = 'app/UnsubscribeListingPage/UNSUBSCRIPTION_SUCCESS';
export const UNSUBSCRIPTION_ERROR = 'app/UnsubscribeListingPage/UNSUBSCRIPTION_ERROR';

export const SHOW_LISTING_REQUEST = 'app/UnsubscribeListingPage/SHOW_LISTING_REQUEST';
export const SHOW_LISTING_SUCCESS = 'app/UnsubscribeListingPage/SHOW_LISTING_SUCCESS';
export const SHOW_LISTING_ERROR = 'app/UnsubscribeListingPage/SHOW_LISTING_ERROR';

// ================ Reducer ================ //

const initialState = {
  isUnsubscribed: false,

  // unsubscription
  unsubscriptionError: null,
  unsubscriptionInProgress: false,

  //show listing
  showListingError: null,
  showListingInProgress: false
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case UNSUBSCRIPTION_REQUEST:
      return {
        ...state,
        unsubscriptionInProgress: true,
        unsubscriptionError: null,
      };
    case UNSUBSCRIPTION_SUCCESS:
      return { ...state, unsubscriptionInProgress: false, isUnsubscribed: true };
    case UNSUBSCRIPTION_ERROR:
      return { ...state, unsubscriptionInProgress: false, unsubscriptionError: payload };
    case SHOW_LISTING_REQUEST:
      return { ...state, showListingError: null, showListingInProgress: true };
    case SHOW_LISTING_SUCCESS:
        return { ...state, showListingInProgress: false };
    case SHOW_LISTING_ERROR:
      return { ...state, showListingError: payload, showListingInProgress: false };
    default:
      return state;
  }
}

// ================ Selectors ================ //

export const unsubscriptionInProgress = state => {
  return state.UnsubscribeListingPage.unsubscriptionInProgress;
};

// ================ Action creators ================ //

export const unsubscriptionRequest = () => ({ type: UNSUBSCRIPTION_REQUEST });
export const unsubscriptionSuccess = () => ({ type: UNSUBSCRIPTION_SUCCESS });
export const unsubscriptionError = error => ({
  type: UNSUBSCRIPTION_ERROR,
  payload: error,
  error: true,
});

export const showListingRequest = () => ({
  type: SHOW_LISTING_REQUEST
});

export const showListingSuccess = () => ({
  type: SHOW_LISTING_SUCCESS
});

export const showListingError = e => ({
  type: SHOW_LISTING_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const unsubscribe = ({ customerId, listingId, unsubscribeToken }) => (dispatch, getState, sdk) => {
  if (unsubscriptionInProgress(getState())) {
    return Promise.reject(new Error('Item unsubscription already in progress'));
  }
  dispatch(unsubscriptionRequest());

  // Note that the thunk does not reject when the unsubscription fails, it
  // just dispatches the login error action.
  return unsubscribeListing({ customerId, listingId: listingId.uuid, unsubscribeToken })
    .then(() => dispatch(unsubscriptionSuccess()))
    .catch(e => dispatch(unsubscriptionError(storableError(e))));
};

export const showListing = (listingId) => (dispatch, getState, sdk) => {
  dispatch(showListingRequest());
  const params = {
    id: listingId,
    include: ['author', 'author.profileImage', 'images'],
    'fields.image': [
      // Listing page
      'variants.landscape-crop',
      'variants.landscape-crop2x',
      'variants.landscape-crop4x',
      'variants.landscape-crop6x',

      // Social media
      'variants.facebook',
      'variants.twitter',

      // Image carousel
      'variants.scaled-small',
      'variants.scaled-medium',
      'variants.scaled-large',
      'variants.scaled-xlarge',

      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
  };
  return sdk.listings.show(params)
    .then(data => {
      dispatch(showListingSuccess());
      dispatch(addMarketplaceEntities(data));
      return data;
    })
    .catch(e => {
      dispatch(showListingError(storableError(e)));
    });
};

export const loadData = (params) => dispatch => {
  const listingId = new UUID(params.id);
  return dispatch(showListing(listingId));
};
