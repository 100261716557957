import React, { Component } from 'react';
import { string, bool, arrayOf, array, func } from 'prop-types';
import { compose } from 'redux';
import arrayMutators from 'final-form-arrays'
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import moment from 'moment';
import { propTypes, YES } from '../../util/types';
import config from '../../config';
import {
  Form,
  IconSpinner,
  PrimaryButton,
  FieldCurrencyInput,
  NamedLink,
  Button,
  FieldRadioButtonGroup,
} from '../../components';
import { types as sdkTypes } from '../../util/sdkLoader';
import { formatMoney, createMoney } from '../../util/currency';

import css from './BookingDatesForm.module.css';

const { Money } = sdkTypes;

const identity = v => v;

export class BookingDatesFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { focusedInput: null, showModal: false };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.onFocusedInputChange = this.onFocusedInputChange.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.getShipping = this.getShipping.bind(this)
    this.getShippingPrice = this.getShippingPrice.bind(this)
  }

  getShipping() {
    if (!this.props.listing.attributes.publicData) {
      return null
    }
    const {
      domestic,
      international
    } = this.props.listing.attributes.publicData
    return {
      domestic, international
    }
  }

  getShippingPrice() {
    const shipping = this.getShipping()
    if (!shipping) {
      return null
    }
    return {
      domestic: shipping.domestic ? formatMoney(
        this.props.intl,
        createMoney(shipping.domestic)
      ) : null,
      international: shipping.international ? formatMoney(
        this.props.intl,
        createMoney(shipping.international)
      ) : null
    }
  }

  // Function that can be passed to nested components
  // so that they can notify this component when the
  // focused input changes.
  onFocusedInputChange(focusedInput) {
    this.setState({ focusedInput });
  }

  handleFormSubmit(e) {
    this.props.onSubmit(e);
  }

  // When the values of the form are updated we need to fetch
  // lineItems from FTW backend for the EstimatedTransactionMaybe
  // In case you add more fields to the form, make sure you add
  // the values here to the bookingData object.
  handleOnChange(formValues) {
    const offer = formValues.values && formValues.values.offer ? formValues.values.offer : {};
    const listingId = this.props.listingId;
    const isOwnListing = this.props.isOwnListing;

    if (offer && !this.props.fetchLineItemsInProgress) {
      this.props.onFetchTransactionLineItems({
        bookingData: {},
        listingId,
        isOwnListing,
      });
    }
  }

  render() {
    const { rootClassName, className, price: unitPrice, showShipping, ...rest } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    if (!unitPrice) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingDatesForm.listingPriceMissing" />
          </p>
        </div>
      );
    }
    if (unitPrice.currency !== config.currency) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingDatesForm.listingCurrencyInvalid" />
          </p>
        </div>
      );
    }

    return (
      <FinalForm
        {...rest}
        unitPrice={unitPrice}
        mutators={arrayMutators}
        onSubmit={this.handleFormSubmit}
        render={fieldRenderProps => {
          const {
            endDatePlaceholder,
            startDatePlaceholder,
            formId,
            handleSubmit,
            intl,
            isOwnListing,
            submitButtonWrapperClassName,
            unitType,
            values,
            timeSlots,
            fetchTimeSlotsError,
            lineItems,
            fetchLineItemsInProgress,
            fetchLineItemsError,
            editParams,
            handleSubmitOffer,
            acceptOffer,
            acceptBuyItNow,
            form,
          } = fieldRenderProps;
          const offer = values && values.offer ? values.offer : 0;

          const timeSlotsError = fetchTimeSlotsError ? (
            <p className={css.sideBarError}>
              <FormattedMessage id="BookingDatesForm.timeSlotsError" />
            </p>
          ) : null;

          // This is the place to collect breakdown estimation data.
          // Note: lineItems are calculated and fetched from FTW backend
          // so we need to pass only booking data that is needed otherwise
          // If you have added new fields to the form that will affect to pricing,
          // you need to add the values to handleOnChange function
          // const bookingData = offer
          //   ? {
          //       unitType,
          //       offer,
          //     }
          //   : null;

          // const showEstimatedBreakdown =
          //   bookingData && lineItems && !fetchLineItemsError && !fetchLineItemsInProgress;

          // const bookingInfoMaybe = showEstimatedBreakdown ? (
          //   <div className={css.priceBreakdownContainer}>
          //     {/* <h3 className={css.priceBreakdownTitle}>
          //       <FormattedMessage id="BookingDatesForm.priceBreakdownTitle" />
          //     </h3> */}
          //     <EstimatedBreakdownMaybe bookingData={bookingData} lineItems={lineItems} />
          //   </div>
          // ) : null;

          const loadingSpinnerMaybe = fetchLineItemsInProgress ? (
            <IconSpinner className={css.spinner} />
          ) : null;

          const bookingInfoErrorMaybe = fetchLineItemsError ? (
            <span className={css.sideBarError}>
              <FormattedMessage id="BookingDatesForm.fetchLineItemsError" />
            </span>
          ) : null;

          const dateFormatOptions = {
            weekday: 'short',
            month: 'short',
            day: 'numeric',
          };

          const now = moment();
          const today = now.startOf('day').toDate();
          const tomorrow = now
            .startOf('day')
            .add(1, 'days')
            .toDate();
          const startDatePlaceholderText =
            startDatePlaceholder || intl.formatDate(today, dateFormatOptions);
          const endDatePlaceholderText =
            endDatePlaceholder || intl.formatDate(tomorrow, dateFormatOptions);
          const submitButtonClasses = classNames(
            submitButtonWrapperClassName || css.submitButtonWrapper
          );

          const listOfShipping = showShipping && !!this.getShippingPrice() ? [
            this.getShippingPrice().domestic && {
              key: 'domestic',
              label: `Domestic ${this.getShippingPrice().domestic}`
            },
            this.getShippingPrice().international && {
              key: 'international',
              label: `International ${this.getShippingPrice().international}`
            },
          ] : [];

          
          return (
            <Form onSubmit={handleSubmit} className={classes}>
              {timeSlotsError}
              <FormSpy
                subscription={{ values: true }}
                onChange={values => {
                  this.handleOnChange(values);
                }}
              />
              {!isOwnListing && acceptOffer === YES && (
                <FieldCurrencyInput
                  id="offer"
                  name="offer"
                  className={css.makeOffer}
                  currencyConfig={config.currencyConfig}
                  label="Make Offer"
                />
              )}
              {/* {bookingInfoMaybe} */}
              {/* {loadingSpinnerMaybe} */}
              {/* {bookingInfoErrorMaybe} */}
              {/* <p className={css.smallPrint}>
                <FormattedMessage
                  id={
                    isOwnListing
                      ? 'BookingDatesForm.ownListing'
                      : 'BookingDatesForm.youWontBeChargedInfo'
                  }
                />
              </p> */}

              {!isOwnListing && (
                <div>
                  {showShipping && !!this.getShippingPrice() && <FieldRadioButtonGroup
                    id='shipping'
                    name='shipping'
                    label='If you do not select a shipping option, you are responsible for collecting the item.'
                    options={listOfShipping}
                  />}
                  <div className={submitButtonClasses}>
                    {acceptOffer === YES && (
                      <PrimaryButton
                        disabled={!offer}
                        className={css.makeOffer}
                        type="submit"
                        onClick={() => {
                          form.change("button", "offer");
                        }}
                      >
                        <FormattedMessage id="MakeOffer.ctaButtonMessage" />
                      </PrimaryButton>
                    )}
                    {acceptBuyItNow === YES && (
                      <PrimaryButton type="submit" onClick={() => {
                        form.change("button", "buyNow");
                      }}>
                        <FormattedMessage id="BookingDatesForm.requestToBook" />
                      </PrimaryButton>
                    )}
                  </div>
                </div>
              )}
              {isOwnListing && (
                <div className={submitButtonClasses}>
                  <NamedLink name="EditListingPage" params={editParams}>
                    <PrimaryButton>
                      <FormattedMessage id="BookingDatesForm.editListing" />
                    </PrimaryButton>
                  </NamedLink>
                </div>
              )}
            </Form>
          );
        }}
      />
    );
  }
}

BookingDatesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  price: null,
  isOwnListing: false,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  timeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
  showShipping: false,
};

BookingDatesFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  unitType: propTypes.bookingUnitType.isRequired,
  price: propTypes.money,
  isOwnListing: bool,
  timeSlots: arrayOf(propTypes.timeSlot),

  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,

  showShipping: bool,
};

const BookingDatesForm = compose(injectIntl)(BookingDatesFormComponent);
BookingDatesForm.displayName = 'BookingDatesForm';

export default BookingDatesForm;
