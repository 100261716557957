import React, { Fragment,  } from 'react';
import { bool, func, shape, string, array } from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes, SESSION_FORM_KEYS, SERVICE_DOCUMENT_UPLOAD } from '../../util/types';
import config from '../../config';
import { useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import { Button, Form, FieldTextInput, FieldDateInput, IconSpinner } from '../../components';
import truncate from 'lodash/truncate';
import RemoveFieldButton from '../../components/RemoveFieldButton/RemoveFieldButton';
import { requestDocumentUpload, requestDocumentRemove } from '../../containers/EditListingPage/EditListingPage.duck';
import { FieldArray } from 'react-final-form-arrays';

import css from './EditListingServiceForm.module.css';

const MAX_FIELD_QTY = 5;
const ACCEPT_DOCS = 'application/pdf, application/msword, image/*';

const EditListingServiceFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        disabled,
        ready,
        rootClassName,
        className,
        name,
        handleSubmit,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        form,
        values,
        saveDataToSessionStorage,
        serviceDocuments
      } = formRenderProps;

      const classes = classNames(rootClassName || css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = disabled || submitInProgress;
      const dispatch = useDispatch();
      const { push } = form.mutators;
      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingServiceForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingServiceForm.showListingFailed" />
        </p>
      ) : null;

      const lastServiceLabel = (
        <span>
          <FormattedMessage id='EditListingConsignForm.lastServiceLabel' />
          <span className={css.optionalLabel}><FormattedMessage id='EditListingConsignForm.optionalLabel' /></span>
        </span>
      );
      const serviceTechnicianLabel = (
        <span>
          <FormattedMessage id='EditListingConsignForm.serviceTechnicianLabel' />
          <span className={css.optionalLabel}><FormattedMessage id='EditListingConsignForm.optionalLabel' /></span>
        </span>
      );
      const serviceDocumentLabel = (
        <span>
          <FormattedMessage id='EditListingConsignForm.serviceDocumentLabel' />
          <span className={css.optionalLabel}><FormattedMessage id='EditListingConsignForm.optionalLabel' /></span>
        </span>
      );
      const chooseFileText = (
        <span className={css.chooseImageText}>
          <span className={css.chooseImage}>
            <FormattedMessage id="EditListingConsignForm.chooseFile" />
          </span>
        </span>
      );

      const loadingSpinner = (
        <div className={css.loader}>
          <IconSpinner />
        </div>
      );

      const onRemoveServiceDocument = (url, removeIndex) => {
        form.change("service.document", null);
        serviceDocuments.splice(removeIndex, 1);
        dispatch(requestDocumentRemove(url));
      }

      const canDisplayServiceDocField = (index) => {
        return index < MAX_FIELD_QTY;
      }

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}
          <FieldDateInput
            id="service.dateTime"
            name="service.dateTime"
            label={lastServiceLabel}
            placeholderText={moment().format('MM/DD/YYYY')}
            className={css.dateInput}
          />
          
          <FieldTextInput
            id="service.technician"
            name="service.technician"
            label={serviceTechnicianLabel}
            className={css.technician}
            type="textarea"
          />

          <FieldArray name="serviceDocuments" >
            {(fieldProps) => {
              const { fields } = fieldProps;
              return fields.map((name, index) => {
                return (
                  <Field
                    key={index}
                    id={name}
                    name={name}
                    accept={ACCEPT_DOCS}
                    parse={x => x}
                    label={chooseFileText}
                    type="file"
                  >
                    {fieldprops => {
                      const { accept, input, label, disabled: fieldDisabled } = fieldprops;
                      const { name, type } = input;
                      const onChange = e => {
                        const file = e.target.files[0];
                        if (file) {
                          saveDataToSessionStorage(SESSION_FORM_KEYS.EDIT_LISTING_SERVICE, values);
                          dispatch(requestDocumentUpload({ file, type: SERVICE_DOCUMENT_UPLOAD }));
                          form.change(name, true);
                          form.blur(name);
                        }
                      };
                      const inputProps = { accept, id: name, name, onChange, type };
                      return (
                        canDisplayServiceDocField(index) && (
                          <Fragment>
                            <label>{serviceDocumentLabel}</label>
                            <div className={css.addFileFullWidthWrapper}>
                              <div className={css.aspectRatioFileWrapper}>
                                {fieldDisabled ? null : (
                                  <input {...inputProps} className={css.addFileInput} />
                                )}
                                {serviceDocuments && serviceDocuments[index] && serviceDocuments[index].url
                                ? (
                                  <div className={css.uploadedFileName}>
                                    <span>{truncate(serviceDocuments[index].name, { length: 20 })}</span>
                                    <RemoveFieldButton 
                                      className={css.removeFileUploadRowButton} 
                                      onClick={() => {
                                        fields.remove(index);
                                        onRemoveServiceDocument(serviceDocuments[index].url, index)}
                                      } 
                                    />
                                  </div>
                                )
                                : (updateInProgress
                                  ? loadingSpinner
                                  : (
                                    <label htmlFor={name} className={css.addFile}>
                                      {label}
                                    </label>
                                  )
                                )}
                              </div>
                            </div>
                          </Fragment>
                        )
                      );
                    }}
                  </Field>
                  );
                })
              }
            }
          </FieldArray>

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingServiceFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingServiceFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  name: string.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  saveDataToSessionStorage: func.isRequired,
  serviceDocuments: array.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

const EditListingServiceForm = EditListingServiceFormComponent;

export default EditListingServiceForm;
