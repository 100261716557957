import React, { Fragment } from 'react';
import { FieldTextInput } from '../../../components';
import { string, array } from 'prop-types';
import { intlShape } from '../../../util/reactIntl';
import CustomSellingPairSelectFieldMaybe from '../../EditListingMicDetailsForm/CustomSellingPairSelectFieldMaybe';
import CustomIssueSelect from '../../EditListingMicDetailsForm/CustomIssueSelect';
import CustomConditionSelect from '../../EditListingMicDetailsForm/CustomConditionSelect';
import CustomIssueSelectFieldMaybe from '../../EditListingMicDetailsForm/CustomIssueSelectFieldMaybe';
import CustomMicPairSelectFieldMaybe from '../../EditListingMicDetailsForm/CustomMicpairSelectFieldMaybe';
import { YES } from '../../../util/types';

import css from '../EditListingConsignForm.module.css';


export const FieldSetMicrophoneInformation = props => {
  const { 
    intl, 
    sellingPairOptions,
    sellingPairValue, 
    sequentSerialOptions, 
    conditionsMicPairOptions,
    issuesMicPairOptions,
    micPairOptions,
  }  = props;

   // Labels
   const serialLabel = intl.formatMessage({
    id: 'EditListingConsignForm.serialLabel',
  });
  const brandLabel = intl.formatMessage({
    id: 'EditListingConsignForm.brandLabel',
  });
  const modelLabel = intl.formatMessage({
    id: 'EditListingConsignForm.modelLabel',
  });
  const yearLabel = intl.formatMessage({
    id: 'EditListingConsignForm.yearLabel',
  });
  const colorLabel = intl.formatMessage({
    id: 'EditListingConsignForm.colorLabel',
  });
  const makeOrModelLabel = intl.formatMessage({
    id: 'EditListingConsignForm.makeOrModelLabel',
  });
  const skuLabel = intl.formatMessage({
    id: 'EditListingConsignForm.skuLabel'
  });

  return (
  <Fragment>
    <CustomSellingPairSelectFieldMaybe
      id="micDetails.sellingPair"
      name="micDetails.sellingPair"
      sellingPair={sellingPairOptions}
      intl={intl}
    />

    <CustomSellingPairSelectFieldMaybe
      id="micDetails.sequentSerial"
      name="micDetails.sequentSerial"
      sequentSerial={sequentSerialOptions}
      intl={intl}
    />

    <FieldTextInput
      id="micDetails.serialMicPair"
      name="micDetails.serialMicPair"
      className={css.micpair}
      type="micDetails.textarea"
      label={serialLabel}
    />

    <FieldTextInput
      id="micDetails.skuMicPair"
      name="micDetails.skuMicPair"
      className={css.micpair}
      type="text"
      label={skuLabel}
    />

    <FieldTextInput
      id="micDetails.brandMicPair"
      name="micDetails.brandMicPair"
      className={css.micpair}
      type="micDetails.textarea"
      label={brandLabel}
    />

    <FieldTextInput
      id="micDetails.modelMicPair"
      name="micDetails.modelMicPair"
      className={css.micpair}
      type="textarea"
      label={modelLabel}
    />
    {sellingPairValue &&
      (sellingPairValue === YES ? (
        <Fragment>
          <div className={css.wrapper}>
            <div className={css.content}>
              <h2 className={css.titleContent}>A</h2>
              <FieldTextInput
                id="micDetails.yearMicPairA"
                name="micDetails.yearMicPairA"
                className={css.micpair}
                type="text"
                label={yearLabel}
              />
              <FieldTextInput
                id="micDetails.colorMicPairA"
                name="micDetails.colorMicPairA"
                className={css.micpair}
                type="textarea"
                label={colorLabel}
              />
              <CustomConditionSelect
                id="micDetails.conditionMicPairA"
                name="micDetails.conditionMicPairA"
                conditionMicPairA={conditionsMicPairOptions}
                intl={intl}
              />
              <br />
              <CustomIssueSelect
                id="micDetails.issuesMicPairOptionsA"
                name="micDetails.issuesMicPairOptionsA"
                issueMicPairA={issuesMicPairOptions}
                intl={intl}
              />
            </div>
            <div className={css.content}>
              <h2 className={css.titleContent}>B</h2>
              <FieldTextInput
                id="micDetails.yearMicPairB"
                name="micDetails.yearMicPairB"
                className={css.micpair}
                type="text"
                label={yearLabel}
              />
              <FieldTextInput
                id="micDetails.colorMicPairB"
                name="micDetails.colorMicPairB"
                className={css.micpair}
                type="textarea"
                label={colorLabel}
              />
              <CustomConditionSelect
                id="micDetails.conditionMicPairB"
                name="micDetails.conditionMicPairB"
                conditionMicPairB={conditionsMicPairOptions}
                intl={intl}
              />
              <br />
              <CustomIssueSelect
                id="micDetails.issueMicPairB"
                name="micDetails.issueMicPairB"
                issueMicPairA={issuesMicPairOptions}
                intl={intl}
              />
            </div>
          </div>
          <div className={css.wrapper}>
            <h2 className={css.titleContent}>TUBE</h2>
            <div className={css.content}>
              <CustomIssueSelectFieldMaybe
                id="micDetails.issueTubeA"
                name="micDetails.issueTubeA"
                issues={issuesMicPairOptions}
                intl={intl}
              />
              <FieldTextInput
                id="micDetails.modelTubeA"
                name="micDetails.modelTubeA"
                className={css.micpair}
                type="textarea"
                label={makeOrModelLabel}
                //Make/Model
              />
            </div>
            <div className={css.content}>
              <CustomIssueSelectFieldMaybe
                id="micDetails.issueTubeB"
                name="micDetails.issueTubeB"
                issues={issuesMicPairOptions}
                intl={intl}
              />
              <FieldTextInput
                id="micDetails.modelTubeB"
                name="micDetails.modelTubeB"
                className={css.micpair}
                type="textarea"
                label={makeOrModelLabel}
              />
            </div>
          </div>
          <div className={css.wrapper}>
            <h2 className={css.titleContent}>CAPSULE</h2>
            <div className={css.content}>
              <CustomIssueSelectFieldMaybe
                id="micDetails.issueCapsuleA"
                name="micDetails.issueCapsuleA"
                issues={issuesMicPairOptions}
                intl={intl}
              />
              <FieldTextInput
                id="micDetails.modelCapsuleA"
                name="micDetails.modelCapsuleA"
                className={css.micpair}
                type="textarea"
                label={makeOrModelLabel}
              />
            </div>
            <div className={css.content}>
              <CustomIssueSelectFieldMaybe
                id="micDetails.issueCapsuleB"
                name="micDetails.issueCapsuleB"
                issues={issuesMicPairOptions}
                intl={intl}
              />
              <FieldTextInput
                id="micDetails.modelCapsuleB"
                name="micDetails.modelCapsuleB"
                className={css.micpair}
                type="textarea"
                label={makeOrModelLabel}
              />
            </div>
          </div>
          <div className={css.wrapper}>
            <h2 className={css.titleContent}>TRANSFORMER</h2>
            <div className={css.content}>
              <CustomIssueSelectFieldMaybe
                id="micDetails.issueTransformerA"
                name="micDetails.issueTransformerA"
                issues={issuesMicPairOptions}
                intl={intl}
              />
              <FieldTextInput
                id="micDetails.modelTransformerA"
                name="micDetails.modelTransformerA"
                className={css.micpair}
                type="textarea"
                label={makeOrModelLabel}
              />
            </div>
            <div className={css.content}>
              <CustomIssueSelectFieldMaybe
                id="micDetails.issueTransformerB"
                name="micDetails.issueTransformerB"
                issues={issuesMicPairOptions}
                intl={intl}
              />
              <FieldTextInput
                id="micDetails.modelTransformerB"
                name="micDetails.modelTransformerB"
                className={css.micpair}
                type="textarea"
                label={makeOrModelLabel}
              />
            </div>
          </div>
          <div className={css.wrapper}>
            <h2 className={css.titleContent}>POWER SUPPLY</h2>
            <div className={css.content}>
              <CustomMicPairSelectFieldMaybe
                id="micDetails.powerSupplyA"
                name="micDetails.powerSupplyA"
                powerSupplyA={micPairOptions}
                intl={intl}
              />
              <CustomIssueSelectFieldMaybe
                id="micDetails.issueSupplyA"
                name="micDetails.issueSupplyA"
                issues={issuesMicPairOptions}
                intl={intl}
              />
              <FieldTextInput
                id="micDetails.modelSupplyA"
                name="micDetails.modelSupplyA"
                className={css.micpair}
                type="textarea"
                label={makeOrModelLabel}
              />
            </div>
            <div className={css.content}>
              <CustomMicPairSelectFieldMaybe
                id="micDetails.powerSupplyB"
                name="micDetails.powerSupplyB"
                powerSupplyB={micPairOptions}
                intl={intl}
              />
              <CustomIssueSelectFieldMaybe
                id="micDetails.issueSupplyB"
                name="micDetails.issueSupplyB"
                issues={issuesMicPairOptions}
                intl={intl}
              />
              <FieldTextInput
                id="micDetails.modelSupplyB"
                name="micDetails.modelSupplyB"
                className={css.micpair}
                type="textarea"
                label={makeOrModelLabel}
              />
            </div>
          </div>
          <div className={css.wrapper}>
            <h2 className={css.titleContent}>CABLING</h2>
            <div className={css.content}>
              <CustomMicPairSelectFieldMaybe
                id="micDetails.cablingA"
                name="micDetails.cablingA"
                cablingA={micPairOptions}
                intl={intl}
              />
              <CustomIssueSelectFieldMaybe
                id="micDetails.issueCablingA"
                name="micDetails.issueCablingA"
                issues={issuesMicPairOptions}
                intl={intl}
              />
              <FieldTextInput
                id="micDetails.modelCablingA"
                name="micDetails.modelCablingA"
                className={css.micpair}
                type="textarea"
                label={makeOrModelLabel}
              />
            </div>
            <div className={css.content}>
              <CustomMicPairSelectFieldMaybe
                id="micDetails.cablingB"
                name="micDetails.cablingB"
                cablingB={micPairOptions}
                intl={intl}
              />
              <CustomIssueSelectFieldMaybe
                id="micDetails.issueCablingB"
                name="micDetails.issueCablingB"
                issues={issuesMicPairOptions}
                intl={intl}
              />
              <FieldTextInput
                id="micDetails.modelCablingB"
                name="micDetails.modelCablingB"
                className={css.micpair}
                type="textarea"
                label={makeOrModelLabel}
              />
            </div>
          </div>
          <div className={css.wrapper}>
            <h2 className={css.titleContent}>SHOCKMOUNT</h2>
            <div className={css.content}>
              <CustomMicPairSelectFieldMaybe
                id="micDetails.shockMountA"
                name="micDetails.shockMountA"
                shockMountA={micPairOptions}
                intl={intl}
              />
              <CustomIssueSelectFieldMaybe
                id="micDetails.issueShockMountA"
                name="micDetails.issueShockMountA"
                issues={issuesMicPairOptions}
                intl={intl}
              />
              <FieldTextInput
                id="micDetails.modelShockMountA"
                name="micDetails.modelShockMountA"
                className={css.micpair}
                type="textarea"
                label={makeOrModelLabel}
              />
            </div>
            <div className={css.content}>
              <CustomMicPairSelectFieldMaybe
                id="micDetails.shockMountB"
                name="micDetails.shockMountB"
                shockMountB={micPairOptions}
                intl={intl}
              />
              <CustomIssueSelectFieldMaybe
                id="micDetails.issueShockMountB"
                name="micDetails.issueShockMountB"
                issues={issuesMicPairOptions}
                intl={intl}
              />
              <FieldTextInput
                id="micDetails.modelShockMountB"
                name="micDetails.modelShockMountB"
                className={css.micpair}
                type="textarea"
                label={makeOrModelLabel}
              />
            </div>
          </div>
          <div className={css.wrapper}>
            <h2 className={css.titleContent}>MIC CASE</h2>
            <div className={css.content}>
              <CustomMicPairSelectFieldMaybe
                id="micDetails.micCaseA"
                name="micDetails.micCaseA"
                micCaseA={micPairOptions}
                intl={intl}
              />
              <CustomIssueSelectFieldMaybe
                id="micDetails.issueMicCaseA"
                name="micDetails.issueMicCaseA"
                issues={issuesMicPairOptions}
                intl={intl}
              />
              <FieldTextInput
                id="micDetails.modelMicCaseA"
                name="micDetails.modelMicCaseA"
                className={css.micpair}
                type="textarea"
                label={makeOrModelLabel}
              />
            </div>
            <div className={css.content}>
              <CustomMicPairSelectFieldMaybe
                id="micDetails.micCaseB"
                name="micDetails.micCaseB"
                micCaseB={micPairOptions}
                intl={intl}
              />
              <CustomIssueSelectFieldMaybe
                id="micDetails.issueMicCaseB"
                name="micDetails.issueMicCaseB"
                issues={issuesMicPairOptions}
                intl={intl}
              />
              <FieldTextInput
                id="micDetails.modelMicCaseB"
                name="micDetails.modelMicCaseB"
                className={css.micpair}
                type="textarea"
                label={makeOrModelLabel}
              />
            </div>
          </div>
          <div className={css.wrapper}>
            <h2 className={css.titleContent}>CARRYING CASE</h2>
            <div className={css.content}>
              <CustomMicPairSelectFieldMaybe
                id="micDetails.carryCaseA"
                name="micDetails.carryCaseA"
                carryCaseA={micPairOptions}
                intl={intl}
              />
              <CustomIssueSelectFieldMaybe
                id="micDetails.issueCarryCaseA"
                name="micDetails.issueCarryCaseA"
                issues={issuesMicPairOptions}
                intl={intl}
              />
              <FieldTextInput
                id="micDetails.modelCarryCaseA"
                name="micDetails.modelCarryCaseA"
                className={css.micpair}
                type="textarea"
                label={makeOrModelLabel}
              />
            </div>
            <div className={css.content}>
              <CustomMicPairSelectFieldMaybe
                id="micDetails.carryCaseB"
                name="micDetails.carryCaseB"
                carryCaseB={micPairOptions}
                intl={intl}
              />
              <CustomIssueSelectFieldMaybe
                id="micDetails.issueCarryCaseB"
                name="micDetails.issueCarryCaseB"
                issues={issuesMicPairOptions}
                intl={intl}
              />
              <FieldTextInput
                id="micDetails.modelCarryCaseB"
                name="micDetails.modelCarryCaseB"
                className={css.micpair}
                type="textarea"
                label={makeOrModelLabel}
              />
            </div>
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <FieldTextInput
            id="micDetails.yearMicPair"
            name="micDetails.yearMicPair"
            className={css.micpair}
            type="text"
            label={yearLabel}
          />

          <FieldTextInput
            id="micDetails.colorMicPair"
            name="micDetails.colorMicPair"
            className={css.micpair}
            type="textarea"
            label={colorLabel}
          />

          <CustomConditionSelect
            id="micDetails.conditionMicPair"
            name="micDetails.conditionMicPair"
            condition={conditionsMicPairOptions}
            intl={intl}
          />
          <br />
          <CustomIssueSelect
            id="micDetails.issueMicPair"
            name="micDetails.issueMicPair"
            issue={issuesMicPairOptions}
            intl={intl}
          />
          <h2 className={css.titleContent}>TUBE</h2>
          <CustomIssueSelectFieldMaybe
            id="micDetails.issueTube"
            name="micDetails.issueTube"
            issues={issuesMicPairOptions}
            intl={intl}
          />
          <FieldTextInput
            id="micDetails.modelTube"
            name="micDetails.modelTube"
            className={css.micpair}
            type="micDetails.textarea"
            label={makeOrModelLabel}
          />
          <h2 className={css.titleContent}>CAPSULE</h2>
          <CustomIssueSelectFieldMaybe
            id="micDetails.issueCapsule"
            name="micDetails.issueCapsule"
            issues={issuesMicPairOptions}
            intl={intl}
          />
          <FieldTextInput
            id="micDetails.modelCapsule"
            name="micDetails.modelCapsule"
            className={css.micpair}
            type="textarea"
            label={makeOrModelLabel}
          />
          <h2 className={css.titleContent}>TRANSFORMER</h2>
          <CustomIssueSelectFieldMaybe
            id="micDetails.issueTransformer"
            name="micDetails.issueTransformer"
            issues={issuesMicPairOptions}
            intl={intl}
          />
          <FieldTextInput
            id="micDetails.modelTransformer"
            name="micDetails.modelTransformer"
            className={css.micpair}
            type="textarea"
            label={makeOrModelLabel}
          />
          <h2 className={css.titleContent}>POWER SUPPLY</h2>
          <CustomMicPairSelectFieldMaybe
            id="micDetails.powerSupply"
            name="micDetails.powerSupply"
            powerSupply={micPairOptions}
            intl={intl}
          />

          <CustomIssueSelectFieldMaybe
            id="micDetails.issueSupply"
            name="micDetails.issueSupply"
            issues={issuesMicPairOptions}
            intl={intl}
          />
          <FieldTextInput
            id="micDetails.modelSupply"
            name="micDetails.modelSupply"
            className={css.micpair}
            type="textarea"
            label={makeOrModelLabel}
          />
          <h2 className={css.titleContent}>CABLING</h2>
          <CustomMicPairSelectFieldMaybe
            id="micDetails.cabling"
            name="micDetails.cabling"
            cabling={micPairOptions}
            intl={intl}
          />
          <CustomIssueSelectFieldMaybe
            id="micDetails.issueCabling"
            name="micDetails.issueCabling"
            issues={issuesMicPairOptions}
            intl={intl}
          />
          <FieldTextInput
            id="micDetails.modelCabling"
            name="micDetails.modelCabling"
            className={css.micpair}
            type="textarea"
            label={makeOrModelLabel}
          />
          <h2 className={css.titleContent}>SHOCKMOUNT</h2>
          <CustomMicPairSelectFieldMaybe
            id="micDetails.shockMount"
            name="micDetails.shockMount"
            shockMount={micPairOptions}
            intl={intl}
          />
          <CustomIssueSelectFieldMaybe
            id="micDetails.issueShockMount"
            name="micDetails.issueShockMount"
            issues={issuesMicPairOptions}
            intl={intl}
          />
          <FieldTextInput
            id="micDetails.modelShockMount"
            name="micDetails.modelShockMount"
            className={css.micpair}
            type="textarea"
            label={makeOrModelLabel}
          />
          <h2 className={css.titleContent}>MIC CASE</h2>
          <CustomMicPairSelectFieldMaybe
            id="micDetails.micCase"
            name="micDetails.micCase"
            micCase={micPairOptions}
            intl={intl}
          />
          <CustomIssueSelectFieldMaybe
            id="micDetails.issueMicCase"
            name="micDetails.issueMicCase"
            issues={issuesMicPairOptions}
            intl={intl}
          />
          <FieldTextInput
            id="micDetails.modelMicCase"
            name="micDetails.modelMicCase"
            className={css.micpair}
            type="textarea"
            label={makeOrModelLabel}
          />
          <h2 className={css.titleContent}>CARRYING CASE</h2>
          <CustomMicPairSelectFieldMaybe
            id="micDetails.carryCase"
            name="micDetails.carryCase"
            carryCase={micPairOptions}
            intl={intl}
          />
          <CustomIssueSelectFieldMaybe
            id="micDetails.issueCarryCase"
            name="micDetails.issueCarryCase"
            issues={issuesMicPairOptions}
            intl={intl}
          />
          <FieldTextInput
            id="micDetails.modelCarryCase"
            name="micDetails.modelCarryCase"
            className={css.micpair}
            type="textarea"
            label={makeOrModelLabel}
          />
        </Fragment>
      ))}
    </Fragment>
  )
}

FieldSetMicrophoneInformation.defaultProps = {
  sellingPairOptions: [],
  sellingPairValue: '',
  sequentSerialOptions: [],
  conditionsMicPairOptions: [],
  issuesMicPairOptions: [],
  originalOptions: [],
  micPairOptions: [],
}

FieldSetMicrophoneInformation.propTypes = {
  intl: intlShape.isRequired,
  sellingPairOptions: array.isRequired,
  sellingPairValue: string.isRequired,
  sequentSerialOptions: array.isRequired, 
  conditionsMicPairOptions: array.isRequired,
  issuesMicPairOptions: array.isRequired,
  originalOptions: array.isRequired,
  micPairOptions: array.isRequired,
};

export default FieldSetMicrophoneInformation;

 