import React, { useState } from 'react';
import { func, string } from 'prop-types';
import classNames from 'classnames';
import { injectIntl, intlShape, FormattedMessage } from '../../util/reactIntl';
import {
  IconArrowHead,
  IconCheckmark,
  InlineTextButton,
  Menu,
  MenuLabel,
  MenuItem,
  MenuContent,
} from '..';
import { EXISTING_ADDRESS, NEW_ADDRESS } from '../../util/types';
import truncate from 'lodash/truncate';

import css from './SavedShippingAddress.module.css';

const SavedShippingAddress = props => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [active, setActive] = useState(EXISTING_ADDRESS);

  const {
    rootClassName,
    className,
    intl,
    shippingAddress,
    onChange,
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  const existingShippingAddress = (
    <div className={css.existingAddress}>
      {`${shippingAddress.addressLine1}, ${shippingAddress.addressLine2 ? shippingAddress.addressLine2 + ', ' : ''} ${shippingAddress.city}, ${shippingAddress.state ? shippingAddress.state + ', ' : ''} ${shippingAddress.postal}, ${shippingAddress.country}`}
    </div>
  );

  const existingShippingAddressPreview = (
    <div className={css.existingAddress}>
      {truncate(
        `${shippingAddress.addressLine1}, ${shippingAddress.addressLine2 ? shippingAddress.addressLine2 + ', ' : ''} ${shippingAddress.city}, ${shippingAddress.state ? shippingAddress.state + ', ' : ''} ${shippingAddress.postal}, ${shippingAddress.country}`, 
        { length: 35 }
      )}
    </div>
  )

  const newShippingAddress = <FormattedMessage id='SavedShippingAddress.newShippingAddressText' />;

  const handleClick = item => e => {
    // Clicking buttons inside a form will call submit
    e.preventDefault();
    e.stopPropagation();

    setActive(item);
    setMenuOpen(false);
    if (onChange) {
      onChange(item);
    }
  };

  const onToggleActive = isOpen => {
    setMenuOpen(isOpen);
  };

  const iconArrowClassName = menuOpen ? css.IconArrowAnimation : null;

  return (
    <div className={classes}>
      <Menu className={css.menu} isOpen={menuOpen} onToggleActive={onToggleActive} useArrow={false}>
        <MenuLabel className={css.menuLabel}>
          <div className={css.menuLabelWrapper}>
            {active === EXISTING_ADDRESS ? existingShippingAddressPreview : newShippingAddress}
            <span>
              <IconArrowHead
                direction="down"
                size="small"
                rootClassName={css.iconArrow}
                className={iconArrowClassName}
              />
            </span>
          </div>
        </MenuLabel>

        <MenuContent rootClassName={css.menuContent}>
          <MenuItem key="first item" className={css.menuItem}>
            <IconCheckmark
              className={active === EXISTING_ADDRESS ? css.iconCheckmark : css.iconCheckmarkHidden}
              size="small"
            />
            <InlineTextButton className={css.menuText} onClick={handleClick(EXISTING_ADDRESS)}>
              {existingShippingAddress}
            </InlineTextButton>
          </MenuItem>
          <MenuItem key="second item" className={css.menuItem}>
            <IconCheckmark
              className={active === NEW_ADDRESS ? css.iconCheckmark : css.iconCheckmarkHidden}
              size="small"
            />
            <InlineTextButton
              className={css.menuText}
              onClick={handleClick(NEW_ADDRESS)}
            >
              {newShippingAddress}
            </InlineTextButton>
          </MenuItem>
        </MenuContent>
      </Menu>
    </div>
  );
};

SavedShippingAddress.defaultProps = {
  rootClassName: null,
  className: null,
  card: null,
  onChange: null,
};

SavedShippingAddress.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
  onChange: func,
};

export default injectIntl(SavedShippingAddress);
