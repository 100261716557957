import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './PrivacyPolicy.module.css';

const PrivacyPolicy = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>

      <h2>August 10 2021</h2>
      <h3> <u>Your European Privacy Rights </u> </h3>
      <p>
      We process your personal data to the extent permitted by law. Such processing may include the transfer to and storage of your data at servers outside the European Union including locations within the United States. In this context we rely on a variety of legitimate reasons for data processing including your informed consent (Article 6(1) lit. (a) GDPR), the necessity to process your personal data as part of the performance of a contract to which you are or wish to become a party (Article 6(1) lit. (b) GDPR ) and/or the necessity to process your personal data for the purposes of our legitimate interests (Article 6(1) lit. (f) GDPR ). Those interests include in particular the improvement of our services. Any personal data will only be stored on file for the time necessary to fulfill the legitimate purpose including the observance of applicable statutory requirements to maintain certain documentation of our services.
      </p>
      <p>
      We do not share your data with any third parties unless permitted by law. However, we may involve data processors acting on our behalf and helping us to perform our contractual services.
      </p>
      <p>
      We hereby inform you that you as the data subject have the right to request from us access to and rectification or erasure of your personal data or restriction of processing or to object to such processing as well as the right to data portability. You also have the right to lodge a complaint with the competent a supervisory authority. Further, you may at any time withdraw your complaint once given consent. Either of those rights may be exercised by contacting us via email. Please specify your demand so that we can act in accordance with your wishes.
      </p>

      <h3> <u>Information We Collect on the Website </u> </h3>
      <p>
        Collection of Usage Information. When users come to our Website, we automatically collect “Usage
        Information” which include among other things, which pages of our Website were visited, the order in
        which they were visited, when they were visited, and which hyperlinks were “clicked.” We also collect
        information from the URLs from which you linked to our Website. Collecting such information may
        involve logging the IP address or other unique identifier (“Device Identifier”) for your computer, phone,
        tablet or other device used to access the Website (any, a “Device”), operating system and browser
        software used by each user of the Website. Although such information is not personally identifiable, we
        may be able to determine from an IP address a user’s internet service provider and the geographic
        location of his or her point of connectivity. Whenever we associate usage information or a device
        identifier with your Personally Identifiable Information, we will treat it as Personally Identifiable
        Information.
      </p>
      <p>
        Technology Used on the Website. We also use or may use cookies, web beacons and/or embedded
        scripts to help us determine and identify repeat visitors, the type of content and sites to which a user of
        our Website links, the length of time each user spends at any particular area of our Website, and the
        specific functionalities that users choose to use. Essentially, cookies are a user’s identification card for
        the ANALOGr servers. Web beacons are small graphic files linked to our servers that allow us to track
        your use of our Website and related functionalities. An embedded script is programming code that is
        designed to collect information about your interactions with the Website, such as the links you click on.
        The code is temporarily downloaded onto your Device from our web server or a third party service
        provider, is active only while you are connected to the Website, and is deactivated or deleted
        thereafter.
      </p>
      <p>
        Cookies, web beacons and embedded script allow ANALOGr to serve you better and more efficiently,
        and to personalize your experience at our Website. Cookies, web beacons and embedded script are not
        used to retain personally identifiable information. You should be able to control how and whether
        cookies will be accepted by your web browser. Most browsers offer instructions on how to reset the
        browser to reject cookies in the “Help” section of the toolbar. If you reject our cookies, certain of the
        functions and conveniences of this Website may not work properly, but you do not have to accept our
        cookies in order to use many functionalities of the Website.
      </p>
      <p>
        California Business & Professions Code Section 22575(b)(as amended effective January 1, 2014) provides
        that California residents are entitled to know how ANALOGr responds to “Do Not Track” browser
        settings. ANALOGr does not currently take actions to respond to Do Not Track signals because a uniform
        technological standard has not yet been developed. We continue to review new technologies and may
        adopt a standard once one is created.
      </p>
      <p>
        Collection of personally identifiable information. In order to access certain features and benefits on our
        Website, you may need to submit “Personally Identifiable Information” (i.e., information that can be
        used to identify you). Personally Identifiable Information can include information such as your name,
        address, telephone number and email address as well as credit card or bank information, shipping
        information, and User Content (described below). You are responsible for ensuring the accuracy of the
        Personally Identifiable Information you submit to ANALOGr. Inaccurate information may affect your
        ability to use the Website, the information you receive when using the Website, and our ability to
        contact you. For example, your email address should be kept current because that is one of the primary
        manners in which we communicate with you. We may collect Personally Identifiable Information in
        connection with various functionality and services offered via the Website, including the following:
      </p>
      <p>
        Updates and Newsletters. ANALOGr may now, or in the future, offer you the ability to receive email
        updates and newsletters. In order to subscribe to these services, you may be required to provide us with
        Personally Identifiable Information such as your name, email address and zip code.
      </p>
      <p>
        Communications with ANALOGr. We may receive Personally Identifiable Information when you send us
        an email message or otherwise contact us
      </p>
      <p>
        Social Media Interaction. We may receive Personally Identifiable Information when you engage with our
        Website on or through third-party social networking sites, such as Facebook or Twitter, and allow us to
        have access to information from your profile (e.g., name, email address, photo, gender, birthday,
        location, friends, followers, the posts or the ‘likes you make). You can control what data you share
        through privacy settings available on some social networking sites. For more information about how you
        can customize your privacy settings and how such third-party sites treat your Personally Identifiable
        Information, please refer to their privacy policies and terms of use.
      </p>
      <p>
        Metadata. Metadata is usually technical data that is associated with User Content. For example,
        Metadata can describe how, when and by whom a piece of User Content was collected and how that
        content is formatted. Users can add or may have Metadata added to their User Content including a
        hashtag (e.g., to mark keywords when you post a photo), geotag (e.g., to mark your location to a photo),
        comments or other data. This makes your User Content more searchable by others and more
        interactive.
      </p>
      <p>
        Payment Information. Payment information is Personally Identifiable Information in
        connection with your bank, credit card, crypto currency wallet or other source of funds. Payment
        information may include identity verification information for purposes of fraud prevention. We may
        collect or receive payment information when you conduct a shopping transaction.
        How We Use Your Personally Identifiable Information and Usage Information
      </p>
      <p>
        <b> Please take some time to familiarize yourself with the different ways Analogr uses the information
            that we gather.</b>
      </p>
      <p>
        Usage Information. We use Usage Information in aggregate form to build higher quality, more useful
        services by performing statistical analyses of the collective characteristics and behavior of our users, and
        by measuring demographics and interests regarding specific areas of our Website.
      </p>
      <p>
        Personally Identifiable Information. Except as set forth in this Privacy Policy or as specifically agreed to
        by you, ANALOGr will not disclose any Personally Identifiable Information to third parties we collect
        from you on the Website. In addition to the other uses set forth in this Privacy Policy, we may disclose
        and otherwise use Personally Identifiable Information as described below.<br/>
        Updates. We use Personally Identifiable Information collected when you sign-up for our various email,
        or update services to send you the messages that you request us to send.
      </p>
      <p>
        Participation in Contests, Sweepstakes and Other Promotions. We may now or in the future use the
        Personally Identifiable Information that we collect from you when you participate in a contest,
        sweepstakes, or other promotion through the Website for processing purposes and in connection with
        Winner’s lists. For instance, when a promotion involves a users uploading of User Content to the
        Website, we may use the Metadata associated with that User Content in order to identify the source of
        the content and to increase its visibility for the purposes of the promotion.
      </p>
      <p>
        Communications with ANALOGr. When you send us an email message or otherwise contact us, we may
        use the information provided by you to respond to your communication and/or as described in this
        Privacy Policy.
      </p>
      <p>
        Disclosure to Third Parties. You may be presented with an opportunity to receive information and/or
        marketing offers directly from third parties. If you do agree (or do not decline) to have your Personally
        Identifiable Information shared, your Personally Identifiable Information will be disclosed to such third
        parties and all information you disclose will be subject to the privacy policy and practices of such third
        parties. We are not responsible for the privacy policies and practices of such third parties and, therefore,
        you should review the privacy policies and practices of such third parties prior to agreeing to receive
        such information from third parties. If you later decide that you no longer want to receive
        communication from a third party, you will need to contact that third party directly.
      </p>
      <p>
        Disclosure to ANALOGr Operations and Maintenance Contractors. Our operations and maintenance
        contractors may have access to your Personally Identifiable Information in the course of providing
        products or services to us. These contractors may include vendors and suppliers that provide us with
        technology, services, and/or content related to the operation and maintenance of the Website.
      </p>
      <p>
        Government Authorities, Legal Rights and Actions. We may share your Personally Identifiable
        Information with various government authorities in response to subpoenas, court orders, or other legal
        process; to establish or exercise our legal rights or to protect our property; to defend against legal
        claims; or as otherwise required by law. In such cases we reserve the right to raise or waive any legal
        objection or right available to us. We also may share your Personally Identifiable Information when we
        believe it is appropriate to investigate, prevent, or take action regarding illegal or suspected illegal
        activities; to protect and defend the rights, property, or safety of ANALOGr, the Website, our users,
        customers, or others; and in connection with our Terms of Use and other agreements. We may also use
        IP address or other Device Identifiers to identify users, and may do so in cooperation with copyright
        owners, Internet Service Providers, wireless service providers or law enforcement agencies in our
        discretion. Such disclosures may be carried out without notice to you.
      </p>
      <p>
        Disclosure to Acquirers. We may disclose and/or transfer your Personally Identifiable Information to an
        acquirer, assignee or other successor entity in connection with a sale, merger, or reorganization of all or
        substantially all of the equity, business or assets of Analogr to which your Personally Identifiable
        Information relates.
      </p>
      <p>
        Administrative and Other Purposes. We may use your Personally Identifiable Information for
        administrative reasons, such as to contact you about Website or policy updates, or for other reasons
        disclosed by us when you submit your Personally Identifiable Information.
      </p>
      <p>
        Payment Information. We only use payment and identity verification information as necessary to
        complete a particular transaction, provide a requested service, enforce legal rights or comply with
        applicable law.
      </p>
      <p>
        Corporate Family. Your Personally Identifiable Information may also be shared with our subsidiaries and affiliates.
      </p>

      <h3><u> External Links </u></h3>
      <p>
        For your convenience we may provide links to websites operated by companies other than Analogr
        (“Third Party Sites”) that we believe may be of interest to you. We do not disclose your Personally
        Identifiable Information to these Third Party Sites without obtaining your consent. We do not endorse
        and are not responsible for the privacy practices of these Third Party Sites. If you choose to link to one
        of these Third Party Sites, you should review the privacy policy posted on the other website to
        understand how that Third Party Site collects and uses your Personally Identifiable Information.
      </p>

      <h3><u> Public Forums/User Content </u></h3>
      <p>
        When you post User Content (as defined herein and in the Terms of Use (LINK) on the Website) you do
        so at your own risk. ANALOGr cannot control the actions of other users with whom you may choose to
        share your content and Personally Identifiable Information. Therefore, we cannot and do not guarantee
        that what you post on the Website will not be viewed by unauthorized persons. Please be aware that
        whenever you voluntarily post public information to websites, blogs, message boards, chat rooms or any
        other public forums, such information can be accessed by the public and can in turn be used by those
        people to send you unsolicited communications. If you choose to “like” content on our Website or to
        otherwise post information from or via our Website to third party web sites, features or applications,
        that information will become public and the third party web sites may have access to information about
        you and your use of our Website.
      </p>

      <h3><u> Security </u></h3>
      <p>
        We incorporate commercially reasonable safeguards to help protect and secure your Personally
        Identifiable Information. However, no data transmission over the Internet, wireless transmission or
        electronic storage of information can be guaranteed to be 100% secure. Please note that we cannot
        ensure or warrant the security of any information you transmit to us, and you use our Websites and
        provide us with your information at your own risk.
      </p>

      <h3><u> Updating or Deleting Your Personally Identifiable Information </u></h3>
      <p>
        You may unsubscribe from certain email communications by clicking the “Unsubscribe” link provided at
        the bottom of an email sent to you by ANALOGr. Additionally, you can unsubscribe or delete your
        account by emailing us at <a className={css.link} href='#'> admin@analogr.com </a>. We try to answer email promptly, but may not always
        be able to do so. Keep in mind, however, that there will be residual information that will remain within
        our databases, access logs and other records, which may or may not contain your Personally Identifiable
        Information. Please note that we reserve the right to send you certain communications relating to your
        use of the Website and these transactional messages may be unaffected if you choose to opt-out from
        marketing communications.
      </p>
      
      <h3><u> Ad Serving </u></h3>
      <p>
        We may use third party network advertisers and similar vendors to serve our advertisements across the
        internet and third party analytics service providers to evaluate and provide us with information about
        the use of the Website and viewing of our content. We do not share Personally Identifiable Information
        with these third parties, but ad network providers and analytics service providers may set and access
        their own cookies, web beacons and embedded scripts on your Device and they may otherwise collect
        or have access to information about you, including Usage Information.
      </p>
      <p>
        With regard to advertisements, we and our network advertisers may target advertisements for products
        and services in which you might be interested based on your visits to both the Website and other web
        sites. Cookies and web beacons, including those set by third party network advertisers, may be used to,
        among other things, target advertisements, prevent you from seeing the same advertisements too many
        times, or to conduct research regarding the usefulness of certain advertisements to you. We may share
        usage Information about visitors to the Website with third party advertising companies for the same
        purposes. We may use a variety of companies to serve advertisements. You may wish to visit
        http://www.networkadvertising.org/managing/opt_out.asp, which provides information regarding the
        “opt-out” procedures of certain third party ad servers we may use.
      </p>

      <h3><u> Google Analytics </u></h3>
      <p>
        We use a tool called “Google Analytics” to collect information about use of this site. Google Analytics
        collects information such as how often users visit this site, what pages they visit when they do so, what
        other sites they used prior to coming to this site, and user demographic data, such as age, gender, and
        interests. We use the information we get from Google Analytics only to improve this site. Google
        Analytics collects only the IP address assigned to you on the date you visit this site, rather than your
        name or other identifying information. We do not combine the information collected through the use of
        Google Analytics with personally identifiable information, unless you explicitly submit that information
        through our website. Although Google Analytics plants a permanent cookie on your web browser to
        identify you as a unique user the next time you visit this site, the cookie cannot be used by anyone but
        Google. Google’s ability to use and share information collected by Google Analytics about your visits to
        this site is restricted by the Google Analytics Terms of Use and the Google Privacy Policy.
      </p>

      <h3><u> Changing Our Privacy Policy </u></h3>
      <p>
        Please note that we review our privacy practices from time to time, and that these practices are subject
        to change. Any prospective change, update, or modification will be effective immediately upon posting
        on our Website. We will not use your Personally Identifiable Information in a manner materially
        different than what was stated at the time it was collected without your consent.
      </p>

      <h3><u> Jurisdictional Issues — Consent to Transfer </u></h3>
      <p>
        The Website is operated in the United States and intended for users located in the United States. If you
        are located in the European Union, Canada or anywhere else outside of the United States, please be
        aware that information we collect, including, without limitation, Personally Identifiable Information, will
        be transferred to, processed and stored in the United States. The data protection laws in the United
        States may differ from those of the country in which you are located, and your Personally Identifiable
        Information may be subject to access requests from governments, courts, or law enforcement in the
        United States according to laws of the United States. By using the Website or providing us with any
        information, you consent to this transfer, processing and storage of your information in the United
        States.
      </p>

      <h3><u> Children’s Privacy </u></h3>
      <p>
        The Website is a general audience web site and we do not knowingly collect any Personally Identifiable
        Information from children younger than the age of eighteen (18). We will delete any Personally
        Identifiable Information collected that we later determine to be from a user younger than the age of
        eighteen (18).
      </p>

      <h3><u> Contacting ANALOGr </u></h3>
      <p>
        If you have any questions or comments about this Privacy Policy or our Website, please contact us:
      </p>
      <p> Address: </p>
      <h3> ANALOGr, LLC </h3>
      <p> 6450 Sunset Blvd #1077 <br/>
          Hollywood CA 90028 </p>
      <p> EMAIL: info@analogr.com </p>
    </div>
  );
};

PrivacyPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

PrivacyPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default PrivacyPolicy;
