import React, { useEffect } from 'react';
import PropTypes, { oneOfType, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { ensureListing, ensureTransaction } from '../../util/data';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { initializeCardPaymentData, confirmCardPayment, retrievePaymentIntent } from '../../ducks/stripe.duck.js';
import {
  NamedRedirect,
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  TimedAuctionTransactionPanel,
} from '../../components';
import { TopbarContainer } from '..';

import {
  loadData,
  setInitialValues,
  sendMessage,
  fetchMoreMessages,
  fetchTransactionLineItems,
  confirmPayment,
  stripeCustomer,
  initializeStripePayment,
  initializeNonStripePayment,
} from './TimedAuctionTransactionPage.duck';
import { savePaymentMethod } from '../../ducks/paymentMethods.duck';
import isEmpty from 'lodash/isEmpty';

import css from './TimedAuctionTransactionPage.module.css';

const PROVIDER = 'provider';
const CUSTOMER = 'customer';

// TimedAuctionTransactionPage handles data loading for Sale and Order views to transaction pages in Inbox.
export const TimedAuctionTransactionPageComponent = props => {
  const {
    currentUser,
    initialMessageFailedToTransaction,
    savePaymentMethodFailed,
    fetchMessagesError,
    fetchMessagesInProgress,
    totalMessagePages,
    oldestMessagePageFetched,
    fetchTransactionError,
    history,
    intl,
    messages,
    onManageDisableScrolling,
    onSendMessage,
    onSendReview,
    onShowMoreMessages,
    params,
    scrollingDisabled,
    sendMessageError,
    sendMessageInProgress,
    sendReviewError,
    sendReviewInProgress,
    transaction,
    transactionRole,
    processTransitions,
    onFetchTransactionLineItems,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    confirmCardPaymentError,
    paymentIntent,
    retrievePaymentIntentError,
    confirmPaymentError,
    stripeCustomerFetched,
    fetchStripeCustomer,
    onInitializeStripePayment,
    onInitializeNonStripePayment,
    onConfirmCardPayment,
    onConfirmPayment,
    onSavePaymentMethod,
    onRetrievePaymentIntent
  } = props;

  useEffect(() => {
    fetchStripeCustomer();
  }, []);

  const currentTransaction = ensureTransaction(transaction);
  const currentListing = ensureListing(currentTransaction.listing);
  const isProviderRole = transactionRole === PROVIDER;
  const isCustomerRole = transactionRole === CUSTOMER;

  const deletedListingTitle = intl.formatMessage({
    id: 'TimedAuctionTransactionPage.deletedListing',
  });
  const listingTitle = currentListing.attributes.deleted
    ? deletedListingTitle
    : currentListing.attributes.title;

  // Redirect users with someone else's direct link to their own inbox/sales or inbox/orders page.
  const isDataAvailable =
    currentUser &&
    currentTransaction.id &&
    currentTransaction.id.uuid === params.id &&
    currentTransaction.attributes.lineItems &&
    currentTransaction.customer &&
    currentTransaction.provider &&
    !fetchTransactionError;

  const isOwnSale =
    isDataAvailable &&
    isProviderRole &&
    currentUser.id.uuid === currentTransaction.provider.id.uuid;
  const isOwnOrder =
    isDataAvailable &&
    isCustomerRole &&
    currentUser.id.uuid === currentTransaction.customer.id.uuid;

  const { isTimedAuction } = currentTransaction.attributes?.metadata || {};
  
  if (!isEmpty(currentTransaction.attributes.metadata) && !isTimedAuction) {
    console.error('Tried to access the Timed Auction transaction page with a normal listing');
    return <NamedRedirect name="InboxPage" params={{ tab: isProviderRole ? 'sales' : 'orders' }} />;
  }
  if (isDataAvailable && isProviderRole && !isOwnSale) {
    // eslint-disable-next-line no-console
    console.error('Tried to access a sale that was not owned by the current user');
    return <NamedRedirect name="InboxPage" params={{ tab: 'sales' }} />;
  } else if (isDataAvailable && isCustomerRole && !isOwnOrder) {
    // eslint-disable-next-line no-console
    console.error('Tried to access an order that was not owned by the current user');
    return <NamedRedirect name="InboxPage" params={{ tab: 'orders' }} />;
  }

  const detailsClassName = classNames(css.tabContent, css.tabContentVisible);

  const fetchErrorMessage = isCustomerRole
    ? 'TimedAuctionTransactionPage.fetchOrderFailed'
    : 'TimedAuctionTransactionPage.fetchSaleFailed';
  const loadingMessage = isCustomerRole
    ? 'TimedAuctionTransactionPage.loadingOrderData'
    : 'TimedAuctionTransactionPage.loadingSaleData';

  const loadingOrFailedFetching = fetchTransactionError ? (
    <p className={css.error}>
      <FormattedMessage id={`${fetchErrorMessage}`} />
    </p>
  ) : (
    <p className={css.loading}>
      <FormattedMessage id={`${loadingMessage}`} />
    </p>
  );

  const initialMessageFailed = !!(
    initialMessageFailedToTransaction &&
    currentTransaction.id &&
    initialMessageFailedToTransaction.uuid === currentTransaction.id.uuid
  );

  // TransactionPanel is presentational component
  // that currently handles showing everything inside layout's main view area.
  const panel = isDataAvailable ? (
    <TimedAuctionTransactionPanel
      className={detailsClassName}
      currentUser={currentUser}
      transaction={currentTransaction}
      fetchMessagesInProgress={fetchMessagesInProgress}
      totalMessagePages={totalMessagePages}
      oldestMessagePageFetched={oldestMessagePageFetched}
      messages={messages}
      initialMessageFailed={initialMessageFailed}
      savePaymentMethodFailed={savePaymentMethodFailed}
      fetchMessagesError={fetchMessagesError}
      sendMessageInProgress={sendMessageInProgress}
      sendMessageError={sendMessageError}
      sendReviewInProgress={sendReviewInProgress}
      sendReviewError={sendReviewError}
      onManageDisableScrolling={onManageDisableScrolling}
      onShowMoreMessages={onShowMoreMessages}
      onSendMessage={onSendMessage}
      onSendReview={onSendReview}
      transactionRole={transactionRole}
      nextTransitions={processTransitions}
      onFetchTransactionLineItems={onFetchTransactionLineItems}
      lineItems={lineItems}
      fetchLineItemsInProgress={fetchLineItemsInProgress}
      fetchLineItemsError={fetchLineItemsError}
      confirmCardPaymentError={confirmCardPaymentError}
      confirmPaymentError={confirmPaymentError}
      paymentIntent={paymentIntent}
      retrievePaymentIntentError={retrievePaymentIntentError}
      stripeCustomerFetched={stripeCustomerFetched}
      onInitializeStripePayment={onInitializeStripePayment}
      onInitializeNonStripePayment={onInitializeNonStripePayment}
      onConfirmCardPayment={onConfirmCardPayment}
      onConfirmPayment={onConfirmPayment}
      onSavePaymentMethod={onSavePaymentMethod}
      onRetrievePaymentIntent={onRetrievePaymentIntent}
      history={history}
    />
  ) : (
    loadingOrFailedFetching
  );

  return (
    <Page
      title={intl.formatMessage({ id: 'TimedAuctionTransactionPage.title' }, { title: listingTitle })}
      scrollingDisabled={scrollingDisabled}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.root}>{panel}</div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter className={css.footer}>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

TimedAuctionTransactionPageComponent.defaultProps = {
  currentUser: null,
  fetchTransactionError: null,
  transaction: null,
  fetchMessagesError: null,
  initialMessageFailedToTransaction: null,
  savePaymentMethodFailed: false,
  sendMessageError: null,
  lineItems: null,
  fetchLineItemsError: null,
  paymentIntent: null,
  confirmPaymentError: null,
};

const { bool, func, oneOf, shape, string, array, arrayOf, number } = PropTypes;

TimedAuctionTransactionPageComponent.propTypes = {
  params: shape({ id: string }).isRequired,
  transactionRole: oneOf([PROVIDER, CUSTOMER]).isRequired,
  currentUser: propTypes.currentUser,
  fetchTransactionError: propTypes.error,
  scrollingDisabled: bool.isRequired,
  transaction: propTypes.transaction,
  fetchMessagesError: propTypes.error,
  totalMessagePages: number.isRequired,
  oldestMessagePageFetched: number.isRequired,
  messages: arrayOf(propTypes.message).isRequired,
  initialMessageFailedToTransaction: propTypes.uuid,
  savePaymentMethodFailed: bool,
  sendMessageInProgress: bool.isRequired,
  sendMessageError: propTypes.error,
  onShowMoreMessages: func.isRequired,
  onSendMessage: func.isRequired,
  callSetInitialValues: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  onFetchTransactionLineItems: func.isRequired,
  onRetrievePaymentIntent: func.isRequired,
  onSavePaymentMethod: func.isRequired,
  onConfirmCardPayment: func.isRequired,
  onConfirmPayment: func.isRequired,
  fetchStripeCustomer: func.isRequired,
  stripeCustomerFetched: bool.isRequired,
  onInitializeStripePayment: func.isRequired,
  onInitializeNonStripePayment: func.isRequired,

  // confirmCardPaymentError comes from Stripe so that's why we can't expect it to be in a specific form
  confirmCardPaymentError: oneOfType([propTypes.error, object]),
  paymentIntent: object,

  // line items
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    fetchTransactionError,
    transactionRef,
    fetchMessagesInProgress,
    fetchMessagesError,
    totalMessagePages,
    oldestMessagePageFetched,
    messages,
    initialMessageFailedToTransaction,
    savePaymentMethodFailed,
    sendMessageInProgress,
    sendMessageError,
    sendReviewInProgress,
    sendReviewError,
    processTransitions,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    confirmPaymentError,
    stripeCustomerFetched
  } = state.TimedAuctionTransactionPage;
  const { currentUser } = state.user;
  const { confirmCardPaymentError, paymentIntent, retrievePaymentIntentError } = state.stripe;
  const transactions = getMarketplaceEntities(state, transactionRef ? [transactionRef] : []);
  const transaction = transactions.length > 0 ? transactions[0] : null;

  return {
    currentUser,
    fetchTransactionError,
    scrollingDisabled: isScrollingDisabled(state),
    transaction,
    fetchMessagesInProgress,
    fetchMessagesError,
    totalMessagePages,
    oldestMessagePageFetched,
    messages,
    initialMessageFailedToTransaction,
    savePaymentMethodFailed,
    sendMessageInProgress,
    sendMessageError,
    sendReviewInProgress,
    sendReviewError,
    processTransitions,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    confirmCardPaymentError,
    paymentIntent,
    retrievePaymentIntentError,
    confirmPaymentError,
    stripeCustomerFetched
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onShowMoreMessages: txId => dispatch(fetchMoreMessages(txId)),
    onSendMessage: (txId, message) => dispatch(sendMessage(txId, message)),
    onManageDisableScrolling: (componentId, disableScrolling) =>
      dispatch(manageDisableScrolling(componentId, disableScrolling)),
    callSetInitialValues: (setInitialValues, values) => dispatch(setInitialValues(values)),
    onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
    onFetchTransactionLineItems: (bookingData, listingId, isOwnListing) =>
      dispatch(fetchTransactionLineItems(bookingData, listingId, isOwnListing)),
    onConfirmCardPayment: params => dispatch(confirmCardPayment(params)),
    onConfirmPayment: params => dispatch(confirmPayment(params)),
    onRetrievePaymentIntent: params => dispatch(retrievePaymentIntent(params)),
    onSavePaymentMethod: (stripeCustomer, stripePaymentMethodId) => 
      dispatch(savePaymentMethod(stripeCustomer, stripePaymentMethodId)),
    fetchStripeCustomer: () => dispatch(stripeCustomer()),
    onInitializeStripePayment: (params, transactionId) => dispatch(initializeStripePayment(params, transactionId)),
    onInitializeNonStripePayment: (params, transactionId) => dispatch(initializeNonStripePayment(params, transactionId))
  };
};

const TimedAuctionTransactionPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(TimedAuctionTransactionPageComponent);

TimedAuctionTransactionPage.loadData = loadData;
TimedAuctionTransactionPage.setInitialValues = setInitialValues;

export default TimedAuctionTransactionPage;
