import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape, FormattedHTMLMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { loadData, unsubscribe } from './UnsubscribeListingPage.duck';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { parse, createSlug } from '../../util/urlHelpers';
import { ensureCurrentUser, ensureListing } from '../../util/data';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedRedirect,
  ResponsiveImage,
  IconEmailSent,
  NamedLink,
  PrimaryButton,
  IconSpinner,
  IconCheckmark,
} from '../../components';
import { TopbarContainer } from '..';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';

import css from './UnsubscribeListingPage.module.css';

const { UUID } = sdkTypes;

/**
  Parse verification token from URL

  Returns stringified token, if the token is provided.

  Returns `null` if verification token is not provided.

  Please note that we need to explicitely stringify the token, because
  the unwanted result of the `parse` method is that it automatically
  parses the token to number.
*/
const parseToken = search => {
  const urlParams = parse(search);
  const verificationToken = urlParams.t;

  if (verificationToken) {
    return `${verificationToken}`;
  }

  return null;
};

export const UnsubscribeListingPageComponent = props => {
  const {
    currentUser,
    intl,
    scrollingDisabled,
    location,
    params: rawParams,
    submitUnsubscription,
    unsubscriptionInProgress,
    isUnsubscribed,
    unsubscriptionError,
    showListingError,
    showListingInProgress,
    getListing
  } = props;
  const title = intl.formatMessage({
    id: 'UnsubscribeListingPage.title',
  });

  const listingId = new UUID (rawParams.id);
  const listing = ensureListing(getListing(listingId));
  const user = ensureCurrentUser(currentUser);
  const unsubscribeToken = parseToken(location ? location.search : null)

  // If the listing id is not included in the url
  // We can redirect user to homepage.
  if (!listingId || !listing || isUnsubscribed) {
    if (isUnsubscribed)
      alert(intl.formatMessage({ id: "UnsubscribeListingPage.unsubscriptionSuccessful" }));
    return <NamedRedirect name="LandingPage" />;
  }

  const { images, attributes: listingAttributes } = listing;
  const { title: listingTitle = "" } = listingAttributes;
  const imageTitle = intl.formatMessage({ id: "UnsubscribeListingPage.imageTitle" }, { title: listingTitle });
  const unsubscriptionButtonText = intl.formatMessage({ id: "UnsubscribeListingPage.unsubscribe" });
  
  const unsubscriptionErrorMessage = (
    <div className={css.error}>
      <FormattedMessage id="UnsubscribeListingPage.unsubscriptionFailed" />
    </div>
  );

  const showListingErrorMessage = (
    <div className={css.error}>
      <FormattedMessage id="UnsubscribeListingPage.showListingFailed" />
    </div>
  );

  const unsubscriptionContent = (
    <>
      <IconEmailSent />
      <p className={css.confirmUnsubscription}>
        <FormattedHTMLMessage id="UnsubscribeListingPage.confirmUnsubscription" values={{ title: listingTitle }}/>
      </p>
      <NamedLink name="ListingPage" params={{ id: listingId.uuid, slug: createSlug(listingTitle)}}>
        <ResponsiveImage
          className={css.listingImage}
          alt={listingTitle}
          image={images[0]}
          variants={['landscape-crop']}
          sizes="(max-width: 767px) 100vw, 80vw"
          title={imageTitle}
        />
      </NamedLink>
      {unsubscriptionError && unsubscriptionErrorMessage}
      <PrimaryButton
        className={css.unsubscribeButton}
        onClick={() => submitUnsubscription({ 
          customerId: user.id.uuid,
          listingId, 
          unsubscribeToken: unsubscribeToken
        })}
        inProgress={unsubscriptionInProgress}
      >
        {unsubscriptionButtonText}
      </PrimaryButton>
    </>
  );

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled} referrer="origin">
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.layoutWrapperMain}>
          <div className={css.root}>
            <div className={css.content}>
              {showListingInProgress
                ? (<FormattedMessage 
                    id="UnsubscribeListingPage.loadingListingInformation" 
                  />)
                : showListingError 
                  ? showListingErrorMessage 
                  : unsubscriptionContent
              }
            </div>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

UnsubscribeListingPageComponent.defaultProps = {
  currentUser: null,
  unsubscriptionError: null,
  showListingError: null,
};

UnsubscribeListingPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  scrollingDisabled: bool.isRequired,
  submitUnsubscription: func.isRequired,
  isUnsubscribed: bool,
  unsubscriptionInProgress: bool.isRequired,
  unsubscriptionError: propTypes.error,
  showListingError: propTypes.error,
  showListingInProgress: bool.isRequired,

  // from withRouter
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const { 
    isUnsubscribed, 
    unsubscriptionError, 
    unsubscriptionInProgress, 
    showListingInProgress, 
    showListingError 
  } = state.UnsubscribeListingPage;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };
  
  return {
    isUnsubscribed,
    unsubscriptionError,
    unsubscriptionInProgress,
    showListingInProgress,
    showListingError,
    currentUser,
    getListing,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({
  submitUnsubscription: ({ customerId, listingId, unsubscribeToken }) => {
    return dispatch(unsubscribe({ customerId, listingId, unsubscribeToken }));
  },
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const UnsubscribeListingPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(UnsubscribeListingPageComponent);

UnsubscribeListingPage.loadData = loadData;

export default UnsubscribeListingPage;
