import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { IconSpinner, ListingCard } from '../../components';
import { propTypes } from '../../util/types';

import css from './SectionProduct.module.css';

const SectionProduct = props => {
  const { rootClassName, className, listings, onActivateListing, category, inProgress, intl } = props;
  const classes = classNames(rootClassName || css.root, className);
  const titleKey = intl.formatMessage({
    id: `SectionProduct.${category}`
  });

  // Panel width relative to the viewport
  const panelMediumWidth = 50;
  const panelLargeWidth = 62.5;
  const cardRenderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) ${panelMediumWidth}vw`,
    `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
    `${panelLargeWidth / 3}vw`,
  ].join(', ');

  const productList = (
    <ul className={css.listingCards}>
      {listings.map((l, index) => (
        <li key={index} className={css.colList}>
          <ListingCard
            className={css.listingCard}
            key={l.id.uuid}
            listing={l}
            renderSizes={cardRenderSizes}
            setActiveListing={onActivateListing}
          />
        </li>
      ))}
    </ul>
  );

  return (
    <div className={classes}>
      <h2 className={css.title}>
        {titleKey}
      </h2>
      {inProgress
        ? (<div className={css.loader}>
            <IconSpinner />
           </div>)
        : productList
      }
    </div>
  );
};

SectionProduct.defaultProps = { rootClassName: null, className: null };

const { string, arrayOf } = PropTypes;

SectionProduct.defaultProps = {
  listings: []
}

SectionProduct.propTypes = {
  rootClassName: string,
  className: string,
  listings: arrayOf(propTypes.listing)
};

export default SectionProduct;
