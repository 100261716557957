import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconShareFacebook.module.css';

const IconShareFacebook = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}></div>
  );
};

IconShareFacebook.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconShareFacebook.propTypes = { rootClassName: string, className: string };

export default IconShareFacebook;
