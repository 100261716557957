import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { Form, Button, FieldTextInput, FieldRichText } from '../../components';

import * as validators from '../../util/validators';
import classNames from 'classnames';
import css from './EditListingDescriptionForm.module.css';

const TITLE_MAX_LENGTH = 60;
const DESCRIPTION_MAX_LENGTH = 30000;

export const EditListingDescriptionFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
      } = formRenderProps;

      // title
      const titleMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.title' });
      const titleRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.titleRequired',
      });
      const maxLengthMessage = intl.formatMessage(
        { id: 'EditListingDescriptionForm.maxLength' },
        {
          maxLength: TITLE_MAX_LENGTH,
        }
      );
      const titleRequired = validators.required(titleRequiredMessage);

      const titleMaxlength = validators.maxLength(maxLengthMessage, TITLE_MAX_LENGTH);

      const titleValidators = validators.composeValidators(titleRequired, titleMaxlength);

      // description

      const descriptionMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.description',
      });
      const descriptionRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionRequired',
      });
      const descriptionMaxLengthMessage = intl.formatMessage(
        { id: 'EditListingDescriptionForm.maxLength' },
        {
          maxLength: DESCRIPTION_MAX_LENGTH,
        }
      );
      const descriptionRequired = validators.required(descriptionRequiredMessage);
      const descriptionMaxlength = validators.required(descriptionMaxLengthMessage, DESCRIPTION_MAX_LENGTH);
      const descriptionValidators = validators.composeValidators(descriptionRequired, descriptionMaxlength);

      const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
        </p>
      ) : null;

      // Zoho Item ID
      const zohoItemId = intl.formatMessage({ id: 'EditListingDescriptionForm.zohoItemId' });
      const zohoItemIdRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.zohoItemIdRequired',
      });
      const zohoItemIdRequired = validators.required(zohoItemIdRequiredMessage);

      // This error happens only on first tab (of EditListingWizard)
      const errorMessageCreateListingDraft = createListingDraftError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessageCreateListingDraft}
          {errorMessageUpdateListing}
          {errorMessageShowListing}

          <FieldTextInput
            id="title"
            name="title"
            className={css.title}
            type="text"
            label={titleMessage}
            maxLength={TITLE_MAX_LENGTH}
            validate={titleValidators}
          />

          <FieldTextInput
            id="zohoItemId"
            name="zohoItemId"
            className={css.title}
            type="text"
            label={zohoItemId}
            validate={zohoItemIdRequired}
          />

          <FieldRichText
            id="description"
            name="description"
            className={css.description}
            label={descriptionMessage}
            validate={descriptionValidators}
          />

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingDescriptionFormComponent.defaultProps = { className: null, fetchErrors: null };

EditListingDescriptionFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  categories: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditListingDescriptionFormComponent);
