import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import BitpayLogo from './bitpay-logo.png';
import css from './IconBitpayLogo.module.css';

const IconBitpayLogo = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <img className={classes} src={BitpayLogo} alt="Bitpay Logo"/>
  );
}

IconBitpayLogo.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconBitpayLogo.propTypes = { rootClassName: string, className: string };

export default IconBitpayLogo;
