import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import facebookImage from '../../assets/saunatimeFacebook-1200x630.jpg';
import twitterImage from '../../assets/saunatimeTwitter-600x314.jpg';
import { 
  Footer, 
  LayoutSingleColumn, 
  LayoutWrapperFooter, 
  LayoutWrapperMain, 
  LayoutWrapperTopbar, 
  Modal, 
  Page, 
  SectionHero, 
  SectionHowItWorks, 
  SectionProduct 
} from '../../components';
import config from '../../config';
import { TopbarContainer } from '../../containers';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { enableInfoModal, infoImageLink, infoLandingPageBottomText, infoLandingPageTopText, infoYoutubeLink } from '../../heroImages';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { getCloseInformationModalToSession, saveCloseInformationModalToSession } from '../../util/storageHelper';
import { loadData } from '../LandingPage/LandingPage.duck';
import { setActiveListing } from './LandingPage.duck';
import { getListingsById } from '../../ducks/marketplaceData.duck';

import css from './LandingPage.module.css';

const NEWLY_LISTED = 'newlyListed';
const FEATURED = 'featured';
const TIMED_AUCTION = 'timedAuction';

export const LandingPageComponent = props => {
  const {
    history,
    intl,
    location,
    scrollingDisabled,
    newlyListings,
    onActivateListing,
    featuredListings,
    timedAuctionListings,
    fetchNewlyListingsInProgress,
    fetchFeaturedListingsInProgress,
    fetchTimedAuctionListingsInProgress,
    onManageDisableScrolling,
  } = props;

  const [openInformationModal, setOpenInformationModal] = useState(true);
  const isOpenInformationModalSession = !getCloseInformationModalToSession();
  const closeInformationModal = () => {
    setOpenInformationModal(false);
    saveCloseInformationModalToSession(true);
  };
  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  const isTimedAuctionListingsAvailable = timedAuctionListings.length > 0;
  const isNewlyListingsAvailable = newlyListings.length > 0;
  const isFeaturedListingsAvailable = featuredListings.length > 0;

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.heroContainer}>
            <SectionHero className={css.hero} history={history} location={location} />
          </div>
          <ul className={css.sections}>
            {isFeaturedListingsAvailable  &&
              <li className={css.sectionLargeCards}>
                <SectionProduct
                  category={FEATURED}
                  listings={featuredListings}
                  onActivateListing={onActivateListing}
                  inProgress={fetchFeaturedListingsInProgress}
                  intl={intl}
                />
              </li>
            }
            {isTimedAuctionListingsAvailable  &&
              <li className={css.sectionLargeCards}>
                <SectionProduct
                  category={TIMED_AUCTION}
                  listings={timedAuctionListings}
                  onActivateListing={onActivateListing}
                  inProgress={fetchTimedAuctionListingsInProgress}
                  intl={intl}
                />
              </li>
            }
            {isNewlyListingsAvailable &&
              <li className={css.sectionLargeCards}>
                <SectionProduct
                  category={NEWLY_LISTED}
                  listings={newlyListings}
                  onActivateListing={onActivateListing}
                  inProgress={fetchNewlyListingsInProgress}
                  intl={intl}
                />
              </li>
            }
            <li className={css.section}>
              <SectionHowItWorks />
            </li>
          </ul>
          {enableInfoModal && <Modal
            id="LandingPage.informationModal"
            isOpen={openInformationModal && isOpenInformationModalSession}
            onClose={closeInformationModal}
            usePortal
            onManageDisableScrolling={onManageDisableScrolling}
          >
            <div>
              <h2>{infoLandingPageTopText}</h2>
              {infoImageLink ? (
                <img className={css.informationImage} src={infoImageLink} alt="information image" />
              ) : openInformationModal && (
                <iframe
                  className={css.informationContentWrapper}
                  src={infoYoutubeLink && infoYoutubeLink.split("&")[0].replace("watch?v=", "embed/")}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                ></iframe>
              )}
            </div>
            <p>{infoLandingPageBottomText}</p>
          </Modal>}
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const { bool, object } = PropTypes;

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    currentNewlyListingIds,
    currentFeaturedListingIds,
    currentTimedAuctionListingIds,
    fetchNewlyListingsInProgress,
    fetchFeaturedListingsInProgress,
    featchTimedAuctionListingsInProgress
  } = state.LandingPage;

  const newlyListings = getListingsById(state, currentNewlyListingIds);
  const featuredListings = getListingsById(state, currentFeaturedListingIds);
  const timedAuctionListings = getListingsById(state, currentTimedAuctionListingIds);
  
  return {
    newlyListings,
    fetchNewlyListingsInProgress,
    featuredListings,
    fetchFeaturedListingsInProgress,
    timedAuctionListings,
    featchTimedAuctionListingsInProgress,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onActivateListing: listingId => dispatch(setActiveListing(listingId)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(LandingPageComponent);

LandingPage.loadData = loadData;

export default LandingPage;
