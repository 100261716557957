import config from "../config";

const parseCookie = str =>
  str
  .split(';')
  .reduce((acc, v) => {
    const [tokenKey, tokenValue] = v.split('=');
    acc[decodeURIComponent(tokenKey.trim())] = decodeURIComponent(tokenValue.trim());
    return acc;
  }, {});

export function getFlexToken() {
  const key = `st-${config.sdk.clientId}-token`;

  const cookie = parseCookie(document.cookie) ?? "";

  return cookie ? JSON.parse(cookie[key]) : null;
}

export function getTokenFromCookie() {
  const key = `st-${config.sdk.clientId}-token`;

  const cookie = parseCookie(document.cookie) ?? "";

  return cookie ? cookie[key] : "";
}