import React from 'react'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { SavedCardDetails, FieldTextInput, FieldCheckbox, ValidationError, FieldRadioButtonGroup } from '../../components';
import { Field } from 'react-final-form';
import * as validators from '../../util/validators';
import { propTypes } from '../../util/types';
import { func, object, string } from 'prop-types';

import css from './TimedAuctionPaymentForm.module.css';

const DEFAULT_CARD = 'defaultCard';
const ONETIME_CARD = 'onetimeCardPayment';

const OneTimePaymentWithCardElement = props => {
  const { cardClasses, formId, handleStripeElementRef, hasCardError, error, label, intl } = props;
  const labelText =
    label || intl.formatMessage({ id: 'TimedAuctionPaymentForm.saveAfterOnetimePayment' });
  return (
    <React.Fragment>
      <label className={css.paymentLabel} htmlFor={`${formId}-card`}>
        <FormattedMessage id="TimedAuctionPaymentForm.paymentCardDetails" />
      </label>
      <div className={cardClasses} id={`${formId}-card`} ref={handleStripeElementRef} />
      {hasCardError ? <span className={css.error}>{error}</span> : null}
      <div className={css.saveForLaterUse}>
        <FieldCheckbox
          className={css.saveForLaterUseCheckbox}
          textClassName={css.saveForLaterUseLabel}
          id="saveAfterOnetimePayment"
          name="saveAfterOnetimePayment"
          label={labelText}
          value="saveAfterOnetimePayment"
          useSuccessColor
        />
        <span className={css.saveForLaterUseLegalInfo}>
          <FormattedHTMLMessage id="TimedAuctionPaymentForm.saveforLaterUseLegalInfo" />
        </span>
      </div>
    </React.Fragment>
  );
};

const PaymentMethodSelector = props => {
  const {
    cardClasses,
    formId,
    changePaymentMethod,
    defaultPaymentMethod,
    handleStripeElementRef,
    hasCardError,
    error,
    paymentMethod,
    intl,
  } = props;
  const last4Digits = defaultPaymentMethod.attributes.card.last4Digits;
  const labelText = intl.formatMessage(
    { id: 'TimedAuctionPaymentForm.replaceAfterOnetimePayment' },
    { last4Digits }
  );

  return (
    <React.Fragment>
      <SavedCardDetails
        className={css.paymentMethodSelector}
        card={defaultPaymentMethod.attributes.card}
        onChange={changePaymentMethod}
      />
      {paymentMethod === 'replaceCard' ? (
        <OneTimePaymentWithCardElement
          cardClasses={cardClasses}
          formId={formId}
          handleStripeElementRef={handleStripeElementRef}
          hasCardError={hasCardError}
          error={error}
          label={labelText}
          intl={intl}
        />
      ) : null}
    </React.Fragment>
  );
};

const PaymentMethods = props => {
  const { 
    cardClasses, 
    formId, 
    defaultPaymentMethod, 
    changePaymentMethod, 
    handleStripeElementRef, 
    hasCardError, 
    error, 
    paymentMethod, 
    intl, 
    showPaymentMethodSelector, 
    showOnetimePaymentFields, 
    billingAddress,
    form,
    isOfferHigherThanStripeLimit,
    isCCTabActive,
  } = props;

  const billingDetailsNameLabel = intl.formatMessage({
    id: 'TimedAuctionPaymentForm.billingDetailsNameLabel',
  });

  const unavailablePaymentText = intl.formatMessage({
    id: 'TimedAuctionPaymentForm.unavailablePayment'
  });

  const unavailablePayment = validators.required(unavailablePaymentText);

  const paymentByCard = isOfferHigherThanStripeLimit 
  //This is a field to prevent the user from pressing submit if C/DC payment is unavailable
  ? (
    <Field
      component={props => {
        const { input, meta } = props;
        return (
          <div>
            <input {...input} />
            <ValidationError fieldMeta={{...meta, touched: true}} />
          </div>
        );
      }}
      name="unavailable"
      type="hidden"
      validate={unavailablePayment}
    />
  )
  : (
    <>
      {showPaymentMethodSelector ? (
        <PaymentMethodSelector
          cardClasses={cardClasses}
          formId={formId}
          defaultPaymentMethod={defaultPaymentMethod}
          changePaymentMethod={changePaymentMethod}
          handleStripeElementRef={handleStripeElementRef}
          hasCardError={hasCardError}
          error={error}
          paymentMethod={paymentMethod}
          intl={intl}
        />
      ) : (
        <React.Fragment>
          <h3 className={css.paymentHeading}>
            <FormattedMessage id="TimedAuctionPaymentForm.paymentHeading" />
          </h3>
          <OneTimePaymentWithCardElement
            cardClasses={cardClasses}
            formId={formId}
            handleStripeElementRef={handleStripeElementRef}
            hasCardError={hasCardError}
            error={error}
            intl={intl}
          />
        </React.Fragment>
      )}

      {showOnetimePaymentFields ? (
        <div className={css.paymentAddressField}>
          <h3 className={css.billingHeading}>
            <FormattedMessage id="TimedAuctionPaymentForm.billingDetails" />
          </h3>
          {(
            <>
              <FieldTextInput
                className={css.field}
                type="text"
                id="name"
                name="name"
                autoComplete="cc-name"
                label={billingDetailsNameLabel}
              />
              {billingAddress}
            </>
          )}
        </div>
      ) : null}
    </>
  );
  
  const requiredText = intl.formatMessage({
    id: "TimedAuctionPaymentForm.fieldRequired"
  });
  const fieldRequired = validators.required(requiredText);

  const otherPaymentOptions = [
    {
      key: 'wiretransfer',
      label: 'TimedAuctionPaymentForm.wiretransferLabel'
    },
    {
      key: 'crypto',
      label: 'TimedAuctionPaymentForm.cryptoLabel'
    }
  ];

  const paymentByWireTransferOrCrypto = (
    <>
      <FieldRadioButtonGroup
        id="otherPayment"
        name="otherPayment"
        className={css.wcPaymentOptions}
        options={otherPaymentOptions}
        intl={intl}
      />
      <div className={css.wcPaymentNotes}>
        <p>
          <FormattedMessage id="TimedAuctionPaymentForm.noteWCPaymentLine1" />
        </p>
        <p>
          <FormattedMessage id="TimedAuctionPaymentForm.noteWCPaymentLine2" />
        </p>
        <p>
          <FormattedHTMLMessage id="TimedAuctionPaymentForm.noteWCPaymentLine3" />
        </p>
      </div>
    </>
  );
  
  const paymentCCLabel = intl.formatMessage({
    id: "TimedAuctionPaymentForm.paymentCCLabel"
  });
  const paymentWCLabel = intl.formatMessage({
    id: "TimedAuctionPaymentForm.paymentWCLabel"
  });

  const onClickCCPaymentTab = () => {
    form.change("otherPayment", undefined); // Set this to undefined will remove this key-value pair from 'values'
    changePaymentMethod(showPaymentMethodSelector ? DEFAULT_CARD : ONETIME_CARD);
  }

  const onClickWCPaymentTab = () => { 
    form.change("otherPayment", ""); // Set this to an empty string to continue keeping the key-value pair in 'values'
  }

  return (
    <div>
      <h3 className={css.paymentHeading}>
        <FormattedMessage id="TimedAuctionPaymentForm.paymentMethod" />
      </h3>
      <ul className={css.tabList}>
        <li 
          className={isCCTabActive ? css.activeTabListItem : css.tabListItem}  onClick={onClickCCPaymentTab}
        >
          {paymentCCLabel}
        </li>
        <li 
          className={!isCCTabActive ? css.activeTabListItem : css.tabListItem} 
          onClick={onClickWCPaymentTab}
        >
          {paymentWCLabel}
        </li>
      </ul>
      <div className={css.paymentFormContent}>
        {isCCTabActive ? paymentByCard : paymentByWireTransferOrCrypto}
      </div>
    </div>
  )
}

PaymentMethods.propTypes = {
  formId: string.isRequired, 
  defaultPaymentMethod: propTypes.defaultPaymentMethod, 
  changePaymentMethod: func, 
  handleStripeElementRef: func, 
  error: object, 
}

export default PaymentMethods
