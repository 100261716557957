import React, { Fragment } from 'react';
import * as validators from '../../../util/validators';
import { intlShape, FormattedMessage } from '../../../util/reactIntl';
import { FieldCurrencyInput } from '../../../components';
import config from '../../../config';

import css from '../EditListingConsignForm.module.css';

const FieldSetShippingPriceInformation = props => {
  const { intl } = props;

  const domesticLabel = intl.formatMessage({
    id: 'EditListingConsignForm.domesticLabel',
  });
  const internationalLabel = intl.formatMessage({
    id: 'EditListingConsignForm.internationalLabel',
  });

  return (
    <Fragment>
      <h2>
        <FormattedMessage id="EditListingConsignForm.shippingTitle" />
      </h2>

      <FieldCurrencyInput
        id="shipping.domestic"
        name="shipping.domestic"
        className={css.shipping}
        currencyConfig={config.currencyConfig}
        label={domesticLabel}
      />

      <FieldCurrencyInput
        id="shipping.international"
        name="shipping.international"
        className={css.shipping}
        currencyConfig={config.currencyConfig}
        label={internationalLabel}
      />
    </Fragment>
  );
}

FieldSetShippingPriceInformation.propTypes = {
  intl: intlShape.isRequired,
};

export default FieldSetShippingPriceInformation;