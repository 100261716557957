import React, { Component, useState } from 'react';
import { string, func } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { propTypes, YES } from '../../util/types';
import { formatMoney, createMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import config from '../../config';
import { NamedLink, ResponsiveImage, CountdownTimer } from '../../components';
import { types as sdkTypes } from '../../util/sdkLoader';
import moment from 'moment-timezone';

import css from './ListingCard.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;
const { Money } = sdkTypes;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const ListingCardComponent = props => {
  const { className, rootClassName, intl, listing, renderSizes, setActiveListing } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { publicData = {}, title = '', price, metadata } = currentListing.attributes;
  const { pricing = {}, highestOffer, category, isTimedAuction = false, timedAuction = {}, isPendingAutomatedTimedAuction } = publicData;
  const { askingPrice, displayHighestOffer, minEstPrice = {}, maxEstPrice = {}, showEstimatePrice } = pricing;
  const isCollection = category === "collection";
  const isAskingPriceEnabled = askingPrice === YES;
  const isHighestOfferEnabled = displayHighestOffer === YES && highestOffer;
  const { numOfWatchers, isSold } = metadata || {};
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;
  const displayPrice = isAskingPriceEnabled ? { price, type: 'askingPrice'}
                                            : isHighestOfferEnabled
                                              ? { price: new Money(highestOffer, price.currency), type: 'highestBid'}
                                              : null
  const { formattedPrice, priceTitle} = displayPrice ? priceData(displayPrice.price, intl) : {};
  const { endDate, isAutomated, isReservePriceMet, hasStartDate, startDate } = timedAuction;

  const priceTypeText = displayPrice && intl.formatMessage({
    id: `ListingCard.${displayPrice.type}`
  });
  const bestOfferText = intl.formatMessage({
    id: 'ListingCard.bestOffer'
  });
  const soldText = intl.formatMessage({
    id: 'ListingCard.sold'
  });
  const collectionText = intl.formatMessage({
    id: 'ListingCard.collection'
  });

  const hasStartDateBegun = hasStartDate && startDate && moment().isSameOrAfter(moment(startDate));
  const isEndDateValid = endDate && moment(endDate).isAfter(moment());
  const shouldTimerDisplay = isEndDateValid && (!isAutomated || (isAutomated && isReservePriceMet && (!hasStartDate || hasStartDateBegun)));
  const [timerDisplay, setTimerDisplay] = useState(shouldTimerDisplay);
  const remainingTimeLabel = intl.formatMessage({ 
    id: 'ListingCard.remainingTime'
  });
  const timedAuctionLabel = intl.formatMessage({ 
    id: 'ListingCard.timedAuction'
  });

  const isEstimatePriceEnabled = !!(minEstPrice?.amount || maxEstPrice?.amount) && showEstimatePrice === YES;
  const formattedMinEstPrice = minEstPrice?.amount && priceData(createMoney(minEstPrice), intl);
  const formattedMaxEstPrice = maxEstPrice?.amount && priceData(createMoney(maxEstPrice), intl);
  const estimatePriceLabel = intl.formatMessage({
    id: 'ListingCard.estimatePriceLabel'
  });
  const formattedEstimatePrice = intl.formatMessage({
    id: 'ListingCard.formattedEstimatePrice'
  }, {
    minEstPrice: formattedMinEstPrice?.formattedPrice,
    maxEstPrice: formattedMaxEstPrice?.formattedPrice
  });

  return (
    <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
      <div
        className={css.threeToTwoWrapper}
        onMouseEnter={() => setActiveListing(currentListing.id)}
        onMouseLeave={() => setActiveListing(null)}
      >
        <div className={css.aspectWrapper}>
          <LazyImage
            rootClassName={css.rootForImage}
            alt={title}
            image={firstImage}
            variants={['landscape-crop', 'landscape-crop2x']}
            sizes={renderSizes}
          />
          {isSold && <div className={css.soldBadge}>{soldText}</div>}
          {isTimedAuction && 
            <>
              {!isSold && !isPendingAutomatedTimedAuction && <div className={css.timedAuctionRibbon}>{timedAuctionLabel}</div>}
              {timerDisplay && (
                <div className={css.timerBadge}>
                  <p className={css.timerBadgeText}>{remainingTimeLabel}</p>
                  <CountdownTimer endDate={endDate} onContinue={setTimerDisplay} className={css.timerBadgeText} />
                </div>
              )}
            </>
          }
        </div>
      </div>
      <div className={css.info}>
        <div className={css.mainInfo}>
          <div className={css.title}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div>
        </div>
        {(isEstimatePriceEnabled || formattedPrice) &&
          <div className={css.priceRow}>
            {isEstimatePriceEnabled && (
              <div className={css.estPrice}>
                <span className={css.estPriceLabel}>{estimatePriceLabel}</span>
                <span className={css.estPriceValue}>{formattedEstimatePrice}</span>
              </div>
            )}
            {formattedPrice && 
              <div className={css.price}>
                <div className={css.priceValue} title={priceTitle}>
                  {formattedPrice}
                </div>
              </div>
            }
          </div>
        }
        <div className={css.infoRow}>
          <div className={css.watcher}>
            {!!numOfWatchers &&
              <FormattedMessage
                id={'ListingCard.watcher'}
                values={{ number: numOfWatchers }}
              />
            }
          </div>
            <div className={css.pricingType}>
              {priceTypeText 
                ? priceTypeText 
                : isCollection 
                  ? collectionText 
                  : bestOfferText
              }
            </div>
        </div>
      </div>
    </NamedLink>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: () => null,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
