import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes, YES } from '../../util/types';
import { Form, Button, FieldTextInput } from '../../components';
import CustomSellingPairSelectFieldMaybe from './CustomSellingPairSelectFieldMaybe';
import CustomIssueSelectFieldMaybe from './CustomIssueSelectFieldMaybe';
import CustomMicPairSelectFieldMaybe from './CustomMicpairSelectFieldMaybe';
import CustomConditionSelect from './CustomConditionSelect';
import CustomIssueSelect from './CustomIssueSelect';
import { Fragment } from 'react';

import css from './EditListingMicDetailsForm.module.css';

export const EditListingMicDetailsFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        sellingPairOptions,
        sequentSerialOptions,
        conditionMicPairOptions,
        issueMicPairOptions,
        micPairOptions,
        originalOptions,
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        values,
      } = formRenderProps;
      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDetailsForm.updateFailed" />
        </p>
      ) : null;
      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDetailsForm.showListingFailed" />
        </p>
      ) : null;
      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      const skuLabel = intl.formatMessage({
        id: 'EditListingDetailsForm.skuLabel'
      });

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}

          <CustomSellingPairSelectFieldMaybe
            id="micDetails.sellingPair"
            name="micDetails.sellingPair"
            sellingPair={sellingPairOptions}
            intl={intl}
          />

          <CustomSellingPairSelectFieldMaybe
            id="micDetails.sequentSerial"
            name="micDetails.sequentSerial"
            sequentSerial={sequentSerialOptions}
            intl={intl}
          />

          <FieldTextInput
            id="micDetails.serialMicPair"
            name="micDetails.serialMicPair"
            className={css.micpair}
            type="textarea"
            label="Serial #s"
          />

          <FieldTextInput
            id="micDetails.skuMicPair"
            name="micDetails.skuMicPair"
            className={css.micpair}
            type="text"
            label={skuLabel}
          />

          <FieldTextInput
            id="micDetails.brandMicPair"
            name="micDetails.brandMicPair"
            className={css.micpair}
            type="textarea"
            label="Brand"
          />

          <FieldTextInput
            id="micDetails.modelMicPair"
            name="micDetails.modelMicPair"
            className={css.micpair}
            type="textarea"
            label="Model"
          />

          {values.micDetails.sellingPair &&
            (values.micDetails.sellingPair == YES ? (
              <Fragment>
                <div className={css.wrapper}>
                  <div className={css.content}>
                    <h2 className={css.titleContentPair}>A</h2>
                    <FieldTextInput
                      id="micDetails.yearMicPairA"
                      name="micDetails.yearMicPairA"
                      className={css.micpair}
                      type="text"
                      label="Year"
                    />
                    <FieldTextInput
                      id="micDetails.colorMicPairA"
                      name="micDetails.colorMicPairA"
                      className={css.micpair}
                      type="textarea"
                      label="Color"
                    />
                    <CustomConditionSelect
                      id="micDetails.conditionMicPairA"
                      name="micDetails.conditionMicPairA"
                      conditionMicPairA={conditionMicPairOptions}
                      intl={intl}
                    />
                    <br />
                    <CustomIssueSelect
                      id="micDetails.issueMicPairA"
                      name="micDetails.issueMicPairA"
                      issueMicPairA={issueMicPairOptions}
                      intl={intl}
                    />
                  </div>
                  <div className={css.content}>
                    <h2 className={css.titleContentPair}>B</h2>
                    <FieldTextInput
                      id="micDetails.yearMicPairB"
                      name="micDetails.yearMicPairB"
                      className={css.micpair}
                      type="text"
                      label="Year"
                    />
                    <FieldTextInput
                      id="micDetails.colorMicPairB"
                      name="micDetails.colorMicPairB"
                      className={css.micpair}
                      type="textarea"
                      label="Color"
                    />
                    <CustomConditionSelect
                      id="micDetails.conditionMicPairB"
                      name="micDetails.conditionMicPairB"
                      conditionMicPairB={conditionMicPairOptions}
                      intl={intl}
                    />
                    <br />
                    <CustomIssueSelect
                      id="micDetails.issueMicPairB"
                      name="micDetails.issueMicPairB"
                      issueMicPairA={issueMicPairOptions}
                      intl={intl}
                    />
                  </div>
                </div>
                <div className={css.wrapper}>
                  <h2 className={css.titleContentPair}>TUBE</h2>
                  <div className={css.content}>
                    <CustomIssueSelectFieldMaybe
                      id="micDetails.issueTubeA"
                      name="micDetails.issueTubeA"
                      issues={issueMicPairOptions}
                      intl={intl}
                    />
                    <FieldTextInput
                      id="micDetails.modelTubeA"
                      name="micDetails.modelTubeA"
                      className={css.micpair}
                      type="textarea"
                      label="Make/Model"
                    />
                  </div>
                  <div className={css.content}>
                    <CustomIssueSelectFieldMaybe
                      id="micDetails.issueTubeB"
                      name="micDetails.issueTubeB"
                      issues={issueMicPairOptions}
                      intl={intl}
                    />
                    <FieldTextInput
                      id="micDetails.modelTubeB"
                      name="micDetails.modelTubeB"
                      className={css.micpair}
                      type="textarea"
                      label="Make/Model"
                    />
                  </div>
                </div>
                <div className={css.wrapper}>
                  <h2 className={css.titleContentPair}>CAPSULE</h2>
                  <div className={css.content}>
                    <CustomIssueSelectFieldMaybe
                      id="micDetails.issueCapsuleA"
                      name="micDetails.issueCapsuleA"
                      issues={issueMicPairOptions}
                      intl={intl}
                    />
                    <FieldTextInput
                      id="micDetails.modelCapsuleA"
                      name="micDetails.modelCapsuleA"
                      className={css.micpair}
                      type="textarea"
                      label="Make/Model"
                    />
                  </div>
                  <div className={css.content}>
                    <CustomIssueSelectFieldMaybe
                      id="micDetails.issueCapsuleB"
                      name="micDetails.issueCapsuleB"
                      issues={issueMicPairOptions}
                      intl={intl}
                    />
                    <FieldTextInput
                      id="micDetails.modelCapsuleB"
                      name="micDetails.modelCapsuleB"
                      className={css.micpair}
                      type="textarea"
                      label="Make/Model"
                    />
                  </div>
                </div>
                <div className={css.wrapper}>
                  <h2 className={css.titleContentPair}>TRANSFORMER</h2>
                  <div className={css.content}>
                    <CustomIssueSelectFieldMaybe
                      id="micDetails.issueTransformerA"
                      name="micDetails.issueTransformerA"
                      issues={issueMicPairOptions}
                      intl={intl}
                    />
                    <FieldTextInput
                      id="micDetails.modelTransformerA"
                      name="micDetails.modelTransformerA"
                      className={css.micpair}
                      type="textarea"
                      label="Make/Model"
                    />
                  </div>
                  <div className={css.content}>
                    <CustomIssueSelectFieldMaybe
                      id="micDetails.issueTransformerB"
                      name="micDetails.issueTransformerB"
                      issues={issueMicPairOptions}
                      intl={intl}
                    />
                    <FieldTextInput
                      id="micDetails.modelTransformerB"
                      name="micDetails.modelTransformerB"
                      className={css.micpair}
                      type="textarea"
                      label="Make/Model"
                    />
                  </div>
                </div>
                <div className={css.wrapper}>
                  <h2 className={css.titleContentPair}>POWER SUPPLY</h2>
                  <div className={css.content}>
                    <CustomMicPairSelectFieldMaybe
                      id="micDetails.powerSupplyA"
                      name="micDetails.powerSupplyA"
                      powerSupplyA={micPairOptions}
                      intl={intl}
                    />
                    <CustomIssueSelectFieldMaybe
                      id="micDetails.issueSupplyA"
                      name="micDetails.issueSupplyA"
                      issues={issueMicPairOptions}
                      intl={intl}
                    />
                    <FieldTextInput
                      id="micDetails.modelSupplyA"
                      name="micDetails.modelSupplyA"
                      className={css.micpair}
                      type="textarea"
                      label="Make/Model"
                    />
                  </div>
                  <div className={css.content}>
                    <CustomMicPairSelectFieldMaybe
                      id="micDetails.powerSupplyB"
                      name="micDetails.powerSupplyB"
                      powerSupplyB={micPairOptions}
                      intl={intl}
                    />
                    <CustomIssueSelectFieldMaybe
                      id="micDetails.issueSupplyB"
                      name="micDetails.issueSupplyB"
                      issues={issueMicPairOptions}
                      intl={intl}
                    />
                    <FieldTextInput
                      id="micDetails.modelSupplyB"
                      name="micDetails.modelSupplyB"
                      className={css.micpair}
                      type="textarea"
                      label="Make/Model"
                    />
                  </div>
                </div>
                <div className={css.wrapper}>
                  <h2 className={css.titleContentPair}>CABLING</h2>
                  <div className={css.content}>
                    <CustomMicPairSelectFieldMaybe
                      id="micDetails.cablingA"
                      name="micDetails.cablingA"
                      cablingA={micPairOptions}
                      intl={intl}
                    />
                    <CustomIssueSelectFieldMaybe
                      id="micDetails.issueCablingA"
                      name="micDetails.issueCablingA"
                      issues={issueMicPairOptions}
                      intl={intl}
                    />
                    <FieldTextInput
                      id="micDetails.modelCablingA"
                      name="micDetails.modelCablingA"
                      className={css.micpair}
                      type="textarea"
                      label="Make/Model"
                    />
                  </div>
                  <div className={css.content}>
                    <CustomMicPairSelectFieldMaybe
                      id="micDetails.cablingB"
                      name="micDetails.cablingB"
                      cablingB={micPairOptions}
                      intl={intl}
                    />
                    <CustomIssueSelectFieldMaybe
                      id="micDetails.issueCablingB"
                      name="micDetails.issueCablingB"
                      issues={issueMicPairOptions}
                      intl={intl}
                    />
                    <FieldTextInput
                      id="micDetails.modelCablingB"
                      name="micDetails.modelCablingB"
                      className={css.micpair}
                      type="textarea"
                      label="Make/Model"
                    />
                  </div>
                </div>
                <div className={css.wrapper}>
                  <h2 className={css.titleContentPair}>SHOCKMOUNT</h2>
                  <div className={css.content}>
                    <CustomMicPairSelectFieldMaybe
                      id="micDetails.shockMountA"
                      name="micDetails.shockMountA"
                      shockMountA={micPairOptions}
                      intl={intl}
                    />
                    <CustomIssueSelectFieldMaybe
                      id="micDetails.issueShockMountA"
                      name="micDetails.issueShockMountA"
                      issues={issueMicPairOptions}
                      intl={intl}
                    />
                    <FieldTextInput
                      id="micDetails.modelShockMountA"
                      name="micDetails.modelShockMountA"
                      className={css.micpair}
                      type="textarea"
                      label="Make/Model"
                    />
                  </div>
                  <div className={css.content}>
                    <CustomMicPairSelectFieldMaybe
                      id="micDetails.shockMountB"
                      name="micDetails.shockMountB"
                      shockMountB={micPairOptions}
                      intl={intl}
                    />
                    <CustomIssueSelectFieldMaybe
                      id="micDetails.issueShockMountB"
                      name="micDetails.issueShockMountB"
                      issues={issueMicPairOptions}
                      intl={intl}
                    />
                    <FieldTextInput
                      id="micDetails.modelShockMountB"
                      name="micDetails.modelShockMountB"
                      className={css.micpair}
                      type="textarea"
                      label="Make/Model"
                    />
                  </div>
                </div>
                <div className={css.wrapper}>
                  <h2 className={css.titleContentPair}>MIC CASE</h2>
                  <div className={css.content}>
                    <CustomMicPairSelectFieldMaybe
                      id="micDetails.micCaseA"
                      name="micDetails.micCaseA"
                      micCaseA={micPairOptions}
                      intl={intl}
                    />
                    <CustomIssueSelectFieldMaybe
                      id="micDetails.issueMicCaseA"
                      name="micDetails.issueMicCaseA"
                      issues={issueMicPairOptions}
                      intl={intl}
                    />
                    <FieldTextInput
                      id="micDetails.modelMicCaseA"
                      name="micDetails.modelMicCaseA"
                      className={css.micpair}
                      type="textarea"
                      label="Make/Model"
                    />
                  </div>
                  <div className={css.content}>
                    <CustomMicPairSelectFieldMaybe
                      id="micDetails.micCaseB"
                      name="micDetails.micCaseB"
                      micCaseB={micPairOptions}
                      intl={intl}
                    />
                    <CustomIssueSelectFieldMaybe
                      id="micDetails.issueMicCaseB"
                      name="micDetails.issueMicCaseB"
                      issues={issueMicPairOptions}
                      intl={intl}
                    />
                    <FieldTextInput
                      id="micDetails.modelMicCaseB"
                      name="micDetails.modelMicCaseB"
                      className={css.micpair}
                      type="textarea"
                      label="Make/Model"
                    />
                  </div>
                </div>
                <div className={css.wrapper}>
                  <h2 className={css.titleContentPair}>CARRYING CASE</h2>
                  <div className={css.content}>
                    <CustomMicPairSelectFieldMaybe
                      id="micDetails.carryCaseA"
                      name="micDetails.carryCaseA"
                      carryCaseA={micPairOptions}
                      intl={intl}
                    />
                    <CustomIssueSelectFieldMaybe
                      id="micDetails.issueCarryCaseA"
                      name="micDetails.issueCarryCaseA"
                      issues={issueMicPairOptions}
                      intl={intl}
                    />
                    <FieldTextInput
                      id="micDetails.modelCarryCaseA"
                      name="micDetails.modelCarryCaseA"
                      className={css.micpair}
                      type="textarea"
                      label="Make/Model"
                    />
                  </div>
                  <div className={css.content}>
                    <CustomMicPairSelectFieldMaybe
                      id="micDetails.carryCaseB"
                      name="micDetails.carryCaseB"
                      carryCaseB={micPairOptions}
                      intl={intl}
                    />
                    <CustomIssueSelectFieldMaybe
                      id="micDetails.issueCarryCaseB"
                      name="micDetails.issueCarryCaseB"
                      issues={issueMicPairOptions}
                      intl={intl}
                    />
                    <FieldTextInput
                      id="micDetails.modelCarryCaseB"
                      name="micDetails.modelCarryCaseB"
                      className={css.micpair}
                      type="textarea"
                      label="Make/Model"
                    />
                  </div>
                </div>
              </Fragment>
            ) : (
              <Fragment>
                <FieldTextInput
                  id="micDetails.yearMicPair"
                  name="micDetails.yearMicPair"
                  className={css.micpair}
                  type="text"
                  label="Year"
                />

                <FieldTextInput
                  id="micDetails.colorMicPair"
                  name="micDetails.colorMicPair"
                  className={css.micpair}
                  type="textarea"
                  label="Color"
                />

                <CustomConditionSelect
                  id="micDetails.conditionMicPair"
                  name="micDetails.conditionMicPair"
                  condition={conditionMicPairOptions}
                  intl={intl}
                />
                <br />
                <CustomIssueSelect
                  id="micDetails.issueMicPair"
                  name="micDetails.issueMicPair"
                  issue={issueMicPairOptions}
                  intl={intl}
                />
                <br />
                <h2 className={css.titleContent}>TUBE</h2>
                <CustomIssueSelectFieldMaybe
                  id="micDetails.issueTube"
                  name="micDetails.issueTube"
                  issues={issueMicPairOptions}
                  intl={intl}
                />
                <FieldTextInput
                  id="micDetails.modelTube"
                  name="micDetails.modelTube"
                  className={css.micpair}
                  type="textarea"
                  label="Make/Model"
                />
                <h2 className={css.titleContent}>CAPSULE</h2>
                <CustomIssueSelectFieldMaybe
                  id="micDetails.issueCapsule"
                  name="micDetails.issueCapsule"
                  issues={issueMicPairOptions}
                  intl={intl}
                />
                <FieldTextInput
                  id="micDetails.modelCapsule"
                  name="micDetails.modelCapsule"
                  className={css.micpair}
                  type="textarea"
                  label="Make/Model"
                />
                <h2 className={css.titleContent}>TRANSFORMER</h2>
                <CustomIssueSelectFieldMaybe
                  id="micDetails.issueTransformer"
                  name="micDetails.issueTransformer"
                  issues={issueMicPairOptions}
                  intl={intl}
                />
                <FieldTextInput
                  id="micDetails.modelTransformer"
                  name="micDetails.modelTransformer"
                  className={css.micpair}
                  type="textarea"
                  label="Make/Model"
                />
                <h2 className={css.titleContent}>POWER SUPPLY</h2>
                <CustomMicPairSelectFieldMaybe
                  id="micDetails.powerSupply"
                  name="micDetails.powerSupply"
                  powerSupply={micPairOptions}
                  intl={intl}
                />

                <CustomIssueSelectFieldMaybe
                  id="micDetails.issueSupply"
                  name="micDetails.issueSupply"
                  issues={issueMicPairOptions}
                  intl={intl}
                />
                <FieldTextInput
                  id="micDetails.modelSupply"
                  name="micDetails.modelSupply"
                  className={css.micpair}
                  type="textarea"
                  label="Make/Model"
                />
                <h2 className={css.titleContent}>CABLING</h2>
                <CustomMicPairSelectFieldMaybe
                  id="micDetails.cabling"
                  name="micDetails.cabling"
                  cabling={micPairOptions}
                  intl={intl}
                />
                <CustomIssueSelectFieldMaybe
                  id="micDetails.issueCabling"
                  name="micDetails.issueCabling"
                  issues={issueMicPairOptions}
                  intl={intl}
                />
                <FieldTextInput
                  id="micDetails.modelCabling"
                  name="micDetails.modelCabling"
                  className={css.micpair}
                  type="textarea"
                  label="Make/Model"
                />
                <h2 className={css.titleContent}>SHOCKMOUNT</h2>
                <CustomMicPairSelectFieldMaybe
                  id="micDetails.shockMount"
                  name="micDetails.shockMount"
                  shockMount={micPairOptions}
                  intl={intl}
                />
                <CustomIssueSelectFieldMaybe
                  id="micDetails.issueShockMount"
                  name="micDetails.issueShockMount"
                  issues={issueMicPairOptions}
                  intl={intl}
                />
                <FieldTextInput
                  id="micDetails.modelShockMount"
                  name="micDetails.modelShockMount"
                  className={css.micpair}
                  type="textarea"
                  label="Make/Model"
                />
                <h2 className={css.titleContent}>MIC CASE</h2>
                <CustomMicPairSelectFieldMaybe
                  id="micDetails.micCase"
                  name="micDetails.micCase"
                  micCase={micPairOptions}
                  intl={intl}
                />
                <CustomIssueSelectFieldMaybe
                  id="micDetails.issueMicCase"
                  name="micDetails.issueMicCase"
                  issues={issueMicPairOptions}
                  intl={intl}
                />
                <FieldTextInput
                  id="micDetails.modelMicCase"
                  name="micDetails.modelMicCase"
                  className={css.micpair}
                  type="textarea"
                  label="Make/Model"
                />
                <h2 className={css.titleContent}>CARRYING CASE</h2>
                <CustomMicPairSelectFieldMaybe
                  id="micDetails.carryCase"
                  name="micDetails.carryCase"
                  carryCase={micPairOptions}
                  intl={intl}
                />
                <CustomIssueSelectFieldMaybe
                  id="micDetails.issueCarryCase"
                  name="micDetails.issueCarryCase"
                  issues={issueMicPairOptions}
                  intl={intl}
                />
                <FieldTextInput
                  id="micDetails.modelCarryCase"
                  name="micDetails.modelCarryCase"
                  className={css.micpair}
                  type="textarea"
                  label="Make/Model"
                />
              </Fragment>
            ))}

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingMicDetailsFormComponent.defaultProps = {
  selectedPlace: null,
  updateError: null,
};

EditListingMicDetailsFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingMicDetailsFormComponent);
