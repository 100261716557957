import React, { useEffect, useRef, useState } from 'react';
import { Editor } from 'slate';
import { useSlate } from 'slate-react';
import {
  toggleBlock,
  toggleMark,
  toggleAlignStrategy,
  toggleWrapTextAlignForImage,
  isBlockActive,
  isMarkActive,
  isAlignActive,
  isWrapTextActive,
  addMark,
  removeMark,
} from './RichTextConfig';
import { fnRemoteImage } from './RichTextFeatures';

import {
  MdCode,
  MdFormatBold,
  MdFormatItalic,
  MdFormatListBulleted,
  MdFormatListNumbered,
  MdFormatQuote,
  MdFormatUnderlined,
  MdImage,
  MdLooksOne,
  MdLooksTwo,
  MdLooks3,
  MdFormatAlignLeft,
  MdFormatAlignRight,
  MdFormatAlignCenter,
  MdBurstMode,
  MdRecentActors,
} from 'react-icons/md';
import classNames from 'classnames';

import css from '../FieldRichText.module.css';

const iconMapping = {
  heading_one: <MdLooksOne size={24} />,
  heading_two: <MdLooksTwo size={24} />,
  heading_three: <MdLooks3 size={24} />,
  format_quote: <MdFormatQuote size={24} />,
  format_list_numbered: <MdFormatListNumbered size={24} />,
  format_list_bulleted: <MdFormatListBulleted size={24} />,
  format_bold: <MdFormatBold size={24} />,
  format_italic: <MdFormatItalic size={24} />,
  format_underlined: <MdFormatUnderlined size={24} />,
  code: <MdCode size={24} />,
  align_left: <MdFormatAlignLeft size={24} />,
  align_center: <MdFormatAlignCenter size={24} />,
  align_right: <MdFormatAlignRight size={24} />,
  image: <MdImage size={24} />,
  wrap_text_left: <MdRecentActors size={24} />,
  wrap_text_right: <MdBurstMode size={24} />,
  font_arsenal: <span>Arsenal</span>,
  font_pt_serif: <span>PT Serif</span>,
  font_default: <span>Default</span>
};

const getButtonColor = (reversed, active) => {
  if (reversed) {
    return active ? 'colorReversedActive' : 'colorReversedNotActive';
  }

  return active ? 'colorNotReversedActive' : 'colorNotReversedNotActive';
};

const Menu = React.forwardRef(({ className, ...props }, ref) => (
  <div {...props} ref={ref} className={className} />
));

const Button = React.forwardRef(({ className, active, reversed, title, ...props }, ref) => {
  const color = getButtonColor(reversed, active);

  return (
    <span
      {...props}
      ref={ref}
      title={title}
      className={classNames(className, css.button, css[color])}
    />
  );
});

export const Toolbar = React.forwardRef(({ className, ...props }, ref) => (
  <Menu {...props} ref={ref} className={classNames(className, css.menu)} />
));

export const BlockButton = ({ format, icon, title }) => {
  const editor = useSlate();

  return (
    <Button
      active={isBlockActive(editor, format)}
      title={title}
      onMouseDown={event => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      {iconMapping[icon]}
    </Button>
  );
};

export const MarkButton = ({ format, icon, title }) => {
  const editor = useSlate();

  return (
    <Button
      active={isMarkActive(editor, format)}
      title={title}
      onMouseDown={event => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      {iconMapping[icon]}
    </Button>
  );
};

export const ImageButton = ({ title }) => {
  const editor = useSlate();
  const fileUploadRef = useRef(null);

  const onChangeUpload = e => {
    fnRemoteImage(editor, e.target.files);
  };

  return (
    <div>
      <Button
        title={title}
        onMouseDown={event => {
          event.preventDefault();
          fileUploadRef.current.click();
        }}
      >
        {iconMapping['image']}
      </Button>
      <input
        type="file"
        onChange={onChangeUpload}
        id="chooseFile"
        accept="image/*"
        className={css.uploadImageInput}
        ref={fileUploadRef}
      />
    </div>
  );
};

export const AlignButton = ({ align, icon, title }) => {
  const editor = useSlate();

  return (
    <Button
      title={title}
      onMouseDown={event => {
        event.preventDefault();
        toggleAlignStrategy(editor, align);
      }}
      active={isAlignActive(editor, align)}
    >
      {iconMapping[icon]}
    </Button>
  );
};

export const WrapTextButton = ({ wrapAlign, icon, title }) => {
  const editor = useSlate();

  return (
    <Button
      title={title}
      onMouseDown={event => {
        event.preventDefault();
        toggleWrapTextAlignForImage(editor, wrapAlign);
      }}
      active={isWrapTextActive(editor, wrapAlign)}
    >
      {iconMapping[icon]}
    </Button>
  );
};

export const FontButton = ({ format, fontFamily, title }) => {
  const editor = useSlate();
  const { 'font-family': currentFontFamily } = Editor.marks(editor) || {};

  return (
    <Button
      title={title}
      className={css.fontButton}
      onMouseDown={event => {
        event.preventDefault();
        if (fontFamily === 'font_default') {
          removeMark(editor, format);
        }
        else addMark(editor, format, fontFamily);
      }}
      active={currentFontFamily === fontFamily}
    >
      {iconMapping[fontFamily]}
    </Button>
  );
};