import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ensureOwnListing } from '../../util/data';
import { EditListingShippingForm } from '../../forms';
import { types as sdkTypes } from '../../util/sdkLoader';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { FormattedMessage } from '../../util/reactIntl';
import ListingLink from '../ListingLink/ListingLink';
import moment from 'moment-timezone';

import css from './EditListingShippingPanel.module.css';


const { Money } = sdkTypes;

const EditListingShippingPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { publicData } = currentListing.attributes;

  const shipping = publicData && publicData.shipping;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;

  const panelTitle = isPublished ? (
    <FormattedMessage id="EditListingShippingPanel.title" values={{ listingTitle: <ListingLink listing={listing} /> }} />
  ) : (
    <FormattedMessage id="EditListingShippingPanel.createListingTitle" />
  );

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingShippingForm
        className={css.form}
        publicData={publicData}
        initialValues={{
          shipping: {
            domestic: shipping && publicData.shipping.domestic
              ? Object.setPrototypeOf(publicData.shipping.domestic, Money.prototype)
              : null,
            international: shipping && publicData.shipping.international
              ? Object.setPrototypeOf(publicData.shipping.international, Money.prototype)
              : null
          }
        }}
        onSubmit={values => {
          const { shipping } = values;
          const publishTimestamp = isPublished ? {} : { publishTimestamp: moment().valueOf() };
          const updateValues = {
            publicData: {
              shipping: {
                domestic: shipping.domestic
                  ? {
                    amount: shipping.domestic.amount ? shipping.domestic.amount : null,
                    currency: shipping.domestic.currency ? shipping.domestic.currency : null,
                  }
                  : null,
                international: shipping.international
                  ? {
                    amount: shipping.international.amount ? shipping.international.amount : null,
                    currency: shipping.international.currency ? shipping.international.currency : null,
                  }
                  : null,
              },
              numberOfOffers: 0,
              ...publishTimestamp
            },
          };
          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        saveActionMsg={submitButtonText}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
      />
    </div>
  );
};

const { func, object, string, bool } = PropTypes;

EditListingShippingPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingShippingPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingShippingPanel;
