import React, { useMemo, useState } from 'react';
import { func } from 'prop-types';
import { compose } from 'redux';
import arrayMutators from 'final-form-arrays'
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage, FormattedHTMLMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import {
  AffirmMessage,
  Form,
  IconAffirmLogo,
  InlineModal,
} from '../../components';
import FieldSetMakeOffer from './fieldsets/FieldSetMakeOffer';
import FieldSetPurchase from './fieldsets/FieldSetPurchase';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';
import { YES } from '../../util/types';
import { MAX_AMOUNT_AFFIRM, MIN_AMOUNT_AFFIRM } from '../../util/validators';
import { enableInfoModalListingPage, imageAltListingPageModal, imageLinkListingPageModal, infoInstructionListingPageModal } from '../../heroImages';

import css from './TradingForm.module.css';

const { Money } = sdkTypes;

export const TradingFormComponent = props => {
  const [isPaymentPlanPopupOpen, setIsPaymentPlanPopupOpen] = useState(enableInfoModalListingPage && !!imageLinkListingPageModal);
  const { rootClassName, className, onSubmit, currentUser, currentHighestBidder, ...rest } = props;
  const classes = classNames(rootClassName || css.root, className);
  const { highestOffer, pricing } = rest;
  const highestBidValue = pricing.displayHighestOffer === YES && highestOffer ? highestOffer : 0;
  const defaultBid = highestBidValue + (highestBidValue * 5 / 100); // Higher 5%
  
  return (
    <FinalForm
      {...rest}
      defaultBid={defaultBid}
      mutators={arrayMutators}
      onSubmit={onSubmit}
      initialValues={{ shipping: "collect", offer: new Money(defaultBid, config.currency) }}
      render={fieldRenderProps => {
        const {
          handleSubmit,
          intl,
          isTradingDisabled,
          values,
          price,
          pricing,
          numberOfOffers,
          highestOffer,
          form,
          isBuyItNowAccepted,
          isOfferAccepted
        } = fieldRenderProps;
        const offer = values.offer || 0;

        const shouldDisplayAffirmBreakdown = offer.amount >= MIN_AMOUNT_AFFIRM && offer.amount <= MAX_AMOUNT_AFFIRM;
        const affirmDefaultMessage = (
          <div className={css.affirmDefaultMessage}>
            <FormattedMessage id="TradingForm.affirmDefaultMessage"/>
            <IconAffirmLogo rootClassName={css.affirmDefaultMessageLogo}/>
          </div>
        );

        const affirmMessage = shouldDisplayAffirmBreakdown 
          ? <AffirmMessage rootClassName={css.affirmMessage} price={offer} />
          : affirmDefaultMessage

        const popupModal = isPaymentPlanPopupOpen 
          ? (<InlineModal
              id="SectionTrading.paymentPlanPopupModal"
              isOpen={isPaymentPlanPopupOpen}
              onClose={() => setIsPaymentPlanPopupOpen(false)}
              className={css.paymentPlanInlineModal}
              closeButtonClassName={css.closeButtonInlineModal}
            >
              <img className={css.inlineModalContent} src={imageLinkListingPageModal} alt={imageAltListingPageModal} />
              {infoInstructionListingPageModal && <p>
                <FormattedHTMLMessage id="instruction" defaultMessage={infoInstructionListingPageModal} />
              </p>}
            </InlineModal>)
          : null;
        
        return (
          <Form onSubmit={handleSubmit} className={classes}>
            {isBuyItNowAccepted && (
              <FieldSetPurchase
                intl={intl}
                price={price}
                form={form}
                isTradingDisabled={isTradingDisabled}
                acceptBuyItNow={pricing.acceptBuyItNow}
              />
            )}
            {isPaymentPlanPopupOpen 
              ? popupModal 
              : isOfferAccepted && (
                <>
                  <FieldSetMakeOffer
                    numberOfOffers={numberOfOffers} 
                    highestOffer={highestOffer}
                    displayHighestOffer={pricing.displayHighestOffer}
                    intl={intl}
                    form={form}
                    currentOffer={offer}
                    isTradingDisabled={isTradingDisabled}
                    defaultBid={defaultBid}
                    highestBidValue={highestBidValue}
                    currentUser={currentUser}
                    currentHighestBidder={currentHighestBidder}
                  />
                  {affirmMessage}
                </>
              )
            }
          </Form>
        );
      }}
    />
  )
};

TradingFormComponent.defaultProps = {
  rootClassName: null,
  className: null
};

TradingFormComponent.propTypes = {
  onSubmit: func,
  intl: intlShape
};

export default compose(injectIntl)(TradingFormComponent);
