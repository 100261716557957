import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { formatMoney } from '../../util/currency';
import { ensureListing } from '../../util/data';
import { propTypes, NO, YES } from '../../util/types';
import { NamedLink, ResponsiveImage } from '..';
import config from '../../config';
import { createSlug } from '../../util/urlHelpers';
import { types as sdkTypes } from '../../util/sdkLoader';
import RemoveButton from './remove-btn.png';

import css from './ListingTable.module.css';

const { Money } = sdkTypes;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return formattedPrice;
  } else if (price) {
    return intl.formatMessage(
        { id: 'ListingItem.unsupportedPrice' },
        { currency: price.currency }
      )
  }
  return null;
};

export const ListingItemComponent = props => {
  const { intl, listing, onRemoveFromWatchlist } = props;
  const currentListing = ensureListing(listing);
  const { publicData = {}, title = '', price, metadata = {} } = currentListing.attributes;
  const id = currentListing.id.uuid;
  const slug = createSlug(title);
  const { numOfWatchers = 0 } = metadata;
  const { highestOffer, numberOfOffers } = publicData;
  const { acceptOffer, displayHighestOffer, askingPrice } = publicData.pricing;
  const formattedAskingPrice = priceData(price, intl);
  const formattedHighestOffer = 
    highestOffer
      ? priceData(new Money(highestOffer, config.currency), intl)
      : "-";
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;
  return (  
    <tr className={css.rootItem}>
      <td className={css.itemInfo}>
        <ResponsiveImage 
          rootClassName={css.rootForImage}
          image={firstImage} 
          variants={['landscape-crop', 'landscape-crop2x']}
          alt={title}
        />
        <span className={css.itemTitle}>{title}</span>
      </td>
      <td className={css.itemPrice}>
        {askingPrice !== NO
          ? formattedAskingPrice
          : "-"
        }
      </td>
      <td className={css.itemBids}>
        { acceptOffer === YES ? numberOfOffers : "-" }
      </td>
      <td className={css.itemHighestBid}>
        { acceptOffer === YES && displayHighestOffer === YES ? formattedHighestOffer : "-" }
      </td>
      <td className={css.itemWatchers}>
        {numOfWatchers}
      </td>
      <td className={css.itemView}>
        <NamedLink name="ListingPage" params={{id, slug}}>
          <FormattedMessage id="ListingItem.view" />
        </NamedLink>
      </td>
      <td className={css.itemRemove}>
        <img src={RemoveButton} onClick={() => onRemoveFromWatchlist(currentListing.id)}/>
      </td>
    </tr>
  );
}
 
ListingItemComponent.defaultProps = {
  className: null,
  rootClassName: null,
};

ListingItemComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
};

export default injectIntl(ListingItemComponent);