/**
 * Note: This form is using card from Stripe Elements https://stripe.com/docs/stripe-js#elements
 * Card is not a Final Form field so it's not available trough Final Form.
 * It's also handled separately in handleSubmit function.
 */
import React, { Component } from 'react';
import { bool, func, object, string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { EXISTING_ADDRESS, NEW_ADDRESS } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import { formatMoney, createMoney } from '../../util/currency';
import arrayMutators from 'final-form-arrays';
import * as validators from '../../util/validators';
import { OnChange } from 'react-final-form-listeners';
import {
  Form,
  PrimaryButton,
  FieldTextInput,
  IconSpinner,
  FieldRadioButtonGroup,
  ShippingAddress,
} from '../../components';

import css from './TimedAuctionCheckoutForm.module.css';

const { Money } = sdkTypes;

const getShippingPrice = (shippingOption) => {
  const price = shippingOption && shippingOption.amount && createMoney(shippingOption);

  return price;
}

const FieldSetShippingOptions = props => {
  const { shipping, intl, isPickupOnly } = props;
  const domestic = getShippingPrice(shipping.domestic);
  const international = getShippingPrice(shipping.international);

  const domesticOption = domestic ? {
    key: 'domestic',
    label: 'CheckoutPage.domestic',
    paramsLabel: { price: formatMoney(intl, domestic) }
  } : null;

  const internationalOption = international ? {
    key: 'international',
    label: 'CheckoutPage.international',
    paramsLabel: { price: formatMoney(intl, international) }
  } : null;

  const collectOption = {
    key: 'collect',
    label: isPickupOnly ? 'CheckoutPage.collectOnly' : 'CheckoutPage.collect'
  };

  const shippingOptions = [domesticOption, internationalOption, collectOption].filter(option => option);

  const shippingOptionsLabel = intl.formatMessage({
    id: "CheckoutPage.shippingOptionsLabel"
  })

  const fieldRequired = validators.required(
    intl.formatMessage({
      id: 'CheckoutPage.fieldRequired',
    })
  );

  return (
    <FieldRadioButtonGroup 
      className={css.shippingOptions}
      id="shippingOptions"
      name="shippingOptions"
      label={shippingOptionsLabel}
      options={shippingOptions}
      intl={intl}
      validate={fieldRequired}
      initialValue={isPickupOnly ? 'collect' : null}
    />
  );
}

const initialState = {
  error: null,
};

/**
 * Timed Auction Tab form that asks for shipping address and phone number.
 */
class TimedAuctionCheckoutForm extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      ...initialState,  
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.checkoutTimedAuctionForm = this.checkoutTimedAuctionForm.bind(this);
  }

  handleSubmit(values) {
    const {
      onSubmit,
      inProgress,
      formId,
      currentShippingAddress,
      isShippingAddressExisting,
      isPickupOnly
    } = this.props;
    const { initialMessage } = values;
    const { currentShippingAddressType } = this.props;

    if (inProgress) {
      // Already submitting or card value incomplete/invalid
      return;
    }

    const params = {
      message: initialMessage ? initialMessage.trim() : null,
      card: this.card,
      formId,
      formValues: {...values, isSameShippingAddress: currentShippingAddressType === EXISTING_ADDRESS, currentShippingAddress, isShippingAddressExisting, isPickupOnly },
    };
    onSubmit(params);
  }

  checkoutTimedAuctionForm(formRenderProps) {
    const {
      className,
      rootClassName,
      inProgress: submitInProgress,
      loadingData,
      formId,
      authorDisplayName,
      showInitialMessageInput,
      intl,
      initiateOrderError,
      invalid,
      handleSubmit,
      form,
      values,
      changeShippingOption,
      isPickupOnly,
      agreement
    } = formRenderProps;
    
    const submitDisabled = invalid || submitInProgress;
    const classes = classNames(rootClassName || css.root, className);

    const messagePlaceholder = intl.formatMessage(
      { id: 'StripePaymentForm.messagePlaceholder' },
      { name: authorDisplayName }
    );

    const messageOptionalText = intl.formatMessage({
      id: 'StripePaymentForm.messageOptionalText',
    });

    const initialMessageLabel = intl.formatMessage(
      { id: 'StripePaymentForm.messageLabel' }
    );

    const shippingAddress = (
      <ShippingAddress 
        intl={intl} 
        form={form} 
        fieldId={formId}
        currentShippingAddress={this.props.currentShippingAddress} 
        onChangeShippingAddress={this.props.changeShippingAddress}
        isShippingAddressExisting={this.props.isShippingAddressExisting}
        currentShippingAddressType={this.props.currentShippingAddressType}
      />
    );

    return (
      <Form className={classes} onSubmit={handleSubmit}>
        {!loadingData ? (
          <React.Fragment>
            <OnChange name="shippingOptions">
              {(value, previous) => {
                changeShippingOption(value);
              }}
            </OnChange>
            <FieldSetShippingOptions isPickupOnly={isPickupOnly} shipping={this.props.shipping} intl={this.props.intl} />

            {!isPickupOnly && shippingAddress}

          </React.Fragment>
        ) : loadingData ? (
          <p className={css.spinner}>
            <IconSpinner />
          </p>
        ) : null}

        {initiateOrderError ? (
          <span className={css.errorMessage}>{initiateOrderError.message}</span>
        ) : null}
        {showInitialMessageInput ? (
          <div>
            <h3 className={css.messageHeading}>
              <FormattedMessage id="StripePaymentForm.messageHeading" />
            </h3>

            <label>
              {initialMessageLabel}
              <span className={css.messageOptional}>{messageOptionalText}</span>
              </label>
            <FieldTextInput
              type="textarea"
              id={`${formId}-message`}
              name="initialMessage"
              className={css.message}
            />
          </div>
        ) : null}
        <div className={css.agreementOnMobile}>
          {agreement}
        </div>
        <div className={css.submitContainer}>
          <PrimaryButton
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
          >
            <FormattedMessage id="TimedAuctionCheckoutForm.submitPaymentInfo" />
          </PrimaryButton>
        </div>
      </Form>
    )
  }

  render() {
    const { onSubmit, ...rest } = this.props;
    return <FinalForm mutators={{ ...arrayMutators }} onSubmit={this.handleSubmit} {...rest} render={this.checkoutTimedAuctionForm} />;
  }
}

TimedAuctionCheckoutForm.defaultProps = {
  className: null,
  rootClassName: null,
  inProgress: false,
  loadingData: false,
  showInitialMessageInput: true,
  initiateOrderError: null,
};

TimedAuctionCheckoutForm.propTypes = {
  className: string,
  rootClassName: string,
  inProgress: bool,
  loadingData: bool,
  initiateOrderError: object,
  formId: string.isRequired,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  authorDisplayName: string.isRequired,
  showInitialMessageInput: bool,
};

export default injectIntl(TimedAuctionCheckoutForm);
