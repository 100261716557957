import React, { useState } from 'react';
import { Elements, AffirmMessageElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { stripePublishableKey } from '../../stripe-config';
import config from '../../config';
import { bool } from 'prop-types';
import { propTypes } from '../../util/types';
import classNames from 'classnames';
import { MAX_AMOUNT_AFFIRM, MIN_AMOUNT_AFFIRM } from '../../util/validators';
import IconSpinner from '../IconSpinner/IconSpinner';

import css from './AffirmMessage.module.css';

// Set your publishable key. Remember to change this to your live publishable key in production!
// See your keys here: https://dashboard.stripe.com/apikeys
const stripePromise = loadStripe(stripePublishableKey);

const AffirmMessage = props => {
  const [isLoading, setIsLoading] = useState(true);
  const { isDisplay, price, className, rootClassName } = props;
  const { amount, currency } = price;

  const classes = rootClassName || classNames(css.root, className);

// Affirm payment accepts a payment in range $50 - $10000 only
  const shouldDisplayAffirm = isDisplay && amount >= MIN_AMOUNT_AFFIRM && amount <= MAX_AMOUNT_AFFIRM && currency === config.currencyConfig.currency;

  return shouldDisplayAffirm && (
    <div className={classes}>
      {isLoading && <div className={css.loading}><IconSpinner /></div>}
      <Elements stripe={stripePromise}>
        <AffirmMessageElement options={{ amount, currency, textAlign: 'center', fontSize: '20px' }} onReady={() => setIsLoading(false)}/>
      </Elements>
    </div>
  );
};

AffirmMessage.propTypes = {
  isDisplay: bool,
  price: propTypes.money
}

AffirmMessage.defaultProps = {
  isDisplay: true
}

export default AffirmMessage;
