import React, { Component } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import {
  Form,
  Button,
  FieldTextInput,
} from '../../components';
import { propTypes, SESSION_FORM_KEYS, NO } from '../../util/types';
import arrayMutators from 'final-form-arrays';
import css from './EditListingConsignForm.module.css';
import isEqual from 'lodash/isEqual';
import {
  FieldSetBasicInformation,
  FieldSetItemInformation,
  FieldSetMicrophoneInformation,
  FieldSetPriceInformation,
  FieldSetShippingPriceInformation,
  FieldSetServiceInformation,
  FieldSetFeaturedRecords,
  FieldSetAuthenticity,
  FieldSetMedia
} from './components';

export class EditListingConsignFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { imageUploadRequested: false };
    this.onImageUploadHandler = this.onImageUploadHandler.bind(this);
    this.submittedImages = [];
  }
  onImageUploadHandler(file) {
    if (file) {
      this.setState({ imageUploadRequested: true });
      this.props
        .onImageUpload({ id: `${file.name}_${Date.now()}`, file })
        .then(() => {
          this.setState({ imageUploadRequested: false });
        })
        .catch(() => {
          this.setState({ imageUploadRequested: false });
        });
    }
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        onImageUploadHandler={this.onImageUploadHandler}
        imageUploadRequested={this.state.imageUploadRequested}
        mutators={{ ...arrayMutators }}
        render={formRenderProps => {
          const {
            form,
            categoryOptions,
            sellingPairOptions,
            sequentSerialOptions,
            conditionsMicPairOptions,
            issuesMicPairOptions,
            micPairOptions,
            originalOptions,
            conditionOptions,
            issueOptions,
            askingPriceOptions,
            acceptOfferOptions,
            highestOfferOptions,
            acceptBuyItNowOptions,
            estimatePriceOptions,
            authenticityDocsOptions,
            className,
            ready,
            handleSubmit,
            intl,
            invalid,
            pristine,
            updated,
            updateInProgress,
            fetchErrors,
            values,
            images,
            recordImages,
            onRemoveImage,
            onImageUploadHandler,
            onUpdateImageOrder,
            errors
          } = formRenderProps;
          const { updateListingError, createListingDraftError, showListingsError } =
            fetchErrors || {};
          const errorMessageUpdateListing = updateListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
            </p>
          ) : null;

          // This error happens only on first tab (of EditListingWizard)
          const errorMessageCreateListingDraft = createListingDraftError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
            </p>
          ) : null;

          const errorMessageShowListing = showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
            </p>
          ) : null;

          const submittedOnce = this.submittedImages.length > 0;
          // imgs can contain added images (with temp ids) and submitted images with uniq ids.
          const arrayOfImgIds = imgs =>
            imgs.map(i => (typeof i.id === 'string' ? i.imageId : i.id));
          const imageIdsFromProps = arrayOfImgIds(images);
          const imageIdsFromPreviousSubmit = arrayOfImgIds(this.submittedImages);
          const imageArrayHasSameImages = isEqual(imageIdsFromProps, imageIdsFromPreviousSubmit);
          const pristineSinceLastSubmit = submittedOnce && imageArrayHasSameImages;

          const classes = classNames(css.root, className);
          const submitReady = (updated && pristine && pristineSinceLastSubmit) || ready;
          const submitInProgress = updateInProgress;
          // At least one of these options is 'Yes'
          const isPricingChoiceInvalid = 
            values.pricing.acceptOffer === NO && 
            (values.pricing.askingPrice === NO || values.pricing.acceptBuyItNow === NO);
          const submitDisabled = invalid || submitInProgress || isPricingChoiceInvalid;

          const isTypeMicrophones = values && values.category === 'microphones';
          const isCollection = values && values.category === 'collection';

          const saveDataToSessionStorage = () => sessionStorage.setItem(SESSION_FORM_KEYS.CREATE_LISTING_VALUES, JSON.stringify(values));

          return (
            <Form
              className={classes}
              onSubmit={e => {
                this.submittedImages = images;
                handleSubmit(e);
              }}
            >
              {errorMessageCreateListingDraft}
              {errorMessageUpdateListing}
              {errorMessageShowListing}
              <FieldSetBasicInformation
                intl={intl}
                categoryOptions={categoryOptions}
                isCollection={isCollection}
              />

              {!isCollection &&
                (!isTypeMicrophones
                  ? (
                    <FieldSetItemInformation
                      intl={intl}
                      conditionOptions={conditionOptions}
                      issueOptions={issueOptions}
                    />
                  )
                  : (
                    <FieldSetMicrophoneInformation
                      intl={intl}
                      sellingPairOptions={sellingPairOptions}
                      sellingPairValue={values.micDetails.sellingPair}
                      sequentSerialOptions={sequentSerialOptions}
                      conditionsMicPairOptions={conditionsMicPairOptions}
                      issuesMicPairOptions={issuesMicPairOptions}
                      originalOptions={originalOptions}
                      micPairOptions={micPairOptions}
                    />
                  )
                )
              }
              <FieldSetMedia
                intl={intl}
                form={form}
                saveDataToSessionStorage={saveDataToSessionStorage}
                onRemoveImage={onRemoveImage}
                onImageUploadHandler={onImageUploadHandler}
                onUpdateImageOrder={onUpdateImageOrder}
                images={images}
              />
              {!isCollection &&
                <FieldSetPriceInformation
                  intl={intl}
                  askingPriceOptions={askingPriceOptions}
                  acceptOfferOptions={acceptOfferOptions}
                  acceptBuyItNowOptions={acceptBuyItNowOptions}
                  highestOfferOptions={highestOfferOptions}
                  estimatePriceOptions={estimatePriceOptions}
                  pricing={values.pricing}
                  isPricingChoiceInvalid={isPricingChoiceInvalid}
                />
              }
              <FieldSetAuthenticity
                intl={intl}
                form={form}
                saveDataToSessionStorage={saveDataToSessionStorage}
                authenticity={values.authenticity}
                authenticityDocsOptions={authenticityDocsOptions}
              />
              {!isCollection && <FieldSetServiceInformation
                intl={intl}
                form={form}
                saveDataToSessionStorage={saveDataToSessionStorage}
                service={values.service}
              />}
              <FieldSetFeaturedRecords
                intl={intl}
                form={form}
                recordImages={recordImages}
                saveDataToSessionStorage={saveDataToSessionStorage}
                featuredRecords={values.featuredRecords}
              />
              {!isCollection && <FieldSetShippingPriceInformation intl={intl} />}

              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={submitReady}
              >
                <FormattedMessage id="EditListingConsignForm.submitButton" />
              </Button>
            </Form>
          );
        }}
      />
    );
  }
}

EditListingConsignFormComponent.defaultProps = {
  className: null,
  fetchErrors: null,
  imageUploadRequested: false,
  updated: false
};

EditListingConsignFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  imageUploadRequested: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  categoryOptions: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditListingConsignFormComponent);
