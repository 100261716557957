import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { Form, Button, FieldTextInput } from '../../components';

import css from './EditListingDetailsForm.module.css';
import CustomConditionSelectFieldMaybe from './CustomConditionSelectFieldMaybe';
import CustomIssueSelectFieldMaybe from './CustomIssueSelectFieldMaybe';
import * as validators from '../../util/validators';

export const EditListingDetailsFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        conditionOptions,
        issueOptions,
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
      } = formRenderProps;

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDetailsForm.updateFailed" />
        </p>
      ) : null;
      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDetailsForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      const brandRequiredMessage = intl.formatMessage({
        id: 'EditListingDetailsForm.brandRequired',
      });
      const brandRequired = validators.required(brandRequiredMessage);
      const brandValidators = validators.composeValidators(brandRequired);

      const quantityValidMessage = intl.formatMessage({
        id: 'EditListingDetailsForm.quantityValid',
      });
      const quantityValidators = validators.validQuantity(quantityValidMessage);

      const skuLabel = intl.formatMessage({
        id: 'EditListingDetailsForm.skuLabel'
      });

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}

          <FieldTextInput
            id="itemDetails.brand"
            name="itemDetails.brand"
            className={css.details}
            type="textarea"
            label="Brand"
            validate={brandValidators}
          />

          <FieldTextInput
            id="itemDetails.model"
            name="itemDetails.model"
            className={css.details}
            type="textarea"
            label="Model"
          />

          <FieldTextInput
            id="itemDetails.year"
            name="itemDetails.year"
            className={css.details}
            type="text"
            label="Year"
          />

          <FieldTextInput
            id="itemDetails.color"
            name="itemDetails.color"
            className={css.details}
            type="textarea"
            label="Color"
          />

          <CustomConditionSelectFieldMaybe
            id="itemDetails.condition"
            name="itemDetails.condition"
            condition={conditionOptions}
            intl={intl}
          />
          
          <CustomIssueSelectFieldMaybe
            id="itemDetails.issue"
            name="itemDetails.issue"
            issue={issueOptions}
            intl={intl}
          />

          <FieldTextInput
            id="itemDetails.quantity"
            name="itemDetails.quantity"
            className={css.details}
            type="number"
            min={1}
            label="Quantity"
            validate={quantityValidators}
          />

          <FieldTextInput
            id="itemDetails.serial"
            name="itemDetails.serial"
            className={css.details}
            type="textarea"
            label="Serial #s"
          />

          <FieldTextInput
            id="itemDetails.sku"
            name="itemDetails.sku"
            className={css.details}
            type="text"
            label={skuLabel}
          />

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingDetailsFormComponent.defaultProps = {
  selectedPlace: null,
  updateError: null,
};

EditListingDetailsFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  condition: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
  issue: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditListingDetailsFormComponent);
