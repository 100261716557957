import React from 'react';
import { formatMoney } from '../../../util/currency';
import { object } from 'prop-types';
import { types as sdkTypes } from '../../../util/sdkLoader';
import { FormattedMessage } from 'react-intl';

import css from '../ListingPage.module.css';

const { Money } = sdkTypes;

const SectionShippingOptions = props => {
  const { shipping, intl } = props;

  const domestic = shipping.domestic && shipping.domestic.amount && new Money(shipping.domestic.amount, shipping.domestic.currency);
  const international =  shipping.international && shipping.international.amount && new Money(shipping.international.amount, shipping.international.currency);

  const domesticOption = domestic ? {
    key: 'domestic',
    label: 'SectionShippingOptions.domestic',
    paramValue: { price: formatMoney(intl, domestic) }
  } : null;

  const internationalOption = international ? {
    key: 'international',
    label: 'SectionShippingOptions.international',
    paramValue: { price: formatMoney(intl, international) }
  } : null;

  const collectOption = {
    key: 'collect',
    label: 'SectionShippingOptions.collect'
  };
  
  const shippingLabel = intl.formatMessage({
    id: 'SectionShippingOptions.shipping'
  }) ;

  const shippingOptions = [domesticOption, internationalOption, collectOption].filter(option => option);
  
  return (
    <div className={css.sectionShippingOptions}>
      <div className={css.shippingLabelColumn}>
        <label className={css.shippingLabel}>{shippingLabel}</label>
      </div>
      <div className={css.shippingOptionsColumn}>
        {shippingOptions.map((option) => (
          <FormattedMessage key={option.key} id={option.label} values={option.paramValue}/>
        ))}
      </div>
    </div>
  );
}

SectionShippingOptions.propTypes = {
  shipping: object
};

export default SectionShippingOptions;