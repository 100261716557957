// These helpers are calling FTW's own server-side routes
// so, they are not directly calling Marketplace API or Integration API.
// You can find these api endpoints from 'server/api/...' directory

import { types as sdkTypes, transit } from './sdkLoader';
import config from '../config';
import Decimal from 'decimal.js';
import axios from 'axios';

const REQUEST_HEADERS = {
  'Access-Control-Allow-Origin': '*',
  'Content-Type': 'application/json'
}

export const apiBaseUrl = () => {
  const { port, remoteUrl } = config.externalServer;
  const useDevApiServer = process.env.NODE_ENV === 'development' && !!port;

  // In development, the dev API server is running in a different port
  if (useDevApiServer) {
    return `http://localhost:${port}`;
  }
  return remoteUrl;
  // Otherwise, use the same domain and port as the frontend
};

// Application type handlers for JS SDK.
//
// NOTE: keep in sync with `typeHandlers` in `server/api-util/sdk.js`
export const typeHandlers = [
  // Use Decimal type instead of SDK's BigDecimal.
  {
    type: sdkTypes.BigDecimal,
    customType: Decimal,
    writer: v => new sdkTypes.BigDecimal(v.toString()),
    reader: v => new Decimal(v.value),
  },
];

const serialize = data => {
  return transit.write(data, { typeHandlers, verbose: config.sdk.transitVerbose });
};

const deserialize = str => {
  return transit.read(str, { typeHandlers });
};

const readFileAsDataURL = async (file) => {
  const resultBase64 = await new Promise((resolve) => {
    const fileReader = new FileReader();
    fileReader.onload = (e) => resolve(fileReader.result);
    fileReader.readAsDataURL(file);
  });
  return resultBase64.split(',')[1];
}

/* File APIs */
export const uploadFile = async (body) => {
  const url = `${apiBaseUrl()}/assets/documents`;
  const fileBase64 = await readFileAsDataURL(body.file);
  return axios.post(url, { fileName: body.file.name, fileData: fileBase64 }, { headers: REQUEST_HEADERS });
}

export const removeFile = async (body) => {
  const url = `${apiBaseUrl()}/assets/documents`;
  return axios.delete(url, { data: { name: body.name }, headers: REQUEST_HEADERS });
}

/* Email APIs */
export const notifyPriceChanged = ({ listingId }) => {
  const url = `${apiBaseUrl()}/listing/${listingId}/email-changed-price`;
  return axios.post(url, {}, { headers: REQUEST_HEADERS });
}

export const notifyNewOffer = ({ transactionId, currentBidderId }) => {
  const url = `${apiBaseUrl()}/transaction/${transactionId}/email-add-bid`;
  return axios.post(url, { currentBidderId }, { headers: REQUEST_HEADERS });
}

export const sendTimedAuctionEmail = ({ listingId, transactionId, type }) => {
  const url = `${apiBaseUrl()}/timed-auction/email/listings/${listingId}/notify`;
  return axios.post(url, { transactionId, type }, { headers: REQUEST_HEADERS });
}

export const sendTimedAuctionEmailToBidder = ({ transactionId }) => {
  const url = `${apiBaseUrl()}/timed-auction/email/transactions/${transactionId}/notify`;
  return axios.post(url, { type }, { headers: REQUEST_HEADERS });
}

export const unsubscribeListing = ({ customerId, listingId, unsubscribeToken }) => {
  const url = `${apiBaseUrl()}/listing/${listingId}/unsubscribe`;
  return axios.post(url, { customerId, unsubscribeToken }, { headers: REQUEST_HEADERS });
}

export const sendInvoiceEmail = ({ transactionId }) => {
  const url = `${apiBaseUrl()}/transaction/${transactionId}/invoice/email`;
  return axios.post(url, {}, { headers: REQUEST_HEADERS });
}
export const sendInvoiceEmailTimedAuction = ({ transactionId }) => {
  const url = `${apiBaseUrl()}/transaction/${transactionId}/timed-auction/invoice/email`;
  return axios.post(url, {}, { headers: REQUEST_HEADERS });
}

/* Timed Auction APIs */
export const setTime = ({ listingId, endDate }) => {
  const url = `${apiBaseUrl()}/listing/${listingId}/time`;
  return axios.post(url, { endDate }, { headers: REQUEST_HEADERS });
}

export const removeTime = ({ listingId }) => {
  const url = `${apiBaseUrl()}/listing/${listingId}/time`;
  return axios.delete(url, { headers: REQUEST_HEADERS });
}

export const setStartDate = ({ listingId, startDate }) => {
  const url = `${apiBaseUrl()}/timed-auction/${listingId}/start-date`;
  return axios.post(url, { startDate }, { headers: REQUEST_HEADERS });
}

export const removeStartDate = ({ listingId }) => {
  const url = `${apiBaseUrl()}/timed-auction/${listingId}/start-date`;
  return axios.delete(url, { headers: REQUEST_HEADERS });
}