import React from 'react';
import { propTypes, YES } from '../../../util/types';
import { SecondaryButton } from '../../../components';
import { FormattedMessage } from 'react-intl';
import { formatMoney } from '../../../util/currency';
import { intlShape } from '../../../util/reactIntl';
import { bool, string } from 'prop-types';

import css from '../TradingForm.module.css';

const FieldSetPurchase = props => {
  const { price, form, intl, isTradingDisabled, acceptBuyItNow } = props;

  const askingPriceLabel = intl.formatMessage({
    id: "TradingForm.askingPrice"
  });

  const formatPrice = formatMoney(intl, price);

  return (
    <div className={css.fieldsetPurchase}>
      <div className={css.askingPrice}>
        <div className={css.priceValue}>
          {formatPrice}
        </div>
      </div>
      {acceptBuyItNow === YES && 
        <div className={css.submitButtonWrapper}>
          <SecondaryButton disabled={isTradingDisabled} type="submit" onClick={() => {
            form.change("button", "buyNow");
          }}>
            <FormattedMessage id="TradingForm.buyItNow" />
          </SecondaryButton>
        </div>
      }  
    </div>
  );
}

FieldSetPurchase.propTypes = {
  price: propTypes.money,
  intl: intlShape,
  isTradingDisabled: bool,
  acceptBuyItNow: string
};

export default FieldSetPurchase;