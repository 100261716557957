import { updatedEntities, denormalisedEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { updateWatchlist } from '../../util/api';
import * as log from '../../util/log';
import { parse } from '../../util/urlHelpers';
// ================ Action types ================ //

export const FETCH_WATCHLIST_REQUEST = 'app/WatchlistPage/FETCH_WATCHLIST_REQUEST';
export const FETCH_WATCHLIST_SUCCESS = 'app/WatchlistPage/FETCH_WATCHLIST_SUCCESS';
export const FETCH_WATCHLIST_ERROR = 'app/WatchlistPage/FETCH_WATCHLIST_ERROR';

export const UPDATE_WATCHLIST_REQUEST = 'app/ListingPage/UPDATE_WATCHLIST_REQUEST';
export const UPDATE_WATCHLIST_SUCCESS = 'app/ListingPage/UPDATE_WATCHLIST_SUCCESS';
export const UPDATE_WATCHLIST_ERROR = 'app/ListingPage/UPDATE_WATCHLIST_ERROR';

export const ADD_OWN_WATCHLIST_ENTITIES = 'app/WatchlistPage/ADD_OWN_WATCHLIST_ENTITIES';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  queryParams: null,
  queryInProgress: false,
  queryListingsError: null,
  currentPageResultIds: [],
  watchlistEntities: {},
  updateWatchlistInProgress: false,
  updateWatchlistError: null
};

const resultIds = data => data.data.map(l => l.id);

const merge = (state, sdkResponse) => {
  const apiResponse = sdkResponse.data;
  return {
    ...state,
    watchlistEntities: updatedEntities({ ...state.watchlistEntities }, apiResponse),
  };
};

const watchlistPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_WATCHLIST_REQUEST:
      return {
        ...state,
        queryParams: payload.queryParams,
        queryInProgress: true,
        queryListingsError: null,
        currentPageResultIds: [],
      };
    case FETCH_WATCHLIST_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        queryInProgress: false,
      };
    case FETCH_WATCHLIST_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, queryInProgress: false, queryListingsError: payload };

    case ADD_OWN_WATCHLIST_ENTITIES:
      return merge(state, payload);
    
    case UPDATE_WATCHLIST_REQUEST:
      return { ...state, queryInProgress: false, updateWatchlistError: null, updateWatchlistInProgress: true };
    case UPDATE_WATCHLIST_SUCCESS:
      return { ...state, queryInProgress: false, updateWatchlistInProgress: false };
    case UPDATE_WATCHLIST_ERROR:
      return { ...state, queryInProgress: false, updateWatchlistError: payload, updateWatchlistInProgress: false };

    default:
      return state;
  }
};

export default watchlistPageReducer;

// ================ Selectors ================ //

/**
 * Get the denormalised own listing entities with the given IDs
 *
 * @param {Object} state the full Redux store
 * @param {Array<UUID>} listingIds listing IDs to select from the store
 */
export const getOwnWatchlistById = (state, listingIds) => {
  const { watchlistEntities } = state.WatchlistPage;
  const resources = listingIds.map(id => ({
    id,
    type: 'listing',
  }));
  const throwIfNotFound = false;
  return denormalisedEntities(watchlistEntities, resources, throwIfNotFound);
};

// ================ Action creators ================ //

// This works the same way as addMarketplaceEntities,
// but we don't want to mix own listings with searched listings
// (own listings data contains different info - e.g. exact location etc.)
export const addOwnWatchlistEntities = sdkResponse => ({
  type: ADD_OWN_WATCHLIST_ENTITIES,
  payload: sdkResponse,
});

export const queryWatchlistRequest = queryParams => ({
  type: FETCH_WATCHLIST_REQUEST,
  payload: { queryParams },
});

export const queryWatchlistSuccess = response => ({
  type: FETCH_WATCHLIST_SUCCESS,
  payload: { data: response.data },
});

export const queryWatchlistError = e => ({
  type: FETCH_WATCHLIST_ERROR,
  error: true,
  payload: e,
});

export const updateWatchlistRequest = () => ({ type: UPDATE_WATCHLIST_REQUEST });
export const updateWatchlistSuccess = () => ({ type: UPDATE_WATCHLIST_SUCCESS });
export const updateWatchlistError = error => ({
  type: UPDATE_WATCHLIST_ERROR,
  error: true,
  payload: error,
});

export const updateCurrentWatchlist = ({listingId, userId, action}, {params, search, perPage}) => (dispatch, getState, sdk) => {
  dispatch(updateWatchlistRequest());
  updateWatchlist({listingId, userId: userId.uuid, action})
    .then((response) => {
      dispatch(updateWatchlistSuccess());
      const currentUser = response.data.data.currentUser.data.data;
      const { watchlist: currentWatchlist = [] } = currentUser.attributes.profile.protectedData;

      const paramWatchlistIds = currentWatchlist.join(", ");
      const queryParams = parse(search);
      const page = queryParams.page || 1;
      const params = {
        ...queryParams,
        page,
        per_page: perPage,
        pub_listId: paramWatchlistIds,
        include: ['images'],
        'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
        'limit.images': 1,
      };

      dispatch(queryWatchlistRequest(params));
      return sdk.listings.query(params);
    })
    .then(response => {
      dispatch(addOwnWatchlistEntities(response));
      dispatch(queryWatchlistSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(queryWatchlistError(storableError(e)));
      dispatch(updateWatchlistError(storableError(e)));
      log.error(e, 'update-watchlist-failed', {listingId, userId, action});
    })
}

// Throwing error for new (loadData may need that info)
export const queryOwnWatchlist = queryParams => (dispatch, getState, sdk) => {
  dispatch(queryWatchlistRequest(queryParams));

  const { perPage, ...rest } = queryParams;

  return dispatch(fetchCurrentUser())
  .then(() => {
    const currentUser = getState().user.currentUser;
    const { watchlist: currentWatchlist = [] } = 
      { ...currentUser.attributes.profile.protectedData };

    const paramWatchlistIds = currentWatchlist.join(", ");
    
    // TODO: Will handle later, this is just a hotfix
    const params = { ...rest, pub_listId: paramWatchlistIds, per_page: perPage, meta_isSold: false };
    return sdk.listings.query(params);
  })
  .then(response => {
    dispatch(addOwnWatchlistEntities(response));
    dispatch(queryWatchlistSuccess(response));
    return response;
  })
  .catch(e => {
    dispatch(queryWatchlistError(storableError(e)));
    throw e;
  });
};
