import React, { useState } from 'react'
import DropdownDown from '../../../assets/Dropdown-down.png';
import DropdownUp from '../../../assets/Dropdown-up.png';
import { bool } from 'prop-types';

import css from '../ListingPage.module.css';

const SectionWrapper = ({ children, title, isOpen, sectionRef }) => {
  const [open, setOpen] = useState(isOpen);
  return (
    <div className={css.otherSection} ref={sectionRef}>
      <div className={css.sectionHeader}>
        <h2 className={css.sectionTitle}>
          {title}
        </h2>
        <button className={css.dropdownBtn} onClick={() => setOpen(!open)}>
          <img src={open ? DropdownUp : DropdownDown} alt="dropdown" width="16px" height="8px"/>
        </button>
      </div>
      {open && 
        <div className={css.sectionContent}>
          {children}
        </div>
      }
    </div>
  )
}

SectionWrapper.defaultProps = {
  isOpen: false
}

SectionWrapper.propTypes = {
  isOpen: bool
}

export default SectionWrapper;