import { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import {
  Logo,
  Modal,
  SocialLoginButton,
  TermsOfService,
  LinkTabNavHorizontal,
  PrivacyPolicy,
} from '../../../components';
import routeConfiguration from '../../../routeConfiguration';
import { pathByRouteName } from '../../../util/routes';
import { apiBaseUrl } from '../../../util/api';
import { isSignupEmailTakenError } from '../../../util/errors';
import { login, authenticationInProgress, signup } from '../../../ducks/Auth.duck';
import { manageDisableScrolling } from '../../../ducks/UI.duck';
import { LoginForm, SignupForm } from '../../../forms';
import { FormattedMessage } from '../../../util/reactIntl';
import { ensureCurrentUser } from '../../../util/data';
import css from './AuthenticationModal.module.css';
import { FacebookLogo, GoogleLogo } from './socialLoginLogos';

const AuthenticationModal = (props) => {
  const [ isOpenAuthenticationModal, setIsOpenAuthenticationModal ] = useState(true);
  const [ isOpenTermAndConditionsModal, setIsOpenTermAndConditionsModal ] = useState(false);
  const [ isOpenPrivacyModal, setIsOpenPrivacyModal ] = useState(false);
  const getAuthInfo = Cookies.get('st-authinfo') ? JSON.parse(Cookies.get('st-authinfo').replace('j:', '')) : null;
  const [ authInfo ] = useState(getAuthInfo);
  const [ tab, setTab ] = useState('signup');

  const isAuthenticated = useSelector(state => state.Auth.isAuthenticated);
  const isOpenAuthModalRedux = useSelector(state => state.Auth.isOpenAuthModal);
  const authInProgress = useSelector(state => authenticationInProgress(state));
  const signupError = useSelector(state => state.Auth.signupError);
  const loginError = useSelector(state => state.Auth.loginError);
  const currentUser = useSelector(state => state.user.currentUser);
  const location = useLocation();
  const history = useHistory();

  const dispatch = useDispatch();
  const submitLogin = ({ email, password }) => dispatch(login(email, password));
  const submitSignup = params => dispatch(signup(params));
  const onManageDisableScrolling = (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling));

  useEffect(() => {
    Cookies.remove('st-autherror');
  }, []);

  const isLogin = tab === 'login';
  const user = ensureCurrentUser(currentUser);
  const currentUserLoaded = !!user.id;
  const showEmailVerification = currentUserLoaded && !user.attributes.emailVerified;
  if (isAuthenticated && !showEmailVerification) {
    return null;
  }

  /*  This part to handle authentication modal if users aren't authorized */

  const loginErrorMessage = (
    <div className={css.error}>
      <FormattedMessage id="AuthenticationPage.loginFailed" />
    </div>
  );

  const signupErrorMessage = (
    <div className={css.error}>
      {isSignupEmailTakenError(signupError) ? (
        <FormattedMessage id="AuthenticationPage.signupFailedEmailAlreadyTaken" />
      ) : (
        <FormattedMessage id="AuthenticationPage.signupFailed" />
      )}
    </div>
  );

  const locationFrom = location.state && location.state.from ? location.state.from : null;
  const authinfoFrom = authInfo && authInfo.from ? authInfo.from : null;
  const from = locationFrom 
    ? locationFrom 
    : authinfoFrom ? authinfoFrom : null;

  const errorMessage = (error, message) => (error ? message : null);
  const loginOrSignupError = isLogin ?
    errorMessage(loginError, loginErrorMessage) :
    errorMessage(signupError, signupErrorMessage);

  const closeAuthenticationModal = () => setIsOpenAuthenticationModal(false);

  const openTermAndConditionsModal = () => setIsOpenTermAndConditionsModal(true);
  const closeTermAndConditionsModal = () => setIsOpenTermAndConditionsModal(false);

  const openPrivacyModal = () => setIsOpenPrivacyModal(true);
  const closePrivacyModal = () => setIsOpenPrivacyModal(false);
  
  const onClickLogin = () => setTab('login');
  const onClickSignup = () => setTab('signup');

  const tabs = [
    {
      text: (
        <h1 className={css.tab}>
          <FormattedMessage id="AuthenticationPage.signupLinkText" />
        </h1>
      ),
      selected: !isLogin,
      onClick: onClickSignup,
    },
    {
      text: (
        <h1 className={css.tab}>
          <FormattedMessage id="AuthenticationPage.loginLinkText" />
        </h1>
      ),
      selected: isLogin,
      onClick: onClickLogin,
    },
  ];

  const handleSubmitSignup = values => {
    const { fname, lname, receivePromotionalEmail, confirm_password, ...rest } = values;
    const receivePromotionalEmailParam = !!(receivePromotionalEmail && receivePromotionalEmail[0]);
    const params = { firstName: fname.trim(), lastName: lname.trim(), receivePromotionalEmail: receivePromotionalEmailParam, ...rest };
    submitSignup(params).then(res => {
      if (res && res.error) {
        return;
      }
      const routes = routeConfiguration();
      const signupUrl = pathByRouteName('SignupPage', routes);
      history.push(signupUrl);
    });
  };

  const getDefaultRoutes = () => {
    const routes = routeConfiguration();
    const baseUrl = apiBaseUrl();

    // Route where the user should be returned after authentication
    // This is used e.g. with EditListingPage and ListingPage
    const fromParam = from ? `from=${from}` : '';

    // Default route where user is returned after successfull authentication
    const defaultReturn = pathByRouteName('LandingPage', routes);
    const defaultReturnParam = defaultReturn ? `&defaultReturn=${defaultReturn}` : '';

    // Route for confirming user data before creating a new user
    const defaultConfirm = pathByRouteName('ConfirmPage', routes);
    const defaultConfirmParam = defaultConfirm ? `&defaultConfirm=${defaultConfirm}` : '';

    return { baseUrl, fromParam, defaultReturnParam, defaultConfirmParam };
  };

  const authWithFacebook = () => {
    const defaultRoutes = getDefaultRoutes();
    const { baseUrl, fromParam, defaultReturnParam, defaultConfirmParam } = defaultRoutes;
    window.location.href = `${baseUrl}/api/auth/facebook?${fromParam}${defaultReturnParam}${defaultConfirmParam}`;
  };

  const authWithGoogle = () => {
    const defaultRoutes = getDefaultRoutes();
    const { baseUrl, fromParam, defaultReturnParam, defaultConfirmParam } = defaultRoutes;
    window.location.href = `${baseUrl}/api/auth/google?${fromParam}${defaultReturnParam}${defaultConfirmParam}`;
  };

  const showFacebookLogin = !!process.env.REACT_APP_FACEBOOK_APP_ID;
  const showGoogleLogin = !!process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const showSocialLogins = showFacebookLogin || showGoogleLogin;

  const facebookButtonText = isLogin ? (
    <FormattedMessage id="AuthenticationPage.loginWithFacebook" />
  ) : (
    <FormattedMessage id="AuthenticationPage.signupWithFacebook" />
  );

  const googleButtonText = isLogin ? (
    <FormattedMessage id="AuthenticationPage.loginWithGoogle" />
  ) : (
    <FormattedMessage id="AuthenticationPage.signupWithGoogle" />
  );

  const socialLoginButtonsMaybe = showSocialLogins ? (
    <div className={css.idpButtons}>
      <div className={css.socialButtonsOr}>
        <span className={css.socialButtonsOrText}>
          <FormattedMessage id="AuthenticationPage.or" />
        </span>
      </div>

      {showFacebookLogin ? (
        <div className={css.socialButtonWrapper}>
          <SocialLoginButton onClick={() => authWithFacebook()}>
            <span className={css.buttonIcon}>{FacebookLogo}</span>
            {facebookButtonText}
          </SocialLoginButton>
        </div>
      ) : null}

      {showGoogleLogin ? (
        <div className={css.socialButtonWrapper}>
          <SocialLoginButton onClick={() => authWithGoogle()}>
            <span className={css.buttonIcon}>{GoogleLogo}</span>
            {googleButtonText}
          </SocialLoginButton>
        </div>
      ) : null}
    </div>
  ) : null;

  const authenticationForms = (
    <div className={css.content}>
      <Logo
        format="desktop"
        className={css.logo}
        alt="Analogr"
      />
      <LinkTabNavHorizontal className={css.tabs} tabs={tabs} tabRootClassName={css.tabRoot} />
      {loginOrSignupError}

      {isLogin ? (
        <LoginForm className={css.loginForm} onSubmit={submitLogin} inProgress={authInProgress} />
      ) : (
        <SignupForm
          className={css.signupForm}
          onSubmit={handleSubmitSignup}
          inProgress={authInProgress}
          onOpenTermsOfService={openTermAndConditionsModal}
          onOpenPrivacy={openPrivacyModal}
        />
      )}

      {socialLoginButtonsMaybe}
    </div>
  );

  return (
    <>
      <Modal
        id="AuthenticationModal"
        isOpen={isOpenAuthModalRedux}
        onClose={closeAuthenticationModal}
        hideClose
        onManageDisableScrolling={onManageDisableScrolling}
        usePortal
        containerClassName={css.authenticationModalContainer}
      >
        {authenticationForms}
      </Modal>

      <Modal
        id="AuthenticationModal.tos"
        isOpen={isOpenTermAndConditionsModal}
        onClose={closeTermAndConditionsModal}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div className={css.termsWrapper}>
          <h2 className={css.termsHeading}>
            <FormattedMessage id="AuthenticationPage.termsHeading" />
          </h2>
          <TermsOfService />
        </div>
      </Modal>

      <Modal
        id="AuthenticationModal.privacy"
        isOpen={isOpenPrivacyModal}
        onClose={closePrivacyModal}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div className={css.termsWrapper}>
          <h2 className={css.termsHeading}>
            <FormattedMessage id="PrivacyPolicyPage.heading" />
          </h2>
          <PrivacyPolicy />
        </div>
      </Modal>
    </>
  )
}

AuthenticationModal.propTypes = {
  tab: PropTypes.oneOf(['login', 'signup']),
}

export default AuthenticationModal

