import React from 'react';
import { FieldSelect } from '../../components';

import css from './EditListingMicDetailsForm.module.css';

const CustomMicPairSelectFieldMaybe = props => {
  const {
    name,
    id,
    powerSupply,
    cabling,
    shockMount,
    micCase,
    carryCase,
    powerSupplyA,
    powerSupplyB,
    cablingA,
    cablingB,
    shockMountA,
    shockMountB,
    micCaseA,
    micCaseB,
    carryCaseA,
    carryCaseB,
    intl,
  } = props;
  const powerSupplyLabel = intl.formatMessage({
    id: 'EditListingMicDetailsForm.powerSupplyLabel',
  });
  const cablingLabel = intl.formatMessage({
    id: 'EditListingMicDetailsForm.cablingLabel',
  });
  const shockMountLabel = intl.formatMessage({
    id: 'EditListingMicDetailsForm.shockMountLabel',
  });
  const micCaseLabel = intl.formatMessage({
    id: 'EditListingMicDetailsForm.micCaseLabel',
  });
  const carryCaseLabel = intl.formatMessage({
    id: 'EditListingMicDetailsForm.carryCaseLabel',
  });
  return powerSupply ? (
    <FieldSelect className={css.micPair} name={name} id={id} label={powerSupplyLabel}>
      <option disabled value=""></option>
      {powerSupply.map(c => (
        <option key={c.key} value={c.key}>
          {intl.formatMessage({
            id: c.label
          })}
        </option>
      ))}
    </FieldSelect>
  ) : cabling ? (
    <FieldSelect className={css.micPair} name={name} id={id} label={cablingLabel}>
      <option disabled value=""></option>
      {cabling.map(c => (
        <option key={c.key} value={c.key}>
          {intl.formatMessage({
            id: c.label
          })}
        </option>
      ))}
    </FieldSelect>
  ) : shockMount ? (
    <FieldSelect className={css.micPair} name={name} id={id} label={shockMountLabel}>
      <option disabled value=""></option>
      {shockMount.map(c => (
        <option key={c.key} value={c.key}>
          {intl.formatMessage({
            id: c.label
          })}
        </option>
      ))}
    </FieldSelect>
  ) : micCase ? (
    <FieldSelect className={css.micPair} name={name} id={id} label={micCaseLabel}>
      <option disabled value=""></option>
      {micCase.map(c => (
        <option key={c.key} value={c.key}>
          {intl.formatMessage({
            id: c.label
          })}
        </option>
      ))}
    </FieldSelect>
  ) : carryCase ? (
    <FieldSelect className={css.micPair} name={name} id={id} label={carryCaseLabel}>
      <option disabled value=""></option>
      {carryCase.map(c => (
        <option key={c.key} value={c.key}>
          {intl.formatMessage({
            id: c.label
          })}
        </option>
      ))}
    </FieldSelect>
  ) : powerSupplyA ? (
    <FieldSelect className={css.micPair} name={name} id={id} label={powerSupplyLabel}>
      <option disabled value=""></option>
      {powerSupplyA.map(c => (
        <option key={c.key} value={c.key}>
          {intl.formatMessage({
            id: c.label
          })}
        </option>
      ))}
    </FieldSelect>
  ) : powerSupplyB ? (
    <FieldSelect className={css.micPair} name={name} id={id} label={powerSupplyLabel}>
      <option disabled value=""></option>
      {powerSupplyB.map(c => (
        <option key={c.key} value={c.key}>
          {intl.formatMessage({
            id: c.label
          })}
        </option>
      ))}
    </FieldSelect>
  ) : cablingA ? (
    <FieldSelect className={css.micPair} name={name} id={id} label={cablingLabel}>
      <option disabled value=""></option>
      {cablingA.map(c => (
        <option key={c.key} value={c.key}>
          {intl.formatMessage({
            id: c.label
          })}
        </option>
      ))}
    </FieldSelect>
  ) : cablingB ? (
    <FieldSelect className={css.micPair} name={name} id={id} label={cablingLabel}>
      <option disabled value=""></option>
      {cablingB.map(c => (
        <option key={c.key} value={c.key}>
          {intl.formatMessage({
            id: c.label
          })}
        </option>
      ))}
    </FieldSelect>
  ) : shockMountA ? (
    <FieldSelect className={css.micPair} name={name} id={id} label={shockMountLabel}>
      <option disabled value=""></option>
      {shockMountA.map(c => (
        <option key={c.key} value={c.key}>
          {intl.formatMessage({
            id: c.label
          })}
        </option>
      ))}
    </FieldSelect>
  ) : shockMountB ? (
    <FieldSelect className={css.micPair} name={name} id={id} label={shockMountLabel}>
      <option disabled value=""></option>
      {shockMountB.map(c => (
        <option key={c.key} value={c.key}>
          {intl.formatMessage({
            id: c.label
          })}
        </option>
      ))}
    </FieldSelect>
  ) : micCaseA ? (
    <FieldSelect className={css.micPair} name={name} id={id} label={micCaseLabel}>
      <option disabled value=""></option>
      {micCaseA.map(c => (
        <option key={c.key} value={c.key}>
          {intl.formatMessage({
            id: c.label
          })}
        </option>
      ))}
    </FieldSelect>
  ) : micCaseB ? (
    <FieldSelect className={css.micPair} name={name} id={id} label={micCaseLabel}>
      <option disabled value=""></option>
      {micCaseB.map(c => (
        <option key={c.key} value={c.key}>
          {intl.formatMessage({
            id: c.label
          })}
        </option>
      ))}
    </FieldSelect>
  ) : carryCaseA ? (
    <FieldSelect className={css.micPair} name={name} id={id} label={carryCaseLabel}>
      <option disabled value=""></option>
      {carryCaseA.map(c => (
        <option key={c.key} value={c.key}>
          {intl.formatMessage({
            id: c.label
          })}
        </option>
      ))}
    </FieldSelect>
  ) : carryCaseB ? (
    <FieldSelect className={css.micPair} name={name} id={id} label={carryCaseLabel}>
      <option disabled value=""></option>
      {carryCaseB.map(c => (
        <option key={c.key} value={c.key}>
          {intl.formatMessage({
            id: c.label
          })}
        </option>
      ))}
    </FieldSelect>
  ) : null;
};

export default CustomMicPairSelectFieldMaybe;
