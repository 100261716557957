import React from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { EditListingConsignForm } from '../../forms';
import { findOptionsForSelectFilter } from '../../util/search';
import config from '../../config';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { SESSION_FORM_KEYS, YES, NO } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import { createCurrencyObject } from '../../util/currency';

import css from './EditConsignListingPanel.module.css';

const { Money } = sdkTypes;

const DESCRIPTION_MAX_LENGTH = 1000;

const EditConsignListingPanel = props => {
  const {
    images,
    onImageUpload,
    onUpdateImageOrder,
    onRemoveImage,
    className,
    rootClassName,
    ready,
    onSubmit,
    updateInProgress,
    errors,
    recordImages,
  } = props;

  const imageIds = images => {
    return images
      ? images.map(img => img.imageId ? img.imageId.uuid : img.id.uuid)
      : null
  };

  const classes = classNames(rootClassName || css.root, className);
  const serviceDocuments = useSelector(state => state.EditConsignListingPage.serviceDocuments);
  const authDocuments = useSelector(state => state.EditConsignListingPage.authDocuments);

  const initialData = JSON.parse(sessionStorage.getItem(SESSION_FORM_KEYS.CREATE_LISTING_VALUES)) || {};
  const panelTitle = <FormattedMessage id="EditListingConsignForm.createListingTitle" />;
  const categoryOptions = findOptionsForSelectFilter('category', config.custom.filters);
  const conditionOptions = findOptionsForSelectFilter('condition', config.custom.filters);
  const issueOptions = findOptionsForSelectFilter('issue', config.custom.filters);
  const sellingPairOptions = findOptionsForSelectFilter('sellingPair', config.custom.filters);
  const sequentSerialOptions = findOptionsForSelectFilter('sequentSerial', config.custom.filters);
  const originalOptions = findOptionsForSelectFilter('original', config.custom.filters);
  const micPairOptions = findOptionsForSelectFilter('micPair', config.custom.filters);
  const askingPriceOptions = findOptionsForSelectFilter('askingPrice', config.custom.filters);
  const acceptOfferOptions = findOptionsForSelectFilter('acceptOffer', config.custom.filters);
  const acceptBuyItNowOptions = findOptionsForSelectFilter('acceptBuyItNow', config.custom.filters);
  const highestOfferOptions = findOptionsForSelectFilter('highestOffer', config.custom.filters);
  const authenticityDocsOptions = findOptionsForSelectFilter('authenticity', config.custom.filters);
  const estimatePriceOptions = findOptionsForSelectFilter('showEstimatePrice', config.custom.filters);

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingConsignForm
        className={css.form}
        name="EditListingConsignForm"
        initialValues={{
          title: initialData.title || '',
          description: initialData.description || '',
          price: initialData.price || null,
          category: initialData.category || '',
          itemDetails: initialData.itemDetails || { quantity: 1 },
          micDetails: initialData.micDetails || {},
          authenticity: initialData.authenticity || [''],
          service: initialData.service || {},
          youtubeLinks: initialData.youtubeLinks || [''],
          pricing: initialData.pricing || {},
          shipping: initialData.shipping || { domestic: null, international: null },
          featuredRecords: initialData.featuredRecords || [''],
          serviceDateTime: { date: initialData.serviceDateTime ? new Date(initialData.serviceDateTime.date) : new Date() },
          images,
          recordImages,
          serviceDocuments: serviceDocuments.length > 0 ? [...serviceDocuments, ''] : ['']
        }}
        onSubmit={values => {
          const {
            title,
            description,
            category,
            price = {},
            authenticity = [],
            service = {},
            shipping = {},
            pricing = {},
            micDetails = {},
            itemDetails = {},
            featuredRecords = [],
            serviceDateTime,
            youtubeLinks,
            collectionName
          } = values;

          const isMicrophones = category === "microphones";

          // Return an array of image ids
          const newImages = imageIds(images);
          const newAuthenticity = authenticity.filter(auth => !isEmpty(auth));
          const newFeaturedRecords = featuredRecords.filter(record => !isEmpty(record) && record.url);

          newAuthenticity.length = authDocuments.length;
          newAuthenticity.forEach((auth, index) => {
            auth['file'] = authDocuments[index];
          });

          newFeaturedRecords.forEach((record, index) => {
            record['imgUrl'] = recordImages[index];
          });

          // Process and filter the keywords for searching
          const { brand, model, year, sku } = itemDetails;
          const { skuMicPair, brandMicPair, modelMicPair, yearMicPair, yearMicPairA, yearMicPairB } = micDetails;
          const details = isMicrophones ? { micDetails } : { itemDetails };
          
          const filterDetailsForKeywords = isMicrophones ? [brandMicPair, modelMicPair, yearMicPair, yearMicPairA, yearMicPairB, skuMicPair] : [brand, model, year, sku];
          const keywords = [...filterDetailsForKeywords, category]
                            .filter(keyword => keyword)
                            .join(", ");

          const { minEstPrice, maxEstPrice } = pricing;

          const updateValues = {
            title: title.trim(),
            description: 'extend',
            price: price || { amount: 0, currency: config.currency },
            images: newImages,
            publicData: {
              collectionName,
              keywords,
              extendDescription: description,
              category,
              authenticity: newAuthenticity,
              service: {
                ...service,
                documents: serviceDocuments,
                dateTime: serviceDateTime
              },
              shipping,
              featuredRecords: newFeaturedRecords,
              pricing: {
                ...pricing,
                acceptBuyItNow: pricing.askingPrice === YES ? pricing.acceptBuyItNow : NO, 
                displayHighestOffer: pricing.acceptOffer === YES ? pricing.displayHighestOffer : NO,
                minEstPrice: minEstPrice && createCurrencyObject(minEstPrice),
                maxEstPrice: maxEstPrice && createCurrencyObject(maxEstPrice)
              },
              youtubeLinks: youtubeLinks.filter(url => url),
              ...details
            },
          };

          sessionStorage.setItem(SESSION_FORM_KEYS.CREATE_LISTING_VALUES, JSON.stringify(values));
          
          onSubmit(updateValues);
        }}
        ready={ready}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        categoryOptions={categoryOptions}
        conditionOptions={conditionOptions}
        issueOptions={issueOptions}
        askingPriceOptions={askingPriceOptions}
        acceptOfferOptions={acceptOfferOptions}
        acceptBuyItNowOptions={acceptBuyItNowOptions}
        highestOfferOptions={highestOfferOptions}
        estimatePriceOptions={estimatePriceOptions}
        sellingPairOptions={sellingPairOptions}
        sequentSerialOptions={sequentSerialOptions}
        conditionsMicPairOptions={conditionOptions}
        issuesMicPairOptions={issueOptions}
        originalOptions={originalOptions}
        micPairOptions={micPairOptions}
        authenticityDocsOptions={authenticityDocsOptions}
        images={images}
        recordImages={recordImages}
        onImageUpload={onImageUpload}
        onUpdateImageOrder={onUpdateImageOrder}
        onRemoveImage={onRemoveImage}
      />
    </div>
  );
};

EditConsignListingPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
  disabled: false,
};

EditConsignListingPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditConsignListingPanel;
