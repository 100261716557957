import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney, createMoney } from '../../util/currency';
import { propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import { object, string, oneOfType } from 'prop-types';

import css from './BookingBreakdown.module.css';

const { Money } = sdkTypes

const LineItemShippingMaybe = props => {
  const { shippingUnitPrice, selectedShippingOption, intl } = props;

  const shippingPrice = createMoney(shippingUnitPrice);
  const shippingLabel = <FormattedMessage id={`BookingBreakdown.${selectedShippingOption}`} />;
  
  const formattedTotalPrice = formatMoney(intl, shippingPrice);

  return (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        {shippingLabel}
      </span>
      <span className={css.itemValue}>{formattedTotalPrice}</span>
    </div>
  );
};

LineItemShippingMaybe.propTypes = {
  shippingUnitPrice: oneOfType([propTypes.money, object]),
  intl: intlShape.isRequired,
  selectedShippingOption: string
};

export default LineItemShippingMaybe;
