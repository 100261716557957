import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { Button, IconClose } from '..';
import { FormattedMessage } from '../../util/reactIntl';

import css from './InlineModal.module.css';

const InlineModal = props => {
  const intl = useIntl();
  const { id, isOpen, onClose, className, closeButtonClassName, children, hideClose, lightCloseButton, isClosedClassName } = props;
  const modalClass = isOpen ? css.isOpen : isClosedClassName;
  const classes = classNames(modalClass, className);
  const closeModalMessage = intl.formatMessage({ id: 'Modal.closeModal' });
  const closeButtonClasses = classNames(css.close, closeButtonClassName, {
    [css.closeLight]: lightCloseButton,
  });
  const closeBtn = isOpen && !hideClose ? (
    <Button
      onClick={onClose}
      rootClassName={closeButtonClasses}
      title={closeModalMessage}
    >
      <span className={css.closeText}>
        <FormattedMessage id="Modal.close" />
      </span>
      <IconClose rootClassName={css.closeIcon} />
    </Button>
  ) : null;

  return (
    <div id={id} className={classes}>
      {closeBtn}
      {children}
    </div>
  )
};

InlineModal.defaultProps = {
  children: null,
  className: null,
  closeButtonMessage: null,
  containerClassName: null,
  lightCloseButton: false,
  isClosedClassName: css.isClosed,
  isOpen: false,
  onClose: null,
  usePortal: false,
};

const { bool, func, node, string } = PropTypes;

InlineModal.propTypes = {
  children: node,
  className: string,
  closeButtonMessage: node,
  contentClassName: string,
  lightCloseButton: bool,
  id: string.isRequired,
  isClosedClassName: string,
  isOpen: bool,
  onClose: func.isRequired,
  usePortal: bool,
};

export default InlineModal;
