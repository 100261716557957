import pick from 'lodash/pick';
import config from '../../config';
import { createPaymentIntent, initiatePrivileged, transitionPrivileged, updateBiddingData } from '../../util/api';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import {
  TRANSITION_REQUEST_PAYMENT,
  TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY,
  TRANSITION_CONFIRM_PAYMENT,
  isPrivileged,
  TRANSITION_BYPASS_STRIPE_AFTER_ENQUIRY,
  TRANSITION_BYPASS_STRIPE,
  TRANSITION_AUTO_DECLINE_BELOW_RESERVE_PRICE,
} from '../../util/transaction';
import * as log from '../../util/log';
import { fetchCurrentUserHasOrdersSuccess, fetchCurrentUser } from '../../ducks/user.duck';
import { currentUserShowSuccess } from '../../ducks/user.duck';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { notifyNewOffer } from '../../util/custom-api';
import { convertMoneyAmount } from '../../util/currency';

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/CheckoutPage/SET_INITIAL_VALUES';

export const INITIATE_ORDER_REQUEST = 'app/CheckoutPage/INITIATE_ORDER_REQUEST';
export const INITIATE_ORDER_SUCCESS = 'app/CheckoutPage/INITIATE_ORDER_SUCCESS';
export const INITIATE_ORDER_ERROR = 'app/CheckoutPage/INITIATE_ORDER_ERROR';

export const CONFIRM_PAYMENT_REQUEST = 'app/CheckoutPage/CONFIRM_PAYMENT_REQUEST';
export const CONFIRM_PAYMENT_SUCCESS = 'app/CheckoutPage/CONFIRM_PAYMENT_SUCCESS';
export const CONFIRM_PAYMENT_ERROR = 'app/CheckoutPage/CONFIRM_PAYMENT_ERROR';

export const SPECULATE_TRANSACTION_REQUEST = 'app/ListingPage/SPECULATE_TRANSACTION_REQUEST';
export const SPECULATE_TRANSACTION_SUCCESS = 'app/ListingPage/SPECULATE_TRANSACTION_SUCCESS';
export const SPECULATE_TRANSACTION_ERROR = 'app/ListingPage/SPECULATE_TRANSACTION_ERROR';

export const STRIPE_CUSTOMER_REQUEST = 'app/CheckoutPage/STRIPE_CUSTOMER_REQUEST';
export const STRIPE_CUSTOMER_SUCCESS = 'app/CheckoutPage/STRIPE_CUSTOMER_SUCCESS';
export const STRIPE_CUSTOMER_ERROR = 'app/CheckoutPage/STRIPE_CUSTOMER_ERROR';

export const SAVE_SHIPPING_ADDRESS_REQUEST = 'app/ShippingAddressPage/SAVE_SHIPPING_ADDRESS_REQUEST';
export const SAVE_SHIPPING_ADDRESS_SUCCESS = 'app/ShippingAddressPage/SAVE_SHIPPING_ADDRESS_SUCCESS';
export const SAVE_SHIPPING_ADDRESS_ERROR = 'app/ShippingAddressPage/SAVE_SHIPPING_ADDRESS_ERROR';

export const UPDATE_BIDDING_DATA_REQUEST = 'app/ShippingAddressPage/UPDATE_BIDDING_DATA_REQUEST';
export const UPDATE_BIDDING_DATA_SUCCESS = 'app/ShippingAddressPage/UPDATE_BIDDING_DATA_SUCCESS';
export const UPDATE_BIDDING_DATA_ERROR = 'app/ShippingAddressPage/UPDATE_BIDDING_DATA_ERROR';

export const CREATE_PAYMENT_INTENT_REQUEST = 'app/ShippingAddressPage/CREATE_PAYMENT_INTENT_REQUEST';
export const CREATE_PAYMENT_INTENT_ERROR = 'app/ShippingAddressPage/CREATE_PAYMENT_INTENT_ERROR';

// ================ Reducer ================ //

const initialState = {
  listing: null,
  offer: null,
  shipping: null,
  bookingData: null,
  bookingDates: null,
  speculateTransactionInProgress: false,
  speculateTransactionError: null,
  speculatedTransaction: null,
  transaction: null,
  initiateOrderError: null,
  confirmPaymentError: null,
  stripeCustomerFetched: false,
  saveShippingAddressError: null,
  saveShippingAddressInProgress: false,
  updateBiddingDataError: null,
  updateBiddingDataInProgress: false,
  createPaymentIntentInProgress: false,
  createPaymentIntentError: null,
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SPECULATE_TRANSACTION_REQUEST:
      return {
        ...state,
        speculateTransactionInProgress: true,
        speculateTransactionError: null,
        speculatedTransaction: null,
      };
    case SPECULATE_TRANSACTION_SUCCESS:
      return {
        ...state,
        speculateTransactionInProgress: false,
        speculatedTransaction: payload.transaction,
      };
    case SPECULATE_TRANSACTION_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return {
        ...state,
        speculateTransactionInProgress: false,
        speculateTransactionError: payload,
      };

    case INITIATE_ORDER_REQUEST:
      return { ...state, initiateOrderError: null };
    case INITIATE_ORDER_SUCCESS:
      return { ...state, transaction: payload };
    case INITIATE_ORDER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, initiateOrderError: payload };

    case CONFIRM_PAYMENT_REQUEST:
      return { ...state, confirmPaymentError: null };
    case CONFIRM_PAYMENT_SUCCESS:
      return state;
    case CONFIRM_PAYMENT_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, confirmPaymentError: payload };

    case STRIPE_CUSTOMER_REQUEST:
      return { ...state, stripeCustomerFetched: false };
    case STRIPE_CUSTOMER_SUCCESS:
      return { ...state, stripeCustomerFetched: true };
    case STRIPE_CUSTOMER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, stripeCustomerFetchError: payload };
    case SAVE_SHIPPING_ADDRESS_REQUEST:
      return {
        ...state,
        saveShippingAddressInProgress: true,
        saveShippingAddressError: null,
      };
    case SAVE_SHIPPING_ADDRESS_SUCCESS:
      return { ...state, saveShippingAddressInProgress: false };
    case SAVE_SHIPPING_ADDRESS_ERROR:
      return { ...state, saveShippingAddressInProgress: false, saveShippingAddressError: payload };
    case UPDATE_BIDDING_DATA_REQUEST:
      return {
        ...state,
        updateBiddingDataInProgress: true,
        updateBiddingDataError: null,
      };
    case UPDATE_BIDDING_DATA_SUCCESS:
      return { ...state, updateBiddingDataInProgress: false };
    case UPDATE_BIDDING_DATA_ERROR:
      return { ...state, updateBiddingDataInProgress: false, updateBiddingDataError: payload };
    case CREATE_PAYMENT_INTENT_REQUEST:
      return { ...state, createPaymentIntentInProgress: true };
    case CREATE_PAYMENT_INTENT_ERROR:
      return { ...state, createPaymentIntentInProgress: false, createPaymentIntentError: payload };
    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

const initiateOrderRequest = () => ({ type: INITIATE_ORDER_REQUEST });

const initiateOrderSuccess = order => ({
  type: INITIATE_ORDER_SUCCESS,
  payload: order,
});

const initiateOrderError = e => ({
  type: INITIATE_ORDER_ERROR,
  error: true,
  payload: e,
});

const confirmPaymentRequest = () => ({ type: CONFIRM_PAYMENT_REQUEST });

const confirmPaymentSuccess = orderId => ({
  type: CONFIRM_PAYMENT_SUCCESS,
  payload: orderId,
});

const confirmPaymentError = e => ({
  type: CONFIRM_PAYMENT_ERROR,
  error: true,
  payload: e,
});

export const speculateTransactionRequest = () => ({ type: SPECULATE_TRANSACTION_REQUEST });

export const speculateTransactionSuccess = transaction => ({
  type: SPECULATE_TRANSACTION_SUCCESS,
  payload: { transaction },
});

export const speculateTransactionError = e => ({
  type: SPECULATE_TRANSACTION_ERROR,
  error: true,
  payload: e,
});

export const stripeCustomerRequest = () => ({ type: STRIPE_CUSTOMER_REQUEST });
export const stripeCustomerSuccess = () => ({ type: STRIPE_CUSTOMER_SUCCESS });
export const stripeCustomerError = e => ({
  type: STRIPE_CUSTOMER_ERROR,
  error: true,
  payload: e,
});

export const saveShippingAddressRequest = () => ({ type: SAVE_SHIPPING_ADDRESS_REQUEST });
export const saveShippingAddressSuccess = () => ({ type: SAVE_SHIPPING_ADDRESS_SUCCESS });
export const saveShippingAddressError = error => ({
  type: SAVE_SHIPPING_ADDRESS_ERROR,
  payload: error,
  error: true,
});

export const updateBiddingDataRequest = () => ({ type: UPDATE_BIDDING_DATA_REQUEST });
export const updateBiddingDataSuccess = () => ({ type: UPDATE_BIDDING_DATA_SUCCESS });
export const updateBiddingDataError = error => ({
  type: UPDATE_BIDDING_DATA_ERROR,
  payload: error,
  error: true,
});

export const createPaymentIntentRequest = () => ({ type: CREATE_PAYMENT_INTENT_REQUEST });
export const createPaymentIntentError = error => ({
  type: CREATE_PAYMENT_INTENT_ERROR,
  payload: error,
  error: true,
});

/* ================ Thunks ================ */

export const initiateOrder = (orderParams, transactionId) => (dispatch, getState, sdk) => {
  dispatch(initiateOrderRequest());

  // If we already have a transaction ID, we should transition, not
  // initiate.
  const isTransition = !!transactionId;
  const transition = isTransition
    ? TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY
    : TRANSITION_REQUEST_PAYMENT;
  const isPrivilegedTransition = isPrivileged(transition);

  const bookingData = {
    offer: {
      amount: orderParams.offer.amount / 100, // For the notification email as we can't calculate in the template
      currency: orderParams.offer.currency
    },
    lineItems: orderParams.lineItems,
    remainingTime: orderParams.remainingTime
  };

  const bodyParams = isTransition
    ? {
        id: transactionId,
        transition,
        params: orderParams,
      }
    : {
        processAlias: config.bookingProcessAlias,
        transition,
        params: orderParams,
      };
  const queryParams = {
    include: ['booking', 'provider', 'customer', 'listing'],
    expand: true,
  };

  const handleSuccess = response => {
    const entities = denormalisedResponseEntities(response);
    const order = entities[0];
    dispatch(updateCurrentBiddingData({
      listingId: order.listing.id,
      userId: order.customer.id,
      offerPrice: orderParams.offer.amount,
      transactionId: order.id.uuid
    }));
    dispatch(initiateOrderSuccess(order));
    dispatch(fetchCurrentUserHasOrdersSuccess(true));
    return order;
  };

  const handleError = e => {
    dispatch(initiateOrderError(storableError(e)));
    const transactionIdMaybe = transactionId ? { transactionId: transactionId.uuid } : {};
    log.error(e, 'initiate-order-failed', {
      ...transactionIdMaybe,
      listingId: orderParams.listingId.uuid,
      bookingStart: orderParams.bookingStart,
      bookingEnd: orderParams.bookingEnd,
    });
    throw e;
  };

  if (isTransition && isPrivilegedTransition) {
    // transition privileged
    return transitionPrivileged({ isSpeculative: false, bookingData, bodyParams, queryParams })
      .then(handleSuccess)
      .catch(handleError);
  } else if (isTransition) {
    // transition non-privileged
    return sdk.transactions
      .transition(bodyParams, queryParams)
      .then(handleSuccess)
      .catch(handleError);
  } else if (isPrivilegedTransition) {
    // initiate privileged
    return initiatePrivileged({ isSpeculative: false, bookingData, bodyParams, queryParams })
      .then(handleSuccess)
      .catch(handleError);
  } else {
    // initiate non-privileged
    return sdk.transactions
      .initiate(bodyParams, queryParams)
      .then(handleSuccess)
      .catch(handleError);
  }
};

export const initiateOrderWithoutStripe = (orderParams, transactionId) => (dispatch, getState, sdk) => {
  dispatch(initiateOrderRequest());

  // If we already have a transaction ID, we should transition, not
  // initiate.
  const isTransition = !!transactionId;
  const transition = isTransition
    ? TRANSITION_BYPASS_STRIPE_AFTER_ENQUIRY
    : TRANSITION_BYPASS_STRIPE

  const offerData = {
    offer: {
      amount: orderParams.offer.amount / 100, // For the notification email as we can't calculate in the template
      currency: orderParams.offer.currency
    },
    lineItems: orderParams.lineItems
  };

  const bodyParams = isTransition
    ? {
        id: transactionId,
        transition,
        params: orderParams,
      }
    : {
        processAlias: config.bookingProcessAlias,
        transition,
        params: orderParams,
      };
  const queryParams = {
    include: ['booking', 'provider', 'customer', 'listing'],
    expand: true,
  };

  const handleSuccess = response => {
    const entities = denormalisedResponseEntities(response);
    const order = entities[0];
    dispatch(updateCurrentBiddingData({
      listingId: order.listing.id,
      userId: order.customer.id,
      offerPrice: orderParams.offer.amount,
      transactionId: order.id.uuid
    }));
    dispatch(initiateOrderSuccess(order));
    dispatch(fetchCurrentUserHasOrdersSuccess(true));
    return order;
  };

  const handleError = e => {
    dispatch(initiateOrderError(storableError(e)));
    const transactionIdMaybe = transactionId ? { transactionId: transactionId.uuid } : {};
    log.error(e, 'initiate-order-failed', {
      ...transactionIdMaybe,
      listingId: orderParams.listingId.uuid,
    });
    throw e;
  };

  if (isTransition) {
    // transition privileged
    return transitionPrivileged({ isSpeculative: false, bookingData: offerData, bodyParams, queryParams })
      .then(handleSuccess)
      .catch(handleError);
  } else {
    // initiate privileged
    return initiatePrivileged({ isSpeculative: false, bookingData: offerData, bodyParams, queryParams })
      .then(handleSuccess)
      .catch(handleError);
  }
};

export const initiateOrderAutomaticDeclined = (orderParams, transactionId) => (dispatch, getState, sdk) => {
  dispatch(initiateOrderRequest());

  const transition = TRANSITION_AUTO_DECLINE_BELOW_RESERVE_PRICE;

  const offerData = {
    offer: {
      amount: convertMoneyAmount(orderParams.offer.amount), // For the notification email as we can't calculate in the template
      currency: orderParams.offer.currency
    },
    lineItems: orderParams.lineItems
  };

  const bodyParams = {
    processAlias: config.bookingProcessAlias,
    transition,
    params: orderParams,
  };
  const queryParams = {
    include: ['booking', 'provider', 'customer', 'listing'],
    expand: true,
  };

  const handleSuccess = response => {
    const entities = denormalisedResponseEntities(response);
    const order = entities[0];
    dispatch(updateCurrentBiddingData({
      listingId: order.listing.id,
      userId: order.customer.id,
      offerPrice: orderParams.offer.amount,
      transactionId: order.id.uuid
    }));
    dispatch(initiateOrderSuccess(order));
    dispatch(fetchCurrentUserHasOrdersSuccess(true));
    return order;
  };

  const handleError = e => {
    dispatch(initiateOrderError(storableError(e)));
    const transactionIdMaybe = transactionId ? { transactionId: transactionId.uuid } : {};
    log.error(e, 'initiate-order-failed', {
      ...transactionIdMaybe,
      listingId: orderParams.listingId.uuid,
    });
    throw e;
  };

  return initiatePrivileged({ isSpeculative: false, bookingData: offerData, bodyParams, queryParams })
    .then(handleSuccess)
    .catch(handleError);
};

export const initiateAffirmOrder = (orderParams, transactionId) => (dispatch, getState, sdk) => {
  // If we already have a transaction ID, we should transition, not
  // initiate.
  const isTransition = !!transactionId;
  const transition = isTransition
    ? TRANSITION_BYPASS_STRIPE_AFTER_ENQUIRY
    : TRANSITION_BYPASS_STRIPE

  const offerData = {
    offer: {
      amount: convertMoneyAmount(orderParams.offer.amount), // For the notification email as we can't calculate in the template
      currency: orderParams.offer.currency
    },
    lineItems: orderParams.lineItems
  };

  const bodyParams = isTransition
    ? {
        id: transactionId,
        transition,
        params: orderParams,
      }
    : {
        processAlias: config.bookingProcessAlias,
        transition,
        params: orderParams,
      };
  const queryParams = {
    include: ['booking', 'provider', 'customer', 'listing'],
    expand: true,
  };

  const handleSuccess = async response => {
    const entities = denormalisedResponseEntities(response);
    const order = entities[0];
    dispatch(updateCurrentBiddingData({
      listingId: order.listing.id,
      userId: order.customer.id,
      offerPrice: orderParams.offer.amount,
      transactionId: order.id.uuid
    }));
    dispatch(initiateOrderSuccess(order));
    dispatch(fetchCurrentUserHasOrdersSuccess(true));
    return order;
  };

  const handleError = e => {
    dispatch(initiateOrderError(storableError(e)));
    const transactionIdMaybe = transactionId ? { transactionId: transactionId.uuid } : {};
    log.error(e, 'initiate-order-failed', {
      ...transactionIdMaybe,
      listingId: orderParams.listingId.uuid,
    });
    throw e;
  };

  if (isTransition) {
    // transition privileged
    return transitionPrivileged({ isSpeculative: false, bookingData: offerData, bodyParams, queryParams })
      .then(handleSuccess)
      .catch(handleError);
  } else {
    // initiate privileged
    return initiatePrivileged({ isSpeculative: false, bookingData: offerData, bodyParams, queryParams })
      .then(handleSuccess)
      .catch(handleError);
  }
}

export const confirmPayment = orderParams => (dispatch, getState, sdk) => {
  dispatch(confirmPaymentRequest());

  const bodyParams = {
    id: orderParams.transactionId,
    transition: TRANSITION_CONFIRM_PAYMENT,
    params: {},
  };

  return sdk.transactions
    .transition(bodyParams)
    .then(response => {
      const order = response.data.data;
      dispatch(confirmPaymentSuccess(order.id));
      return order;
    })
    .catch(e => {
      dispatch(confirmPaymentError(storableError(e)));
      const transactionIdMaybe = orderParams.transactionId
        ? { transactionId: orderParams.transactionId.uuid }
        : {};
      log.error(e, 'initiate-order-failed', {
        ...transactionIdMaybe,
      });
      throw e;
    });
};

export const sendMessage = params => (dispatch, getState, sdk) => {
  const message = params.message;
  const orderId = params.id;

  if (message) {
    return sdk.messages
      .send({ transactionId: orderId, content: message })
      .then(() => {
        return { orderId, messageSuccess: true };
      })
      .catch(e => {
        log.error(e, 'initial-message-send-failed', { txId: orderId });
        return { orderId, messageSuccess: false };
      });
  } else {
    return Promise.resolve({ orderId, messageSuccess: true });
  }
};

/**
 * Initiate or transition the speculative transaction with the given
 * booking details
 *
 * The API allows us to do speculative transaction initiation and
 * transitions. This way we can create a test transaction and get the
 * actual pricing information as if the transaction had been started,
 * without affecting the actual data.
 *
 * We store this speculative transaction in the page store and use the
 * pricing info for the booking breakdown to get a proper estimate for
 * the price with the chosen information.
 */
export const speculateTransaction = (orderParams, transactionId) => (dispatch, getState, sdk) => {
  dispatch(speculateTransactionRequest());

  // If we already have a transaction ID, we should transition, not
  // initiate.
  const isTransition = !!transactionId;
  const transition = isTransition
    ? TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY
    : TRANSITION_REQUEST_PAYMENT;
  const isPrivilegedTransition = isPrivileged(transition);

  const bookingData = {
    offer: orderParams.offer,
    lineItems: orderParams.lineItems
  };

  const params = {
    ...orderParams,
    cardToken: 'CheckoutPage_speculative_card_token',
  };

  const bodyParams = isTransition
    ? {
        id: transactionId,
        transition,
        params,
      }
    : {
        processAlias: config.bookingProcessAlias,
        transition,
        params,
      };

  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };

  const handleSuccess = response => {
    const entities = denormalisedResponseEntities(response);
    if (entities.length !== 1) {
      throw new Error('Expected a resource in the speculate response');
    }
    const tx = entities[0];
    dispatch(speculateTransactionSuccess(tx));
  };

  const handleError = e => {
    // const { listingId, bookingStart, bookingEnd } = params;
    const { listingId } = params;

    log.error(e, 'speculate-transaction-failed', {
      listingId: listingId.uuid,
    });
    return dispatch(speculateTransactionError(storableError(e)));
  };

  if (isTransition && isPrivilegedTransition) {
    // transition privileged
    return transitionPrivileged({ isSpeculative: true, bookingData, bodyParams, queryParams })
      .then(handleSuccess)
      .catch(handleError);
  } else if (isTransition) {
    // transition non-privileged
    return sdk.transactions
      .transitionSpeculative(bodyParams, queryParams)
      .then(handleSuccess)
      .catch(handleError);
  } else if (isPrivilegedTransition) {
    // initiate privileged
    return initiatePrivileged({ isSpeculative: true, bookingData, bodyParams, queryParams })
      .then(handleSuccess)
      .catch(handleError);
  } else {
    // initiate non-privileged
    return sdk.transactions
      .initiateSpeculative(bodyParams, queryParams)
      .then(handleSuccess)
      .catch(handleError);
  }
};

// StripeCustomer is a relantionship to currentUser
// We need to fetch currentUser with correct params to include relationship
export const stripeCustomer = () => (dispatch, getState, sdk) => {
  dispatch(stripeCustomerRequest());

  return dispatch(fetchCurrentUser({ include: ['stripeCustomer.defaultPaymentMethod'] }))
    .then(response => {
      dispatch(stripeCustomerSuccess());
    })
    .catch(e => {
      dispatch(stripeCustomerError(storableError(e)));
    });
};

export const saveShippingAddress = params => (dispatch, getState, sdk) => {
  dispatch(saveShippingAddressRequest());

  return sdk.currentUser
    .updateProfile(
      {
        publicData: { shippingAddress: params }
      },
      {
        expand: true,
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      }
    )
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.changeEmail response');
      }

      const currentUser = entities[0];
      dispatch(currentUserShowSuccess(currentUser));
      dispatch(saveShippingAddressSuccess());
    })
    .catch(e => {
      dispatch(saveShippingAddressError(storableError(e)));
      throw e;
    })
};

export const updateCurrentBiddingData = ({listingId, userId, offerPrice, transactionId}) => (dispatch, getState, sdk) => {
  dispatch(updateBiddingDataRequest());
  return updateBiddingData({listingId, userId, offerPrice, transactionId})
    .then((response) => {
      const { currentListing, currentUser } = response.data.data;
      dispatch(updateBiddingDataSuccess());
      notifyNewOffer({ transactionId, currentBidderId: userId.uuid });
      dispatch(addMarketplaceEntities(currentListing));
      dispatch(addMarketplaceEntities(currentUser));
    })
    .catch(e => {
      dispatch(updateBiddingDataError(storableError(e)));
      log.error(e, 'update-bidding-data-failed', {listingId, userId, offerPrice});
    })
};

export const createStripePaymentIntent = ({ transactionId, type }) => (dispatch, getState, sdk) => {
  dispatch(createPaymentIntentRequest());
  return createPaymentIntent({ transactionId, type })
    .then((response) => {
      dispatch(addMarketplaceEntities(response.data));
      const entities = denormalisedResponseEntities(response.data);
      
      return entities[0];
    })
    .catch(e => {
      dispatch(createPaymentIntentError(storableError(e)));
      log.error(e, 'create-stripe-payment-intent-failed', { transactionId });
    })
};
